/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { CHECK_LIST_FRAGMENT, CheckListFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const CHECK_LIST = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CheckList {
        ...CheckListFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
`;

export type CheckListQuery = { node: CheckListFragment };

export const useCheckListQuery = (id: string) => {
  const { data, loading } = useQuery<CheckListQuery>(CHECK_LIST, {
    variables: { id },
  });

  return { checkList: data?.node, isLoading: loading };
};

export const useCheckListLazyQuery = () => {
  const [getCheckListQuery, { data, loading, called }] = useLazyQuery<
    CheckListQuery,
    QueryNodeArgs
  >(CHECK_LIST);

  const resolveRef = useRef<(checkList?: CheckListFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getCheckList = async (id: string) => {
    getCheckListQuery({ variables: { id } });

    return new Promise<CheckListFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getCheckList,
    checkList: data?.node,
    isLoading: loading,
  };
};
