/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";

const REJECT_JOB_APPLICATION = gql`
  mutation ($id: ID!) {
    rejectJobApplication(input: { id: $id }) {
      jobApplication {
        ...JobApplicationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
  errors: ErrorFragment[];
}

interface RejectJobApplicationMutation {
  rejectJobApplication: Payload | null;
}

export const useRejectJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    RejectJobApplicationMutation,
    { id: string; freelanceProfileId?: string }
  >(REJECT_JOB_APPLICATION);

  const rejectJobApplication = (
    id: string,
    onSuccess?: (jobApplication: JobApplicationFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.rejectJobApplication?.errors ?? [];
        const jobApplication = data?.rejectJobApplication?.jobApplication;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && jobApplication && onSuccess(jobApplication),
          onRetry: () => rejectJobApplication(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    rejectJobApplication,
    isLoading: loading,
    jobApplication: data?.rejectJobApplication?.jobApplication,
    errors: data?.rejectJobApplication?.errors ?? [],
  };
};
