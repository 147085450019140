/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { FREELANCE_PROFILES, FreelanceProfilesQuery } from "graphql/queries";

const UNARCHIVE_FREELANCE_PROFILE = gql`
  mutation ($id: ID!) {
    unarchiveFreelanceProfile(input: { id: $id }) {
      freelanceProfile {
        ...FreelanceProfileFragment
      }
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
`;

interface Payload {
  freelanceProfile: FreelanceProfileFragment;
}

interface UnarchiveFreelanceProfileMutation {
  unarchiveFreelanceProfile: Payload | null;
}

export const useUnarchiveFreelanceProfileMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    UnarchiveFreelanceProfileMutation,
    { id: string }
  >(UNARCHIVE_FREELANCE_PROFILE);

  const unarchiveFreelanceProfile = (id: string) =>
    mutation({
      variables: { id },
      update(cache, { data: unarchivedProfileData }) {
        cache.modify({
          fields: {
            freelanceProfiles() {
              const unarchivedProfile =
                unarchivedProfileData?.unarchiveFreelanceProfile
                  ?.freelanceProfile;
              if (!unarchivedProfile) return;

              const existingFreelanceProfiles =
                cache.readQuery<FreelanceProfilesQuery>({
                  query: FREELANCE_PROFILES,
                });
              const profiles =
                existingFreelanceProfiles?.freelanceProfiles.items;
              if (!profiles) return;

              const isArchivedProfiles = Boolean(profiles[0].archivedAt);

              const items = isArchivedProfiles
                ? profiles.filter((profile) => profile?.id !== id)
                : [...profiles, unarchivedProfile];
              const total = isArchivedProfiles
                ? existingFreelanceProfiles.freelanceProfiles.total - 1
                : existingFreelanceProfiles.freelanceProfiles.total + 1;

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: { freelanceProfiles: { items, total } },
              });
            },
          },
        });
      },
    }).catch((_error: ApolloError) => null);

  return {
    unarchiveFreelanceProfile,
    isLoading: loading,
    freelanceProfile: data?.unarchiveFreelanceProfile?.freelanceProfile,
  };
};
