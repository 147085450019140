import { useContext } from "react";

import { ClientFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { PopupWrapper } from "../PopupWrapper";
import AddClient from "./AddClient";
import EditClient from "./EditClient";

interface Props {
  hasPrevious: boolean;
  clientOrFreelanceProfileId: ClientFragment | string;
  onSuccess?: (client: ClientFragment) => void;
}

export default ({
  clientOrFreelanceProfileId,
  onSuccess,
  ...props
}: Props): JSX.Element => {
  const formId = "client-creation-form";

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  const closeOnSuccess = (client: ClientFragment) => {
    onSuccess?.(client);

    closeAllPopups();
  };

  const isCreatingNew = typeof clientOrFreelanceProfileId === "string";

  return (
    <PopupWrapper
      {...props}
      title={
        isCreatingNew
          ? t("popup.clientCreation.heading", "Add a new client")
          : t("popup.clientCreation_edit.heading", "Edit client")
      }
      variant="popup"
      footerActions={[
        {
          id: "client_creation-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.clientCreation.cancel", "Cancel"),
        },
        {
          id: "client_creation-save",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.clientCreation.submit", "Submit and cancel"),
        },
      ]}
    >
      {typeof clientOrFreelanceProfileId === "string" ? (
        <AddClient
          formId={formId}
          freelanceProfileId={clientOrFreelanceProfileId}
          onClose={closeAllPopups}
          onSuccess={closeOnSuccess}
        />
      ) : (
        <EditClient
          formId={formId}
          client={clientOrFreelanceProfileId}
          onClose={closeAllPopups}
          onSuccess={closeOnSuccess}
        />
      )}
    </PopupWrapper>
  );
};
