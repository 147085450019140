import { RouteProps } from "react-router-dom";

import { Home, TokenHandler } from "components/app";
import {
  BankIDVerification,
  Benefits,
  CriiptoCallback,
  GateKeeper,
  IdentityVerification,
  Privacy,
  Terms,
} from "components/common/pages";
import { Freelancer } from "components/markdown/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("common","HOME"), element: <Home /> },
    { path: getRoutePath("common","PRIVACY"), element: <Privacy /> },
    { path: getRoutePath("common","TERMS"), element: <Terms /> },
    { path: getRoutePath("common","FREELANCER"), element: <Freelancer /> },
    { path: getRoutePath("common","TOKEN_HANDLER", ":destination"), element: <TokenHandler /> },
    { path: getRoutePath("common","CRIIPTO_CALLBACK"), element: <CriiptoCallback /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("common","GATE_KEEPER"), element: <GateKeeper /> },
    { path: getRoutePath("common","BANK_ID_VERIFICATION"), element: <BankIDVerification /> },
    { path: getRoutePath("common","IDENTITY_VERIFICATION"), element: <IdentityVerification /> },

    { path: getRoutePath("common", "BENEFITS"), element: <Benefits /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "common", routes, loggedInRoutes, loggedOutRoutes };
};
