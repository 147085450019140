import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Input } from "components/common/basic";
import { AccountFragment } from "graphql/fragments";
import { useMembersLazyQuery } from "graphql/queries";
import { useErrorLogger } from "hooks";
import { useTranslation } from "translations";
import { emailValidationSchema } from "utils";
import { tw } from "utils/tw";

interface FormValues {
  email: string;
}

interface Props {
  onSuccess: (data: AccountFragment | string) => void;
}

export default ({ onSuccess }: Props): JSX.Element => {
  const { getMembers } = useMembersLazyQuery();

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    email: emailValidationSchema(t).required(),
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);

  const onSubmit = ({ email }: FormValues) =>
    getMembers({ filter: { search: email } }).then((accounts) =>
      onSuccess(accounts?.length ? accounts[0] : email)
    );

  return (
    <form
      className={tw(
        "w-full",
        "flex",
        "flex-col",
        "md:flex-row",
        "gap-4",
        "my-8"
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={tw("w-full", "md:w-1/3")}>
        <Input
          id="email"
          {...register("email")}
          type="email"
          label={t("popup.teamMember.form.email.label", "Email")}
          placeholder={t("popup.teamMember.form.email.placeholder", "Email")}
          errorMessage={errors.email?.message}
          fullWidth
        />
      </div>

      <div className={tw("pt-6")}>
        <Button
          id="member_popup-check_email"
          type="submit"
          variant="primary"
          size="md"
          disabled={!isValid}
        >
          {t("popup.teamMember.form.checkEmail", "Next")}
        </Button>
      </div>
    </form>
  );
};
