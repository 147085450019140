/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { WB_REPORT_FRAGMENT, WbReportFragment } from "graphql/fragments";

export const WB_REPORT = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on WbReport {
        ...WbReportFragment
      }
    }
  }
  ${WB_REPORT_FRAGMENT}
`;

export type WbReportQuery = {
  node: WbReportFragment;
};

export const useWbReportQuery = (id: string) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<WbReportQuery>(WB_REPORT, {
    variables: { id },
  });

  return { wbReport: data?.node, isLoading, called };
};

export const useWbReportLazyQuery = () => {
  const [getWbReportQuery, { data, loading: isLoading, called }] =
    useLazyQuery<WbReportQuery>(WB_REPORT, {
      fetchPolicy: "network-only",
    });

  const resolveRef = useRef<(wbReport?: WbReportFragment) => void>();

  const wbReport = data?.node;

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(wbReport);
      resolveRef.current = undefined;
    }
  }, [wbReport, called, isLoading]);

  const getWbReport = async (id: string) => {
    const variables = { id };

    getWbReportQuery({ variables });

    return new Promise<WbReportFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getWbReport, wbReport, isLoading };
};
