/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { MEMBERS, MembersQuery, SESSION, SessionQuery } from "graphql/queries";
import { AddAccountMemberInput } from "graphql/types";

const ADD_ACCOUNT_MEMBER = gql`
  mutation ($input: AddAccountMemberInput!) {
    addAccountMember(input: $input) {
      member {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

interface Payload {
  member?: AccountFragment | null;
  errors: ErrorFragment[];
}

export interface AddAccountMemberMutation {
  addAccountMember: Payload | null;
}

export const useAddAccountMemberMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    AddAccountMemberMutation,
    { input: AddAccountMemberInput }
  >(ADD_ACCOUNT_MEMBER);

  const addAccountMember = (
    input: AddAccountMemberInput,
    onSuccess?: (member?: AccountFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache, { data: newMemberData }) {
        cache.modify({
          fields: {
            members() {
              const newMember = newMemberData?.addAccountMember?.member;
              if (!newMember) return;

              const membersQuery = cache.readQuery<MembersQuery>({
                query: MEMBERS,
              });
              if (!membersQuery) return;

              const items = [...(membersQuery.members.items ?? []), newMember];
              const total = membersQuery.members.total + 1;

              cache.writeQuery<MembersQuery>({
                query: MEMBERS,
                data: {
                  members: {
                    items,
                    total,
                  },
                },
              });
            },
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              const counts = sessionQuery.session.account.counts
                ? {
                    ...sessionQuery.session.account.counts,
                    members: sessionQuery.session.account.counts.members + 1,
                  }
                : undefined;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      counts,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.addAccountMember?.errors ?? [];
        const person = data?.addAccountMember?.member ?? undefined;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(person),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    addAccountMember,
    isLoading: loading,
    person: data?.addAccountMember?.member,
    errors: data?.addAccountMember?.errors ?? [],
  };
};
