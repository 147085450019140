import { useContext } from "react";

import { Icon } from "components/common/basic";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PermissionsTable, { Role } from "./PermissionsTable";

export default (): JSX.Element => {
  const { isLg } = useContext(TailwindContext);
  const { t } = useTranslation("account");

  const roles: Role[] = [
    {
      icon: "HiOutlineBriefcase",
      title: t("accountSettings_org.team.roles_team.owner.title", "Owner"),
      accessLevel: 1,
    },
    {
      icon: "HiOutlineDesktopComputer",
      title: t("accountSettings_org.team.roles_team.admin.title", "Admin"),
      accessLevel: 2,
    },
    {
      icon: "HiOutlineUserGroup",
      title: t(
        "accountSettings_org.team.roles_department.manager.title",
        "Manager"
      ),
      accessLevel: 3,
    },

    {
      icon: "HiOutlineUser",
      title: t(
        "accountSettings_org.team.roles_department.member.title",
        "Member"
      ),
      accessLevel: 4,
    },
  ];

  return (
    <div className={tw("space-y-8")}>
      <div className={tw("space-y-4")}>
        <div className={tw("flex", "items-center")}>
          <Icon
            icon="HiOutlineKey"
            size={24}
            margin="mr-2"
            color="text-deepBlue-900"
          />

          <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
            {t("accountSettings_org.team.roles.header", "Roles")}
          </h2>
        </div>
      </div>

      {isLg ? (
        <PermissionsTable roles={roles} />
      ) : (
        roles.map((role, index) => (
          <PermissionsTable key={index} roles={[role]} />
        ))
      )}
    </div>
  );
};
