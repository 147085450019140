import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { Trans } from "translations";
import { tw } from "utils/tw";

export interface Props {
  page: number;
  pages: number;
  goBack: () => void;
  goNext: () => void;
}

export default ({ page, pages, goBack, goNext }: Props): JSX.Element =>
  pages > 1 ? (
    <div className={tw("flex", "justify-center", "items-center", "space-x-4")}>
      <button type="button" onClick={goBack} className={tw("text-blue-900")}>
        <HiChevronLeft size={24} />
      </button>

      <p className={tw("text-sm", "font-semibold", "text-deepBlue-900")}>
        <Trans
          ns="common"
          i18nKey="search.pageNav"
          defaults="{{ page }} of {{ pages }}"
          values={{ page, pages }}
        />
      </p>

      <button type="button" onClick={goNext} className={tw("text-blue-900")}>
        <HiChevronRight size={24} />
      </button>
    </div>
  ) : (
    <div className={tw("h-6")} />
  );
