import { gql } from "@apollo/client";

import { User } from "graphql/types";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    confirmed
    email
    firstName
    lastName
    language
    marketingConsent
    necessaryCookiesConsent
    referrer
    occupation
  }
`;

export interface UserFragment
  extends Pick<
    User,
    | "id"
    | "confirmed"
    | "email"
    | "firstName"
    | "lastName"
    | "language"
    | "marketingConsent"
    | "necessaryCookiesConsent"
    | "referrer"
    | "occupation"
  > {}
