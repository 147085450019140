/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { PHONE_NUMBER_FRAGMENT, PhoneNumberFragment } from "graphql/fragments";

const LAST_USED_PHONE_NUMBER = gql`
  query {
    lastUsedPhoneNumber {
      ...PhoneNumberFragment
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export type LastUsedPhoneNumberQuery = {
  lastUsedPhoneNumber: PhoneNumberFragment;
};

export const useLastUsedPhoneNumberQuery = () => {
  const { data, loading } = useQuery<LastUsedPhoneNumberQuery>(
    LAST_USED_PHONE_NUMBER
  );

  return { phoneNumber: data?.lastUsedPhoneNumber, isLoading: loading };
};

export const useLastUsedPhoneNumberLazyQuery = () => {
  const [getLastUsedPhoneNumberQuery, { data, loading, called }] =
    useLazyQuery<LastUsedPhoneNumberQuery>(LAST_USED_PHONE_NUMBER);

  const resolveRef = useRef<(phoneNumber?: PhoneNumberFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.lastUsedPhoneNumber);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getLastUsedPhoneNumber = async () => {
    getLastUsedPhoneNumberQuery();

    return new Promise<PhoneNumberFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getLastUsedPhoneNumber,
    phoneNumber: data?.lastUsedPhoneNumber,
    isLoading: loading,
  };
};
