import { useState } from "react";

import { LoadingBar } from "components/common/basic";
import { FileUpload } from "components/common/composite";
import { AttachmentFragment } from "graphql/fragments";
import { Base64AttachmentAttributes } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import Attachment from "./Attachment";
import Base64Attachment from "./Base64Attachment";

interface Props {
  files: AttachmentFragment[] | Base64AttachmentAttributes[];
  createFile: (attributes: {
    file: Base64AttachmentAttributes;
    filename: string;
  }) => void;
  destroyFile: (id: string | number) => void;
  shouldDestroyWithoutConfirmation?: boolean;
  // FileUpload props
  illustration: {
    src: string;
    alt: string;
  };
  fileRequirementsText: string;
  validFileTypes?: string[];
  isImageUpload?: boolean;
  allowMultiple?: boolean;
  hasEditAccess?: boolean;
  isFullWidth?: boolean;
}

export default ({
  files,
  createFile,
  destroyFile,
  shouldDestroyWithoutConfirmation,
  hasEditAccess = true,
  isFullWidth = false,
  ...fileUploadProps
}: Props): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false);

  const { t } = useTranslation("common");

  const uploadFiles = (files: File[]) => {
    if (files.length < 1) return;

    setIsCreating(true);

    const promisedAttributes: Promise<{
      file: Base64AttachmentAttributes;
      filename: string;
    }>[] = files.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            if (typeof event.target?.result !== "string") return;

            const attributes = {
              file: { data: event.target.result, filename: file.name },
              filename: file.name,
            };
            resolve(attributes);
          };

          reader.readAsDataURL(file);
        })
    );

    Promise.all(promisedAttributes).then((contents) => {
      const promisedFiles = contents.map((attributes) =>
        createFile(attributes)
      );

      Promise.all(promisedFiles).then(() => setIsCreating(false));
    });
  };

  const isBase64Attachments =
    files.length > 0 && Object.prototype.hasOwnProperty.call(files[0], "data");

  return (
    <div className={tw("flex", "flex-col", "space-y-4")}>
      {hasEditAccess && (
        <FileUpload
          {...fileUploadProps}
          id="document-upload"
          selectFile={uploadFiles}
          clearFile={() => null}
          maxSize={10_000_000}
        />
      )}

      <div
        className={tw("-mr-4", "-mb-4", "flex", "justify-start", "flex-wrap")}
      >
        {isBase64Attachments
          ? (files as Base64AttachmentAttributes[]).map((file, index) => (
              <div
                key={index}
                className={tw("mr-4", "mb-4", { "flex-1": isFullWidth })}
              >
                <Base64Attachment
                  file={file}
                  destroyFile={
                    hasEditAccess ? () => destroyFile(index) : undefined
                  }
                  fallbackImageSrc={fileUploadProps.illustration.src}
                />
              </div>
            ))
          : (files as AttachmentFragment[]).map((file) => (
              <div
                key={file.id}
                className={tw("mr-4", "mb-4", { "flex-1": isFullWidth })}
              >
                <Attachment
                  file={file}
                  filename={file.filename ?? "uploaded file"}
                  destroyFile={
                    hasEditAccess ? () => destroyFile(file.id) : undefined
                  }
                  skipConfirmation={shouldDestroyWithoutConfirmation}
                />
              </div>
            ))}
      </div>

      {isCreating && (
        <LoadingBar
          message={t("attachments.loadingBar.label", "Uploading attachment")}
        />
      )}
    </div>
  );
};
