import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type ValueRecord = Record<string, string>;
type SetValues = (valueRecord: ValueRecord) => void;
type ClearValues = () => void;

export default (
  defaultValues: ValueRecord,
  onChange: (values: ValueRecord) => void
): [ValueRecord, SetValues, ClearValues] => {
  const [searchParams, setSearchParams] = useSearchParams(defaultValues);

  const getNonEmptyValues = (values: ValueRecord) =>
    Object.fromEntries(Object.entries(values).filter(([_key, value]) => value));

  const setValues: SetValues = (valueRecord) =>
    setSearchParams(
      (prev) =>
        getNonEmptyValues({ ...Object.fromEntries(prev), ...valueRecord }),
      { replace: true }
    );

  const clearValues = () => setSearchParams({}, { replace: true });

  useEffect(() => {
    onChange(Object.fromEntries(searchParams));
  }, [searchParams]);

  useEffect(() => {
    if (searchParams) return;

    setValues(defaultValues);
  }, []);

  return [Object.fromEntries(searchParams), setValues, clearValues];
};
