import { BEMultiProps, BESingleProps } from "../shared/types";
import MultiSearch from "./MultiSearch";
import SingleSearch from "./SingleSearch";

type Props<T> = BESingleProps<T> | BEMultiProps<T>;

export default <T,>(props: Props<T>): JSX.Element => {
  if (props.isMultiSelect) return <MultiSearch {...props} />;

  return <SingleSearch {...props} />;
};
