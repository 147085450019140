import { gql } from "@apollo/client";

import { CheckRequest } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { CRC_PURPOSE_FRAGMENT, CrcPurposeFragment } from "./crcPurpose";
import { DEPARTMENT_FRAGMENT, DepartmentFragment } from "./department";
import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const CHECK_REQUEST_FRAGMENT = gql`
  fragment CheckRequestFragment on CheckRequest {
    id
    type
    status
    account {
      ...SimplifiedAccountFragment
    }
    files {
      ...AttachmentFragment
    }
    checkLists {
      id
      email
      name
      alreadyEmployed
      temporaryJob
      jobDescription
      jobRole
      phone
      employedOn
      department {
        ...DepartmentFragment
      }
    }
    contactName
    contactPhoneNumber
    contactEmail
    organizationName
    organizationNumber
    message
    crcPurpose {
      ...CrcPurposeFragment
    }
    previewPurposeLetterPath
    eLearningCourse
    checkTypes
    countries
  }
  ${ATTACHMENT_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
  ${CRC_PURPOSE_FRAGMENT}
`;

export interface CheckRequestFragment
  extends Pick<
    CheckRequest,
    | "id"
    | "type"
    | "status"
    | "files"
    | "contactName"
    | "contactPhoneNumber"
    | "contactEmail"
    | "organizationName"
    | "organizationNumber"
    | "message"
    | "crcPurpose"
    | "previewPurposeLetterPath"
    | "eLearningCourse"
    | "checkTypes"
    | "countries"
  > {
  crcPurpose?: CrcPurposeFragment;
  account: SimplifiedAccountFragment;
  checkLists: {
    id: string;
    email: string;
    name: string;
    alreadyEmployed: boolean;
    temporaryJob: boolean;
    employedOn?: string;
    jobDescription?: string;
    jobRole?: string;
    phone?: string;
    department?: DepartmentFragment;
  }[];
  files: AttachmentFragment[];
}
