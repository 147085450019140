import { PropsWithChildren } from "react";

import { tw } from "utils/tw";

import { PopupContent } from "./PopupContent";
import { PopupContentProps } from "./PopupContent/PopupContent";

interface Props
  extends Omit<PopupContentProps, "isRounded" | "shouldAnimateIn"> {}

export default (contentProps: PropsWithChildren<Props>) => (
  <div
    className={tw(
      "z-50",
      "fixed",
      "top-0",
      "left-0",
      "w-screen",
      "h-screen",
      "overflow-y-auto",
      "overflow-x-hidden",
      "bg-white"
    )}
  >
    <PopupContent {...contentProps} isFullHeight />
  </div>
);
