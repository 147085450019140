import { useContext } from "react";

import { Heading } from "components/account/composite";
import { useBankAccountsByProfileQuery } from "graphql/queries";
import { FreelanceTypeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import EmptyState from "./EmptyState";
import ProfileWithBankAccounts from "./ProfileWithBankAccounts";

export default (): JSX.Element => {
  const { freelanceProfiles, isLoading } = useBankAccountsByProfileQuery({
    perPage: 100,
  });

  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  const openBankAccountPopup = () => openPopup({ id: "AddBankAccount" });

  const hasBankAccounts = freelanceProfiles.some(
    (profile) =>
      profile.bankAccounts.filter(({ deletedAt }) => !deletedAt).length > 0
  );

  return (
    <>
      <Heading
        header={t("bankAccounts.header", "My Bank Accounts")}
        action={{
          type: "click",
          actionID: "bank_accounts_header-add_bank_account",
          onClick: openBankAccountPopup,
        }}
        actionLabel={t("bankAccounts.action.addBankAccount", "Add new")}
      />

      {isLoading ? null : hasBankAccounts ? (
        <div className={tw("mt-12", "flex", "flex-col", "space-y-4")}>
          {freelanceProfiles.map((profile) => {
            const bankAccounts = profile.bankAccounts.filter(
              ({ deletedAt }) => !deletedAt
            );
            if (bankAccounts.length < 1) return null;

            const isOrg =
              profile.freelanceType === FreelanceTypeEnum.Organization;
            const name = isOrg
              ? `${profile.organizationName} (${profile.organizationNumber})`
              : `${profile.firstName} ${profile.lastName}`;

            return (
              <ProfileWithBankAccounts
                key={profile.id}
                name={name}
                bankAccounts={bankAccounts}
                isOrg={isOrg}
              />
            );
          })}
        </div>
      ) : (
        <EmptyState />
      )}
    </>
  );
};
