import { ReactNode, useEffect } from "react";

import {
  Placement,
  ReferenceElement,
  autoUpdate,
  shift,
  useFloating,
} from "@floating-ui/react-dom";

import { PopoverTemplate, Tooltip } from "./popovers";

export interface Popover {
  id: string;
  targetRef: ReferenceElement | null;
  placement: Placement;
  variant?: "default" | "tooltip";
  isShown?: boolean;
  content: ReactNode;
}

export default ({
  targetRef,
  placement,
  variant = "default",
  isShown = false,
  content,
}: Popover) => {
  const floatingReturn = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [shift()],
  });

  useEffect(() => {
    floatingReturn.refs.setReference(targetRef);
  }, []);

  if (variant === "tooltip")
    return (
      <Tooltip
        floatingReturn={floatingReturn}
        placement={placement}
        isShown={isShown}
        content={content}
      />
    );

  return (
    <PopoverTemplate floatingReturn={floatingReturn} isShown={isShown}>
      {content}
    </PopoverTemplate>
  );
};
