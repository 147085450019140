import React, { useContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import Cookie from "js-cookie";

import { Spinner } from "components/common/basic";
import { SessionFragment } from "graphql/fragments";
import { useSessionLazyQuery } from "graphql/queries";
import { AccountTypeEnum } from "graphql/types";
import { storage } from "utils";

import { AnalyticsContext } from "./Analytics";

interface Context {
  isLoggedIn: boolean;
  isPartnerAccount: boolean;
  session?: SessionFragment;
  login: (token: string) => void;
  logout: () => void;
  refresh: () => Promise<SessionFragment | undefined>;
}

export const AuthContext = React.createContext<Context>({
  isLoggedIn: false,
  isPartnerAccount: false,
  session: undefined,
  login: () => null,
  logout: () => null,
  refresh: () => new Promise(() => undefined),
});

interface Props {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [token, setToken] = useState(Cookie.get("dp-token"));

  const { hasConsented, identify, reset, reportEvent } =
    useContext(AnalyticsContext);

  const login = (authToken: string) => setToken(authToken);
  const logout = () => {
    reset();

    setToken(undefined);
  };

  const client = useApolloClient();
  const { isLoading, session, getSession, refetch } = useSessionLazyQuery();

  useEffect(() => {
    // Reset store on load to prevent breaking changes from BE to affect users
    client.resetStore();
  }, []);

  // Get session for the current token
  useEffect(() => {
    if (!token) {
      storage.removeSession("dp-token");
      Cookie.remove("dp-token");
      Cookie.remove("mm-user");
      client.clearStore();

      return;
    }

    storage.setSession("dp-token", token);
    Cookie.set("dp-token", token);
    if (session) client.resetStore();
    else
      setTimeout(() => {
        getSession();
      }, 0);
  }, [token]);

  useEffect(() => {
    // Prevent errors when BE returns a null session
    if (session === null) logout();

    // Set analytics information
    if (session) identify(session.user);
  }, [session, hasConsented]);

  // Save current user id
  useEffect(() => {
    if (!session) return;

    Cookie.set("mm-user", session.user.id);
  }, [session?.user.id]);

  // Ensure that all tabs have the same user
  useEffect(() => {
    const checkUserID = () => {
      const currentToken = Cookie.get("dp-token");
      if (!currentToken) return logout();

      if (!session) return login(currentToken);

      const currentUser = Cookie.get("mm-user");
      if (currentUser !== session.user.id) login(currentToken);
    };

    window.addEventListener("visibilitychange", checkUserID);
    return () => window.removeEventListener("visibilitychange", checkUserID);
  }, [session?.user.id]);

  // Update session on tab change
  useEffect(() => {
    window.addEventListener("visibilitychange", refetch);
    return () => window.removeEventListener("visibilitychange", refetch);
  }, [session?.user.id]);

  const isLoggedIn = Boolean(token);
  const isPartnerAccount =
    session?.account?.accountType === AccountTypeEnum.Team;

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isPartnerAccount,
        session,
        login: (token) => {
          reportEvent("Login");
          login(token);
        },
        logout: () => {
          reportEvent("Logout");
          logout();
        },
        refresh: getSession,
      }}
    >
      {isLoading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};
