import React, { useEffect } from "react";
import { IconType } from "react-icons";

import { tw } from "utils/tw";

export interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  id: string;
  name: string;
  label: string | JSX.Element;
  hint?: string;
  helpText?: string;
  errorMessage?: string;
  hideLabel?: boolean;
  isAutoSized?: boolean;
  rows?: number;
  trailingAction?: {
    action: () => void;
    Icon: IconType;
  };
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      hint,
      helpText,
      errorMessage,
      hideLabel = false,
      isAutoSized = false,
      rows = isAutoSized ? 1 : undefined,
      trailingAction,
      ...props
    }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText && `${id}-help`;
    const errorId = hasError && `${id}-error`;
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

    const labelContainerStyles = tw("flex", "justify-between");

    const labelStyles = tw("block", "text-sm", "text-gray-700", {
      "sr-only": hideLabel,
    });

    const hintStyles = tw("text-sm", "text-gray-500");

    const inputContainerStyles = tw("mt-1", "relative");

    const inputBase = tw(
      "w-full",
      "rounded-md",
      "shadow-sm",
      "shadow-sm",
      "sm:text-sm",
      "rounded-md",
      "focus:ring-gray-900",
      "focus:border-gray-900",
      "border-gray-300"
    );
    const inputError = tw("border-2", "border-error", "bg-error-light");
    const inputDisabled = tw("bg-gray-100", "text-gray-700");
    const inputAutoSized = tw("resize-none", "max-h-40", "md:max-h-72");
    const inputStyles = tw(inputBase, {
      [inputError as string]: hasError,
      [inputDisabled as string]: props.disabled || props.readOnly,
      [inputAutoSized as string]: isAutoSized,
      "pr-10": Boolean(trailingAction),
    });

    const iconWrapperStyles = tw(
      "absolute",
      "top-0",
      "right-0",
      "p-3",
      "pt-2",
      "inset-y-0",
      "self-start",
      "cursor-pointer",
      "hover:opacity-90"
    );
    const iconStyles = tw("h-5", "w-5", {
      "text-gray-700": !hasError,
      "text-error": hasError,
    });

    const textBase = tw("mt-2", "text-sm");
    const helpTextStyles = tw(textBase, "text-gray-700");
    const errorMessageStyles = tw(textBase, "text-error");

    const resizeElement = (element: HTMLElement) => {
      if (isAutoSized) {
        element.style.height = "0";
        element.style.height = `${element.scrollHeight + 2}px`;
      }
    };

    // Set textarea size on cleared value
    useEffect(() => {
      if (props.value) return;

      const textareaElement = document.getElementById(id);
      if (textareaElement?.style.height) resizeElement(textareaElement);
    }, [props.value]);

    return (
      <div>
        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {hint && <span className={hintStyles}>{hint}</span>}
        </div>

        <div className={inputContainerStyles}>
          <textarea
            {...props}
            id={id}
            ref={ref}
            name={name}
            className={inputStyles}
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
            onInput={(event) => {
              resizeElement(event.currentTarget);

              props.onInput?.(event);
            }}
            rows={rows}
          />

          {trailingAction && (
            <button
              type="button"
              className={iconWrapperStyles}
              onClick={trailingAction.action}
            >
              <trailingAction.Icon className={iconStyles} />
            </button>
          )}
        </div>

        {helpTextId && (
          <p className={helpTextStyles} id={helpTextId}>
            {helpText}
          </p>
        )}

        {errorId && (
          <p className={errorMessageStyles} id={errorId}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
