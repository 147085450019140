/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { QuerySuppliersArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const SUPPLIERS = gql`
  query (
    $page: Int
    $perPage: Int
    $sortBy: FreelanceProfilesSortByEnum
    $filter: FreelanceProfilesFilter
  ) {
    suppliers(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      filter: $filter
    ) {
      exportPath
      items {
        ...FreelanceProfileFragment
      }
      total
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
`;

export type SuppliersQuery = {
  suppliers: PaginatedPayload<FreelanceProfileFragment>;
};

type QueryArgs = Pick<
  QuerySuppliersArgs,
  "page" | "perPage" | "sortBy" | "filter"
>;

export const useSuppliersQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<SuppliersQuery, QueryArgs>(
    SUPPLIERS,
    { variables: { ...args } }
  );

  const suppliers = data?.suppliers.items?.filter(Boolean) ?? [];

  return {
    suppliers,
    isLoading,
  };
};

export const useSuppliersLazyQuery = () => {
  const [getSuppliersQuery, { data, loading: isLoading, called }] =
    useLazyQuery<SuppliersQuery, QueryArgs>(SUPPLIERS, {
      fetchPolicy: "network-only",
    });

  const suppliers = data?.suppliers.items?.filter(Boolean) ?? [];
  const total = data?.suppliers.total ?? 0;

  const resolveRef = useRef<(suppliers?: FreelanceProfileFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(suppliers);
      resolveRef.current = undefined;
    }
  }, [suppliers, called, isLoading]);

  const getSuppliers = async (args?: QueryArgs) => {
    const variables = { ...args };

    getSuppliersQuery({ variables });

    return new Promise<FreelanceProfileFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getSuppliers, suppliers, total, isLoading, called };
};
