import { tw } from "utils/tw";

import MenuItemComponent from "./MenuItem";
import { MenuItem } from "./types";

interface Props {
  menuItems: MenuItem[];
  iconsOnly?: boolean;
  isSubmenu?: boolean;
  closeSelf?: () => void;
  marginLeft?: "ml-5" | "ml-7";
}

export default ({
  menuItems,
  iconsOnly,
  isSubmenu,
  closeSelf,
  marginLeft = "ml-7",
}: Props): JSX.Element => {
  const submenuStyles = tw("px-2", "mb-4", {
    [tw(marginLeft, "border-l", "border-gray-100")]: !iconsOnly,
  });
  const listStyles = tw(
    "space-y-1",
    "max-w-full",
    isSubmenu ? submenuStyles : tw("p-4", "w-full")
  );

  return (
    <ul
      className={listStyles}
      // Prevents visual issue where focus is lost when clicking padding
      tabIndex={-1}
    >
      {menuItems.map((item, index) => (
        <MenuItemComponent
          key={index}
          item={item}
          closeSelf={closeSelf}
          iconsOnly={iconsOnly}
        />
      ))}
    </ul>
  );
};
