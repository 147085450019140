import { gql } from "@apollo/client";

import { DepartmentMembership } from "graphql/types";

import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const DEPARTMENT_MEMBERSHIP_FRAGMENT = gql`
  fragment DepartmentMembershipFragment on DepartmentMembership {
    id
    criminalRecordAccess
    identityAccess
    role
    department {
      id
      name
      description
    }
    account {
      ...SimplifiedAccountFragment
    }
  }
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
`;

export interface DepartmentMembershipFragment
  extends Pick<
    DepartmentMembership,
    "id" | "criminalRecordAccess" | "identityAccess" | "role"
  > {
  department: {
    id: string;
    name: string;
    description?: string;
  };
  account: SimplifiedAccountFragment;
}
