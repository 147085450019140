import { gql } from "@apollo/client";

import { Attachment } from "graphql/types";

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    id
    contentType
    downloadPath
    thumbPath
    filename
  }
`;

export interface AttachmentFragment
  extends Pick<
    Attachment,
    "id" | "contentType" | "downloadPath" | "thumbPath" | "filename"
  > {}
