import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "components/common/basic";
import { useSwitchAccountMutation } from "graphql/mutations";
import { AuthContext } from "providers/Authentication";
import { NameSpace } from "translations/shared";
import {
  unspecifiedSlugString,
  useDefaultRoutePath,
  useRoutePaths,
} from "utils";

import NSRoute from "./NSRoute";

interface Props {
  isLoggedInRoute: boolean;
  nameSpace?: NameSpace;
}

export default ({ isLoggedInRoute, nameSpace }: Props): JSX.Element => {
  const [verifiedPathname, setVerifiedPathname] = useState("");

  const { isLoggedIn, session, login } = useContext(AuthContext);
  const { switchAccount } = useSwitchAccountMutation();
  const navigate = useNavigate();

  const defaultRoutePath = useDefaultRoutePath();
  const getRoutePath = useRoutePaths();
  const { pathname } = useLocation();
  const { slug: expectedAccountSlug } = useParams();

  useEffect(() => {
    if (isLoggedInRoute !== isLoggedIn) return navigate(defaultRoutePath);
    else if (isLoggedIn) {
      if (!session) return;

      // Should not redirect from certain paths or for administrators
      const isWizard = pathname.includes(
        getRoutePath({
          module: "checkmore",
          routeName: "CHECK_WIZARD",
          arg1: "",
        })
      );
      const isCriipto = pathname.includes(
        getRoutePath(
          { module: "common", routeName: "CRIIPTO_CALLBACK" },
          { isGeneric: true }
        )
      );
      const isAdmin = session.administrator;
      const shouldSkipActions = isAdmin || isWizard || isCriipto;

      // Check that there are no required actions pending
      if (isLoggedInRoute && !shouldSkipActions) {
        // const hasReferrer = typeof session.user.referrer === "string";
        // const hasOccupation = typeof session.user.occupation === "string";
        const isConfirmed = session.user.confirmed === true;
        if (
          // !hasReferrer || !hasOccupation ||
          !isConfirmed
        ) {
          const gateKeeperPath = getRoutePath({
            module: "common",
            routeName: "GATE_KEEPER",
          });
          const isGateKeeper = pathname === gateKeeperPath;
          if (!isGateKeeper) return navigate(gateKeeperPath);
        } else if (session.needsOnboarding) {
          const onboardingPath = getRoutePath({
            module: "checkmore",
            routeName: "ONBOARDING_WIZARD",
          });
          const isOnboarding = pathname.includes(onboardingPath);

          if (!isOnboarding) return navigate(onboardingPath);
        }
      }

      // Check that the correct account is selected
      const isAcceptedAccount =
        expectedAccountSlug === unspecifiedSlugString ||
        expectedAccountSlug === session.account.slug;
      if (expectedAccountSlug && !isAcceptedAccount) {
        const availableAccount = session.accounts.find(
          ({ slug }) => slug === expectedAccountSlug
        );

        if (!availableAccount) return navigate(defaultRoutePath);

        switchAccount({ id: availableAccount.id }, login);
        return;
      }

      const scheduledDeletionPath = getRoutePath({
        module: "account",
        routeName: "SCHEDULED_DELETION",
      });
      const isScheduledDeletionPath = pathname === scheduledDeletionPath;
      if (session.account.deletionScheduledOn && !isScheduledDeletionPath)
        return navigate(scheduledDeletionPath);
    }

    setVerifiedPathname(pathname);
  }, [isLoggedInRoute, pathname, isLoggedIn, session]);

  // Prevent blinking when navigating within the same base route
  const overviewPath = getRoutePath({
    module: "account",
    routeName: "OVERVIEW",
  });
  const isSubRouteNavigation =
    verifiedPathname.includes(overviewPath) && pathname.includes(overviewPath);

  if (!isSubRouteNavigation && pathname !== verifiedPathname)
    return <Spinner />;

  if (nameSpace) return <NSRoute nameSpace={nameSpace} />;

  return <Outlet />;
};
