/* eslint-disable @typescript-eslint/ban-types */
interface Deferred {
  promise?: Promise<Function | undefined>;
  resolve?: (value?: Function | PromiseLike<Function> | undefined) => void;
  reject?: (reason?: unknown) => void;
}

type DebouncedPromise = (
  ...args: unknown[]
) => Promise<Function | undefined> | undefined;
export default (fn: Function, delay: number): DebouncedPromise => {
  let deferred: Deferred | null;
  let timer: NodeJS.Timeout;

  return (...args: unknown[]) => {
    if (deferred) clearTimeout(timer);
    else deferred = defer();

    timer = setTimeout(() => {
      clearTimeout(timer);

      Promise.resolve(fn(...args))
        .then(deferred?.resolve, deferred?.reject)
        .catch(console.error)
        .then(() => (deferred = null));
    }, delay);

    return deferred.promise;
  };
};

const defer = () => {
  const deferred: Deferred = {};
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
};
