import { RouteProps } from "react-router-dom";

import { Purchases } from "components/invoices/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("payoutmore", "PURCHASES"), element: <Purchases /> },
    { path: getRoutePath("payoutmore", "PURCHASES_ALL"), element: <Purchases /> },
    { path: getRoutePath("payoutmore", "PURCHASES_BY_SUPPLIER"), element: <Purchases /> },
    { path: getRoutePath("payoutmore", "PURCHASES_REFUNDS"), element: <Purchases /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "invoices", routes, loggedInRoutes, loggedOutRoutes };
};
