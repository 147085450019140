import { useState } from "react";

import { LoadingBar } from "components/common/basic";
import { FileUpload } from "components/common/composite";
import { DocumentFileFragment } from "graphql/fragments";
import { useCreateDocumentFileMutation } from "graphql/mutations";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import FileUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";

import Attachment from "./Attachment";

interface Props {
  documentFiles: DocumentFileFragment[];
  documentId?: string;
  shouldDestroyWithoutConfirmation?: boolean;
}

export default ({
  documentFiles,
  documentId,
  shouldDestroyWithoutConfirmation,
}: Props): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false);

  const { createDocumentFile } = useCreateDocumentFileMutation();

  const { t } = useTranslation("documents");

  const uploadFiles = (files: File[]) => {
    if (files.length < 1) return;

    setIsCreating(true);

    const promisedAttributes: Promise<{
      file: { data: string };
      name: string;
    }>[] = files.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            if (typeof event.target?.result !== "string") return;

            const attributes = {
              file: { data: event.target.result },
              name: file.name,
            };
            resolve(attributes);
          };

          reader.readAsDataURL(file);
        })
    );

    Promise.all(promisedAttributes).then((contents) => {
      const promisedFiles = contents.map((attributes) =>
        createDocumentFile({ documentId, attributes })
      );

      Promise.all(promisedFiles).then(() => setIsCreating(false));
    });
  };

  return (
    <div className={tw("flex", "flex-col", "space-y-4")}>
      <FileUpload
        id="document-upload"
        selectFile={uploadFiles}
        clearFile={() => null}
        illustration={{
          src: FileUploadIllustration,
          alt: t(
            "createDocument.uploadFiles.illustration.alt",
            "File attachment"
          ),
        }}
        validFileTypes={["application/pdf"]}
        fileRequirementsText={t(
          "createDocument.uploadFiles.validFileTypes",
          "Files with .pdf extension"
        )}
      />

      <div
        className={tw("-mr-4", "-mb-4", "flex", "justify-start", "flex-wrap")}
      >
        {documentFiles.map((file) => (
          <div key={file.id} className={tw("mr-4", "mb-4")}>
            <Attachment
              documentFile={file}
              isDestructible
              skipConfirmation={shouldDestroyWithoutConfirmation}
            />
          </div>
        ))}
      </div>

      {isCreating && (
        <LoadingBar
          message={t(
            "createDocument.uploadFiles.loadingBar.label",
            "Uploading attachment"
          )}
        />
      )}
    </div>
  );
};
