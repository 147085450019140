/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_FRAGMENT,
  CheckFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CHECK_LIST, CheckListQuery } from "graphql/queries";
import { CheckAttributes } from "graphql/types";

const SUBMIT_CHECK = gql`
  mutation ($id: ID!, $attributes: CheckAttributes) {
    submitCheck(input: { id: $id, attributes: $attributes }) {
      check {
        ...CheckFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  check?: CheckFragment | null;
  errors: ErrorFragment[];
}

interface SubmitCheckMutation {
  submitCheck: Payload | null;
}

export const useSubmitCheckMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    SubmitCheckMutation,
    { id: string; attributes?: CheckAttributes }
  >(SUBMIT_CHECK);

  const submitCheck = ({
    id,
    attributes,
    onSuccess,
  }: {
    id: string;
    attributes?: CheckAttributes;
    onSuccess?: (check?: CheckFragment) => void;
  }) =>
    mutation({
      variables: { id, attributes },
      update(cache, { data }) {
        cache.modify({
          fields: {
            node() {
              const updatedCheckStatus = data?.submitCheck?.check?.status;
              if (!updatedCheckStatus) return;

              const existingCheckList = cache.readQuery<CheckListQuery>({
                query: CHECK_LIST,
              });
              const checkListNode = existingCheckList?.node;
              if (!checkListNode) return;

              const updatedChecks = checkListNode.checks.map((check) =>
                check.id === id
                  ? { ...check, status: updatedCheckStatus }
                  : check
              );

              cache.writeQuery<CheckListQuery>({
                query: CHECK_LIST,
                data: { node: { ...checkListNode, checks: updatedChecks } },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitCheck?.errors ?? [];
        const check = data?.submitCheck?.check;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(check ?? undefined),
          onRetry: () => submitCheck({ id, attributes, onSuccess }),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    submitCheck,
    isLoading: loading,
    called,
    check: data?.submitCheck?.check,
    errors: data?.submitCheck?.errors ?? [],
  };
};
