/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { JOB_FRAGMENT, JobFragment } from "graphql/fragments";
import { QueryPartnerJobsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const PARTNER_JOBS = gql`
  query ($page: Int, $perPage: Int) {
    partnerJobs(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...JobFragment
      }
      total
    }
  }
  ${JOB_FRAGMENT}
`;

export type PartnerJobsQuery = {
  partnerJobs: PaginatedPayload<JobFragment>;
};

type QueryArgs = Pick<QueryPartnerJobsArgs, "page" | "perPage">;

export const usePartnerJobsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<PartnerJobsQuery, QueryArgs>(
    PARTNER_JOBS,
    { variables: { ...args } }
  );

  const partnerJobs = data?.partnerJobs.items?.filter(Boolean) ?? [];
  const total = data?.partnerJobs.total ?? 0;

  return { partnerJobs, isLoading, total };
};

export const usePartnerJobsLazyQuery = () => {
  const [getPartnerJobsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PartnerJobsQuery, QueryArgs>(PARTNER_JOBS, {
      fetchPolicy: "network-only",
    });

  const partnerJobs = data?.partnerJobs.items?.filter(Boolean) ?? [];
  const total = data?.partnerJobs.total ?? 0;

  const resolveRef = useRef<(jobs?: JobFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(partnerJobs);
      resolveRef.current = undefined;
    }
  }, [partnerJobs, total, called, isLoading]);

  const getPartnerJobs = async (args: QueryArgs) => {
    const variables = { ...args };

    getPartnerJobsQuery({ variables });

    return new Promise<JobFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getPartnerJobs, partnerJobs, total, called, isLoading };
};
