import { useContext } from "react";
import { IconType } from "react-icons";

import { Button, CheckBox } from "components/common/basic";
import { ActionMenu } from "components/common/composite";
import { TailwindContext } from "providers/Tailwind";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  shown: number;
  total: number;
  selectedAmount: number;
  isAllSelected: boolean;
  clearSelection: () => void;
  selectPage: () => void;
  selectAll: () => void;
  actions: {
    icon: IconType;
    label: string;
    onClick: () => void;
    isDestructive?: boolean;
  }[];
}

export default ({
  shown,
  total,
  actions,
  selectedAmount,
  isAllSelected,
  clearSelection,
  selectPage,
  selectAll,
}: Props): JSX.Element => {
  const hasSelected = selectedAmount > 0;
  const hasMultiplePages = total > shown;

  const { isLg } = useContext(TailwindContext);
  const { t } = useTranslation("common");

  const isDisabled = actions.length === 0;

  return (
    <div
      className={tw(
        "px-4",
        "pb-6",
        "bg-silver",
        "flex",
        "justify-between",
        "items-end"
      )}
    >
      <div className={tw("flex", "space-x-4")}>
        <ActionMenu
          id="action-bar"
          isDisabled={isDisabled}
          hasDynamicMenuItems
          menuItems={[
            ...(hasSelected
              ? [
                  {
                    id: "action_bar-clear_selection" as const,
                    label: t(
                      "actionBar.bulkSelect.option.clearSelection",
                      "Clear selection"
                    ),
                    onClick: clearSelection,
                  },
                ]
              : []),
            {
              id: "action_bar-select_page",
              label: t(
                "actionBar.bulkSelect.option.selectPage",
                "Select this page"
              ),
              onClick: selectPage,
            },
            ...(hasMultiplePages
              ? [
                  {
                    id: "action_bar-select_all" as const,
                    label: t(
                      "actionBar.bulkSelect.option.selectAll",
                      "Select all"
                    ),
                    onClick: selectAll,
                  },
                ]
              : []),
          ]}
          label={
            <div className={tw("flex", "items-center")}>
              <CheckBox
                id=""
                name=""
                label=""
                isNonClickable
                checked={hasSelected}
                disabled={isDisabled}
              />

              {isDisabled && (
                <span>
                  {t("actionBar.bulkSelect.disabled", "Not available")}
                </span>
              )}

              {hasSelected && (
                <span>
                  {t(
                    "actionBar.bulkSelect.selectedAmount",
                    "{{ amount }} selected",
                    { amount: selectedAmount }
                  )}
                </span>
              )}
            </div>
          }
          alignToSide="left"
        />

        {hasSelected &&
          actions.map((action) => {
            if (action.isDestructive && isAllSelected) return null;

            return (
              <Button
                key={action.label}
                id="action_bar-perform_action"
                size="sm"
                variant={action.isDestructive ? "danger" : "tertiary"}
                onClick={action.onClick}
                LeadingIcon={isLg ? action.icon : undefined}
              >
                {isLg ? action.label : <action.icon size={16} />}
              </Button>
            );
          })}
      </div>

      <p className={tw("text-deepBlue-500", "text-end")}>
        <Trans
          ns="common"
          i18nKey="actionBar.resultsShown"
          defaults="Showing <b>{{ shown }}</b> of <b>{{ total }} results</b>"
          values={{ shown, total }}
          components={{
            b: <span className={tw("text-deepBlue-900", "font-semibold")} />,
          }}
        />
      </p>
    </div>
  );
};
