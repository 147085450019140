/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  PUBLIC_WB_REPORT_FRAGMENT,
  PublicWbReportFragment,
} from "graphql/fragments";
import { QueryWbReportArgs } from "graphql/types";

export const PUBLIC_WB_REPORT = gql`
  query ($wbSlug: String!, $accessCode: String!, $reportNumber: String) {
    wbReport(
      wbSlug: $wbSlug
      accessCode: $accessCode
      reportNumber: $reportNumber
    ) {
      ...PublicWbReportFragment
    }
  }
  ${PUBLIC_WB_REPORT_FRAGMENT}
`;

export type PublicWbReportQuery = {
  wbReport: PublicWbReportFragment;
};

type QueryArgs = Pick<
  QueryWbReportArgs,
  "wbSlug" | "accessCode" | "reportNumber"
>;

export const usePublicWbReportQuery = (args: QueryArgs) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<PublicWbReportQuery, QueryArgs>(PUBLIC_WB_REPORT, {
    variables: { ...args },
  });

  return { wbReport: data?.wbReport, isLoading, called };
};

export const usePublicWbReportLazyQuery = () => {
  const [getPublicWbReportQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PublicWbReportQuery, QueryArgs>(PUBLIC_WB_REPORT, {
      fetchPolicy: "network-only",
    });

  const resolveRef = useRef<(wbReport?: PublicWbReportFragment) => void>();

  const wbReport = data?.wbReport;

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(wbReport);
      resolveRef.current = undefined;
    }
  }, [wbReport, called, isLoading]);

  const getWbReport = async (args: QueryArgs) => {
    const variables = { ...args };

    getPublicWbReportQuery({ variables });

    return new Promise<PublicWbReportFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getWbReport, wbReport, isLoading, called };
};
