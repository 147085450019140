import { useCallback, useRef, useState } from "react";

export const useDialog = (
  verifyErrorShouldTriggerDialog: (errorMessage: string) => boolean
): {
  reject: () => void;
  resolve: () => void;
  isOpen: boolean;
  handleError: (errorCode: string) => Promise<unknown>;
} => {
  const resolveRef = useRef<unknown>();
  const rejectRef = useRef<unknown>();
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useCallback(() => {
    setIsOpen(false);

    if (resolveRef && typeof resolveRef.current === "function")
      resolveRef.current();
  }, []);

  const reject = useCallback(() => {
    setIsOpen(false);

    if (rejectRef && typeof rejectRef.current === "function")
      rejectRef.current();
  }, []);

  const handleError = useCallback(
    (errorCode: string) =>
      new Promise((resolve, reject) => {
        if (!verifyErrorShouldTriggerDialog(errorCode)) {
          reject(errorCode);

          return;
        }

        resolveRef.current = resolve;
        rejectRef.current = () => reject(errorCode);

        setIsOpen(true);
      }),
    [verifyErrorShouldTriggerDialog]
  );

  return {
    reject,
    resolve,
    isOpen,
    handleError,
  };
};
