import { Marked } from "@ts-stack/markdown";

import { Page } from "components/common/wrappers";
import { tw } from "utils/tw";

interface Props {
  title: string;
  markdownText: string;
}

export default ({ title, markdownText }: Props): JSX.Element => (
  <Page title={title}>
    <section
      className={tw("mx-auto", "max-w-screen-md")}
      dangerouslySetInnerHTML={{ __html: Marked.parse(markdownText) }}
    />
  </Page>
);
