/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useQuery } from "@apollo/client";

import {
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";
import { QueryJobApplicationsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const JOB_APPLICATIONS = gql`
  query ($page: Int, $perPage: Int) {
    jobApplications(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...JobApplicationFragment
      }
      total
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
`;

export type JobApplicationsQuery = {
  jobApplications: PaginatedPayload<JobApplicationFragment>;
};

type QueryArgs = Pick<QueryJobApplicationsArgs, "page" | "perPage">;

export const useJobApplicationsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<
    JobApplicationsQuery,
    QueryArgs
  >(JOB_APPLICATIONS, {
    variables: { ...args },
  });

  const jobApplications = data?.jobApplications.items?.filter(Boolean) ?? [];

  return { jobApplications, isLoading };
};
