import { tw } from "utils/tw";

interface Props {
  heading: string;
  body: string;
}

export default ({ heading, body }: Props): JSX.Element => (
  <div className={tw("space-y-2")}>
    <h2 className={tw("text-2xl", "font-extrabold", "text-gray-900")}>
      {heading}
    </h2>

    <p className={tw("text-sm", "text-gray-700", "whitespace-pre-line")}>
      {body}
    </p>
  </div>
);
