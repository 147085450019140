/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DOCUMENT, DocumentQuery } from "graphql/queries";
import { SignDocumentAttributes } from "graphql/types";

const SIGN_DOCUMENT = gql`
  mutation ($attributes: SignDocumentAttributes!, $token: String, $id: ID) {
    signDocument(input: { attributes: $attributes, token: $token, id: $id }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface SignDocumentMutation {
  signDocument: Payload | null;
}

export const useSignDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    SignDocumentMutation,
    { attributes: SignDocumentAttributes; token?: string; id?: string }
  >(SIGN_DOCUMENT);

  const signDocument = (
    {
      attributes,
      token,
      id,
    }: {
      attributes: SignDocumentAttributes;
      token?: string;
      id?: string;
    },
    onSuccess?: (response: DocumentFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables: { attributes, token, id },
      update(cache, { data: signedDocumentData }) {
        const documentNode = signedDocumentData?.signDocument?.document;
        if (!documentNode) return;

        cache.modify({
          fields: {
            node() {
              const document = cache.readQuery<DocumentQuery>({
                query: DOCUMENT,
              });
              if (!document) return;

              cache.writeQuery<DocumentQuery>({
                query: DOCUMENT,
                data: {
                  node: {
                    ...document.node,
                    signatures: documentNode.signatures,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.signDocument?.errors ?? [];
        const document = data?.signDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => signDocument({ attributes, token, id }, onSuccess),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    signDocument,
    isLoading: loading,
    called,
    document: data?.signDocument?.document,
    errors: data?.signDocument?.errors ?? [],
  };
};
