import { IconType } from "react-icons";

import { tw } from "utils/tw";

import Link from "./Link";
import { ActionID } from "./types";

interface Props<T> {
  tabs: {
    label: string;
    value: T;
    LeadingIcon?: IconType;
    link?: {
      id: ActionID;
      to: string;
      isExternal?: boolean;
    };
    badgeValue?: number | string;
  }[];
  activeTab: T;
  setActiveTab: (tab: T) => void;
  size?: "sm" | "md";
}

export default <T,>({
  tabs,
  activeTab,
  setActiveTab,
  size = "sm",
}: Props<T>): JSX.Element => {
  const tabContainerStyles = tw("flex", "space-x-8");

  const smStyles = tw("text-sm", "font-bold");
  const mdStyles = tw("text-base", "font-semibold");

  const tabBase = tw(
    "inline-flex",
    "items-center",
    "space-x-2",
    "text-left",
    "px-1",
    "border-b-2",
    "pt-1",
    "pt-2",
    "pb-1",
    "sm:pt-4",
    "sm:pb-3",
    {
      [smStyles as string]: size === "sm",
      [mdStyles as string]: size === "md",
    }
  );
  const inactiveTabStyles = tw(
    tabBase,
    "border-transparent",
    "text-gray-500",
    "hover:border-gray-300",
    "hover:text-gray-700"
  );
  const activeTabStyles = tw(tabBase, "border-blue-900", "text-blue-900");

  const badgeBase = tw(
    "h-5",
    "min-w-5",
    "px-1.5",
    "rounded-full",
    "flex",
    "items-center",
    "justify-center",
    "text-xs"
  );
  const inactiveBadgeStyles = tw(
    badgeBase,
    "bg-deepBlue-50",
    "text-deepBlue-500"
  );
  const activeBadgeStyles = tw(badgeBase, "bg-blue-100", "text-blue-900");

  return (
    <div className={tabContainerStyles}>
      {tabs.map(({ label, value, LeadingIcon, link, badgeValue }) => {
        const isActive = activeTab === value;
        const setIsActive = () => setActiveTab(value);
        const tabStyles = isActive ? activeTabStyles : inactiveTabStyles;
        const badgeStyles = isActive ? activeBadgeStyles : inactiveBadgeStyles;

        if (link)
          return (
            <Link
              key={label}
              className={tabStyles}
              id={link.id}
              to={link.to}
              isExternal={link.isExternal}
              unstyled
            >
              {LeadingIcon && <LeadingIcon size={size === "sm" ? 16 : 20} />}

              <span>{label}</span>

              {typeof badgeValue !== "undefined" && (
                <span className={badgeStyles}>{badgeValue}</span>
              )}
            </Link>
          );

        return (
          <button
            key={label}
            className={tabStyles}
            type="button"
            onClick={setIsActive}
          >
            {LeadingIcon && <LeadingIcon size={size === "sm" ? 16 : 20} />}

            <span>{label}</span>

            {typeof badgeValue !== "undefined" && (
              <span className={badgeStyles}>{badgeValue}</span>
            )}
          </button>
        );
      })}
    </div>
  );
};
