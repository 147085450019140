/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PUBLIC_WB_REPORT_FRAGMENT,
  PublicWbReportFragment,
} from "graphql/fragments";

const DESTROY_WB_REPORT_FILE = gql`
  mutation ($id: ID!, $fileId: ID!) {
    destroyWbReportFile(input: { id: $id, fileId: $fileId }) {
      wbReport {
        ...PublicWbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PUBLIC_WB_REPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbReport?: PublicWbReportFragment | null;
  errors: ErrorFragment[];
}

interface DestroyWbReportFileMutation {
  destroyWbReportFile: Payload | null;
}

export const useDestroyWbReportFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyWbReportFileMutation,
    { id: string; fileId: string }
  >(DESTROY_WB_REPORT_FILE);

  const destroyWbReportFile = (
    variables: {
      id: string;
      fileId: string;
    },
    onSuccess?: (wbReport: PublicWbReportFragment) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyWbReportFile?.errors ?? [];
        const wbReport = data?.destroyWbReportFile?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyWbReportFile,
    isLoading: loading,
    wbReport: data?.destroyWbReportFile?.wbReport,
    errors: data?.destroyWbReportFile?.errors ?? [],
  };
};
