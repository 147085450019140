import { Link } from "components/common/basic";
import { HeaderTemplate } from "components/common/wrappers";
import { useTranslation } from "translations";
import { symbol } from "utils/brand";
import { tw } from "utils/tw";

export default () => {
  const { t } = useTranslation("common");

  return (
    <HeaderTemplate
      left={
        <Link
          id="focused_header-go_to_homepage"
          to={t("links.homepage", "https://manymore.com/en")}
          isExternal
          unstyled
        >
          <img src={symbol} alt="Logo symbol" className={tw("w-8", "h-8")} />
        </Link>
      }
      right={null}
      menuList={[]}
      showMenuToggle={false}
    />
  );
};
