import { RouteProps } from "react-router-dom";

import {
  CreateDocumentWizard,
  Document,
  Documents,
  PublicDocument,
  SignDocumentWizard,
} from "components/documents/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("signmore","PUBLIC_DOCUMENT", ":token"), element: <PublicDocument /> },
    { path: getRoutePath("signmore","PUBLIC_DOCUMENT"), element: <PublicDocument /> },
    { path: getRoutePath("signmore","SIGN_DOCUMENT_WIZARD", ":token", ":step"), element: <SignDocumentWizard /> },
    { path: getRoutePath("signmore","SIGN_DOCUMENT_WIZARD", ":token"), element: <SignDocumentWizard /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("signmore","CREATE_DOCUMENT_WIZARD", ":id", ":step"), element: <CreateDocumentWizard /> },
    { path: getRoutePath("signmore","CREATE_DOCUMENT_WIZARD", ":id"), element: <CreateDocumentWizard /> },
    { path: getRoutePath("signmore","CREATE_DOCUMENT_WIZARD"), element: <CreateDocumentWizard /> },
    { path: getRoutePath("signmore","DOCUMENTS"), element: <Documents /> },
    { path: getRoutePath("signmore","AGREEMENTS"), element: <Documents /> },
    { path: getRoutePath("signmore","DOCUMENT", ":id"), element: <Document /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "documents", routes, loggedInRoutes, loggedOutRoutes };
};
