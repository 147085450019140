import { gql } from "@apollo/client";

import { SalarySlip } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const SALARY_SLIP_FRAGMENT = gql`
  fragment SalarySlipFragment on SalarySlip {
    id
    payoutAmount
    payoutDate
    pdfDocument {
      ...AttachmentFragment
    }
    salaryTax
    salaryTaxAmount
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface SalarySlipFragment
  extends Pick<
    SalarySlip,
    "id" | "payoutAmount" | "payoutDate" | "salaryTax" | "salaryTaxAmount"
  > {
  pdfDocument?: AttachmentFragment;
}
