import { useContext, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { Heading } from "components/account/composite";
import { Page } from "components/common/wrappers";
import { useFreelanceProfilesQuery } from "graphql/queries";
import { AccountTypeEnum, FreelanceTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { AccountCrumbs } from "../../shared";
import EmptyState from "./EmptyState";
import PayoutProfilesList from "./PayoutProfilesList";

export default (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const getRoutePath = useRoutePaths();
  const { t } = useTranslation("account");

  const { session } = useContext(AuthContext);
  const isTeam = session?.account.accountType === AccountTypeEnum.Team;

  const individualProfilePayload = useFreelanceProfilesQuery({
    page: 1,
    perPage: 1,
    filter: { freelanceType: FreelanceTypeEnum.Individual },
  });
  const hasIndividual = individualProfilePayload.freelanceProfiles.length > 0;

  const activeProfilesPayload = useFreelanceProfilesQuery({
    page: 1,
    perPage: 1,
    filter: { archived: false },
  });
  const hasActive = activeProfilesPayload.freelanceProfiles.length > 0;

  const archivedProfilesPayload = useFreelanceProfilesQuery({
    page: 1,
    perPage: 1,
    filter: { archived: true },
  });
  const hasArchived = archivedProfilesPayload.freelanceProfiles.length > 0;

  const hasLoaded =
    !individualProfilePayload.isLoading &&
    !activeProfilesPayload.isLoading &&
    !archivedProfilesPayload.isLoading;

  return (
    <Page title={t("payoutProfiles.pageTitle", "Payout settings")}>
      <div className={tw("max-w-screen-md")}>
        <div className={tw("space-y-4")}>
          <AccountCrumbs
            currentPage={
              isTeam
                ? t("payoutProfiles_team.breadcrumb", "Organizations")
                : t("payoutProfiles_personal.breadcrumb", "Payout Settings")
            }
          />

          <Heading
            header={
              isTeam
                ? t("payoutProfiles_team.header", "My Organizations")
                : t("payoutProfiles_personal.header", "My Payout Settings")
            }
            action={
              hasLoaded && (isTeam || !hasIndividual)
                ? {
                    type: "link",
                    actionID: "payout_profiles_header-add_profile",
                    to: getRoutePath({
                      module: "invoicemore",
                      routeName: "FREELANCE_PROFILE_WIZARD",
                    }),
                  }
                : undefined
            }
            actionLabel={t(
              "payoutProfiles.action.newProfile",
              "Add payout method"
            )}
          />
        </div>

        {hasLoaded && (
          <div className={tw("my-6", "space-y-6")}>
            {!hasActive && !hasArchived && <EmptyState />}

            {hasActive && <PayoutProfilesList filter={{ archived: false }} />}

            {hasArchived && (
              <div
                className={tw("rounded-lg", "shadow-md", "p-4", "space-y-4")}
              >
                <button
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className={tw(
                    "w-full",
                    "flex",
                    "justify-between",
                    "items-center"
                  )}
                >
                  <h3 className={tw("text-lg", "font-bold")}>
                    {t(
                      "payoutProfiles.archivedProfiles.heading",
                      "Archived payout methods"
                    )}
                  </h3>

                  {isCollapsed ? (
                    <HiChevronDown size={24} />
                  ) : (
                    <HiChevronUp size={24} />
                  )}
                </button>

                {!isCollapsed && (
                  <PayoutProfilesList filter={{ archived: true }} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};
