/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { Base64AttachmentAttributes } from "graphql/types";

const UPLOAD_CHECK_LISTS = gql`
  mutation ($id: ID!, $file: Base64AttachmentAttributes!) {
    uploadCheckLists(input: { checkRequestId: $id, file: $file }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest: CheckRequestFragment;
  errors: ErrorFragment[];
}

interface UploadCheckListsMutation {
  uploadCheckLists: Payload | null;
}

export const useUploadCheckListsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UploadCheckListsMutation,
    { id: string; file: Base64AttachmentAttributes }
  >(UPLOAD_CHECK_LISTS);

  const uploadCheckLists = (
    variables: { id: string; file: Base64AttachmentAttributes },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.uploadCheckLists?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    uploadCheckLists,
    isLoading: loading,
    errors: data?.uploadCheckLists?.errors ?? [],
  };
};
