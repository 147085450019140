import Request from "components/checkmore/composite/RequestSection";
import { CheckListFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupVariant, PopupWrapper } from "../PopupWrapper";
import Log from "./Log";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  checkList: CheckListFragment;
}

export default ({ checkList, ...props }: Props) => {
  const { t } = useTranslation("checkmore");

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t("checkTimelinePopup.title", "Check Log")}
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        <Request checkList={checkList} />

        <Log checkList={checkList} />
      </div>
    </PopupWrapper>
  );
};
