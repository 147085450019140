import { tw } from "utils/tw";

interface Props {
  label: string;
  onClick?: () => void;
}

export default ({ label, onClick }: Props): JSX.Element => {
  const lineStyles = tw("flex-1", "h-px", "bg-gray-300");
  const buttonStyles = tw(
    "flex",
    "items-center",
    "py-2",
    "px-3",
    "space-x-2",
    "text-sm",
    "text-gray-700",
    "font-semibold",
    "rounded-2xl",
    "shadow-sm",
    "border",
    "border-gray-300",
    "bg-white",
    "hover:bg-gray-100",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900"
  );

  return (
    <div className={tw("flex", "items-center")}>
      <span className={lineStyles} />

      <button type="button" onClick={onClick} className={buttonStyles}>
        {label}
      </button>

      <span className={lineStyles} />
    </div>
  );
};
