/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  CHECK_FRAGMENT,
  CheckFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CHECK_LIST, CheckListQuery } from "graphql/queries";

const REFUSE_CHECK = gql`
  mutation ($id: ID!, $message: String!) {
    refuseCheck(input: { id: $id, refuseReason: $message }) {
      check {
        ...CheckFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  check: CheckFragment;
  errors: ErrorFragment[];
}

interface RefuseCheckMutation {
  refuseCheck: Payload | null;
}

export const useRefuseCheckMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    RefuseCheckMutation,
    { id: string; message: string }
  >(REFUSE_CHECK);

  const refuseCheck = (
    variables: { id: string; message: string },
    onSuccess?: () => void
  ) =>
    mutation({
      variables,
      update(cache, { data: refusedCheckData }) {
        cache.modify({
          fields: {
            node() {
              const refusedCheck = refusedCheckData?.refuseCheck?.check;
              if (!refusedCheck) return;

              const existingCheckList = cache.readQuery<CheckListQuery>({
                query: CHECK_LIST,
              });
              const checkListNode = existingCheckList?.node;
              if (!checkListNode) return;

              const updatedChecks = checkListNode.checks.map((check) =>
                check.id === refusedCheck.id ? refusedCheck : check
              );

              cache.writeQuery<CheckListQuery>({
                query: CHECK_LIST,
                data: { node: { ...checkListNode, checks: updatedChecks } },
              });
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data?.refuseCheck?.check && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    refuseCheck,
    isLoading: loading,
    check: data?.refuseCheck?.check,
  };
};
