/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";
import { UpdateJobApplicationAttributes } from "graphql/types";

const UPDATE_JOB_APPLICATION = gql`
  mutation ($attributes: UpdateJobApplicationAttributes!, $id: ID!) {
    updateJobApplication(input: { attributes: $attributes, id: $id }) {
      jobApplication {
        ...JobApplicationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
  errors: ErrorFragment[];
}

interface UpdateJobApplicationMutation {
  updateJobApplication: Payload | null;
}

export const useUpdateJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateJobApplicationMutation,
    { id: string; attributes: UpdateJobApplicationAttributes }
  >(UPDATE_JOB_APPLICATION);

  const updateJobApplication = (
    id: string,
    attributes: UpdateJobApplicationAttributes,
    onSuccess?: (jobApplication: JobApplicationFragment) => void
  ) =>
    mutation({
      variables: { id, attributes },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateJobApplication?.errors ?? [];
        const jobApplication = data?.updateJobApplication?.jobApplication;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && jobApplication && onSuccess(jobApplication),
          onRetry: () => updateJobApplication(id, attributes, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateJobApplication,
    isLoading: loading,
    jobApplication: data?.updateJobApplication?.jobApplication,
    errors: data?.updateJobApplication?.errors ?? [],
  };
};
