// import { HiExclamation } from "react-icons/hi";
import { TFunction } from "i18next";

import { Badge } from "components/common/basic";
import { CheckStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  viewer: "sender" | "receiver";
  status: CheckStatusEnum;
  showStatus?: boolean;
  message?: string;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  viewer,
  status,
  showStatus,
  message,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const isSender = viewer === "sender";

  const statusMessage =
    message ??
    (showStatus
      ? getTranslatableCheckListStatus(status, t)
      : ((): string => {
          switch (status) {
            case CheckStatusEnum.Requested:
              return isSender
                ? t("checkListStatusMessage_sender.requestSent", "Request sent")
                : t(
                    "checkListStatusMessage_receiver.requestSent",
                    "Request sent"
                  );
            case CheckStatusEnum.Consented:
              return isSender
                ? t("checkListStatusMessage_sender.inProgress", "In Progress")
                : t(
                    "checkListStatusMessage_receiver.inProgress",
                    "In Progress"
                  );
            case CheckStatusEnum.Cleared:
              return isSender
                ? t("checkListStatusMessage_sender.cleared", "Cleared")
                : t("checkListStatusMessage_receiver.cleared", "Cleared");
            case CheckStatusEnum.Rejected:
              return isSender
                ? t("checkListStatusMessage_sender.rejected", "Rejected")
                : t("checkListStatusMessage_receiver.rejected", "Rejected");
            case CheckStatusEnum.Refused:
              return isSender
                ? t("checkListStatusMessage_sender.refused", "Refused")
                : t("checkListStatusMessage_receiver.refused", "Refused");
            case CheckStatusEnum.Cancelled:
              return isSender
                ? t("checkListStatusMessage_sender.cancelled", "Cancelled")
                : t("checkListStatusMessage_receiver.cancelled", "Cancelled");
            case CheckStatusEnum.InReview:
              return isSender
                ? t("checkListStatusMessage_sender.inReview", "In review")
                : t("checkListStatusMessage_receiver.inReview", "In review");

            default:
              return ((_status: never) => "")(status);
          }
        })());

  const badgeVariant = (() => {
    switch (status) {
      case CheckStatusEnum.Cleared:
        return "success";

      case CheckStatusEnum.Rejected:
        return "error";

      case CheckStatusEnum.Consented:
      case CheckStatusEnum.InReview:
        return "pending";

      case CheckStatusEnum.Requested:
      case CheckStatusEnum.Refused:
      case CheckStatusEnum.Cancelled:
        return "default_outlined";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "font-bold", "text-deepBlue-900", {
          "text-xs": badgeSize === "sm",
        })}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};

export const getTranslatableCheckListStatus = (
  status: CheckStatusEnum,
  t: TFunction
): string => {
  switch (status) {
    case CheckStatusEnum.Requested:
      return t("checkListStatus.requestSent", status);
    case CheckStatusEnum.Consented:
      return t("checkListStatus.inProgress", status);
    case CheckStatusEnum.Cancelled:
      return t("checkListStatus.cancelled", status);
    case CheckStatusEnum.Cleared:
      return t("checkListStatus.cleared", status);
    case CheckStatusEnum.Rejected:
      return t("checkListStatus.rejected", status);
    case CheckStatusEnum.Refused:
      return t("checkListStatus.refused", status);
    case CheckStatusEnum.InReview:
      return t("checkListStatus.inReview", status);

    default:
      return ((_status: never) => "")(status);
  }
};
