/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { SIGNATURE_FRAGMENT, SignatureFragment } from "graphql/fragments";
import { QueryPublicSignatureArgs } from "graphql/types";

const PUBLIC_SIGNATURE = gql`
  query ($token: String!) {
    publicSignature(token: $token) {
      ...SignatureFragment
    }
  }
  ${SIGNATURE_FRAGMENT}
`;

type QueryArgs = Pick<QueryPublicSignatureArgs, "token">;

export const usePublicSignatureLazyQuery = () => {
  const [getPublicSignatureQuery, { data, loading, called }] = useLazyQuery<
    { publicSignature: SignatureFragment },
    QueryArgs
  >(PUBLIC_SIGNATURE, { fetchPolicy: "network-only" });

  const resolveRef = useRef<(signature?: SignatureFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.publicSignature);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getPublicSignature = async (token: string) => {
    getPublicSignatureQuery({ variables: { token } });

    return new Promise<SignatureFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPublicSignature,
    publicSignature: data?.publicSignature,
    isLoading: loading,
  };
};
