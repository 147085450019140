import { HiOutlineShieldCheck } from "react-icons/hi";

import { Trans } from "translations";
import { tw } from "utils/tw";

export default (): JSX.Element => (
  <div className={tw("flex", "items-center", "space-x-2", "text-blue-900")}>
    <HiOutlineShieldCheck size={20} />

    <p className={tw("text-sm", "font-semibold", "uppercase")}>
      <Trans ns="common" i18nKey="secureUpload.message">
        Secure upload
      </Trans>
    </p>
  </div>
);
