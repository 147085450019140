import {
  FieldError,
  FieldErrors,
  FieldValues,
  Merge,
  UseFormRegister,
} from "react-hook-form";
import { HiOutlineTrash } from "react-icons/hi";

import { TWidth } from "tw-generated";
import { tw } from "utils/tw";

import InputCell, { InputType } from "./InputCell";

interface ItemBase extends FieldValues {
  id: string;
}

interface Props<T> {
  name: string;
  columns: {
    key: string;
    label: string;
    width: TWidth;
    input: InputType;
  }[];
  fields: Record<string, string>[];
  register: UseFormRegister<FieldValues>;
  removeItem: (rowIndex: number) => void;
  errors: Merge<FieldError, FieldErrors<T[]>>;
  minItems: number;
}

export default <T extends ItemBase>({
  name,
  columns,
  fields,
  register,
  removeItem,
  errors,
  minItems,
}: Props<T>): JSX.Element => {
  const errorMessages =
    (errors as FieldErrors<T[]>).reduce((acc: string[], fieldErrors) => {
      if (fieldErrors) {
        const errorMessages = Object.values(fieldErrors).reduce(
          (newMessages: string[], message) => {
            if (typeof message !== "string") return newMessages;

            if (typeof message === "string" && acc.includes(message))
              return newMessages;

            return [...newMessages, message];
          },
          []
        );

        return [...acc, ...errorMessages];
      }

      return acc;
    }, []) ?? [];

  const columnHeaderStyles = tw(
    "grow",
    "shrink-0",
    "py-3",
    "px-6",
    "text-xs",
    "uppercase",
    "text-deepBlue-700",
    "bg-deepBlue-50"
  );

  return (
    <>
      {fields.length > 0 && (
        <div className={tw("w-full", "overflow-x-auto")}>
          <div className={tw("w-full", "flex")}>
            {columns.map((column) => (
              <p
                key={column.key}
                className={tw(column.width, columnHeaderStyles)}
              >
                {column.label}
              </p>
            ))}

            {fields.length > minItems && (
              <span className={tw("w-14", "shrink-0", "bg-deepBlue-50")} />
            )}
          </div>

          {fields.map((field, rowIndex) => {
            const cellBase = tw("border-deepBlue-100", "border-r", "border-b");
            const rowErrors = errors?.[rowIndex];

            return (
              <div className={tw("w-full", "flex")} key={field.id}>
                {columns.map((column, columnIndex) => {
                  const value = Object.prototype.hasOwnProperty.call(
                    field,
                    column.key
                  )
                    ? field[column.key as keyof typeof field]
                    : "";
                  const error =
                    rowErrors &&
                    Object.prototype.hasOwnProperty.call(
                      rowErrors,
                      column.key
                    ) &&
                    rowErrors[column.key as keyof typeof rowErrors];

                  const isFirstRow = rowIndex === 0;
                  const isFirstColumn = columnIndex === 0;

                  return (
                    <InputCell
                      key={column.key}
                      cellStyles={tw(
                        cellBase,
                        column.width,
                        "grow",
                        "shrink-0",
                        {
                          "border-t": isFirstRow,
                          "border-l": isFirstColumn,
                        }
                      )}
                      id={`${name}.${rowIndex}.${column.key}`}
                      label={column.label}
                      value={value}
                      input={column.input}
                      register={register}
                      hasError={Boolean(error)}
                    />
                  );
                })}

                {fields.length > minItems && (
                  <div className={tw(cellBase, "p-2")}>
                    <button
                      className={tw(
                        "p-2",
                        "rounded-md",
                        "border",
                        "border-deepBlue-100"
                      )}
                      onClick={
                        fields.length > minItems
                          ? () => removeItem(rowIndex)
                          : undefined
                      }
                      type="button"
                    >
                      <HiOutlineTrash size={20} />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {errorMessages.length > 0 && (
        <ul className={tw("list-inside", "list-disc")}>
          {errorMessages.map((errorMessage) => (
            <li key={errorMessage} className={tw("text-error", "text-sm")}>
              {errorMessage}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
