import { useReducer } from "react";

import { Popover } from "./Popover";

enum ActionTypeEnum {
  registerPopover = "REGISTER",
  unregisterPopover = "UNREGISTER",
  updatePopoverContent = "UPDATE_CONTENT",
  setIsShownPopover = "SET_IS_SHOWN",
  clearPopovers = "CLEAR",
}

interface RegisterAction {
  type: ActionTypeEnum.registerPopover;
  payload: Popover;
}
interface UnregisterAction {
  type: ActionTypeEnum.unregisterPopover;
  payload: Popover["id"];
}
interface UpdateContentAction {
  type: ActionTypeEnum.updatePopoverContent;
  payload: Pick<Popover, "id" | "content">;
}
interface SetIsShownAction {
  type: ActionTypeEnum.setIsShownPopover;
  payload: Pick<Popover, "id" | "isShown">;
}
interface ClearAction {
  type: ActionTypeEnum.clearPopovers;
}
type Action =
  | RegisterAction
  | UpdateContentAction
  | UnregisterAction
  | SetIsShownAction
  | ClearAction;

interface State {
  popovers: Popover[];
}
const initialState: State = {
  popovers: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypeEnum.registerPopover:
      return state.popovers.some(({ id }) => id === action.payload.id)
        ? state
        : { ...state, popovers: [...state.popovers, action.payload] };

    case ActionTypeEnum.unregisterPopover:
      return {
        ...state,
        popovers: state.popovers.filter(({ id }) => id !== action.payload),
      };

    case ActionTypeEnum.updatePopoverContent:
      return {
        ...state,
        popovers: state.popovers.map((popover) =>
          popover.id === action.payload.id
            ? { ...popover, content: action.payload.content }
            : popover
        ),
      };

    case ActionTypeEnum.setIsShownPopover:
      return {
        ...state,
        popovers: state.popovers.map((popover) =>
          popover.id === action.payload.id
            ? { ...popover, isShown: action.payload.isShown }
            : popover
        ),
      };

    case ActionTypeEnum.clearPopovers:
      return {
        ...state,
        popovers: [],
      };

    default:
      ((_action: never) => state)(action);
  }

  return state;
};

export interface UsePopoverReturn {
  shownPopovers: Popover[];
  registerPopover: (popover: Popover) => void;
  unregisterPopover: (id: Popover["id"]) => void;
  updatePopoverContent: (
    id: Popover["id"],
    content: Popover["content"]
  ) => void;
  setIsShownPopover: (id: Popover["id"], isShown: Popover["isShown"]) => void;
  clearPopovers: () => void;
}

export default (): UsePopoverReturn => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const registerPopover = (payload: Popover) => {
    // Only register new ids
    if (state.popovers.some(({ id }) => id === payload.id)) return;

    dispatch({ type: ActionTypeEnum.registerPopover, payload });
  };

  const unregisterPopover = (payload: Popover["id"]) =>
    dispatch({ type: ActionTypeEnum.unregisterPopover, payload });

  const updatePopoverContent = (
    id: Popover["id"],
    content: Popover["content"]
  ) =>
    dispatch({
      type: ActionTypeEnum.updatePopoverContent,
      payload: { id, content },
    });

  const setIsShownPopover = (id: Popover["id"], isShown: Popover["isShown"]) =>
    dispatch({
      type: ActionTypeEnum.setIsShownPopover,
      payload: { id, isShown },
    });

  const clearPopovers =
    state.popovers.length === 0
      ? () => null
      : () => dispatch({ type: ActionTypeEnum.clearPopovers });

  const shownPopovers = state.popovers.filter((popover) => popover.isShown);

  return {
    shownPopovers,
    registerPopover,
    updatePopoverContent,
    unregisterPopover,
    setIsShownPopover,
    clearPopovers,
  };
};
