import { gql } from "@apollo/client";

import { Address } from "graphql/types";

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    city
    country
    county
    line1
    line2
    postalCode
    latitude
    longitude
  }
`;

export interface AddressFragment
  extends Pick<
    Address,
    | "city"
    | "country"
    | "county"
    | "line1"
    | "line2"
    | "postalCode"
    | "latitude"
    | "longitude"
  > {}
