/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";

const FIND_OR_CREATE_JOB_APPLICATION = gql`
  mutation ($jobId: ID!) {
    findOrCreateJobApplication(input: { jobId: $jobId }) {
      jobApplication {
        ...JobApplicationFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
}

interface FindOrCreateJobApplicationMutation {
  findOrCreateJobApplication: Payload | null;
}

export const useFindOrCreateJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    FindOrCreateJobApplicationMutation,
    { jobId: string }
  >(FIND_OR_CREATE_JOB_APPLICATION);

  const findOrCreateJobApplication = (
    jobId: string,
    onSuccess?: (jobApplication: JobApplicationFragment) => void
  ) =>
    mutation({
      variables: { jobId },
    })
      .then(({ data, errors }) => {
        const jobApplication = data?.findOrCreateJobApplication?.jobApplication;

        handleErrors({
          dataErrors: [],
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && jobApplication && onSuccess(jobApplication),
          onRetry: () => findOrCreateJobApplication(jobId, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    findOrCreateJobApplication,
    isLoading: loading,
    jobApplication: data?.findOrCreateJobApplication?.jobApplication ?? null,
  };
};
