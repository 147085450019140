/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { SENT_CHECK_LISTS, SentCheckListsQuery } from "graphql/queries";
import { CheckListsFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const ARCHIVE_CHECK_LISTS = gql`
  mutation ($ids: [ID!], $filter: CheckListsFilter) {
    archiveCheckLists(input: { ids: $ids, filter: $filter }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<CheckListsFilter>;

interface Payload {
  errors: ErrorFragment[];
}

interface ArchiveCheckListsMutation {
  archiveCheckLists: Payload | null;
}

export const useArchiveCheckListsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    ArchiveCheckListsMutation,
    Input
  >(ARCHIVE_CHECK_LISTS);

  const archiveCheckLists = (
    variables: Input,
    onSuccess?: () => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            sentCheckLists() {
              const existingCheckLists = cache.readQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
              });
              if (!existingCheckLists?.sentCheckLists.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const ids = isSome ? (variables as { ids: string[] }).ids : [];

              const items = isSome
                ? existingCheckLists.sentCheckLists.items?.filter(
                    (checkListItem) => !ids.includes(checkListItem.id)
                  )
                : [];
              const total = isSome
                ? existingCheckLists.sentCheckLists.total - ids.length
                : 0;

              cache.writeQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
                data: {
                  sentCheckLists: {
                    items,
                    total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.archiveCheckLists?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => archiveCheckLists(variables, onSuccess),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    archiveCheckLists,
    isLoading: loading,
    errors: data?.archiveCheckLists?.errors ?? [],
  };
};
