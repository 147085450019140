/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_HANDLER_FRAGMENT,
  WbHandlerFragment,
} from "graphql/fragments";
import { WB_HANDLERS, WbHandlersQuery } from "graphql/queries";

const DESTROY_WB_HANDLER = gql`
  mutation ($wbHandlerId: ID!) {
    destroyWbHandler(input: { id: $wbHandlerId }) {
      wbHandler {
        ...WbHandlerFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_HANDLER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbHandler?: WbHandlerFragment | null;
  errors: ErrorFragment[];
}

interface DestroyWbHandlerMutation {
  destroyWbHandler: Payload | null;
}

export const useDestroyWbHandlerMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyWbHandlerMutation,
    { wbHandlerId: string }
  >(DESTROY_WB_HANDLER);

  const destroyWbHandler = (
    wbHandlerId: string,
    onSuccess?: (wbHandler: WbHandlerFragment) => void
  ) =>
    mutation({
      variables: { wbHandlerId },
      update(cache) {
        cache.modify({
          fields: {
            wbHandlers() {
              const existing = cache.readQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
              });
              if (!existing) return;

              const items = existing.wbHandlers.items?.filter(
                (wbHandler) => wbHandler.id !== wbHandlerId
              );

              cache.writeQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
                data: {
                  wbHandlers: {
                    items,
                    total: existing.wbHandlers.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyWbHandler?.errors ?? [];
        const wbHandler = data?.destroyWbHandler?.wbHandler;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbHandler && onSuccess(wbHandler),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyWbHandler,
    isLoading: loading,
    wbHandler: data?.destroyWbHandler?.wbHandler,
    errors: data?.destroyWbHandler?.errors ?? [],
  };
};
