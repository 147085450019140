/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DEPARTMENT_FRAGMENT,
  DepartmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import {
  DEPARTMENTS,
  DepartmentsQuery,
  SESSION,
  SessionQuery,
} from "graphql/queries";

const DESTROY_DEPARTMENT = gql`
  mutation ($id: ID!) {
    destroyDepartment(input: { id: $id }) {
      department {
        ...DepartmentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DEPARTMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  department?: DepartmentFragment | null;
  errors: ErrorFragment[];
}

interface DestroyDepartmentMutation {
  destroyDepartment: Payload | null;
}

export const useDestroyDepartmentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.destroyedDepartment",
    "Department destroyed successfully"
  );
  // Values for extraction
  // t("common:success.destroyedDepartment", "Department destroyed successfully")

  const [mutation, { data, loading, called }] = useMutation<
    DestroyDepartmentMutation,
    { id: string }
  >(DESTROY_DEPARTMENT);

  const destroyDepartment = (
    id: string,
    onSuccess?: (response: DepartmentFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            departments() {
              const existingDepartments = cache.readQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
              });
              if (!existingDepartments?.departments.items) return;

              const items = existingDepartments.departments.items?.filter(
                (departmentItem) => departmentItem?.id !== id
              );

              cache.writeQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
                data: {
                  departments: {
                    items,
                    total: existingDepartments.departments.total - 1,
                  },
                },
              });
            },
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              const counts = sessionQuery.session.account.counts
                ? {
                    ...sessionQuery.session.account.counts,
                    departments:
                      sessionQuery.session.account.counts.departments - 1,
                  }
                : undefined;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      counts,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyDepartment?.errors ?? [];
        const department = data?.destroyDepartment?.department;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => department && onSuccess && onSuccess(department),
          onRetry: () => destroyDepartment(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyDepartment,
    isLoading: loading,
    called,
    department: data?.destroyDepartment?.department,
    errors: data?.destroyDepartment?.errors ?? [],
  };
};
