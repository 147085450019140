import { useTranslation } from "translations";

enum BulkVariant {
  One = "ONE",
  Some = "SOME",
  All = "ALL",
}

export default (amount: number) => {
  const variant = ((amount) => {
    switch (amount) {
      case 0:
        return BulkVariant.All;
      case 1:
        return BulkVariant.One;
      default:
        return BulkVariant.Some;
    }
  })(amount);

  const { t } = useTranslation("common");

  const getHeading = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.heading",
          "Are you sure you want to delete this person?"
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.heading",
          "Are you sure you want to delete these people?"
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.heading",
          "Are you sure you want to delete all people?"
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getIsIncludingOwnerText = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.isIncludingOwnerText",
          "Unfortunately you are not able to delete this person because they are the owner of this team account. Ask them to transfer the ownership to you or another team member for them to be deleted."
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.isIncludingOwnerText",
          "Unfortunately you are not able to delete these people. One of the people is the owner of this team account. Ask them to transfer the ownership to you or another team member for them to be deleted."
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.isIncludingOwnerText",
          "Unfortunately you are not able to delete these people. One of the people is the owner of this team account. Ask them to transfer the ownership to you or another team member for them to be deleted."
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getSignedDocumentsBody = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.signedDocuments.body",
          `Deleting this person will delete all documents that they have signed with your team account.
Before deleting, you can download these documents in case you want a backup.`
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.signedDocuments.body",
          `Deleting these people will delete all documents that they have signed with your team account.
Before deleting, you can download these documents in case you want a backup.`
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.signedDocuments.body",
          `Deleting these people will delete all documents that they have signed with your team account.
Before deleting, you can download these documents in case you want a backup.`
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getChecksBody = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.checks.body",
          "Deleting this person will delete all checks that they have done for your team account and these checks will no longer be accessible."
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.checks.body",
          "Deleting these people will delete all checks that they have done for your team account and these checks will no longer be accessible."
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.checks.body",
          "Deleting these people will delete all checks that they have done for your team account and these checks will no longer be accessible."
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getOwnInformationText = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.ownInformationText",
          "The person you are deleting will still have their own information visible on their personal Manymore account."
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.ownInformationText",
          "The people you are deleting will still have their own information visible on their personal Manymore accounts."
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.ownInformationText",
          "The people you are deleting will still have their own information visible on their personal Manymore accounts."
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getFinancialInformationText = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.removePeople_one.financialInformationText",
          "If any financial information has been generated on Manymore, we are obliged by law to store the information."
        );

      case BulkVariant.Some:
        return t(
          "popup.removePeople_some.financialInformationText",
          "If any financial information has been generated on Manymore, we are obliged by law to store the information."
        );

      case BulkVariant.All:
        return t(
          "popup.removePeople_all.financialInformationText",
          "If any financial information has been generated on Manymore, we are obliged by law to store the information."
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getConfirmButtonLabel = () => {
    switch (variant) {
      case BulkVariant.One:
        return t("popup.removePeople_one.button.confirm", "Delete person");

      case BulkVariant.Some:
        return t("popup.removePeople_some.button.confirm", "Delete people");

      case BulkVariant.All:
        return t("popup.removePeople_all.button.confirm", "Delete everyone");

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  return {
    heading: getHeading(),
    isIncludingOwnerText: getIsIncludingOwnerText(),
    signedDocumentsBody: getSignedDocumentsBody(),
    checksBody: getChecksBody(),
    ownInformationText: getOwnInformationText(),
    financialInformationText: getFinancialInformationText(),
    confirmButtonLabel: getConfirmButtonLabel(),
  };
};
