import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import ModuleRedirect from "./ModuleRedirect";
import NSRoute from "./NSRoute";
import NSRouteWithAuth from "./NSRouteWithAuth";
import NoModuleRedirect from "./NoModuleRedirect";
import {
  accountRoutes,
  checkmoreRoutes,
  commonRoutes,
  invoicemoreRoutes,
  loginRoutes,
  payoutmoreRoutes,
  recruitmoreRoutes,
  signmoreRoutes,
  tellmoreRoutes,
  testRoutes,
} from "./paths";

export default (): JSX.Element => {
  const routes = [
    accountRoutes(),
    checkmoreRoutes(),
    commonRoutes(),
    invoicemoreRoutes(),
    loginRoutes(),
    payoutmoreRoutes(),
    recruitmoreRoutes(),
    signmoreRoutes(),
    tellmoreRoutes(),
    testRoutes(),
  ];

  return (
    <Routes>
      <Route path="/v2/:locale">
        <Route index element={<NoModuleRedirect />} />

        {routes.map(({ ns, routes, loggedInRoutes, loggedOutRoutes }) =>
          /* prettier-ignore */
          <Fragment key={ns}>
            {/* Logged out routes */}
            <Route path="/v2/:locale/:module">
              <Route index element={<ModuleRedirect />} />

              <Route  element={<NSRouteWithAuth nameSpace={ns} isLoggedInRoute={false} />}>
                {loggedOutRoutes.map((route) => <Route key={route.path} {...route} />)}
              </Route>

              <Route element={<NSRoute nameSpace={ns} />}>
                {routes.map((route) => <Route key={route.path} {...route} />)}
              </Route>

              <Route path="*" element={<ModuleRedirect />} />
            </Route>              
            
            {/* Logged in routes */}
            <Route path="/v2/:locale/:type/:slug/:module">
              <Route index element={<ModuleRedirect />} />

              <Route element={<NSRouteWithAuth nameSpace={ns} isLoggedInRoute={true} />}>
                {loggedInRoutes.map((route) => <Route key={route.path} {...route} />)}
              </Route>

              <Route element={<NSRoute nameSpace={ns} />}>
                {routes.map((route) => <Route key={route.path} {...route} />)}
              </Route>

              <Route path="*" element={<ModuleRedirect />} />
            </Route>
          </Fragment>
        )}

        <Route path="*" element={<NoModuleRedirect />} />
      </Route>
    </Routes>
  );
};
