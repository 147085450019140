import { gql } from "@apollo/client";

import { OccupationCode } from "graphql/types";

export const OCCUPATION_CODE_FRAGMENT = gql`
  fragment OccupationCodeFragment on OccupationCode {
    code
    id
    name
    notes
    parentCode
    shortName
    tagList
  }
`;

export interface OccupationCodeFragment
  extends Pick<
    OccupationCode,
    "code" | "id" | "name" | "notes" | "parentCode" | "shortName" | "tagList"
  > {}
