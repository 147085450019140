import { gql } from "@apollo/client";

import { Document, Signature } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { DOCUMENT_FILE_FRAGMENT, DocumentFileFragment } from "./documentFile";
import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const SIGNATURE_FRAGMENT = gql`
  fragment SignatureFragment on Signature {
    id
    clientName
    designation
    email
    name
    phone
    notifiedAt
    refusedAt
    signatureNumber
    canSign
    signedAt
    status
    document {
      id
      activatedOn
      activation
      expiration
      expirationMonths
      expiredOn
      name
      status
      documentFiles {
        ...DocumentFileFragment
      }
      signedDocument {
        ...AttachmentFragment
      }
      interactedAt
      message
      account {
        ...SimplifiedAccountFragment
      }
      senderAccount {
        ...SimplifiedAccountFragment
      }
    }
    account {
      ...SimplifiedAccountFragment
    }
  }
  ${DOCUMENT_FILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
`;

interface SignatureDocument
  extends Pick<
    Document,
    | "id"
    | "activatedOn"
    | "activation"
    | "expiration"
    | "expirationMonths"
    | "expiredOn"
    | "name"
    | "status"
    | "interactedAt"
    | "message"
  > {
  documentFiles: DocumentFileFragment[];
  signedDocument?: AttachmentFragment;
  account: SimplifiedAccountFragment;
  senderAccount?: SimplifiedAccountFragment;
}

export interface SignatureFragment
  extends Pick<
    Signature,
    | "id"
    | "clientName"
    | "designation"
    | "email"
    | "name"
    | "phone"
    | "notifiedAt"
    | "refusedAt"
    | "signatureNumber"
    | "canSign"
    | "signedAt"
    | "status"
  > {
  account?: SimplifiedAccountFragment;
  document: SignatureDocument;
}
