/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { MEMBERS, MembersQuery } from "graphql/queries";
import { UpdateAccountMemberInput } from "graphql/types";

const UPDATE_ACCOUNT_MEMBER = gql`
  mutation ($input: UpdateAccountMemberInput!) {
    updateAccountMember(input: $input) {
      member {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

interface Payload {
  member?: AccountFragment | null;
  errors: ErrorFragment[];
}

export interface UpdateAccountMemberMutation {
  updateAccountMember: Payload | null;
}

export const useUpdateAccountMemberMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateAccountMemberMutation,
    { input: UpdateAccountMemberInput }
  >(UPDATE_ACCOUNT_MEMBER);

  const updateAccountMember = (
    input: UpdateAccountMemberInput,
    onSuccess?: (member?: AccountFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache, { data: updatedMemberData }) {
        cache.modify({
          fields: {
            members() {
              const updatedMember =
                updatedMemberData?.updateAccountMember?.member;
              if (!updatedMember) return;

              const membersQuery = cache.readQuery<MembersQuery>({
                query: MEMBERS,
              });
              if (!membersQuery) return;

              const items = [
                ...(membersQuery.members.items ?? []),
                updatedMember,
              ];
              const total = membersQuery.members.total + 1;

              cache.writeQuery<MembersQuery>({
                query: MEMBERS,
                data: {
                  members: {
                    items,
                    total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateAccountMember?.errors ?? [];
        const account = data?.updateAccountMember?.member ?? undefined;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateAccountMember,
    isLoading: loading,
    person: data?.updateAccountMember?.member,
    errors: data?.updateAccountMember?.errors ?? [],
  };
};
