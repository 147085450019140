/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { SendPasswordResetInstructionsInput } from "graphql/types";

const SEND_PASSWORD_RESET_INSTRUCTIONS = gql`
  mutation ($input: SendPasswordResetInstructionsInput!) {
    sendPasswordResetInstructions(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendPasswordResetInstructionsMutation {
  sendPasswordResetInstructions: Payload | null;
}

export const useSendPasswordResetInstructionsMutation = (
  onSuccess?: (input: SendPasswordResetInstructionsInput) => void
) => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentInstructions",
    "Instructions sent successfully"
  );
  // Values for extraction
  // t("common:success.sentInstructions", "Instructions sent successfully")

  const [mutation, { data, loading }] = useMutation<
    SendPasswordResetInstructionsMutation,
    { input: SendPasswordResetInstructionsInput }
  >(SEND_PASSWORD_RESET_INSTRUCTIONS);

  const sendPasswordResetInstructions = (
    input: SendPasswordResetInstructionsInput
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendPasswordResetInstructions?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(input),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendPasswordResetInstructions,
    isLoading: loading,
    errors: data?.sendPasswordResetInstructions?.errors ?? [],
  };
};
