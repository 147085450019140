/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { CREDIT_NOTE_FRAGMENT, CreditNoteFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const CREDIT_NOTE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CreditNote {
        ...CreditNoteFragment
      }
    }
  }
  ${CREDIT_NOTE_FRAGMENT}
`;

export type CreditNoteQuery = { node: CreditNoteFragment };

export const useCreditNoteLazyQuery = () => {
  const [getCreditNoteQuery, { data, loading, called }] = useLazyQuery<
    CreditNoteQuery,
    QueryNodeArgs
  >(CREDIT_NOTE);

  const resolveRef = useRef<(creditNote?: CreditNoteFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getCreditNote = async (id: string) => {
    getCreditNoteQuery({ variables: { id } });

    return new Promise<CreditNoteFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getCreditNote,
    creditNote: data?.node,
    isLoading: loading,
  };
};
