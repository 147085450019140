import { Spinner } from "components/common/basic";
import { FreelanceProfileFragment } from "graphql/fragments";
import { useSessionQuery } from "graphql/queries";
import { lonn } from "utils/brand";
import { tw } from "utils/tw";

import Agreement from "./Agreement";
import CreateIndProfileForm from "./CreateIndProfileForm";

interface Props {
  formId: string;
  onSuccessfulEdit: (profile: FreelanceProfileFragment) => void;
  setIsChecked: () => void;
  profile?: FreelanceProfileFragment;
  isChecked: boolean;
}

export default ({
  formId,
  onSuccessfulEdit,
  setIsChecked,
  profile,
  isChecked,
}: Props): JSX.Element => {
  const { session, isLoading } = useSessionQuery();
  const identity = session?.account.defaultIdentity;

  return (
    <div className={tw("w-full", "flex", "flex-col", "space-y-6")}>
      <div className={tw("p-4", "mb-4", "bg-blue-900")}>
        <img className={tw("h-9")} src={lonn} alt="Lønn" />
      </div>

      <div className={tw("w-full", "flex", "flex-col", "space-y-4")}>
        {isLoading ? (
          <div className={tw("w-full")}>
            <Spinner />
          </div>
        ) : (
          <CreateIndProfileForm
            identity={identity}
            onSuccessfulEdit={onSuccessfulEdit}
            freelanceProfile={profile}
            formId={formId}
          />
        )}
      </div>

      <Agreement
        isChecked={isChecked}
        onChecked={setIsChecked}
        isOrganization={false}
      />
    </div>
  );
};
