import { gql } from "@apollo/client";

import { Invoice, InvoiceEvent } from "graphql/types";

import { ADDRESS_FRAGMENT } from "./address";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { BANK_ACCOUNT_FRAGMENT, BankAccountFragment } from "./bankAccount";
import { CLIENT_FRAGMENT, ClientFragment } from "./client";
import { CREDIT_NOTE_FRAGMENT, CreditNoteFragment } from "./creditNote";
import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "./freelanceProfile";
import { WORK_LINE_FRAGMENT, WorkLine } from "./invoiceLine";
import { RECEIPT_FRAGMENT, ReceiptFragment } from "./receipt";
import {
  REFERENCE_PERSON_FRAGMENT,
  ReferencePersonFragment,
} from "./referencePerson";

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    id
    events {
      actor
      eventType
      occurredAt
      status
    }
    client {
      ...ClientFragment
    }
    lines {
      ...WorkLineFragment
    }
    clientReferencePerson {
      ...ReferencePersonFragment
    }
    senderReferencePerson {
      ...ReferencePersonFragment
    }
    freelanceProfile {
      ...FreelanceProfileFragment
    }
    creditNotes {
      ...CreditNoteFragment
    }
    bankAccount {
      ...BankAccountFragment
    }
    status
    platformFee
    employerTax
    salaryTax
    amount
    vat
    platformFeeAmount
    employerTaxAmount
    salaryTaxAmount
    grossSalary
    payoutAmount
    invoiceNumber
    pdfDocument {
      ...AttachmentFragment
    }
    receipts {
      ...ReceiptFragment
    }
    dueOn
    invoicedOn
    createdAt
    submittedAt
    approvedAt
    publishedAt
    rejectedAt
    resolvedAt
    buyerReference
    orderReference
    kid
    refundStatus
    currency
    remainingAmount
    remainingVat
    remainingPlatformFeeAmount
    remainingEmployerTaxAmount
    remainingSalaryTaxAmount
    remainingGrossSalary
    remainingPayoutAmount
  }
  ${CLIENT_FRAGMENT}
  ${FREELANCE_PROFILE_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${WORK_LINE_FRAGMENT}
  ${CREDIT_NOTE_FRAGMENT}
  ${BANK_ACCOUNT_FRAGMENT}
  ${RECEIPT_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export interface InvoiceFragment
  extends Pick<
    Invoice,
    | "id"
    | "status"
    | "platformFee"
    | "employerTax"
    | "salaryTax"
    | "amount"
    | "vat"
    | "platformFeeAmount"
    | "employerTaxAmount"
    | "salaryTaxAmount"
    | "grossSalary"
    | "payoutAmount"
    | "invoiceNumber"
    | "dueOn"
    | "invoicedOn"
    | "createdAt"
    | "submittedAt"
    | "approvedAt"
    | "publishedAt"
    | "rejectedAt"
    | "resolvedAt"
    | "buyerReference"
    | "orderReference"
    | "kid"
    | "refundStatus"
    | "currency"
    | "remainingAmount"
    | "remainingVat"
    | "remainingPlatformFeeAmount"
    | "remainingEmployerTaxAmount"
    | "remainingSalaryTaxAmount"
    | "remainingGrossSalary"
    | "remainingPayoutAmount"
  > {
  events: Pick<InvoiceEvent, "actor" | "eventType" | "occurredAt" | "status">[];
  client?: ClientFragment;
  lines: WorkLine[];
  clientReferencePerson?: ReferencePersonFragment;
  senderReferencePerson?: ReferencePersonFragment;
  freelanceProfile: FreelanceProfileFragment;
  receipts: ReceiptFragment[];
  creditNotes: CreditNoteFragment[];
  bankAccount?: BankAccountFragment;
  pdfDocument?: AttachmentFragment;
}
