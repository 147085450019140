import { SessionFragment, SubscriptionFragment } from "graphql/fragments";
import { FeatureEnum } from "graphql/types";

export default (
  session: SessionFragment,
  feature: FeatureEnum
): SubscriptionFragment => {
  switch (feature) {
    case FeatureEnum.Checks:
      return session.account.checksSubscription;

    case FeatureEnum.Whistleblowing:
    default:
      return session.account.whistleblowingSubscription;
  }
};
