/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { PEOPLE, PeopleQuery } from "graphql/queries";
import { PeopleFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const UNARCHIVE_PEOPLE = gql`
  mutation ($ids: [ID!], $filter: PeopleFilter) {
    unarchivePeople(input: { ids: $ids, filter: $filter }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<PeopleFilter>;

interface Payload {
  errors: ErrorFragment[];
}

interface UnarchivePeopleMutation {
  unarchivePeople: Payload | null;
}

export const useUnarchivePeopleMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { loading }] = useMutation<UnarchivePeopleMutation, Input>(
    UNARCHIVE_PEOPLE
  );

  const unarchivePeople = (variables: Input, onSuccess?: () => void) =>
    mutation({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            people() {
              const existingPeople = cache.readQuery<PeopleQuery>({
                query: PEOPLE,
              });
              if (!existingPeople?.people.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const items = isSome
                ? existingPeople.people.items?.filter(
                    (person) =>
                      !(variables as { ids: string[] }).ids.includes(person.id)
                  )
                : [];
              const total = isSome
                ? existingPeople.people.total -
                  (variables as { ids: string[] }).ids.length
                : 0;

              cache.writeQuery<PeopleQuery>({
                query: PEOPLE,
                data: {
                  people: {
                    items,
                    total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.unarchivePeople?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    unarchivePeople,
    isLoading: loading,
  };
};
