/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_HANDLER_FRAGMENT,
  WbHandlerFragment,
} from "graphql/fragments";
import { WB_HANDLERS, WbHandlersQuery } from "graphql/queries";
import { WbHandlerAttributes } from "graphql/types";

const CREATE_WB_HANDLER = gql`
  mutation ($accountId: ID!, $attributes: WbHandlerAttributes!) {
    createWbHandler(input: { accountId: $accountId, attributes: $attributes }) {
      wbHandler {
        ...WbHandlerFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_HANDLER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbHandler?: WbHandlerFragment | null;
  errors: ErrorFragment[];
}

interface CreateWbHandlerMutation {
  createWbHandler: Payload | null;
}

export const useCreateWbHandlerMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateWbHandlerMutation,
    { accountId: string; attributes: WbHandlerAttributes }
  >(CREATE_WB_HANDLER);

  const createWbHandler = (
    accountId: string,
    attributes: WbHandlerAttributes,
    onSuccess?: (wbHandler: WbHandlerFragment) => void
  ) =>
    mutation({
      variables: { accountId, attributes },
      update(cache, { data: newJobData }) {
        const newNode = newJobData?.createWbHandler?.wbHandler;
        if (!newNode) return;

        cache.modify({
          fields: {
            wbHandlers() {
              const existing = cache.readQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
              });
              if (!existing) return;

              const items = [newNode, ...(existing.wbHandlers.items ?? [])];

              cache.writeQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
                data: {
                  wbHandlers: {
                    items,
                    total: existing.wbHandlers.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createWbHandler?.errors ?? [];
        const wbHandler = data?.createWbHandler?.wbHandler;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbHandler && onSuccess(wbHandler),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createWbHandler,
    isLoading: loading,
    wbHandler: data?.createWbHandler?.wbHandler,
    errors: data?.createWbHandler?.errors ?? [],
  };
};
