import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { SubscriptionBanner } from "components/account/composite";
import { Button, CheckBox, Link } from "components/common/basic";
import { Banner } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { PricePlanFragment } from "graphql/fragments";
import { useUpdateSubscriptionMutation } from "graphql/mutations";
import { usePricePlansQuery } from "graphql/queries";
import { FeatureEnum, TeamRoleEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { getSubscriptionFromSession } from "utils";
import { tw } from "utils/tw";

import PriceChanger from "./PriceChanger";

interface FormValues {
  consent: boolean;
}

interface Props {
  feature: FeatureEnum;
  direction: "upgrade" | "downgrade";
  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
}

export default ({
  feature,
  onSuccess,
  ...props
}: Props): JSX.Element | null => {
  const [direction, setDirection] = useState(props.direction);
  const [newPricePlan, setNewPricePlan] = useState<PricePlanFragment>();
  const { pricePlans } = usePricePlansQuery({ filter: { feature } });
  const { updateSubscription } = useUpdateSubscriptionMutation();

  const { t } = useTranslation("common");
  const { openPopup, closeAllPopups } = useContext(PopupContext);
  const { session } = useContext(AuthContext);

  if (!session) return null;

  const onSubmit = () => {
    if (!newPricePlan) return closeAllPopups();

    openPopup({
      id: "StepLoader",
      props: {
        title: t(
          "popup.subscriptionChange.fakeLoader.title",
          "Updating subscription"
        ),
        steps: [
          t(
            "popup.subscriptionChange.fakeLoader.step1",
            "Checking information"
          ),
          t("popup.subscriptionChange.fakeLoader.step2", "Changing limits"),
          t(
            "popup.subscriptionChange.fakeLoader.step3",
            "Updating subscription"
          ),
        ],
        action: (onSuccess, onError) =>
          updateSubscription(newPricePlan.id, onSuccess, onError),
        isAutoComplete: true,
      },
    });
  };

  const validationSchema = Yup.object<FormValues>().shape({
    consent: Yup.boolean()
      .oneOf(
        [true],
        t(
          "popup.subscriptionChange.acceptTerms.error.required",
          "Please read and consent to the terms and conditions"
        )
      )
      .required(),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const currentSubscription = getSubscriptionFromSession(session, feature);
  const isCheckmore = feature === FeatureEnum.Checks;

  const title =
    direction === "upgrade"
      ? isCheckmore
        ? t(
            "popup.subscriptionChange_checkmore_upgrade.title",
            "Add more people"
          )
        : t(
            "popup.subscriptionChange_tellmore_upgrade.title",
            "Add more handlers"
          )
      : isCheckmore
      ? t(
          "popup.subscriptionChange_checkmore_downgrade.title",
          "Reduce licenses"
        )
      : t(
          "popup.subscriptionChange_tellmore_downgrade.title",
          "Reduce licenses"
        );

  const isEnterprise = currentSubscription.pricePlan.enterprise;
  const shouldAcceptTerms =
    !isEnterprise && currentSubscription.pricePlan.id !== newPricePlan?.id;

  return (
    <PopupWrapper {...props} title="" nameSpace="account">
      <div
        className={tw(
          "mx-auto",
          "w-full",
          "max-w-lg",
          "bg-white",
          "shadow-md",
          "rounded-lg",
          "overflow-hidden"
        )}
      >
        <div
          className={tw(
            "p-4",
            "flex",
            "space-x-4",
            "items-center",
            "bg-deepBlue-900"
          )}
        >
          <h2 className={tw("text-white", "text-lg", "font-bold")}>{title}</h2>
        </div>

        <div className={tw("px-4", "pt-6", "pb-5", "space-y-8")}>
          <Banner
            variant="success"
            verticallyCentered
            body={
              isCheckmore
                ? t(
                    "popup.subscriptionChange_checkmore.usageBanner.text",
                    "You have used {{ usage }} of {{ limit }} people",
                    {
                      usage: currentSubscription.usage,
                      limit: currentSubscription.pricePlan.limit,
                    }
                  )
                : t(
                    "popup.subscriptionChange_tellmore.usageBanner.text",
                    "You have used {{ usage }} of {{ limit }} handlers",
                    {
                      usage: currentSubscription.usage,
                      limit: currentSubscription.pricePlan.limit,
                    }
                  )
            }
          />

          {feature === FeatureEnum.Checks &&
          session?.role === TeamRoleEnum.Member ? (
            <SubscriptionBanner feature={feature} variant="contactAdmin" />
          ) : isEnterprise ? (
            <SubscriptionBanner feature={feature} variant="contactSales" />
          ) : (
            pricePlans.length > 0 && (
              <PriceChanger
                direction={direction}
                setDirection={setDirection}
                feature={feature}
                subscription={currentSubscription}
                pricePlans={pricePlans}
                onChange={setNewPricePlan}
              />
            )
          )}

          <form className={tw("space-y-4")} onSubmit={handleSubmit(onSubmit)}>
            {shouldAcceptTerms && (
              <CheckBox
                id="consent"
                {...register("consent")}
                label={
                  <Trans
                    ns="account"
                    i18nKey="popup.subscriptionChange.acceptTerms"
                    defaults="I accept the <0>terms and conditions</0>"
                    components={[
                      <Link
                        id="subscription_change_popup-go_to_terms"
                        to={t(
                          "common:links.terms",
                          "https://www.manymore.com/en/terms-of-use"
                        )}
                        isExternal
                      />,
                    ]}
                  />
                }
                errorMessage={errors.consent?.message}
              />
            )}

            {!shouldAcceptTerms ? (
              <Button
                id="subscription_change_popup-close_popup"
                onClick={closeAllPopups}
                variant="primary"
                fullWidth
              >
                {t("popup.subscriptionChange.button.close", "Close")}
              </Button>
            ) : (
              <div className={tw("flex", "flex-col", "lg:flex-row", "gap-6")}>
                {direction === "downgrade" && (
                  <Button
                    id="subscription_change_popup-close_popup"
                    onClick={closeAllPopups}
                    variant="secondary"
                    fullWidth
                  >
                    {t("popup.subscriptionChange.button.cancel", "Cancel")}
                  </Button>
                )}

                <Button
                  id="subscription_change_popup-save_changes"
                  type="submit"
                  variant={direction === "upgrade" ? "primary" : "secondary"}
                  fullWidth
                >
                  {t("popup.subscriptionChange.button.save", "Save")}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </PopupWrapper>
  );
};
