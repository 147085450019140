import { gql } from "@apollo/client";

import { WbMessage } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  PUBLIC_WB_HANDLER_FRAGMENT,
  PublicWbHandlerFragment,
} from "./publicWbHandler";

export const WB_MESSAGE_FRAGMENT = gql`
  fragment WbMessageFragment on WbMessage {
    id
    type
    internal
    content
    files {
      ...AttachmentFragment
    }
    seen
    sentAt
    wbHandler {
      ...PublicWbHandlerFragment
    }
    oldType
    oldCategory
    newType
    newCategory
    addedHandlers {
      ...PublicWbHandlerFragment
    }
    removedHandlers {
      ...PublicWbHandlerFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${PUBLIC_WB_HANDLER_FRAGMENT}
`;

export interface WbMessageFragment
  extends Pick<
    WbMessage,
    | "id"
    | "type"
    | "internal"
    | "content"
    | "files"
    | "seen"
    | "sentAt"
    | "wbHandler"
    | "oldType"
    | "oldCategory"
    | "newType"
    | "newCategory"
  > {
  files: AttachmentFragment[];
  wbHandler: PublicWbHandlerFragment;
  addedHandlers: PublicWbHandlerFragment[];
  removedHandlers: PublicWbHandlerFragment[];
}
