/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { PERSON_FRAGMENT, PersonFragment } from "graphql/fragments";
import { QueryPeopleArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const PEOPLE = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: PeopleFilter
    $sortBy: PeopleSortByEnum
  ) {
    people(page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy) {
      exportPath
      items {
        ...PersonFragment
      }
      total
    }
  }
  ${PERSON_FRAGMENT}
`;

export type PeopleQuery = {
  people: PaginatedPayload<PersonFragment>;
};

type QueryArgs = Pick<
  QueryPeopleArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const usePeopleQuery = (args: QueryArgs = {}) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<PeopleQuery, QueryArgs>(PEOPLE, {
    variables: { ...args },
  });

  const people = data?.people.items?.filter(Boolean) ?? [];
  const total = data?.people.total ?? 0;

  return { people, total, isLoading, called };
};

export const usePeopleLazyQuery = () => {
  const [getPeopleQuery, { data, loading: isLoading, called }] = useLazyQuery<
    PeopleQuery,
    QueryArgs
  >(PEOPLE, { fetchPolicy: "network-only" });

  const exportPath = data?.people.exportPath;
  const people = data?.people.items?.filter(Boolean) ?? [];
  const total = data?.people.total ?? 0;

  const resolveRef = useRef<(people?: PersonFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(people);
      resolveRef.current = undefined;
    }
  }, [people, called, isLoading]);

  const getPeople = async (args?: QueryArgs) => {
    const variables = { ...args };

    getPeopleQuery({ variables });

    return new Promise<PersonFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getPeople, exportPath, people, total, isLoading };
};
