import { FieldValues, UseFormRegister } from "react-hook-form";

import { TTailwindString, tw } from "utils/tw";

interface Option {
  value: string | number;
  label: string;
  key?: string;
  isDisabled?: boolean;
}

export type InputType =
  | { type: "text"; placeholder: string }
  | { type: "email"; placeholder: string }
  | { type: "phone"; placeholder: string }
  | { type: "date" }
  | { type: "select"; multiple: boolean; options: Option[] };

interface Props {
  id: string;
  cellStyles: TTailwindString;
  label: string;
  value: string;
  input: InputType;
  register: UseFormRegister<FieldValues>;
  hasError: boolean;
}

export default ({
  id,
  cellStyles,
  label,
  input,
  value,
  register,
  hasError,
}: Props): JSX.Element => {
  const cellInputStyles = tw(
    "py-4",
    "px-6",
    "h-full",
    "w-full",
    "sm:text-sm",
    "outline-none",
    "border-none",
    "ring-inset",
    "focus-within:ring-2",
    "focus-within:ring-blue-500"
  );
  const errorStyles = tw(
    "ring-2",
    "ring-error",
    "bg-error-light",
    "text-error"
  );

  return (
    <div className={cellStyles}>
      <label htmlFor={id} className={tw("sr-only")}>
        {label}
      </label>

      {(() => {
        const inputStyles = tw(cellInputStyles, {
          [errorStyles]: hasError,
        });

        switch (input.type) {
          case "text":
          case "email":
          case "phone":
            return (
              <input
                className={inputStyles}
                id={id}
                {...register(id)}
                defaultValue={value}
                placeholder={input.placeholder}
              />
            );

          case "date":
            return (
              <input
                className={inputStyles}
                id={id}
                {...register(id)}
                defaultValue={value}
                type="date"
              />
            );

          case "select":
            return (
              <select className={inputStyles} id={id} {...register(id)}>
                {input.options.map(({ label, value, key, isDisabled }) => (
                  <option
                    key={key ?? value}
                    value={value}
                    disabled={isDisabled}
                  >
                    {label}
                  </option>
                ))}
              </select>
            );

          default:
            return ((_type: never) => null)(input);
        }
      })()}
    </div>
  );
};
