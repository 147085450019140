import { useContext } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";

import { AccountFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { tw } from "utils/tw";

interface Props {
  account: AccountFragment;
  heading: string;
  description: string;
  isEditable?: boolean;
}

export default ({
  account,
  heading,
  description,
  isEditable,
}: Props): JSX.Element => {
  const { openPopup } = useContext(PopupContext);

  return (
    <div>
      <div className={tw("space-y-6")}>
        <div className={tw("space-y-2")}>
          <div className={tw("flex", "justify-between")}>
            <h2 className={tw("text-lg", "font-bold")}>{heading}</h2>

            {account.canManage && isEditable && (
              <button onClick={() => openPopup({ id: "AccountEmail" })}>
                <HiOutlinePencilAlt />
              </button>
            )}
          </div>

          <p className={tw("text-sm", "text-deepBlue-500")}>{description}</p>
        </div>

        <p className={tw("text-sm", "text-deepBlue-500")}>{account.email}</p>
      </div>
    </div>
  );
};
