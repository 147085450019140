/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_FRAGMENT,
  JobFragment,
} from "graphql/fragments";
import { UpdateJobInput } from "graphql/types";

const UPDATE_JOB = gql`
  mutation ($input: UpdateJobInput!) {
    updateJob(input: $input) {
      job {
        ...JobFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  job?: JobFragment | null;
  errors: ErrorFragment[];
}

interface UpdateJobMutation {
  updateJob: Payload | null;
}

export const useUpdateJobMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateJobMutation,
    { input: UpdateJobInput }
  >(UPDATE_JOB);

  const updateJob = (
    input: UpdateJobInput,
    onSuccess?: (job: JobFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateJob?.errors ?? [];
        const job = data?.updateJob?.job;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => job && onSuccess && onSuccess(job),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateJob,
    isLoading: loading,
    job: data?.updateJob?.job,
    errors: data?.updateJob?.errors ?? [],
  };
};
