import { useContext, useState } from "react";

import { FreelanceProfileFragment } from "graphql/fragments";
import { FreelanceTypeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { PopupWrapper } from "../PopupWrapper";
import CreateIndividualProfile from "./CreateIndProfile";
import CreateOrganizationProfile from "./CreateOrgProfile";

interface Props {
  hasPrevious: boolean;
  freelanceType: FreelanceTypeEnum;
  profile?: FreelanceProfileFragment;
  onSuccess?: (profile: FreelanceProfileFragment) => void;
}

export default ({
  freelanceType,
  profile,
  onSuccess,
  ...props
}: Props): JSX.Element => {
  const [hasCheckedDocument, setHasCheckedDocument] = useState(
    Boolean(profile?.id)
  );

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  const onSuccessfulEdit = (profile: FreelanceProfileFragment) => {
    onSuccess?.(profile);
    closeAllPopups();
  };

  const formId = "freelance-profile-form";

  return (
    <PopupWrapper
      {...props}
      title={
        profile
          ? t("popup.freelanceProfile_edit.heading", "Edit your payout profile")
          : t(
              "popup.freelanceProfile_create.heading",
              "Add your payout profile"
            )
      }
      variant="popup"
      footerActions={[
        {
          id: "freelance_profile-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.freelanceProfile.cancel", "Cancel"),
        },
        {
          id: "freelance_profile-save",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.freelanceProfile.submit", "Save"),
          disabled: !hasCheckedDocument,
        },
      ]}
    >
      {freelanceType === FreelanceTypeEnum.Organization ? (
        <CreateOrganizationProfile
          formId={formId}
          onSuccessfulEdit={onSuccessfulEdit}
          setIsChecked={() => setHasCheckedDocument(true)}
          isChecked={hasCheckedDocument}
          profile={profile}
        />
      ) : (
        <CreateIndividualProfile
          formId={formId}
          onSuccessfulEdit={onSuccessfulEdit}
          setIsChecked={() => setHasCheckedDocument(true)}
          isChecked={hasCheckedDocument}
          profile={profile}
        />
      )}
    </PopupWrapper>
  );
};
