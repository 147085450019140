import React, { useEffect } from "react";

import PopoverComponent, { Popover } from "./Popover";
import usePopovers, { UsePopoverReturn } from "./usePopovers";

interface Context extends Omit<UsePopoverReturn, "popovers"> {
  shownPopovers: Popover[];
}

export const PopoverContext = React.createContext<Context>({
  registerPopover: () => null,
  unregisterPopover: () => null,
  setIsShownPopover: () => null,
  updatePopoverContent: () => null,
  clearPopovers: () => null,
  shownPopovers: [],
});

interface Props {
  children?: React.ReactNode;
}

export const PopoverProvider = ({ children }: Props): JSX.Element => {
  const {
    shownPopovers,
    registerPopover,
    unregisterPopover,
    updatePopoverContent,
    setIsShownPopover,
    clearPopovers,
  } = usePopovers();

  // Close popovers on browser navigation
  useEffect(() => {
    window.addEventListener("popstate", clearPopovers);

    return () => window.removeEventListener("popstate", clearPopovers);
  }, []);

  return (
    <PopoverContext.Provider
      value={{
        shownPopovers,
        registerPopover,
        unregisterPopover,
        updatePopoverContent,
        setIsShownPopover,
        clearPopovers,
      }}
    >
      {children}

      <div id="popovers">
        {shownPopovers.map((props) => (
          <PopoverComponent key={props.id} {...props} />
        ))}
      </div>
    </PopoverContext.Provider>
  );
};
