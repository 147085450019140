import { gql } from "@apollo/client";

import { PublicWbReport } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  PUBLIC_WB_ACCOUNT_FRAGMENT,
  PublicWbAccountFragment,
} from "./publicWbAccount";
import {
  PUBLIC_WB_HANDLER_FRAGMENT,
  PublicWbHandlerFragment,
} from "./publicWbHandler";
import { WB_MESSAGE_FRAGMENT, WbMessageFragment } from "./wbMessage";

export const PUBLIC_WB_REPORT_FRAGMENT = gql`
  fragment PublicWbReportFragment on PublicWbReport {
    id
    accessCode
    reportNumber
    downloadPath
    status
    type
    privacy
    action
    category
    description
    message
    email
    firstName
    lastName
    location
    people
    timing
    lastInteractionAt
    submittedAt
    deletedBy
    deletedAt
    closedBy
    closedAt
    abandoned
    files {
      ...AttachmentFragment
    }
    routineGuidelines {
      ...AttachmentFragment
    }
    account {
      ...PublicWbAccountFragment
    }
    wbHandlers {
      ...PublicWbHandlerFragment
    }
    phoneNumber {
      countryCode
      number
    }
    wbMessages {
      ...WbMessageFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${PUBLIC_WB_ACCOUNT_FRAGMENT}
  ${PUBLIC_WB_HANDLER_FRAGMENT}
  ${WB_MESSAGE_FRAGMENT}
`;

export interface PublicWbReportFragment
  extends Pick<
    PublicWbReport,
    | "id"
    | "accessCode"
    | "reportNumber"
    | "downloadPath"
    | "status"
    | "type"
    | "privacy"
    | "action"
    | "category"
    | "description"
    | "message"
    | "email"
    | "firstName"
    | "lastName"
    | "location"
    | "people"
    | "timing"
    | "lastInteractionAt"
    | "submittedAt"
    | "deletedBy"
    | "deletedAt"
    | "closedBy"
    | "closedAt"
    | "abandoned"
    | "files"
    | "wbHandlers"
    | "phoneNumber"
    | "wbMessages"
  > {
  files: AttachmentFragment[];
  routineGuidelines: AttachmentFragment[];
  account: PublicWbAccountFragment;
  wbHandlers: PublicWbHandlerFragment[];
  phoneNumber: { countryCode?: string; number?: string };
  wbMessages: WbMessageFragment[];
}
