import React, { useContext, useEffect, useRef, useState } from "react";

import SignaturePad from "signature_pad";

import { Button, Input } from "components/common/basic";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupWrapper } from "./PopupWrapper";

interface Props {
  hasPrevious: boolean;
  documentName: string;
  onSignature: (data: string) => void;
}

export default ({
  documentName,
  onSignature,
  ...props
}: Props): JSX.Element => {
  const [hasSignature, setHasSignature] = useState(false);
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();

  const inputRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    const p = 20; // Horizontal padding
    const w = canvasRef.current.offsetWidth - p;
    const h = canvasRef.current.offsetHeight;

    const textWidth = context.measureText(event.target.value).width;
    context.fillText(
      event.target.value,
      Math.max(w / 2 - textWidth / 2, p / 2), // Centers text
      h / 2,
      w
    );

    setHasSignature(event.target.value.length > 0);
  };

  const clearSignaturePad = () => {
    if (!signaturePad) return;

    signaturePad.clear();
    setHasSignature(false);
  };

  const submitSignature = () => {
    if (!canvasRef.current || !hasSignature) return;

    const data = canvasRef.current.toDataURL();
    onSignature(data);

    // Test image without uploading:
    // canvasRef.current.toBlob((blob) => {
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement("a");
    //   a.className = tw("hidden");
    //   a.href = url;

    //   document.body.appendChild(a);
    //   a.click();

    //   URL.revokeObjectURL(url);
    // });
  };

  // Create signature pad
  useEffect(() => {
    if (signaturePad) return;

    if (canvasRef.current)
      setSignaturePad(
        new SignaturePad(canvasRef.current, {
          backgroundColor: "rgb(255, 255, 255)",
        })
      );
  }, [canvasRef.current]);

  // Setup for signature pad
  const fontFamily = "Reenie Beanie";
  useEffect(() => {
    if (!signaturePad || !canvasRef.current) return;

    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    // Prevents blurry text on HD screens
    const ratio = Math.max(window.devicePixelRatio, 1);
    const w = canvasRef.current.offsetWidth;
    const h = canvasRef.current.offsetHeight;
    canvasRef.current.width = w * ratio;
    canvasRef.current.height = h * ratio;
    canvasRef.current.style.width = w + "px";
    canvasRef.current.style.height = h + "px";
    context.scale(ratio, ratio);

    const fontStyles = "";
    const fontSize = 48;
    context.font = `${fontStyles} ${fontSize}px ${fontFamily}`;

    signaturePad.clear();
  }, [signaturePad]);

  // Clear text and enable submit button when starting to draw
  useEffect(() => {
    if (!signaturePad) return;

    const onBegin = () => {
      if (inputRef.current?.value) {
        inputRef.current.value = "";
        signaturePad.clear();
      }

      setHasSignature(true);
    };
    signaturePad.addEventListener("beginStroke", onBegin);
    return () => signaturePad.removeEventListener("beginStroke", onBegin);
  }, [signaturePad]);

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t("popup.signDocument.heading", "Sign document")}
      subTitle={t(
        "popup.signDocument.body",
        "Add your signature in the box below. By clicking Sign document I am signing the document “{{ documentName }}” and agree to be legally bound by this digital signature - Just the same as a pen-and-paper signature by using initials",
        { documentName }
      )}
      footerActions={[
        {
          id: "signature-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.signDocument.cancel", "Cancel"),
        },
        {
          id: "signature-sign_document",
          onClick: submitSignature,
          variant: "primary",
          label: t("popup.signDocument.submit", "Sign document"),
          disabled: !hasSignature,
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        <div
          className={tw(
            "grid",
            "w-full",
            "border-2",
            "border-gray-100",
            "items-center"
          )}
        >
          <div
            style={{ fontFamily }}
            className={tw(
              "text-transparent",
              "pointer-events-none",
              "fixed",
              "-top-48"
            )}
          >
            Canvas font preloader
          </div>

          <canvas
            className={tw(
              "w-full",
              "row-span-full",
              "col-span-full",
              "z-0",
              "justify-self-stretch",
              "h-48"
            )}
            ref={canvasRef}
          />

          {!hasSignature && (
            <p
              className={tw(
                "row-span-full",
                "col-span-full",
                "z-10",
                "mx-auto",
                "pointer-events-none",
                "select-none"
              )}
            >
              {t(
                "popup.signDocument.canvas.helpText",
                "Draw your signature here"
              )}
            </p>
          )}
        </div>

        <form
          className={tw("w-full", "flex", "flex-col", "space-y-4")}
          autoComplete="off"
        >
          <div className={tw("flex", "space-x-4")}>
            {/* <Button
                id="signature-undo"
                size="sm"
                variant="tertiary"
                onClick={() => {
                  if (!signaturePad) return;

                  const data = signaturePad.toData();
                  if (!data) return;

                  signaturePad.fromData(data.slice(0, -1));
                  if (data.length === 1) setHasSignature(false);
                }}
              >
                <Trans ns="common" i18nKey="popup.signDocument.action.undo">
                  Undo
                </Trans>
              </Button> */}

            <Button
              id="signature-clear"
              size="sm"
              variant="tertiary"
              onClick={clearSignaturePad}
              type="reset"
            >
              {t("popup.signDocument.action.clear", "Clear")}
            </Button>
          </div>

          <Input
            id="name"
            name="name"
            label={t(
              "popup.signDocument.input.name.label",
              "Or type your name and we will create a digital signature for you"
            )}
            placeholder={t(
              "popup.signDocument.input.name.placeholder",
              "My Name"
            )}
            onChange={onNameChange}
            ref={inputRef}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
