import { HiChevronLeft } from "react-icons/hi";

import { ActionID, Link } from "components/common/basic";
import { tw } from "utils/tw";

interface Props {
  previousPages: {
    id: ActionID;
    to: string;
    label: string;
  }[];
  currentPage: string;
}

export default ({ previousPages, currentPage }: Props): JSX.Element => {
  const crumbStyles = tw("flex", "items-center", "space-x-2", "text-gray-900");

  return (
    <div className={tw("flex", "space-x-2")}>
      {previousPages.map(({ id, to, label }) => (
        <Link
          key={id}
          id={id}
          to={to}
          unstyled
          className={tw(crumbStyles, "hover:text-gray-700")}
        >
          <HiChevronLeft size={24} />

          <span>{label}</span>
        </Link>
      ))}

      <div className={crumbStyles}>
        <HiChevronLeft size={24} />

        <span>{currentPage}</span>
      </div>
    </div>
  );
};
