/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_HANDLER_FRAGMENT,
  WbHandlerFragment,
} from "graphql/fragments";
import { WB_HANDLERS, WbHandlersQuery } from "graphql/queries";

const SET_DEFAULT_WB_HANDLER = gql`
  mutation ($wbHandlerId: ID!) {
    setDefaultWbHandler(input: { id: $wbHandlerId }) {
      wbHandler {
        ...WbHandlerFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_HANDLER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbHandler?: WbHandlerFragment | null;
  errors: ErrorFragment[];
}

interface SetDefaultWbHandlerMutation {
  setDefaultWbHandler: Payload | null;
}

export const useSetDefaultWbHandlerMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    SetDefaultWbHandlerMutation,
    { wbHandlerId: string }
  >(SET_DEFAULT_WB_HANDLER);

  const setDefaultWbHandler = (
    wbHandlerId: string,
    onSuccess?: (wbHandler: WbHandlerFragment) => void
  ) =>
    mutation({
      variables: { wbHandlerId },
      update(cache) {
        cache.modify({
          fields: {
            wbHandlers() {
              const existing = cache.readQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
              });
              if (!existing) return;

              const items = existing.wbHandlers.items?.map((wbHandler) => ({
                ...wbHandler,
                default: wbHandler.id === wbHandlerId,
              }));

              cache.writeQuery<WbHandlersQuery>({
                query: WB_HANDLERS,
                data: {
                  wbHandlers: {
                    ...existing.wbHandlers,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.setDefaultWbHandler?.errors ?? [];
        const wbHandler = data?.setDefaultWbHandler?.wbHandler;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbHandler && onSuccess(wbHandler),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setDefaultWbHandler,
    isLoading: loading,
    wbHandler: data?.setDefaultWbHandler?.wbHandler,
    errors: data?.setDefaultWbHandler?.errors ?? [],
  };
};
