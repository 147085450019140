import { useContext, useEffect, useState } from "react";

import { ErrorFragment } from "graphql/fragments";
import { ErrorCodeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { PopupWrapper } from "../PopupWrapper";
import { StepLoader as StepLoaderComponent } from "./FakeStepLoader";

interface Props {
  hasPrevious: boolean;
  title: string;
  steps: string[];
  action: (
    onSuccess: () => void,
    onError: (errors: ErrorFragment[]) => void
  ) => void;
  isAutoComplete?: boolean;
  onComplete?: () => void;
}

export default ({
  steps,
  action,
  isAutoComplete,
  onComplete,
  ...props
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stepErrorIndex, setStepErrorIndex] = useState(-1);
  const [isCompleted, setIsCompleted] = useState(false);

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  useEffect(() => {
    action(
      () => setIsLoading(false),
      (errors) => {
        if (!errors.length) return;

        const isValidationError = errors.some(
          (error) => error.code === ErrorCodeEnum.ValidationFailed
        );
        setStepErrorIndex(isValidationError ? 1 : 2);
        setIsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (isCompleted && isAutoComplete)
      setTimeout(() => {
        if (stepErrorIndex === -1) onComplete?.();

        closeAllPopups();
      }, 1000);
  }, [stepErrorIndex, isCompleted]);

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      popupSize="sm"
      isClosable={false}
      footerActions={
        isAutoComplete
          ? undefined
          : [
              {
                disabled: !isCompleted,
                id: "step_loader-close_popup",
                onClick: closeAllPopups,
                label: t("popup.stepLoader.button.continue", "Continue"),
              },
            ]
      }
    >
      <StepLoaderComponent
        steps={steps}
        setIsCompleted={setIsCompleted}
        stepErrorIndex={stepErrorIndex}
        isLoading={isLoading}
      />
    </PopupWrapper>
  );
};
