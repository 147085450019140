/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_LIST_FRAGMENT,
  CheckListFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SENT_CHECK_LISTS, SentCheckListsQuery } from "graphql/queries";
import { CheckListsFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const REMIND_CHECK_LISTS = gql`
  mutation ($ids: [ID!], $filter: CheckListsFilter) {
    remindCheckLists(input: { ids: $ids, filter: $filter }) {
      checkLists {
        ...CheckListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<CheckListsFilter>;

interface Payload {
  checkLists?: CheckListFragment[];
  errors: ErrorFragment[];
}

interface RemindCheckListsMutation {
  remindCheckLists: Payload | null;
}

export const useRemindCheckListsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.remindCheckLists",
    "Reminder(s) sent successfully"
  );
  // Values for extraction
  // t("common:success.remindCheckLists", "Reminder(s) sent successfully")

  const [mutation, { data, loading }] = useMutation<
    RemindCheckListsMutation,
    Input
  >(REMIND_CHECK_LISTS);

  const remindCheckLists = (variables: Input, onSuccess?: () => void) =>
    mutation({
      variables,
      update(cache, { data }) {
        cache.modify({
          fields: {
            sentCheckLists() {
              const updatedCheckLists = data?.remindCheckLists?.checkLists;
              if (!updatedCheckLists) return;

              const existingCheckLists = cache.readQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
              });
              if (!existingCheckLists?.sentCheckLists.items) return;

              const items = existingCheckLists.sentCheckLists.items?.map(
                (checkListItem) =>
                  updatedCheckLists?.find(
                    ({ id }) => checkListItem.id === id
                  ) ?? checkListItem
              );

              cache.writeQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
                data: {
                  sentCheckLists: {
                    ...existingCheckLists.sentCheckLists,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.remindCheckLists?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    remindCheckLists,
    isLoading: loading,
    errors: data?.remindCheckLists?.errors ?? [],
  };
};
