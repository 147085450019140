import { PropsWithChildren } from "react";

import { UseFloatingReturn } from "@floating-ui/react-dom";

import { TTailwindString, tw } from "utils/tw";

export interface PopoverTemplateProps {
  floatingReturn: UseFloatingReturn;
  isShown: boolean;
  className?: TTailwindString;
}

export default ({
  floatingReturn,
  isShown,
  className,
  children,
}: PropsWithChildren<PopoverTemplateProps>) => (
  <div
    className={tw(className, "z-50", { hidden: !isShown })}
    ref={floatingReturn.refs.setFloating}
    style={floatingReturn.floatingStyles}
  >
    {children}
  </div>
);
