import { ActionID, Button, Link } from "components/common/basic";
import { tw } from "utils/tw";

interface HeaderButtonBase {
  id: ActionID;
  label: string;
  isDisabled?: boolean;
}
interface HeaderButtonLink extends HeaderButtonBase {
  to: string;
  isExternal?: boolean;
}
interface HeaderButtonAction extends HeaderButtonBase {
  onClick: () => void;
}

export type HeaderButtonProps = HeaderButtonLink | HeaderButtonAction;

export default ({
  id,
  label,
  isDisabled = false,
  ...props
}: HeaderButtonProps): JSX.Element | null => {
  if (isDisabled) return null;

  const buttonProps = {
    id,
    variant: "secondary" as const,
    size: "sm" as const,
    className: tw("w-28"),
    disabled: isDisabled,
  };

  if (Object.prototype.hasOwnProperty.call(props, "onClick")) {
    const { onClick } = props as HeaderButtonAction;

    return (
      <Button {...buttonProps} onClick={onClick}>
        {label}
      </Button>
    );
  }

  if (Object.prototype.hasOwnProperty.call(props, "to")) {
    const { to, isExternal } = props as HeaderButtonLink;

    return (
      <Link id={id} to={to} isExternal={isExternal} unstyled>
        <Button {...buttonProps}>{label}</Button>
      </Link>
    );
  }

  return null;
};
