import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props) => {
  const { t } = useTranslation("checkmore");

  const videoId = t("onboardingWizard.videoPopup.videoId", "cBTnSwdHIsw");

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      variant="popup"
      title={t(
        "onboardingWizard.videoPopup.title",
        "See how Manymore works in 2 min!"
      )}
      subTitle={t(
        "onboardingWizard.videoPopup.description",
        "Not sure were to start? Check out our getting started video for a quick introduction."
      )}
    >
      <iframe
        className={tw("w-full", "h-80", "pb-4")}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="Embed videos and playlists"
        allowFullScreen
      />
    </PopupWrapper>
  );
};
