import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PopupContext } from "providers/PopupHandler";
import { TailwindContext } from "providers/Tailwind";
import { IntlContext } from "providers/i18n";
import { NameSpace } from "translations/shared";

import FullScreen from "./FullScreen";
import Popup from "./Popup";
import { PopupContentProps } from "./PopupContent/PopupContent";
import SlideOver from "./SlideOver";

export type PopupVariant = "fullScreen" | "popup" | "slideOver";

interface Props
  extends Omit<
    PopupContentProps,
    "onClose" | "goBack" | "isRounded" | "shouldAnimateIn"
  > {
  variant: PopupVariant;
  hasPrevious: boolean;
  popupSize?: "sm" | "md" | "lg";
  isClosable?: boolean;
  nameSpace?: NameSpace;
  onClose?: () => void;
}

export default ({
  variant,
  isClosable = true,
  nameSpace,
  onClose,
  ...props
}: PropsWithChildren<Props>) => {
  const [isLoadingNS, setIsLoadingNS] = useState(Boolean(nameSpace));

  const contextProps = useContext(PopupContext);
  const { isMd } = useContext(TailwindContext);
  const { i18n } = useTranslation();
  const { currentLocale } = useContext(IntlContext);

  // Load translations for namespace
  useEffect(() => {
    if (!nameSpace) return setIsLoadingNS(false);

    const hasResourceBundle = i18n.hasResourceBundle(currentLocale, nameSpace);
    if (hasResourceBundle) setIsLoadingNS(false);
    else i18n.loadNamespaces(nameSpace).then(() => setIsLoadingNS(false));
  }, [nameSpace]);

  // Closes menus relying on `useMenu`
  useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();

      window.dispatchEvent(new Event("keyup"));
    }
  }, []);

  const closeOne = onClose ?? contextProps.closeOnePopup;
  const closeAll = onClose ?? contextProps.closeAllPopups;

  if (isLoadingNS) return null;

  switch (isMd ? variant : "fullScreen") {
    case "popup":
      return <Popup {...props} onClose={isClosable ? closeOne : undefined} />;

    case "fullScreen":
      return (
        <FullScreen
          {...props}
          goBack={props.hasPrevious ? closeOne : undefined}
          onClose={isClosable ? closeAll : undefined}
        />
      );

    case "slideOver":
      return (
        <SlideOver
          {...props}
          goBack={props.hasPrevious ? closeOne : undefined}
          onClose={isClosable ? closeAll : undefined}
        />
      );

    default:
      return <>UNKNOWN POPUP TYPE</>;
  }
};
