import { useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";

import { AccountFragment } from "graphql/fragments";
import {
  useDestroyAccountLogoMutation,
  useUpdateAccountMutation,
} from "graphql/mutations";
import { AccountTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { TWidth } from "tw-generated";
import { tw } from "utils/tw";

import logoPlaceholder from "assets/illustrations/dragAndDrop/logoUpload_alt.svg";

import { Spinner } from "../basic";

interface Props {
  account: Pick<AccountFragment, "accountType" | "logo" | "canManage">;
  logoWidth?: TWidth;
  maxSize?: number;
}

export default ({
  account,
  maxSize = 5000_000,
  logoWidth = "w-64",
}: Props): JSX.Element => {
  const [fileError, setFileError] = useState<string>();

  const { destroyAccountLogo } = useDestroyAccountLogoMutation();
  const { updateAccount, isLoading } = useUpdateAccountMutation();

  const { t } = useTranslation("common");

  const isOrg = account.accountType === AccountTypeEnum.Team;

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const file = fileList[0];
    if (file.size > maxSize)
      return setFileError(
        t("logoUpload.error.size", "Please provide a file smaller than 5mb")
      );

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (typeof target?.result !== "string") return;

      updateAccount({
        attributes: { logo: { data: target.result } },
        personal: !isOrg,
      });
    };
    reader.readAsDataURL(file);
  };

  const hasUploadedFile = Boolean(account.logo);
  const logo = hasUploadedFile
    ? `${process.env.REACT_APP_API_URL}${account.logo?.thumbPath}`
    : undefined;

  const errorId = "upload-error";

  return (
    <div
      className={tw(
        "w-full",
        "flex",
        "flex-col",
        "space-y-4",
        "items-center",
        "pb-3"
      )}
    >
      <div
        className={tw(
          "relative",
          logoWidth,
          "h-30",
          "self-center",
          "flex",
          "flex-col",
          "items-center",
          "justify-center",
          { "bg-silver": !logo }
        )}
      >
        {isLoading ? (
          <Spinner withoutText />
        ) : logo ? (
          <img
            src={logo}
            className={tw(
              "align-middle",
              "border-none",
              "h-full",
              "w-full",
              "object-contain"
            )}
          />
        ) : (
          <img src={logoPlaceholder} className={tw("h-12")} />
        )}

        {account.canManage && (
          <label
            className={tw(
              "absolute",
              "-bottom-3",
              "rounded-full",
              "cursor-pointer"
            )}
          >
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onFileUpload}
              aria-invalid={Boolean(fileError)}
              aria-describedby={errorId}
            />

            <span className={tw("block", "p-2", "bg-white", "rounded-full")}>
              <HiOutlinePencilAlt size={16} />
            </span>
          </label>
        )}
      </div>

      {fileError && (
        <p className={tw("text-error", "text-center")} id={errorId}>
          {fileError}
        </p>
      )}

      {account.canManage && hasUploadedFile && (
        <button
          className={tw("m-auto", "underline")}
          onClick={() => destroyAccountLogo()}
        >
          {t("logoUpload.button.destroyImage", "Delete logo")}
        </button>
      )}
    </div>
  );
};
