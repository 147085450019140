import { useContext, useRef, useState } from "react";
import { HiInformationCircle, HiPaperAirplane } from "react-icons/hi";

import {
  Button,
  IconButton,
  Textarea,
  ToggleButton,
} from "components/common/basic";
import { Banner } from "components/common/composite";
import {
  useCreateWbMessageMutation,
  usePublicCreateWbMessageMutation,
} from "graphql/mutations";
import { Base64AttachmentAttributes, WbMessageAttributes } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { TMaxWidth } from "tw-generated";
import { tw } from "utils/tw";

interface PropsBase {
  type: "handler" | "whistleblower";
  maxWidth?: TMaxWidth;
  width?: number;
  onInput?: () => void;
  onMessageSent?: () => void;
}
interface HandlerProps extends PropsBase {
  type: "handler";
  wbReportId: string;
}
interface WhistleblowerProps extends PropsBase {
  type: "whistleblower";
  wbSlug: string;
  accessCode: string;
  reportNumber: string;
}
type Props = HandlerProps | WhistleblowerProps;

export default ({
  type,
  width,
  maxWidth,
  onInput,
  onMessageSent,
  ...props
}: Props): JSX.Element => {
  const [isInternalNote, setIsInternalNote] = useState(false);
  const [isShowingInfo, setIsShowingInfo] = useState(false);
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { t } = useTranslation("tellmore");
  const { openPopup } = useContext(PopupContext);
  const { createWbMessage } = useCreateWbMessageMutation();
  const { publicCreateWbMessage } = usePublicCreateWbMessageMutation();

  const createMessage = (
    attributes: WbMessageAttributes,
    onSuccess?: () => void
  ) => {
    if (type === "handler") {
      const { wbReportId } = props as HandlerProps;

      createWbMessage({ id: wbReportId, attributes }, onSuccess);
    } else {
      const { wbSlug, accessCode, reportNumber } = props as WhistleblowerProps;

      publicCreateWbMessage(
        { wbSlug, accessCode, reportNumber, attributes },
        onSuccess
      );
    }
  };

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const promisedAttributes: Promise<Base64AttachmentAttributes>[] =
      Array.from(fileList).map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (event) => {
              if (typeof event.target?.result !== "string") return;

              const attributes = {
                data: event.target.result,
                filename: file.name,
              };
              resolve(attributes);
            };

            reader.readAsDataURL(file);
          })
      );

    Promise.all(promisedAttributes).then((files) =>
      createMessage({ files, internal: isInternalNote }, onMessageSent)
    );
  };

  const hasMessage = Boolean(message.trim());

  const onSendMessage = () => {
    if (!hasMessage) return;

    const attributes = { content: message.trim(), internal: isInternalNote };
    const sendMessage = () =>
      createMessage(attributes, () => {
        setMessage("");
        onMessageSent?.();
      });

    if (isInternalNote) return sendMessage();

    openPopup({
      id: "ConfirmAction",
      props: {
        onConfirmation: sendMessage,
        confirmationLabel: t(
          "wbMessageInput.sendMessageConfirmation.label",
          "Send message"
        ),
        confirmationHeading: t(
          "wbMessageInput.sendMessageConfirmation.heading",
          "Are you sure you want to send this message?"
        ),
        confirmationBody: attributes.content,
        closeOnConfirmation: "one",
      },
    });
  };

  return (
    <div
      style={{ width }}
      className={tw(
        "w-full",
        "p-4",
        "flex",
        "justify-center",
        isInternalNote ? "bg-warning-light" : "bg-white",
        "transition-colors"
      )}
    >
      <div className={tw(maxWidth, "w-full", "flex", "flex-col", "space-y-4")}>
        {type === "handler" && (
          <>
            <div className={tw("flex", "space-x-4")}>
              <ToggleButton
                isToggledOn={isInternalNote}
                onClick={() => setIsInternalNote(!isInternalNote)}
                label={t(
                  "wbMessageInput.toggle.label",
                  "Submit as internal note"
                )}
                showLabel
              />

              <button onClick={() => setIsShowingInfo(!isShowingInfo)}>
                <HiInformationCircle size={20} />
              </button>
            </div>

            {isShowingInfo && (
              <Banner
                onClose={() => setIsShowingInfo(false)}
                body={t(
                  "wbMessageInput.infoBanner.body",
                  "Only report handlers are able to see internal notes. The whistleblower will not see your internal notes."
                )}
              />
            )}
          </>
        )}

        <div className={tw("flex-col", "flex-col", "space-y-1")}>
          <Textarea
            id="wb-message-input"
            label={t("wbMessageInput.label", "message")}
            hideLabel
            placeholder={
              isInternalNote
                ? t(
                    "wbMessageInput_internal.placeholder",
                    "Only report handlers will see this note..."
                  )
                : t("wbMessageInput.placeholder", "Type a message")
            }
            name="message"
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            isAutoSized
            onInput={onInput}
            ref={inputRef}
          />

          <div className={tw("flex", "justify-between")}>
            <label className={tw("contents")}>
              <input
                type="file"
                multiple
                accept={[
                  "application/pdf",
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "audio/*",
                  "video/*",
                ].join(",")}
                style={{ display: "none" }}
                onChange={onFileUpload}
              />

              <IconButton
                id="wb_message_input-send_file"
                icon="HiOutlinePaperClip"
              />
            </label>

            <Button
              id="wb_message_input-send_message"
              LeadingIcon={HiPaperAirplane}
              onClick={onSendMessage}
              size="sm"
              disabled={!hasMessage}
            >
              {isInternalNote
                ? t("wbMessageInput.button.addNote", "Add note")
                : t("wbMessageInput.button.sendMessage", "Send message")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
