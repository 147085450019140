/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { FREELANCE_PROFILES, FreelanceProfilesQuery } from "graphql/queries";

const DESTROY_BANK_ACCOUNT = gql`
  mutation ($id: ID!) {
    destroyBankAccount(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface DestroyBankAccountMutation {
  destroyBankAccount: Payload | null;
}

export const useDestroyBankAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.removedBankAccount",
    "Bank account was removed successfully"
  );
  // Values for extraction
  // t("common:success.removedBankAccount", "Bank account was removed successfully")

  const [mutation, { data, loading }] = useMutation<
    DestroyBankAccountMutation,
    { id: string }
  >(DESTROY_BANK_ACCOUNT);

  const destroyBankAccount = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            freelanceProfiles() {
              const freelanceProfilesQuery =
                cache.readQuery<FreelanceProfilesQuery>({
                  query: FREELANCE_PROFILES,
                });
              if (!freelanceProfilesQuery) return;

              const items =
                freelanceProfilesQuery.freelanceProfiles.items?.reduce(
                  (acc: FreelanceProfileFragment[], profile) => {
                    const bankAccounts = profile.bankAccounts.filter(
                      (account) => account?.id !== id
                    );

                    return [...acc, { ...profile, bankAccounts }];
                  },
                  []
                );

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: freelanceProfilesQuery.freelanceProfiles.total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyBankAccount?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyBankAccount,
    isLoading: loading,
    errors: data?.destroyBankAccount?.errors ?? [],
  };
};
