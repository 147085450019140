import React from "react";

import { tw } from "utils/tw";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  label: string;
}

export default React.forwardRef(
  (
    { id, name, label, ...props }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const pillStyles = tw(
      "py-2",
      "px-4",
      "text-sm",
      "text-deepBlue-900",
      "font-semibold",
      "border",
      "rounded-full",
      "cursor-pointer",
      {
        [tw("border-deepBlue-100", "bg-white", "hover:bg-silver")]:
          !props.checked,
        [tw("ring-1", "ring-deepBlue-900", "border-deepBlue-900", "bg-silver")]:
          props.checked,
      }
    );

    return (
      <label htmlFor={id} className={pillStyles}>
        <input
          {...props}
          id={id}
          ref={ref}
          type="radio"
          className={tw("opacity-0", "absolute", "pointer-events-none")}
        />
        {label}
      </label>
    );
  }
);
