import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Textarea } from "components/common/basic";
import { HelpArticleFragment } from "graphql/fragments";
import { useSendSupportMessageMutation } from "graphql/mutations";
import { LanguageEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupWrapper } from "./PopupWrapper";

interface Props {
  hasPrevious: boolean;
  helpArticle: HelpArticleFragment;
  clientId: string;
  freelanceProfileId: string;
}

export default ({
  helpArticle,
  clientId,
  freelanceProfileId,
  ...props
}: Props): JSX.Element => {
  const { sendSupportMessage } = useSendSupportMessageMutation();

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);
  const { currentLocale } = useContext(IntlContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ message: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string()
          .trim()
          .required(
            t(
              "popup.blockedManualInvoicing.error.required",
              "A message is required"
            )
          ),
      })
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ message }: { message: string }) => {
    const input = {
      clientId,
      freelanceProfileId,
      message,
    };
    sendSupportMessage(input, closeAllPopups);
  };

  const clientName = helpArticle.name ?? "";
  const helpArticleMessage =
    currentLocale === LanguageEnum.En
      ? helpArticle.enMessage
      : helpArticle.nbMessage;

  const formId = "support-message-form";

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t(
        "popup.blockedManualInvoicing.heading",
        "Do not invoice manually"
      )}
      leadingIcon="HiExclamation"
      leadingIconIsFilled
      leadingIconColor="text-error"
      footerActions={[
        {
          id: "blocked_manual_invoicing-send",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.blockedManualInvoicing.button.sendEmail", "Send"),
        },
      ]}
    >
      <div className={tw("flex-1", "space-y-4")}>
        <p className={tw("text-sm", "text-gray-700", "whitespace-pre-wrap")}>
          {helpArticleMessage}
        </p>

        <p className={tw("text-sm", "text-gray-700", "font-bold")}>
          {t(
            "popup.blockedManualInvoicing.emailOption",
            "Do you wish to send an email to {{ clientName }}?",
            { clientName }
          )}
        </p>

        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Textarea
            label={t(
              "popup.blockedManualInvoicing.message.label",
              "Send message"
            )}
            id="message"
            {...register("message")}
            rows={5}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
