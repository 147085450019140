/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_CHECK_FRAGMENT,
  JobCheckFragment,
} from "graphql/fragments";
import { FREELANCE_PROFILES, FreelanceProfilesQuery } from "graphql/queries";
import { JobCheckAttributes } from "graphql/types";

const CREATE_JOB_CHECK = gql`
  mutation ($attributes: JobCheckAttributes!) {
    createJobCheck(input: { attributes: $attributes }) {
      jobCheck {
        ...JobCheckFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_CHECK_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobCheck?: JobCheckFragment | null;
  errors: ErrorFragment[];
}

interface CreateJobCheckMutation {
  createJobCheck: Payload | null;
}

export const useCreateJobCheckMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateJobCheckMutation,
    { attributes: JobCheckAttributes }
  >(CREATE_JOB_CHECK);

  const createJobCheck = (
    attributes: JobCheckAttributes,
    onSuccess?: (jobCheck: JobCheckFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newJobCheckData }) {
        const newJobCheckNode = newJobCheckData?.createJobCheck?.jobCheck;
        if (!newJobCheckNode) return;

        cache.modify({
          fields: {
            freelanceProfiles() {
              const existingProfiles = cache.readQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
              });
              if (!existingProfiles) return;

              const items = existingProfiles?.freelanceProfiles.items?.map(
                (profile) => {
                  if (profile.id !== attributes.freelanceProfileId)
                    return profile;

                  return {
                    ...profile,
                    jobChecks: [...profile.jobChecks, newJobCheckNode],
                  };
                }
              );

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: existingProfiles.freelanceProfiles.total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createJobCheck?.errors ?? [];
        const jobCheck = data?.createJobCheck?.jobCheck;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && jobCheck && onSuccess(jobCheck),
          onRetry: () => createJobCheck(attributes, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createJobCheck,
    isLoading: loading,
    jobCheck: data?.createJobCheck?.jobCheck,
    errors: data?.createJobCheck?.errors ?? [],
  };
};
