// import { HiExclamation } from "react-icons/hi";
import { TFunction } from "i18next";

import { Badge } from "components/common/basic";
import { CheckStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { TTextColor } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  viewer: "sender" | "receiver";
  status: CheckStatusEnum;
  showStatus?: boolean;
  message?: string;
  badgeSize?: "sm" | "lg";
  textColor?: TTextColor;
}

export default ({
  variant,
  viewer,
  status,
  showStatus,
  message,
  badgeSize = "lg",
  textColor = "text-deepBlue-900",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const isSender = viewer === "sender";

  const statusMessage =
    message ??
    (showStatus
      ? getTranslatableCheckStatus(status, t)
      : ((): string => {
          switch (status) {
            case CheckStatusEnum.Requested:
              return isSender
                ? t("checkStatusMessage_sender.requestSent", "Request sent")
                : t("checkStatusMessage_receiver.requestSent", "Request sent");
            case CheckStatusEnum.Consented:
              return isSender
                ? t("checkStatusMessage_sender.inProgress", "In Progress")
                : t("checkStatusMessage_receiver.inProgress", "In Progress");
            case CheckStatusEnum.Cleared:
              return isSender
                ? t("checkStatusMessage_sender.cleared", "Cleared")
                : t("checkStatusMessage_receiver.cleared", "Cleared");
            case CheckStatusEnum.Rejected:
              return isSender
                ? t("checkStatusMessage_sender.rejected", "Rejected")
                : t("checkStatusMessage_receiver.rejected", "Rejected");
            case CheckStatusEnum.Refused:
              return isSender
                ? t("checkStatusMessage_sender.refused", "Refused")
                : t("checkStatusMessage_receiver.refused", "Refused");
            case CheckStatusEnum.Cancelled:
              return isSender
                ? t("checkStatusMessage_sender.cancelled", "Cancelled")
                : t("checkStatusMessage_receiver.cancelled", "Cancelled");
            case CheckStatusEnum.InReview:
              return isSender
                ? t("checkStatusMessage_sender.inReview", "In review")
                : t("checkStatusMessage_receiver.inReview", "In review");

            default:
              return ((_status: never) => "")(status);
          }
        })());

  const badgeVariant = (() => {
    switch (status) {
      case CheckStatusEnum.Cleared:
        return "success";

      case CheckStatusEnum.Rejected:
        return "error";

      case CheckStatusEnum.Consented:
      case CheckStatusEnum.InReview:
        return "pending";

      case CheckStatusEnum.Requested:
      case CheckStatusEnum.Refused:
      case CheckStatusEnum.Cancelled:
        return "default_outlined";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "font-bold", textColor, {
          "text-xs": badgeSize === "sm",
        })}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};

export const getTranslatableCheckStatus = (
  status: CheckStatusEnum,
  t: TFunction
): string => {
  switch (status) {
    case CheckStatusEnum.Requested:
      return t("checkStatus.requestSent", status);
    case CheckStatusEnum.Consented:
      return t("checkStatus.inProgress", status);
    case CheckStatusEnum.Cancelled:
      return t("checkStatus.cancelled", status);
    case CheckStatusEnum.Cleared:
      return t("checkStatus.cleared", status);
    case CheckStatusEnum.Rejected:
      return t("checkStatus.rejected", status);
    case CheckStatusEnum.Refused:
      return t("checkStatus.refused", status);
    case CheckStatusEnum.InReview:
      return t("checkStatus.inReview", status);
    // case CheckStatusEnum.ActionRequired:
    //   return t("checkStatus.actionRequired", status);

    default:
      return ((_status: never) => "")(status);
  }
};
