import { HiChevronDown } from "react-icons/hi";

import { ListCompanyFragment } from "graphql/fragments";
import { useCompaniesLazyQuery } from "graphql/queries";
import { Trans } from "translations";
import { tw } from "utils/tw";

import SingleSearch from "./SingleSearch";

interface Props {
  onSelect: (company: ListCompanyFragment) => void;
  label?: string;
  placeholder?: string;
  hint?: string;
  errorMessage?: string;
}

export default ({
  onSelect,
  label,
  placeholder,
  hint,
  errorMessage,
}: Props): JSX.Element => {
  const { getCompanies } = useCompaniesLazyQuery();

  return (
    <SingleSearch<ListCompanyFragment>
      id="company-search"
      itemKey="orgNumber"
      renderListItemLabel={renderListItemLabel}
      onSearch={(name) =>
        getCompanies({ name }).then((companies) => companies ?? [])
      }
      onSelect={onSelect}
      label={label}
      buttonLabel={buttonLabel(placeholder)}
      hint={hint}
      listHeader={listHeader}
      minQueryLength={3}
      errorMessage={errorMessage}
    />
  );
};

const renderListItemLabel = (company: ListCompanyFragment) => (
  <>
    <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
      {company.name}
    </p>

    <p className={tw("block", "text-gray-500", "text-xs")}>
      <Trans
        ns="common"
        i18nKey="searchBrreg.listItem.orgNumber"
        defaults="Org. nr. {{ orgNumber }}"
        values={{ orgNumber: company.orgNumber }}
      />
    </p>
  </>
);

const buttonLabel = (label?: string) => (
  <>
    {label ?? (
      <Trans ns="common" i18nKey="searchBrreg.label">
        Find your ENK / AS on Brreg
      </Trans>
    )}

    <HiChevronDown size={20} className={tw("text-gray-400")} />
  </>
);

const listHeader = (
  <p
    className={tw("pb-2", "px-4", "uppercase", "text-deepBlue-500", "text-xs")}
  >
    <Trans ns="common" i18nKey="searchBrreg.resultsFromBrreg">
      Results from brreg.no
    </Trans>
  </p>
);
