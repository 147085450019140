import { useContext, useEffect, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { PopoverContext } from "providers/PopoverHandler";
import { tw } from "utils/tw";

import MenuList from "./MenuList";
import { MenuItemGroup } from "./types";

interface Props {
  item: MenuItemGroup;
  iconsOnly?: boolean;
  closeSelf?: () => void;
}
export default ({
  item: {
    id,
    items,
    label,
    image,
    Icon,
    iconClassName,
    isOpenByDefault = false,
    variant = "default",
  },
  iconsOnly,
  closeSelf,
}: Props) => {
  const { registerPopover, unregisterPopover, setIsShownPopover } =
    useContext(PopoverContext);
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  // Unregister popover on unmount
  useEffect(() => () => unregisterPopover(id), []);

  useEffect(() => {
    if (items.length < 1) return;

    const hasActiveItem = items.some(
      (item) =>
        Object.prototype.hasOwnProperty.call(item, "isActive") &&
        (item as { isActive?: boolean }).isActive
    );

    if (hasActiveItem) setIsOpen(true);
  }, [items]);

  const listItemStyles = tw(
    "my-1",
    "rounded-md",
    "hover:bg-blue-100",
    "hover:text-blue-900",
    {
      [tw("p-2", "mx-2")]: iconsOnly,
      [tw(
        "group",
        "flex",
        "items-center",
        "w-full",
        "space-x-3",
        "py-2",
        variant === "default" ? "px-2" : "px-4"
      )]: !iconsOnly,
      "mb-2": !iconsOnly && !isOpen,
    }
  );
  const listItemIconStyles = tw(
    variant === "default" ? "text-deepBlue-500" : "text-deepBlue-900",
    "group-hover:text-blue-900",
    "pointer-events-none"
  );
  const iconStyles = iconClassName ?? listItemIconStyles;

  const Chevron = isOpen ? HiChevronUp : HiChevronDown;

  const popoverProps = !iconsOnly
    ? undefined
    : {
        ref: (targetRef: HTMLSpanElement) =>
          registerPopover({
            id,
            variant: "tooltip",
            content: label,
            targetRef,
            placement: "right",
          }),
        onMouseEnter: () => setIsShownPopover(id, true),
        onMouseLeave: () => setIsShownPopover(id, false),
      };

  return (
    <li>
      <button className={listItemStyles} onClick={() => setIsOpen(!isOpen)}>
        <span {...popoverProps}>
          {image ? (
            <img
              src={image.src}
              alt={image.alt}
              className={tw("rounded-full", "w-6", "h-6", "object-cover")}
            />
          ) : (
            Icon && <Icon size="24px" className={iconStyles} />
          )}
        </span>

        {iconsOnly ? null : (
          <>
            <span className={tw("flex-1", "text-left", "truncate")}>
              {label}
            </span>

            <Chevron size="24px" className={iconStyles} />
          </>
        )}
      </button>

      {isOpen && (
        <MenuList
          menuItems={items}
          closeSelf={closeSelf}
          iconsOnly={iconsOnly}
          isSubmenu
          marginLeft={variant === "default" ? "ml-5" : "ml-7"}
        />
      )}
    </li>
  );
};
