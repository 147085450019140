import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, SuccessCard } from "components/common/basic";
import { PasswordInput } from "components/common/composite";
import { useUpdateUserMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { useTranslation } from "translations";
import { passwordValidationSchema } from "utils";
import { tw } from "utils/tw";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default (): JSX.Element => {
  const { t } = useTranslation("account");

  const { updateUser, isLoading } = useUpdateUserMutation();

  const passwordMismatchErrorMessage = t(
    "accountSettings.passwordSection.form.error.passwordMismatch",
    "Passwords must be identical"
  );
  const validationSchema = Yup.object({
    password: passwordValidationSchema(t)
      .test("matchesPassword", passwordMismatchErrorMessage, function (_value) {
        const { password, confirmPassword } = this.parent;

        return password === confirmPassword;
      })
      .required(),
    confirmPassword: passwordValidationSchema(t)
      .test("matchesPassword", passwordMismatchErrorMessage, function (_value) {
        const { password, confirmPassword } = this.parent;

        return password === confirmPassword;
      })
      .required(),
  });

  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ password }: FormValues) =>
    updateUser({ password }, () =>
      toast.custom((toastProps) => (
        <SuccessCard
          heading={t(
            "accountSettings.passwordSection.successToast.heading",
            "Success!"
          )}
          body={t(
            "accountSettings.passwordSection.successToast.body",
            "Your password has been updated"
          )}
          toastProps={toastProps}
        />
      ))
    );

  const formId = "passwordForm";

  return (
    <div className={tw("space-y-6")}>
      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-bold")}>
          {t("accountSettings.passwordSection.heading", "Update your password")}
        </h2>

        <p className={tw("text-sm", "text-deepBlue-500")}>
          {t(
            "accountSettings.passwordSection.description",
            "Your password must be between 6-64 characters long"
          )}
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={tw("w-full", "flex", "flex-col", "gap-6", "md:flex-row")}
        id={formId}
      >
        <PasswordInput
          id="password"
          label={t(
            "accountSettings.passwordSection.form.password.label",
            "Password"
          )}
          {...register("password")}
          errorMessage={errors.password?.message}
          onChange={(event) => {
            register("password").onChange(event);

            if (isSubmitted) trigger("confirmPassword");
          }}
        />

        <PasswordInput
          id="confirmPassword"
          label={t(
            "accountSettings.passwordSection.form.confirmPassword.label",
            "Confirm password"
          )}
          {...register("confirmPassword")}
          errorMessage={errors.confirmPassword?.message}
          onChange={(event) => {
            register("confirmPassword").onChange(event);

            if (isSubmitted) trigger("password");
          }}
        />

        <div className={tw("mt-6")}>
          <Button
            id="accountSettings-add_password"
            type="submit"
            form={formId}
            variant="secondary"
            size="md"
            disabled={isLoading}
          >
            {t(
              "accountSettings.passwordSection.form.submit.label",
              "Update password"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
