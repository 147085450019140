/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PUBLIC_WB_REPORT_FRAGMENT,
  PublicWbReportFragment,
} from "graphql/fragments";
import { WbReportTypeEnum } from "graphql/types";

const CREATE_WB_REPORT = gql`
  mutation ($wbSlug: String!, $type: WbReportTypeEnum!) {
    createWbReport(input: { wbSlug: $wbSlug, type: $type }) {
      wbReport {
        ...PublicWbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PUBLIC_WB_REPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbReport?: PublicWbReportFragment | null;
  errors: ErrorFragment[];
}

interface CreateWbReportMutation {
  createWbReport: Payload | null;
}

export const useCreateWbReportMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateWbReportMutation,
    { wbSlug: string; type: WbReportTypeEnum }
  >(CREATE_WB_REPORT);

  const createWbReport = (
    wbSlug: string,
    type: WbReportTypeEnum,
    onSuccess?: (wbReport: PublicWbReportFragment) => void
  ) =>
    mutation({ variables: { wbSlug, type } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createWbReport?.errors ?? [];
        const wbReport = data?.createWbReport?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createWbReport,
    isLoading: loading,
    wbReport: data?.createWbReport?.wbReport,
    errors: data?.createWbReport?.errors ?? [],
  };
};
