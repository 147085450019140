import { PropsWithChildren } from "react";

import { tw } from "utils/tw";

import { PopupContent } from "./PopupContent";
import { PopupContentProps } from "./PopupContent/PopupContent";

interface Props
  extends Omit<PopupContentProps, "goBack" | "isRounded" | "shouldAnimateIn"> {
  popupSize?: "sm" | "md" | "lg";
}

export default ({
  popupSize = "md",
  ...contentProps
}: PropsWithChildren<Props>) => (
  <div
    className={tw(
      "fixed",
      "top-0",
      "left-0",
      "z-50",
      "w-screen",
      "h-screen",
      "overflow-x-hidden",
      "overflow-y-auto",
      "flex",
      "justify-center"
    )}
    style={{
      background: "rgba(32, 50, 79, 0.6)",
    }}
  >
    <button
      tabIndex={-1}
      className={tw("z-0", "fixed", "w-full", "h-full", "opacity-0")}
      onClick={contentProps.onClose}
    />

    <div
      className={tw("z-10", "absolute", "w-full", {
        "max-w-80": popupSize === "sm",
        "max-w-screen-sm": popupSize === "md",
        "max-w-360": popupSize === "lg",
      })}
    >
      <button
        tabIndex={-1}
        className={tw("h-10", "w-full", "opacity-0")}
        onClick={contentProps.onClose}
      />

      <PopupContent {...contentProps} isRounded />

      <button
        tabIndex={-1}
        className={tw("h-10", "w-full", "opacity-0")}
        onClick={contentProps.onClose}
      />
    </div>
  </div>
);
