import { gql } from "@apollo/client";

import { PhoneNumber } from "graphql/types";

export const PHONE_NUMBER_FRAGMENT = gql`
  fragment PhoneNumberFragment on PhoneNumber {
    id
    countryCode
    number
    createdAt
    tokenSentAt
    tokenVerifiedAt
  }
`;

export interface PhoneNumberFragment
  extends Pick<
    PhoneNumber,
    | "id"
    | "countryCode"
    | "number"
    | "createdAt"
    | "tokenSentAt"
    | "tokenVerifiedAt"
  > {}
