import { gql } from "@apollo/client";

import { Department } from "graphql/types";

import {
  DEPARTMENT_MEMBERSHIP_FRAGMENT,
  DepartmentMembershipFragment,
} from "./departmentMembership";

export const DEPARTMENT_FRAGMENT = gql`
  fragment DepartmentFragment on Department {
    id
    name
    description
    default
    departmentMemberships {
      ...DepartmentMembershipFragment
    }
    canAccess
    canManage
  }
  ${DEPARTMENT_MEMBERSHIP_FRAGMENT}
`;

export interface DepartmentFragment
  extends Pick<
    Department,
    "id" | "name" | "description" | "default" | "canAccess" | "canManage"
  > {
  departmentMemberships: DepartmentMembershipFragment[];
}
