import { gql } from "@apollo/client";

import { Invoice, Transfer } from "graphql/types";

export const TRANSFER_FRAGMENT = gql`
  fragment TransferFragment on Transfer {
    id
    amount
    currency
    description
    fromFinanceAccount
    toFinanceAccount
    invoice {
      id
    }
  }
`;

export interface TransferFragment
  extends Pick<
    Transfer,
    | "id"
    | "amount"
    | "currency"
    | "description"
    | "fromFinanceAccount"
    | "toFinanceAccount"
  > {
  invoice: Pick<Invoice, "id">;
}
