import { IconBaseProps } from "react-icons";

export default ({
  size,
  strokeWidth,
  ...props
}: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 3C9.89543 3 9 3.89543 9 5V9H5C3.89543 9 3 9.89543 3 11V13C3 14.1046 3.89543 15 5 15H9V19C9 20.1046 9.89543 21 11 21H13C14.1046 21 15 20.1046 15 19V15H19C20.1046 15 21 14.1046 21 13V11C21 9.89543 20.1046 9 19 9H15V5C15 3.89543 14.1046 3 13 3H11Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
  </svg>
);
