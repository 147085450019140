/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  BANK_ACCOUNT_FRAGMENT,
  BankAccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import {
  FREELANCE_PROFILE,
  FREELANCE_PROFILES,
  FreelanceProfileQuery,
  FreelanceProfilesQuery,
} from "graphql/queries";

const SET_DEFAULT_BANK_ACCOUNT = gql`
  mutation ($id: ID!) {
    setDefaultBankAccount(input: { id: $id }) {
      bankAccount {
        ...BankAccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BANK_ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  bankAccount?: BankAccountFragment | null;
  errors: ErrorFragment[];
}

interface SetDefaultBankAccountMutation {
  setDefaultBankAccount: Payload | null;
}

export const useSetDefaultBankAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.setDefaultBankAccount",
    "Default bank account was set successfully"
  );
  // Values for extraction
  // t("common:success.submittedInvoice", "Invoice was submitted successfully")

  const [mutation, { data, loading, called }] = useMutation<
    SetDefaultBankAccountMutation,
    { id: string }
  >(SET_DEFAULT_BANK_ACCOUNT);

  const setDefaultBankAccount = (
    id: string,
    onSuccess?: (response: BankAccountFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache, { data: bankAccountData }) {
        const defaultBankAccount =
          bankAccountData?.setDefaultBankAccount?.bankAccount;
        if (!defaultBankAccount) return;

        cache.modify({
          fields: {
            node() {
              const profile = cache.readQuery<FreelanceProfileQuery>({
                query: FREELANCE_PROFILE,
              });
              if (!profile) return;

              cache.writeQuery<FreelanceProfileQuery>({
                query: FREELANCE_PROFILE,
                data: {
                  node: {
                    ...profile.node,
                    defaultBankAccount,
                  },
                },
              });
            },
            freelanceProfiles() {
              const existingProfiles = cache.readQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
              });
              if (!existingProfiles) return;

              const existingItems = existingProfiles?.freelanceProfiles.items;
              if (!existingItems) return;

              const items = existingItems.map((profile) => {
                if (profile.id === id)
                  return { ...profile, defaultBankAccount };

                return profile;
              });

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: existingProfiles.freelanceProfiles.total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.setDefaultBankAccount?.errors ?? [];
        const bankAccount = data?.setDefaultBankAccount?.bankAccount;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => bankAccount && onSuccess && onSuccess(bankAccount),
          onRetry: () => setDefaultBankAccount(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setDefaultBankAccount,
    isLoading: loading,
    called,
    bankAccount: data?.setDefaultBankAccount?.bankAccount,
    errors: data?.setDefaultBankAccount?.errors ?? [],
  };
};
