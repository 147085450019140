import { gql } from "@apollo/client";

import { Client } from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { HELP_ARTICLE_FRAGMENT, HelpArticleFragment } from "./helpArticle";
import {
  REFERENCE_PERSON_FRAGMENT,
  ReferencePersonFragment,
} from "./referencePerson";

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    address {
      ...AddressFragment
    }
    referencePeople {
      ...ReferencePersonFragment
    }
    clientType
    clientNumber
    email
    friendlyName
    id
    name
    nationalRegisterCheck
    orgNumber
    phone
    preferredDeliveryMethod
    helpArticle {
      ...HelpArticleFragment
    }
  }
  ${ADDRESS_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
  ${HELP_ARTICLE_FRAGMENT}
`;

export interface ClientFragment
  extends Pick<
    Client,
    | "address"
    | "referencePeople"
    | "clientType"
    | "clientNumber"
    | "email"
    | "friendlyName"
    | "id"
    | "name"
    | "nationalRegisterCheck"
    | "orgNumber"
    | "phone"
    | "preferredDeliveryMethod"
    | "helpArticle"
  > {
  referencePeople: ReferencePersonFragment[];
  address?: AddressFragment | null;
  helpArticle?: HelpArticleFragment;
}
