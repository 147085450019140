import { gql } from "@apollo/client";

import { ListCompany } from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";

export const LIST_COMPANY_FRAGMENT = gql`
  fragment ListCompanyFragment on ListCompany {
    address {
      ...AddressFragment
    }
    name
    orgNumber
    markedAsBankruptcy
    underLiquidation
    underForcedLiquidation
    deleted
    accountExists
  }
  ${ADDRESS_FRAGMENT}
`;

export interface ListCompanyFragment
  extends Pick<
    ListCompany,
    | "address"
    | "name"
    | "orgNumber"
    | "markedAsBankruptcy"
    | "underLiquidation"
    | "underForcedLiquidation"
    | "deleted"
    | "accountExists"
  > {
  address?: AddressFragment | null;
}
