import { useContext, useState } from "react";
import { HiExclamation } from "react-icons/hi";

import { CheckBox } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { PersonFragment } from "graphql/fragments";
import { useDestroyPeopleMutation } from "graphql/mutations";
import { PeopleFilter, TeamRoleEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import useVariantText from "./useVariantText";

interface BaseProps {
  hasPrevious: boolean;
}
export interface SomePeopleProps {
  people: PersonFragment[];
  onSuccess?: () => void;
}
export interface AllPeopleProps {
  filter: PeopleFilter;
  onSuccess?: () => void;
}

type Props = BaseProps & (SomePeopleProps | AllPeopleProps);

export default ({ onSuccess, ...props }: Props): JSX.Element => {
  const isSomePeople = Object.prototype.hasOwnProperty.call(props, "people");
  const people = isSomePeople ? (props as SomePeopleProps).people : [];
  const filter = !isSomePeople ? (props as AllPeopleProps).filter : {};

  const isIncludingOwner =
    !isSomePeople ||
    people.some(({ personalAccount: { role } }) => role === TeamRoleEnum.Owner);

  const [isConfirmedDocuments, setIsConfirmedDocuments] = useState(false);
  const [isConfirmedChecks, setIsConfirmedChecks] = useState(false);

  const { destroyPeople } = useDestroyPeopleMutation();

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);
  const {
    heading,
    isIncludingOwnerText,
    signedDocumentsBody,
    checksBody,
    ownInformationText,
    financialInformationText,
  } = useVariantText(people.length);

  return (
    <PopupWrapper
      {...props}
      leadingIcon="HiExclamation"
      leadingIconIsFilled
      leadingIconColor="text-error"
      title={heading}
      variant="popup"
      popupSize="lg"
      footerActions={[
        {
          id: "remove_people_popup-cancel",
          onClick: closeAllPopups,
          variant: "tertiary",
          label: t("popup.removePeople.button.cancel", "Cancel"),
        },
        {
          id: "remove_people_popup-delete",
          onClick: () =>
            destroyPeople(
              isSomePeople
                ? { ids: people.map((person) => person.id) }
                : { filter },
              () => {
                onSuccess?.();
                closeAllPopups();
              }
            ),
          variant: "danger",
          label: t("popup.removePeople.button.delete", "Delete person"),
          disabled:
            isIncludingOwner || !isConfirmedDocuments || !isConfirmedChecks,
        },
      ]}
    >
      {isIncludingOwner ? (
        <div
          className={tw(
            "w-full",
            "rounded-md",
            "border",
            "border-deepBlue-300",
            "p-4",
            "flex",
            "space-x-4"
          )}
        >
          <HiExclamation
            size={20}
            className={tw("text-badge-warning-light", "shrink-0")}
          />

          <p className={tw("w-full", "text-deepBlue-900", "font-semibold")}>
            {isIncludingOwnerText}
          </p>
        </div>
      ) : (
        <div className={tw("flex", "flex-col", "space-y-6")}>
          <div className={tw("bg-silver", "rounded-lg", "p-4", "space-y-4")}>
            <h3 className={tw("text-lg", "font-bold")}>
              {t(
                "popup.removePeople.signedDocuments.heading",
                "Signed documents"
              )}
            </h3>

            <p className={tw("whitespace-pre-wrap")}>{signedDocumentsBody}</p>

            <CheckBox
              id="confirmDocuments"
              name="confirmDocuments"
              label={t(
                "popup.removePeople.signedDocuments.checkbox.label",
                "I understand the implications"
              )}
              onChange={(event) =>
                setIsConfirmedDocuments(event.target.checked)
              }
            />
          </div>

          <div className={tw("bg-silver", "rounded-lg", "p-4", "space-y-4")}>
            <h3 className={tw("text-lg", "font-bold")}>
              {t("popup.removePeople.checks.heading", "Checks")}
            </h3>

            <p className={tw("whitespace-pre-wrap")}>{checksBody}</p>

            <CheckBox
              id="confirmChecks"
              name="confirmChecks"
              label={t(
                "popup.removePeople.checks.checkbox.label",
                "I understand the implications"
              )}
              onChange={(event) => setIsConfirmedChecks(event.target.checked)}
            />
          </div>

          <p className={tw("whitespace-pre-wrap")}>{ownInformationText}</p>

          <p className={tw("whitespace-pre-wrap")}>
            {financialInformationText}
          </p>
        </div>
      )}
    </PopupWrapper>
  );
};
