import { Fragment } from "react";
import { HiShieldCheck } from "react-icons/hi";

import { Badge, Divider } from "components/common/basic";
import { IdentityFragment } from "graphql/fragments";
import { useSetDefaultIdentityMutation } from "graphql/mutations";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  identities: IdentityFragment[];
}

export default ({ identities }: Props): JSX.Element | null => {
  const { setDefaultIdentity } = useSetDefaultIdentityMutation();

  const { t } = useTranslation("account");

  if (identities.length < 1) return null;

  return (
    <div
      className={tw(
        "p-6",
        "space-y-4",
        "rounded-lg",
        "border",
        "border-deepBlue-50"
      )}
    >
      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-bold")}>
          {t("accountSettings.identitiesSection.heading", "Identity")}
        </h2>

        <p className={tw("text-sm", "text-deepBlue-500")}>
          {t(
            "accountSettings.identitiesSection.description",
            "Below are the verified identities we have registered of you"
          )}
        </p>
      </div>

      <Divider borderColor="border-deepBlue-50" />

      <ul className={tw("space-y-4")}>
        {identities.map((identity, index) => (
          <Fragment key={identity.id}>
            {index > 0 && <Divider borderColor="border-deepBlue-50" />}

            <li
              className={tw(
                "flex",
                "flex-col",
                "gap-6",
                "lg:flex-row",
                "lg:items-start",
                "lg:gap-12"
              )}
            >
              <div className={tw("w-28")}>
                <p
                  className={tw(
                    "font-semibold",
                    "text-deepBlue-900",
                    "truncate"
                  )}
                >
                  {t(
                    `common:country.${identity.country?.toLowerCase()}`,
                    identity.country
                  )}
                </p>
              </div>

              <div className={tw("w-36")}>
                <p
                  className={tw(
                    "font-semibold",
                    "text-deepBlue-900",
                    "truncate"
                  )}
                >
                  {identity.identifier.substring(0, 6) + "*****"}
                </p>

                <p
                  className={tw(
                    "font-semibold",
                    "text-deepBlue-900",
                    "break-words"
                  )}
                >
                  {identity.firstName} {identity.lastName}
                </p>
              </div>

              <div className={tw("w-20")}>
                <Badge
                  variant="success"
                  LeadingIcon={HiShieldCheck}
                  text={t(
                    "accountSettings.identitiesSection.identity.verified",
                    "Verified"
                  )}
                />
              </div>

              <div className={tw("w-28", "lg:text-right")}>
                {identity.default ? (
                  <Badge
                    variant="default_outlined"
                    text={t(
                      "accountSettings.identitiesSection.identity.default",
                      "Default"
                    )}
                  />
                ) : (
                  <button
                    className={tw(
                      "underline",
                      "whitespace-nowrap",
                      "text-sm",
                      "text-deepBlue-500",
                      "hover:text-deepBlue-300"
                    )}
                    onClick={() => setDefaultIdentity(identity.id)}
                  >
                    {t(
                      "accountSettings.identitiesSection.identity.makeDefault",
                      "Make default"
                    )}
                  </button>
                )}
              </div>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
};
