import { useContext } from "react";
import toast from "react-hot-toast";

import { GraphQLError } from "graphql";

import { ErrorCard, SuccessCard } from "components/common/basic";
import { ErrorLoggerContext } from "providers/ErrorLogger";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { ErrorFragment } from "./fragments";
import { Error, ErrorCodeEnum } from "./types";

type ErrorReturn = [string, string] | [string, string, Record<string, string>];

const getValidationError = (path?: string | null): ErrorReturn => {
  if (path) {
    const [column, row] = path.split("::");

    return [
      `error.validationFailed_${column}`,
      `Missing ${column} in row {{ row }}`,
      { row },
    ];
  }

  return ["error.validationFailed", "VALIDATION FAILED"];
};

const getErrorCodeMessageId = ({ code, path }: Error): ErrorReturn => {
  switch (code) {
    case ErrorCodeEnum.Blank:
      switch (path) {
        case "email":
          return ["error.blank.email", "BLANK EMAIL"];
        case "referencePerson":
          return ["error.blank.referencePerson", "BLANK REFERENCE PERSON"];
        case "address.line1":
          return ["error.blank.address.line1", "BLANK ADDRESS LINE 1"];
        case "address.city":
          return ["error.blank.address.city", "BLANK ADDRESS CITY"];
        case "address.postalCode":
          return [
            "error.blank.address.postalCode",
            "BLANK ADDRESS POSTAL CODE",
          ];
        case "address.country":
          return ["error.blank.address.country", "BLANK ADDRESS COUNTRY"];
        default:
          return ["error.blank.path", "Missing {{path}}"];
      }

    case ErrorCodeEnum.Invalid:
      switch (path) {
        case "address.country":
          return [
            "error.invalid.address.country",
            "Country must be a 2 character code",
          ];
        default:
          return ["error.invalid.path", "Invalid {{path}}"];
      }

    case ErrorCodeEnum.TooShort:
      return ["error.tooShort", "{{path}} is too short"];

    case ErrorCodeEnum.TooSmall:
      return ["error.tooSmall", "{{path}} is too small"];

    case ErrorCodeEnum.TooBig:
      return ["error.tooBig", "{{path}} is too big"];

    case ErrorCodeEnum.Inclusion:
      return ["error.inclusion", "INCLUSION"];

    case ErrorCodeEnum.Taken:
      switch (path) {
        case "name":
          return ["error.taken.name", "NAME TAKEN"];
        default:
          return ["error.taken.path", "{{path}} TAKEN"];
      }

    case ErrorCodeEnum.AccountBlocked:
      return ["error.accountBlocked", "BLOCKED"];

    case ErrorCodeEnum.AccountNotFound:
      return ["error.accountNotFound", "The account does not exist"];

    case ErrorCodeEnum.UserBlocked:
      return ["error.userBlocked", "BLOCKED"];

    case ErrorCodeEnum.UserNotFound:
      return ["error.userNotFound", "The user does not exist"];

    case ErrorCodeEnum.UserAlreadyConfirmed:
      return ["error.userAlreadyConfirmed", "ALREADY CONFIRMED"];

    case ErrorCodeEnum.UserExists:
      return ["error.userExists", "EXISTS"];

    case ErrorCodeEnum.UserNotConfirmed:
      return ["error.userNotConfirmed", "USER NOT CONFIRMED"];

    case ErrorCodeEnum.AttemptsLimitExceeded:
      return ["error.attemptsLimitExceeded", "LIMIT EXCEEDED"];

    case ErrorCodeEnum.BrregQueryFailed:
      return ["error.brregQueryFailed", "BRREG FAILURE"];

    case ErrorCodeEnum.ClientInsolvent:
      return ["error.clientInsolvent", "CLIENT INSOLVENT"];

    case ErrorCodeEnum.InvalidPhoneNumber:
      return ["error.invalidPhoneNumber", "INVALID PHONE"];

    case ErrorCodeEnum.InvalidToken:
      return ["error.invalidToken", "INVALID TOKEN"];

    case ErrorCodeEnum.InvalidTransition:
      return ["error.invalidTransition", "INVALID TRANSITION"];

    case ErrorCodeEnum.InvoiceCalculationFailed:
      return ["error.invoiceCalculationFailed", "INVOICE CALCULATION FAILED"];

    case ErrorCodeEnum.InvoiceSubmitted:
      return ["error.invoiceSubmitted", "ALREADY SUBMITTED"];

    case ErrorCodeEnum.OccupationCodeDisabled:
      return ["error.occupationCodeDisabled", "DISABLED CODE"];

    case ErrorCodeEnum.SmsSendingFailed:
      return ["error.smsSendingFailed", "SMS FAILURE"];

    case ErrorCodeEnum.TokenExpired:
      return ["error.tokenExpired", "EXPIRED TOKEN"];

    case ErrorCodeEnum.TransitionFailed:
      return ["error.transitionFailed", "TRANSITION FAILED"];

    case ErrorCodeEnum.ValidationFailed:
      return getValidationError(path);

    case ErrorCodeEnum.AuthLevel_2Required:
      return ["error.authLevelTwoRequired", "An SMS confirmation is required."];

    case ErrorCodeEnum.InvoiceNotSubmitted:
      return ["error.invoiceNotSubmitted", "INVOICE NOT SUBMITTED"];

    case ErrorCodeEnum.InvoicePublished:
      return ["error.invoicePublished", "INVOICE PUBLISHED"];

    case ErrorCodeEnum.InvalidPassword:
      return ["error.invalidPassword", "INVALID PASSWORD"];

    case ErrorCodeEnum.InvalidInvoicedOn:
      return ["error.invalidInvoicedOn", "INVALID INVOICE DATE"];

    case ErrorCodeEnum.ClientEmailMissing:
      return ["error.clientEmailMissing", "CLIENT EMAIL IS MISSING"];

    case ErrorCodeEnum.DeliveryFrequencyExceeded:
      return ["error.deliveryFrequencyExceeded", "EXCEEDED DELIVERY FREQUENCY"];

    case ErrorCodeEnum.InvoiceNotOrganization:
      return ["error.invoiceNotOrganization", "NOT AN ORGANIZATION"];

    case ErrorCodeEnum.InvoiceNotPublished:
      return ["error.invoiceNotPublished", "NOT PUBLISHED"];

    case ErrorCodeEnum.InvoiceNotCredited:
      return ["error.invoiceNotCredited", "NOT CREDITED"];

    case ErrorCodeEnum.JobCheckPending:
      return ["error.jobCheckPending", "JOB CHECK PENDING"];

    case ErrorCodeEnum.JobCheckSucceeded:
      return ["error.jobCheckSucceeded", "JOB CHECK SUCCEEDED"];

    case ErrorCodeEnum.Immutable:
      return ["error.immutable", "IMMUTABLE"];

    case ErrorCodeEnum.VerificationFailed:
      return ["error.verificationFailed", "VERIFICATION FAILED"];

    case ErrorCodeEnum.BankIdVerificationRequired:
      return ["error.bankIdVerificationRequired", "VERIFIED BANKID REQUIRED"];

    case ErrorCodeEnum.DocumentSigned:
      return ["error.documentSigned", "DOCUMENT SIGNED"];

    case ErrorCodeEnum.DocumentNotFound:
      return ["error.documentNotFound", "DOCUMENT NOT FOUND"];

    case ErrorCodeEnum.SenderEqualsReceiver:
      return ["error.senderEqualsReceiver", "SENDER EQUALS RECEIVER"];

    case ErrorCodeEnum.InvalidAction:
      return ["error.invalidAction", "INVALID ACTION"];

    case ErrorCodeEnum.TooSmallOrEqual:
      return ["error.tooSmallOrEqual", "TOO SMALL OR EQUAL"];

    case ErrorCodeEnum.TooBigOrEqual:
      return ["error.tooBigOrEqual", "TOO BIG OR EQUAL"];

    case ErrorCodeEnum.OrganizationRoleCheckFailed:
      return [
        "error.organizationRoleCheckFailed",
        "ORGANIZATION ROLE CHECK FAILED",
      ];

    case ErrorCodeEnum.PartnerCallbackError:
      return ["error.partnerCallbackError", "PARTNER CALLBACK ERROR"];

    case ErrorCodeEnum.OtpExpired:
      return ["error.otpExpired", "Your link has expired."];

    case ErrorCodeEnum.OtpExpiredAndResent:
      return [
        "error.otpExpiredAndResent",
        "Your link has expired, but we have sent a new one to your email.",
      ];

    case ErrorCodeEnum.LineMismatch:
      return ["error.lineMismatch", "LINE MISMATCH"];

    case ErrorCodeEnum.PersonalNumberMismatch:
      return ["error.personalNumberMismatch", "PERSONAL NUMBER MISMATCH"];

    case ErrorCodeEnum.FreelanceProfileArchived:
      return ["error.freelanceProfileArchived", "FREELANCE PROFILE ARCHIVED"];

    case ErrorCodeEnum.Present:
      return ["error.present", "PRESENT"];

    case ErrorCodeEnum.Unauthorized:
      return ["error.unauthorized", "UNAUTHORIZED"];

    case ErrorCodeEnum.ImportError:
      return ["error.importError", "IMPORT ERROR"];

    case ErrorCodeEnum.SubscriptionLimitExceeded:
      return [
        "error.subscriptionLimitExceededError",
        "SUBSCRIPTION LIMIT EXCEEDED ERROR",
      ];

    case ErrorCodeEnum.PersonalAccountOwnsTeams:
      return [
        "error.personalAccountOwnsTeamsError",
        "PERSONAL ACCOUNT OWNS TEAMS ERROR",
      ];

    default:
      return ((_code: never) => ["error.unknown", "UNKNOWN ERROR"])(code);
  }
};

// Values for extraction
// t("common:error.blank.referencePerson", "BLANK REFERENCE PERSON")
// t("common:error.blank.address.line1", "BLANK ADDRESS LINE 1")
// t("common:error.blank.address.city", "BLANK ADDRESS CITY")
// t("common:error.blank.address.postalCode","BLANK ADDRESS POSTAL CODE")
// t("common:error.blank.address.country", "BLANK ADDRESS COUNTRY")
// t("common:error.blank.path", "Missing {{path}}")
// t("common:error.invalid.address.country", "Country must be a 2 character code")
// t("common:error.invalid.path", "Invalid {{path}}")
// t("common:error.tooShort", "{{path}} is too short")
// t("common:error.tooSmall", "{{path}} is too small")
// t("common:error.tooBig", "{{path}} is too big")
// t("common:error.inclusion", "INCLUSION")
// t("common:error.taken", "TAKEN")
// t("common:error.accountAlreadyConfirmed", "ALREADY CONFIRMED")
// t("common:error.accountBlocked", "BLOCKED")
// t("common:error.accountExists", "EXISTS")
// t("common:error.userNotConfirmed", "USER NOT CONFIRMED")
// t("common:error.accountNotFound", "The account does not exist")
// t("common:error.attemptsLimitExceeded", "LIMIT EXCEEDED")
// t("common:error.brregQueryFailed", "BRREG FAILURE")
// t("common:error.clientInsolvent", "CLIENT INSOLVENT")
// t("common:error.invalidPhoneNumber", "INVALID PHONE")
// t("common:error.invalidToken", "INVALID TOKEN")
// t("common:error.invalidTransition", "INVALID TRANSITION")
// t("common:error.invoiceCalculationFailed", "INVOICE CALCULATION FAILED")
// t("common:error.invoiceSubmitted", "ALREADY SUBMITTED")
// t("common:error.occupationCodeDisabled", "DISABLED CODE")
// t("common:error.requirementsQuestionnaireAnswerInvalid", "INVALID ANSWER")
// t("common:error.smsSendingFailed", "SMS FAILURE")
// t("common:error.tokenExpired", "EXPIRED TOKEN")
// t("common:error.transitionFailed", "TRANSITION FAILED")
// t("common:error.validationFailed", "VALIDATION FAILED")
// t("common:error.authLevelOneRequired", "AUTH LEVEL 1 REQUIRED")
// t("common:error.authLevelTwoRequired", "An SMS confirmation is required.")
// t("common:error.integrationRequired", "INTEGRATION REQUIRED")
// t("common:error.invoiceNotSubmitted", "INVOICE NOT SUBMITTED")
// t("common:error.invoicePublished", "INVOICE PUBLISHED")
// t("common:error.invalidPassword", "INVALID PASSWORD")
// t("common:error.invalidInvoicedOn", "INVALID INVOICE DATE")
// t("common:error.clientEmailMissing", "CLIENT EMAIL IS MISSING")
// t("common:error.deliveryFrequencyExceeded", "EXCEEDED DELIVERY FREQUENCY")
// t("common:error.invoiceNotOrganization", "NOT AN ORGANIZATION")
// t("common:error.invoiceNotPublished", "NOT PUBLISHED")
// t("common:error.invoiceNotCredited", "NOT CREDITED")
// t("common:error.jobCheckPending", "JOB CHECK PENDING")
// t("common:error.jobCheckSucceeded", "JOB CHECK SUCCEEDED")
// t("common:error.immutable", "IMMUTABLE")
// t("common:error.verificationFailed", "VERIFICATION FAILED")
// t("common:error.bankIdVerificationRequired", "VERIFIED BANKID REQUIRED")
// t("common:error.documentSigned", "DOCUMENT SIGNED")
// t("common:error.documentNotFound", "DOCUMENT NOT FOUND")
// t("common:error.senderEqualsReceiver", "SENDER EQUALS RECEIVER")
// t("common:error.invalidAction", "INVALID ACTION")
// t("common:error.tooSmallOrEqual", "TOO SMALL OR EQUAL")
// t("common:error.tooBigOrEqual", "TOO BIG OR EQUAL")
// t("common:error.organizationRoleCheckFailed", "ORGANIZATION ROLE CHECK FAILED")
// t("common:error.partnerCallbackError", "PARTNER CALLBACK ERROR")
// t("common:error.otpExpired", "Your link has expired.")
// t("common:error.otpExpiredAndResent", "Your link has expired, but we have sent a new one to your email.")
// t("common:error.lineMismatch", "LINE MISMATCH")
// t("common:error.personalNumberMismatch", "PERSONAL NUMBER MISMATCH")
// t("common:error.freelanceProfileArchived", "FREELANCE PROFILE ARCHIVED")
// t("common:error.present", "PRESENT")
// t("common:error.unauthorized", "UNAUTHORIZED")
// t("common:error.importError", "IMPORT ERROR")
// t("common:error.subscriptionLimitExceededError", "SUBSCRIPTION LIMIT EXCEEDED ERROR"]
// t("common:error.personalAccountOwnsTeamsError", "PERSONAL ACCOUNT OWNS TEAMS ERROR"]
// t("common:error.unknown", "UNKNOWN ERROR")

interface Props {
  dataErrors: ErrorFragment[];
  graphqlErrors?: readonly GraphQLError[];
  onSuccess?: () => void;
  onRetry?: () => void;
}

export const useErrorsOrSuccessHandler = (
  successId?: string,
  successDefault = ""
): (({ dataErrors, graphqlErrors, onSuccess, onRetry }: Props) => void) => {
  const { handleSecondSecurityLevelRequiredError } = useContext(PopupContext);
  const { logger } = useContext(ErrorLoggerContext);

  const { t } = useTranslation("common");

  return ({ dataErrors, graphqlErrors, onSuccess, onRetry }: Props) => {
    if (graphqlErrors?.length) {
      toast.custom((toastProps) => (
        <ErrorCard
          heading={t("error.graphql", "GraphQL Error")}
          toastProps={toastProps}
        />
      ));

      if (logger) logger.error(graphqlErrors[0].message);
    } else if (dataErrors.length)
      dataErrors.forEach((error) => {
        handleSecondSecurityLevelRequiredError(error.code)
          .then(onRetry)
          .catch(() => null);

        const [messageId, defaultMessage, variables] =
          getErrorCodeMessageId(error);
        const { path } = error;
        const interpolationValues = {
          ...variables,
          path,
        };

        toast.custom((toastProps) => (
          <ErrorCard
            heading={t(messageId, defaultMessage, interpolationValues)}
            toastProps={toastProps}
          />
        ));
      });
    else {
      if (successId)
        toast.custom((toastProps) => (
          <SuccessCard
            heading={t(successId, successDefault)}
            toastProps={toastProps}
          />
        ));

      if (onSuccess) onSuccess();
    }
  };
};
