import React from "react";
import { Helmet } from "react-helmet-async";

import { TTailwindString } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  title: string;
  className?: TTailwindString;
  disablePadding?: boolean;
}

export default React.forwardRef(
  (
    {
      title,
      className,
      disablePadding,
      children,
    }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const contentBase = tw("flex-1", "flex", "flex-col");
    const contentPadding = tw(
      "pt-6",
      "px-4",
      "pb-28",
      "md:pb-12",
      "md:pt-12",
      "md:px-8",
      "lg:px-10"
    );
    const contentStyles = tw(contentBase, className, {
      [contentPadding]: !disablePadding,
    });

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <div className={contentStyles} ref={ref}>
          {children}
        </div>
      </>
    );
  }
);
