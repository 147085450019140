/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { MEMBERS, MembersQuery, SESSION, SessionQuery } from "graphql/queries";
import { RemoveAccountMemberInput } from "graphql/types";

const REMOVE_ACCOUNT_MEMBER = gql`
  mutation ($input: RemoveAccountMemberInput!) {
    removeAccountMember(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface RemoveAccountMemberMutation {
  removeAccountMember: Payload | null;
}

export const useRemoveAccountMemberMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    RemoveAccountMemberMutation,
    { input: RemoveAccountMemberInput }
  >(REMOVE_ACCOUNT_MEMBER);

  const removeAccountMember = (
    input: RemoveAccountMemberInput,
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache) {
        cache.modify({
          fields: {
            members() {
              const membersQuery = cache.readQuery<MembersQuery>({
                query: MEMBERS,
              });
              if (!membersQuery) return;

              const items = membersQuery.members.items?.filter(
                (member) => member.id !== input.memberId
              );
              const total = membersQuery.members.total - 1;

              cache.writeQuery<MembersQuery>({
                query: MEMBERS,
                data: { members: { items, total } },
              });
            },
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              const counts = sessionQuery.session.account.counts
                ? {
                    ...sessionQuery.session.account.counts,
                    members: sessionQuery.session.account.counts.members - 1,
                  }
                : undefined;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      counts,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.removeAccountMember?.errors ?? [];
        const account = data?.removeAccountMember?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    removeAccountMember,
    isLoading: loading,
    account: data?.removeAccountMember?.account,
    errors: data?.removeAccountMember?.errors ?? [],
  };
};
