/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PersonFragment,
} from "graphql/fragments";
import { PEOPLE, PERSON, PeopleQuery, PersonQuery } from "graphql/queries";
import { PeopleFilter, SetPeopleDepartmentsInput } from "graphql/types";

import { BulkInput } from "./shared/types";

const SET_PEOPLE_DEPARTMENTS = gql`
  mutation ($ids: [ID!], $filter: PeopleFilter, $departmentIds: [ID!]!) {
    setPeopleDepartments(
      input: { ids: $ids, filter: $filter, departmentIds: $departmentIds }
    ) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<PeopleFilter> &
  Pick<SetPeopleDepartmentsInput, "departmentIds">;

interface Payload {
  errors: ErrorFragment[];
}

interface SetPeopleDepartmentsMutation {
  setPeopleDepartments: Payload | null;
}

export const useSetPeopleDepartmentsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { loading }] = useMutation<
    SetPeopleDepartmentsMutation,
    Input
  >(SET_PEOPLE_DEPARTMENTS);

  const setPeopleDepartments = (
    {
      departments,
      ...variables
    }: BulkInput<PeopleFilter> & { departments: PersonFragment["departments"] },
    onSuccess?: () => void
  ) =>
    mutation({
      variables: {
        ...variables,
        departmentIds: departments.map((department) => department.id),
      },
      update(cache) {
        cache.modify({
          fields: {
            node() {
              const person = cache.readQuery<PersonQuery>({
                query: PERSON,
              });
              if (!person) return;

              cache.writeQuery<PersonQuery>({
                query: PERSON,
                data: {
                  node: {
                    ...person.node,
                    departments,
                  },
                },
              });
            },
            people() {
              const existingPeople = cache.readQuery<PeopleQuery>({
                query: PEOPLE,
              });
              if (!existingPeople?.people.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const items = existingPeople.people.items?.filter((person) => {
                const isAffected =
                  !isSome ??
                  (variables as { ids: string[] }).ids.includes(person.id);
                if (isAffected) return { ...person, departments };

                return person;
              });

              cache.writeQuery<PeopleQuery>({
                query: PEOPLE,
                data: {
                  people: {
                    ...existingPeople.people,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.setPeopleDepartments?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setPeopleDepartments,
    isLoading: loading,
  };
};
