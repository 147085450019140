/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  SIGNATURE_FRAGMENT,
  SignatureFragment,
} from "graphql/fragments";

const NOTIFY_SIGNATURE = gql`
  mutation ($id: ID!) {
    notifySignature(input: { id: $id }) {
      signature {
        ...SignatureFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  signature?: SignatureFragment | null;
  errors: ErrorFragment[];
}

interface NotifySignatureMutation {
  notifySignature: Payload | null;
}

export const useNotifySignatureMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.notifiedSignature",
    "Reminder sent successfully"
  );
  // Values for extraction
  // t("common:success.notifiedSignature", "Reminder sent successfully")

  const [mutation, { data, loading, called }] = useMutation<
    NotifySignatureMutation,
    { id: string }
  >(NOTIFY_SIGNATURE);

  const notifySignature = (
    id: string,
    onSuccess?: (response: SignatureFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.notifySignature?.errors ?? [];
        const signature = data?.notifySignature?.signature;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => signature && onSuccess && onSuccess(signature),
          onRetry: () => notifySignature(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    notifySignature,
    isLoading: loading,
    called,
    signature: data?.notifySignature?.signature,
    errors: data?.notifySignature?.errors ?? [],
  };
};
