import React from "react";

import moment from "moment";

import { useTranslation } from "translations";

import Input, { Props as InputProps } from "../basic/Input";

interface Props
  extends Omit<
    InputProps,
    "LeadingIcon" | "TrailingIcon" | "type" | "onChange"
  > {
  onChange: (date: moment.Moment) => void;
}

export default React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
    const { t } = useTranslation("common");

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const dateString = event.target.value;
      if (!dateString) return;

      const date = moment(dateString, "YYYY-MM-DD");
      props.onChange(date);
    };

    return (
      <Input
        {...props}
        onChange={onChange}
        ref={ref}
        type="date"
        placeholder={t("dateInput.placeholder", "yyyy-mm-dd")}
      />
    );
  }
);
