import { ActionID } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  title: string;
  subTitle?: string;
  linkProps?: {
    id: ActionID;
    to: string;
    label: string;
  };
  isClosable?: boolean;
}

export default ({ linkProps, ...props }: Props) => (
  <PopupWrapper
    {...props}
    nameSpace="checkmore"
    footerActions={linkProps ? [linkProps] : undefined}
  />
);
