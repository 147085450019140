/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";
import { CheckRequestAttributes } from "graphql/types";

const SUBMIT_CHECK_REQUEST = gql`
  mutation ($id: ID!, $attributes: CheckRequestAttributes!) {
    submitCheckRequest(input: { id: $id, attributes: $attributes }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest?: CheckRequestFragment | null;
  errors: ErrorFragment[];
}

interface SubmitCheckRequestMutation {
  submitCheckRequest: Payload | null;
}

export const useSubmitCheckRequestMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    SubmitCheckRequestMutation,
    { id: string; attributes: CheckRequestAttributes }
  >(SUBMIT_CHECK_REQUEST);

  const submitCheckRequest = (
    id: string,
    attributes: CheckRequestAttributes,
    onSuccess?: (response: CheckRequestFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables: { id, attributes },
      update(cache) {
        cache.modify({
          fields: {
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      uiFlags: {
                        ...sessionQuery.session.account.uiFlags,
                        hasSentCheckLists: true,
                      },
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitCheckRequest?.errors ?? [];
        const checkRequest = data?.submitCheckRequest?.checkRequest;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => checkRequest && onSuccess && onSuccess(checkRequest),
          onRetry: () => submitCheckRequest(id, attributes, onSuccess),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    submitCheckRequest,
    isLoading: loading,
    called,
    document: data?.submitCheckRequest?.checkRequest,
    errors: data?.submitCheckRequest?.errors ?? [],
  };
};
