/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DEPARTMENT_FRAGMENT,
  DepartmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DEPARTMENTS, DepartmentsQuery } from "graphql/queries";
import { DepartmentAttributes } from "graphql/types";

const UPDATE_DEPARTMENT = gql`
  mutation ($id: ID!, $attributes: DepartmentAttributes!) {
    updateDepartment(input: { id: $id, attributes: $attributes }) {
      department {
        ...DepartmentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DEPARTMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  department?: DepartmentFragment | null;
  errors: ErrorFragment[];
}

interface UpdateDepartmentMutation {
  updateDepartment: Payload | null;
}

export const useUpdateDepartmentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateDepartmentMutation,
    { id: string; attributes: DepartmentAttributes }
  >(UPDATE_DEPARTMENT);

  const updateDepartment = (
    id: string,
    attributes: DepartmentAttributes,
    onSuccess?: (department: DepartmentFragment) => void
  ) =>
    mutation({
      variables: { id, attributes },
      update(cache, { data: departmentData }) {
        const updatedDepartment = departmentData?.updateDepartment?.department;
        if (!updatedDepartment) return;

        cache.modify({
          fields: {
            departments() {
              const existingDepartments = cache.readQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
              });
              if (!existingDepartments?.departments.items) return;

              const items = existingDepartments.departments.items?.map(
                (departmentItem) =>
                  departmentItem?.id === id ? updatedDepartment : departmentItem
              );

              cache.writeQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
                data: {
                  departments: {
                    items,
                    total: existingDepartments.departments.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateDepartment?.errors ?? [];
        const department = data?.updateDepartment?.department;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => department && onSuccess && onSuccess(department),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateDepartment,
    isLoading: loading,
    department: data?.updateDepartment?.department,
    errors: data?.updateDepartment?.errors ?? [],
  };
};

export const useSilentUpdateDepartmentMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    UpdateDepartmentMutation,
    { attributes: DepartmentAttributes }
  >(UPDATE_DEPARTMENT);

  const updateDepartment = (attributes: DepartmentAttributes) =>
    mutation({ variables: { attributes } });

  return {
    updateDepartment,
    isLoading: loading,
    department: data?.updateDepartment?.department,
    errors: data?.updateDepartment?.errors ?? [],
  };
};
