import { useContext } from "react";

import { Page } from "components/common/wrappers";
import { AccountTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";

import OrgInfo from "./organization/AccountInfo";
import IndInfo from "./personal/AccountInfo";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);

  const isOrg = session?.account.accountType === AccountTypeEnum.Team;

  return (
    <Page title={t("accountInfo.pageTitle", "Account settings")}>
      {isOrg ? <OrgInfo /> : <IndInfo />}
    </Page>
  );
};
