import { useContext } from "react";

import { Button, Divider, Icon, Link } from "components/common/basic";
import { WbReportStatusLabel } from "components/common/composite/StatusLabel";
import { getWbReportCategoryString } from "components/tellmore/utils";
import {
  AttachmentFragment,
  PublicWbReportFragment,
  WbReportFragment,
} from "graphql/fragments";
import {
  WbReportPrivacyEnum,
  WbReportStatusEnum,
  WbReportTypeEnum,
} from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { atDateToMoment } from "utils";
import { tw } from "utils/tw";

import { useWbReportActions } from "../hooks";
import Guidelines from "./Guidelines";
import WbMessage from "./WbMessage";

interface PropsBase {
  type: "handler" | "whistleblower";
  wbReport: WbReportFragment | PublicWbReportFragment;
}
interface HandlerProps extends PropsBase {
  type: "handler";
  wbReport: WbReportFragment;
  guidelines: AttachmentFragment[];
}
interface WhistleblowerProps extends PropsBase {
  type: "whistleblower";
  wbReport: PublicWbReportFragment;
}
type Props = HandlerProps | WhistleblowerProps;

export default ({ type, wbReport }: Props): JSX.Element => {
  const { t } = useTranslation("tellmore");
  const { openPopup } = useContext(PopupContext);
  const { getWbReportActions, getPublicWbReportActions } = useWbReportActions();

  const actions =
    type === "handler"
      ? getWbReportActions(wbReport)
      : getPublicWbReportActions(wbReport);

  const sender =
    wbReport.privacy === WbReportPrivacyEnum.Anonymous
      ? t("wbReportDetails.sender.anonymous", "Anonymous")
      : `${wbReport.firstName} ${wbReport.lastName}`;

  const isEditable =
    type === "handler" &&
    [WbReportStatusEnum.Submitted, WbReportStatusEnum.InProgress].includes(
      wbReport.status
    );

  return (
    <div className={tw("space-y-6", "border", "border-silver", "rounded-lg")}>
      <div className={tw("px-4", "py-6", "bg-silver", "space-y-4")}>
        <div
          className={tw(
            "flex",
            "flex-col",
            "sm:flex-row",
            "sm:justify-between"
          )}
        >
          <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
            {t(
              "wbReportDetails.reportNumber",
              "Report number: {{ reportNumber }}",
              { reportNumber: wbReport.reportNumber }
            )}
          </h2>

          <p className={tw("text-sm", "text-deepBlue-500")}>
            {type === "handler"
              ? t(
                  "wbReportDetails.summary_handler.submittedAt",
                  "Received: {{ date }}",
                  {
                    date: atDateToMoment(wbReport.submittedAt ?? "").format(
                      "DD MMM YYYY @ HH:mm"
                    ),
                  }
                )
              : t(
                  "wbReportDetails.summary_whistleblower.submittedAt",
                  "Sent: {{ date }}",
                  {
                    date: atDateToMoment(wbReport.submittedAt ?? "").format(
                      "DD MMM YYYY @ HH:mm"
                    ),
                  }
                )}
          </p>
        </div>

        <Divider />

        <div className={tw("space-y-2")}>
          <p className={tw("text-sm", "text-deepBlue-500")}>
            {t("wbReportDetails.summary.sender", "From: {{ sender }}", {
              sender,
            })}
          </p>

          {wbReport.email && (
            <p className={tw("text-sm", "text-deepBlue-500")}>
              {t("wbReportDetails.summary.email", "Email: {{ email }}", {
                email: wbReport.email,
              })}
            </p>
          )}

          {wbReport.phoneNumber?.number && (
            <p className={tw("text-sm", "text-deepBlue-500")}>
              {t(
                "wbReportDetails.summary.phone",
                "Phone: {{ code }} {{ number }}",
                {
                  code: wbReport.phoneNumber.countryCode,
                  number: wbReport.phoneNumber.number,
                }
              )}
            </p>
          )}

          <div className={tw("flex", "justify-between", "space-x-2")}>
            <p className={tw("text-sm", "text-deepBlue-500", "break-all")}>
              {t("wbReportDetails.summary.type", "Type: {{ type }}", {
                type:
                  wbReport.type &&
                  t(
                    `wbReportDetails.summary.type.value.${wbReport.type.toLowerCase()}`,
                    wbReport.type
                  ),
              })}
            </p>

            {isEditable && (
              <button
                className={tw(
                  "underline",
                  "text-sm",
                  "text-deepBlue-900",
                  "hover:text-deepBlue-700",
                  "shrink-0"
                )}
                onClick={() =>
                  openPopup({
                    id: "UpdateWbReport",
                    props: { wbReport: wbReport as WbReportFragment },
                  })
                }
              >
                {t("wbReportDetails.summary.type.button.label", "Change")}
              </button>
            )}
          </div>

          {wbReport.type === WbReportTypeEnum.Whistleblowing && (
            <div className={tw("flex", "justify-between", "space-x-2")}>
              <p className={tw("text-sm", "text-deepBlue-500", "break-all")}>
                {t(
                  "wbReportDetails.summary.category",
                  "Category: {{ category }}",
                  {
                    category:
                      wbReport.category &&
                      getWbReportCategoryString(wbReport.category, t),
                  }
                )}
              </p>

              {isEditable && (
                <button
                  className={tw(
                    "underline",
                    "text-sm",
                    "text-deepBlue-900",
                    "hover:text-deepBlue-700",
                    "shrink-0"
                  )}
                  onClick={() =>
                    openPopup({
                      id: "UpdateWbReport",
                      props: { wbReport: wbReport as WbReportFragment },
                    })
                  }
                >
                  {t("wbReportDetails.summary.category.button.label", "Change")}
                </button>
              )}
            </div>
          )}

          <div className={tw("flex", "justify-between", "space-x-2")}>
            <p className={tw("text-sm", "text-deepBlue-500", "break-all")}>
              {t(
                "wbReportDetails.summary.reportHandlers",
                "Assigned: {{ reportHandlers }}",
                {
                  reportHandlers: wbReport.wbHandlers
                    .map(({ name, title }) =>
                      title ? `${name} (${title})` : name
                    )
                    .join(", "),
                }
              )}
            </p>

            {isEditable && (
              <button
                className={tw(
                  "underline",
                  "text-sm",
                  "text-deepBlue-900",
                  "hover:text-deepBlue-700",
                  "shrink-0"
                )}
                onClick={() =>
                  openPopup({
                    id: "AssignWbHandlers",
                    props: { wbReport: wbReport as WbReportFragment },
                  })
                }
              >
                {t(
                  "wbReportDetails.summary.reportHandlers.button.label",
                  "Re-assign"
                )}
              </button>
            )}
          </div>
        </div>

        {wbReport.type === WbReportTypeEnum.Whistleblowing &&
          type === "handler" &&
          wbReport.routineGuidelines.length > 0 && (
            <>
              <Divider />

              <div className={tw("space-y-4")}>
                <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
                  {t(
                    "wbReportDetails.guidelines.heading",
                    "Routine guidelines"
                  )}
                </h2>

                <p className={tw("text-sm", "text-deepBlue-900")}>
                  {t(
                    "wbReportDetails.guidelines.body",
                    "Below are some guidelines that case managers should go through in order to be able to assess the case and give the person who complained the correct and fair response."
                  )}
                </p>

                <Guidelines routineGuidelines={wbReport.routineGuidelines} />
              </div>
            </>
          )}
      </div>

      <div className={tw("px-4", "pb-6", "space-y-6")}>
        <div className={tw("space-y-1")}>
          <div className={tw("flex", "justify-between")}>
            <h3 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
              {t("wbReportDetails.status.heading", "Status")}
            </h3>

            <WbReportStatusLabel
              variant="tag"
              status={wbReport.status}
              badgeSize="sm"
            />
          </div>

          <p className={tw("text-right", "text-xs", "text-deepBlue-500")}>
            {t(
              "wbReportDetails.status.lastUpdated",
              "Last updated: {{ date }}",
              {
                date: atDateToMoment(wbReport.lastInteractionAt).format(
                  "DD MMM YYYY @ HH:mm"
                ),
              }
            )}
          </p>

          {actions.length > 0 && (
            <div className={tw("flex", "flex-col", "md:flex-row", "gap-4")}>
              {actions.map((action) =>
                action.to ? (
                  <Link
                    key={action.id}
                    id={action.id}
                    to={action.to as string}
                    isExternal={action.isExternal}
                  >
                    <Button
                      id={action.id}
                      LeadingIcon={action.Icon}
                      variant={action.isDestructive ? "danger" : "tertiary"}
                      size="sm"
                    >
                      {action.label}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    key={action.id}
                    id={action.id}
                    LeadingIcon={action.Icon}
                    onClick={action.onClick}
                    variant={action.isDestructive ? "danger" : "tertiary"}
                    size="sm"
                  >
                    {action.label}
                  </Button>
                )
              )}
            </div>
          )}
        </div>

        <Divider />

        <div className={tw("flex", "space-x-4", "items-start")}>
          <Icon
            icon="HiSpeakerphone"
            size={16}
            margin="my-1"
            color="text-deepBlue-500"
            isFilled
          />

          <div className={tw("flex-1", "space-y-4")}>
            <div className={tw("space-y-1")}>
              <div className={tw("flex", "justify-between")}>
                <h3 className={tw("text-deepBlue-900", "font-semibold")}>
                  {t("wbReportDetails.report.heading", "Report")}
                </h3>

                <p className={tw("text-xs", "text-deepBlue-500")}>
                  {t("wbReportDetails.report.submittedAt", "{{ date }}", {
                    date: wbReport.submittedAt
                      ? atDateToMoment(wbReport.submittedAt).format(
                          "DD MMM YYYY @ HH:mm"
                        )
                      : "",
                  })}
                </p>
              </div>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t("wbReportDetails.report.sender", "Sender: {{ sender }}", {
                  sender,
                })}
              </p>
            </div>

            {wbReport.category && (
              <p className={tw("text-deepBlue-700", "text-sm", "break-all")}>
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t("wbReportDetails.report.category", "Category:")}
                </strong>{" "}
                {getWbReportCategoryString(wbReport.category, t)}
              </p>
            )}

            {wbReport.people && (
              <p className={tw("text-deepBlue-700", "text-sm", "break-all")}>
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t("wbReportDetails.report.people", "Involved people:")}
                </strong>{" "}
                {wbReport.people}
              </p>
            )}

            {wbReport.description && (
              <p
                className={tw(
                  "text-deepBlue-700",
                  "text-sm",
                  "whitespace-pre-wrap",
                  "break-words"
                )}
              >
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t(
                    "wbReportDetails.report.description",
                    "What has happened:"
                  )}
                </strong>{" "}
                {wbReport.description}
              </p>
            )}

            {wbReport.location && (
              <p className={tw("text-deepBlue-700", "text-sm", "break-all")}>
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t(
                    "wbReportDetails.report.location",
                    "Where did the wrongdoing take place:"
                  )}
                </strong>{" "}
                {wbReport.location}
              </p>
            )}

            {wbReport.timing && (
              <p className={tw("text-deepBlue-700", "text-sm", "break-all")}>
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t(
                    "wbReportDetails.report.timing",
                    "When did the wrongdoing take place:"
                  )}
                </strong>{" "}
                {wbReport.timing}
              </p>
            )}

            {wbReport.action && (
              <p
                className={tw(
                  "text-deepBlue-700",
                  "text-sm",
                  "whitespace-pre-wrap",
                  "break-words"
                )}
              >
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t(
                    "wbReportDetails.report.action",
                    "Have you taken any other actions in relation to this case:"
                  )}
                </strong>{" "}
                {wbReport.action}
              </p>
            )}

            {wbReport.message && (
              <p
                className={tw(
                  "text-deepBlue-700",
                  "text-sm",
                  "whitespace-pre-wrap",
                  "break-words"
                )}
              >
                <strong className={tw("text-deepBlue-900", "font-semibold")}>
                  {t("wbReportDetails.report.message", "Message:")}
                </strong>{" "}
                {wbReport.message}
              </p>
            )}

            {wbReport.files.length > 0 && (
              <div>
                <h4 className={tw("text-deepBlue-700", "text-sm")}>
                  {t("wbReportDetails.report.attachments", "Attachments:")}
                </h4>

                <ul className={tw("space-y-1")}>
                  {wbReport.files.map((file) => (
                    <li key={file.id}>
                      <Link
                        id="wb_wizard-download_file"
                        isExternal
                        to={process.env.REACT_APP_API_URL + file.downloadPath}
                        color="deepBlue"
                        className={tw("text-sm")}
                      >
                        {file.filename}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {[...wbReport.wbMessages]
          .sort((a, b) =>
            atDateToMoment(a.sentAt).diff(atDateToMoment(b.sentAt))
          )
          .map((message) => (
            <WbMessage
              key={message.id}
              message={message}
              sender={message.wbHandler ? message.wbHandler.name : sender}
              isHandler={type === "handler"}
            />
          ))}
      </div>
    </div>
  );
};
