import { CopyToClipboardButton } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { InvoiceFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  invoice: InvoiceFragment;
}

export default ({ invoice, ...props }: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <PopupWrapper
      {...props}
      title={t("publicInvoice.paymentInfoPopup.title", "Pay via bank transfer")}
    >
      <div className={tw("flex", "flex-col", "space-y-6")}>
        {invoice.bankAccount && (
          <div className={tw("flex", "flex-col", "space-y-4")}>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h3 className={tw("text-lg", "font-bold")}>
                {t(
                  "publicInvoice.paymentInfoPopup.bankAccount.title",
                  "Bank account: {{ bankAccountNumber }}",
                  {
                    bankAccountNumber: invoice.bankAccount.accountNumber,
                  }
                )}
              </h3>

              <CopyToClipboardButton
                textToCopy={invoice.bankAccount.accountNumber}
              />
            </div>

            <p>
              {t(
                "publicInvoice.paymentInfoPopup.bankAccount.sentCode",
                "Transfer the total amount that is mentioned to this bank account number."
              )}
            </p>
          </div>
        )}

        {invoice.kid && (
          <div className={tw("flex", "flex-col", "space-y-4")}>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h3 className={tw("text-lg", "font-bold")}>
                {t(
                  "publicInvoice.paymentInfoPopup.kid.title",
                  "KID nr. {{ kid }}",
                  { kid: invoice.kid }
                )}
              </h3>

              <CopyToClipboardButton textToCopy={invoice.kid} />
            </div>

            <p>
              {t(
                "publicInvoice.paymentInfoPopup.kid.sentCode",
                "The KID number above is the unique identification number for the invoice you have received. You need to enter this KID nr. into the KID nr. input field provided by your bank when making the bank transfer."
              )}
            </p>
          </div>
        )}
      </div>
    </PopupWrapper>
  );
};
