/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FILE_FRAGMENT,
  DocumentFileFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DOCUMENT, DocumentQuery } from "graphql/queries";

const DESTROY_DOCUMENT_FILE = gql`
  mutation ($id: ID!) {
    destroyDocumentFile(input: { id: $id }) {
      documentType {
        ...DocumentFileFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentFile?: DocumentFileFragment | null;
  errors: ErrorFragment[];
}

interface DestroyDocumentFileMutation {
  destroyDocumentFile: Payload | null;
}

export const useDestroyDocumentFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyDocumentFileMutation,
    { id: string }
  >(DESTROY_DOCUMENT_FILE);

  const destroyDocumentFile = (
    id: string,
    onSuccess?: (documentFile: DocumentFileFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            node() {
              const document = cache.readQuery<DocumentQuery>({
                query: DOCUMENT,
              });
              if (!document) return;

              const documentFiles = document.node.documentFiles.filter(
                (file) => file.id !== id
              );

              cache.writeQuery<DocumentQuery>({
                query: DOCUMENT,
                data: {
                  node: {
                    ...document.node,
                    documentFiles,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyDocumentFile?.errors ?? [];
        const documentFile = data?.destroyDocumentFile?.documentFile;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && documentFile && onSuccess(documentFile),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyDocumentFile,
    isLoading: loading,
    documentFile: data?.destroyDocumentFile?.documentFile,
    errors: data?.destroyDocumentFile?.errors ?? [],
  };
};
