import { useContext, useEffect, useState } from "react";

import { Button } from "components/common/basic";
import { DepartmentMultiSearch } from "components/common/composite/search";
import { DepartmentFragment, PersonFragment } from "graphql/fragments";
import { useSetPeopleDepartmentsMutation } from "graphql/mutations";
import { PeopleFilter } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupVariant, PopupWrapper } from "../PopupWrapper";

interface BaseProps {
  hasPrevious: boolean;
  variant: PopupVariant;
}
export interface SomePeopleProps {
  people: PersonFragment[];
}
export interface AllPeopleProps {
  filter: PeopleFilter;
}

type Props = BaseProps & (SomePeopleProps | AllPeopleProps);

export default (props: Props): JSX.Element => {
  const isSomePeople = Object.prototype.hasOwnProperty.call(props, "people");
  const people = isSomePeople ? (props as SomePeopleProps).people : [];
  const filter = !isSomePeople ? (props as AllPeopleProps).filter : {};

  const [selectedDepartments, setSelectedDepartments] = useState<
    DepartmentFragment[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { setPeopleDepartments } = useSetPeopleDepartmentsMutation();
  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  useEffect(() => {
    if (people.length === 1)
      setSelectedDepartments(people[0].departments as DepartmentFragment[]);
  }, [people]);

  useEffect(() => {
    if (selectedDepartments.length > 0) setErrorMessage("");
  }, [selectedDepartments.length]);

  const onSubmit = () => {
    setErrorMessage("");

    if (selectedDepartments.length < 1)
      return setErrorMessage(
        t(
          "popup.setPeopleDepartments.error.departments.min",
          "Please select at least one department"
        )
      );

    setPeopleDepartments(
      {
        departments: selectedDepartments,
        ...(isSomePeople
          ? { ids: people.map((person) => person.id) }
          : { filter }),
      },
      closeAllPopups
    );
  };

  return (
    <PopupWrapper
      {...props}
      title={t("popup.setPeopleDepartments.heading", "Assign departments")}
    >
      <div className={tw("space-y-4")}>
        <div className={tw("space-y-12")}>
          <p
            className={tw(
              "text-lg",
              "text-deepBlue-500",
              "whitespace-pre-wrap"
            )}
          >
            {t(
              "popup.setPeopleDepartments.body",
              `Which departments should these candidates be part of? Depending on your selection we will either add or remove departments from the candidates.

Changing department roles of your team members (Admins, Mangers or Members) will not be possible here. Please go to your team members page`
            )}
          </p>

          <DepartmentMultiSearch
            label={t("popup.setPeopleDepartments.search.label", "Departments")}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            errorMessage={errorMessage}
          />

          <Button
            id="department_popup-edit"
            variant="primary"
            size="md"
            onClick={onSubmit}
          >
            {t("popup.setPeopleDepartments.button.submit", "Save")}
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
};
