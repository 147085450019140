/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { WB_HANDLER_FRAGMENT, WbHandlerFragment } from "graphql/fragments";
import { QueryWbHandlersArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const WB_HANDLERS = gql`
  query ($page: Int, $perPage: Int) {
    wbHandlers(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...WbHandlerFragment
      }
      total
    }
  }
  ${WB_HANDLER_FRAGMENT}
`;

export type WbHandlersQuery = {
  wbHandlers: PaginatedPayload<WbHandlerFragment>;
};

type QueryArgs = Pick<QueryWbHandlersArgs, "page" | "perPage">;

export const useWbHandlersQuery = (args: QueryArgs = {}) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<WbHandlersQuery, QueryArgs>(WB_HANDLERS, {
    variables: { ...args },
  });

  const wbHandlers = data?.wbHandlers.items?.filter(Boolean) ?? [];
  const total = data?.wbHandlers.total ?? 0;

  return { wbHandlers, total, isLoading, called };
};

export const useWbHandlersLazyQuery = () => {
  const [getWbHandlersQuery, { data, loading: isLoading, called }] =
    useLazyQuery<WbHandlersQuery, QueryArgs>(WB_HANDLERS, {
      fetchPolicy: "network-only",
    });

  const exportPath = data?.wbHandlers.exportPath;
  const wbHandlers = data?.wbHandlers.items?.filter(Boolean) ?? [];
  const total = data?.wbHandlers.total ?? 0;

  const resolveRef = useRef<(wbHandlers?: WbHandlerFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(wbHandlers);
      resolveRef.current = undefined;
    }
  }, [wbHandlers, called, isLoading]);

  const getWbHandlers = async (args?: QueryArgs) => {
    const variables = { ...args };

    getWbHandlersQuery({ variables });

    return new Promise<WbHandlerFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getWbHandlers, exportPath, wbHandlers, total, isLoading };
};
