import { HiCheck, HiX } from "react-icons/hi";

import { tw } from "utils/tw";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isToggledOn: boolean;
  label: string;
  showLabel?: boolean;
}

export default ({
  isToggledOn,
  label,
  showLabel = false,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element => {
  const containerStyles = tw("flex", "space-x-2", "items-center");
  const labelStyles = showLabel
    ? tw("text-sm", "text-deepBlue-900")
    : tw("sr-only");
  const toggleStyles = tw(
    isToggledOn ? "bg-gray-900" : "bg-gray-100",
    "relative",
    "inline-flex",
    "shrink-0",
    "h-6",
    "w-11",
    "border-2",
    "border-transparent",
    "rounded-full",
    "cursor-pointer",
    "transition-colors",
    "ease-in-out",
    "duration-200",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900"
  );
  const iconWrapper = tw(
    isToggledOn ? "translate-x-5" : "translate-x-0",
    "pointer-events-none",
    "relative",
    "inline-block",
    "h-5",
    "w-5",
    "rounded-full",
    "bg-white",
    "shadow",
    "ring-0",
    "transition",
    "ease-in-out",
    "duration-200"
  );

  const iconBase = tw(
    "absolute",
    "inset-0",
    "h-full",
    "w-full",
    "flex",
    "items-center",
    "justify-center",
    "transition-opacity"
  );
  const hiddenIconStyles = tw(
    iconBase,
    "opacity-0",
    "ease-out",
    "duration-100"
  );
  const shownIconStyles = tw(
    iconBase,
    "opacity-100",
    "ease-in",
    "duration-200"
  );

  return (
    <button
      {...props}
      type="button"
      role="switch"
      aria-checked={isToggledOn}
      className={containerStyles}
    >
      <span className={toggleStyles}>
        <span className={iconWrapper}>
          <span
            className={isToggledOn ? hiddenIconStyles : shownIconStyles}
            aria-hidden={isToggledOn}
          >
            <HiX size={12} className={tw("text-gray-500")} />
          </span>

          <span
            className={isToggledOn ? shownIconStyles : hiddenIconStyles}
            aria-hidden={!isToggledOn}
          >
            <HiCheck size={12} className={tw("text-gray-900")} />
          </span>
        </span>
      </span>

      <span className={labelStyles}>{label}</span>
    </button>
  );
};
