import { useContext, useEffect, useRef } from "react";

import { Icon } from "components/common/basic";
import { MenuList } from "components/common/wrappers/Layout/common";
import { MenuItem } from "components/common/wrappers/Layout/common/MenuList/types";
import { useMenu } from "hooks";
import { PopoverContext } from "providers/PopoverHandler";
import { TTailwindString, tw } from "utils/tw";

interface Props {
  id: string;
  label: string;
  sortByActions: MenuItem[];
  className?: TTailwindString;
}

export default ({
  label,
  sortByActions,
  id = label,
  className,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingSortByMenu, setIsShowingSortByMenu] = useMenu(
    containerRef,
    popoverMenuRef
  );
  const toggleMenu = () => setIsShowingSortByMenu(!isShowingSortByMenu);

  const {
    registerPopover,
    unregisterPopover,
    setIsShownPopover,
    shownPopovers,
  } = useContext(PopoverContext);

  useEffect(() => {
    const isShowingPopover = shownPopovers.some((popover) => popover.id === id);
    if (isShowingSortByMenu === isShowingPopover) return;

    setIsShownPopover(id, isShowingSortByMenu);
  }, [isShowingSortByMenu]);

  // Unregister popover on unmount
  useEffect(() => () => unregisterPopover(id), []);

  const popoverContent = (
    <div
      className={tw(
        "absolute",
        "z-20",
        "rounded-md",
        "shadow-lg",
        "bg-white",
        "-ml-1",
        "mt-1"
      )}
      ref={popoverMenuRef}
    >
      <MenuList
        menuItems={sortByActions}
        closeSelf={() => setIsShowingSortByMenu(false)}
      />
    </div>
  );

  return (
    <div ref={containerRef} className={className}>
      <button
        className={tw("flex", "space-x-1")}
        onClick={toggleMenu}
        ref={(targetRef) =>
          registerPopover({
            id,
            variant: "default",
            content: popoverContent,
            targetRef,
            placement: "left-end",
          })
        }
      >
        <span
          className={tw(
            "whitespace-nowrap",
            "overflow-hidden",
            "text-ellipsis",
            "uppercase",
            "text-xs",
            "font-semibold"
          )}
        >
          {label}
        </span>

        <Icon
          icon={
            isShowingSortByMenu ? "HiOutlineChevronUp" : "HiOutlineChevronDown"
          }
        />
      </button>
    </div>
  );
};
