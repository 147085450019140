import { Icon } from "components/common/basic";
import { CheckStatusEnum, CheckTypeEnum } from "graphql/types";

interface Props {
  type: CheckTypeEnum;
  status: CheckStatusEnum;
}

export default ({ type, status }: Props): JSX.Element => {
  const badgeColor = (() => {
    switch (status) {
      case CheckStatusEnum.Cleared:
        return "text-success";

      case CheckStatusEnum.Rejected:
        return "text-error";

      case CheckStatusEnum.Consented:
      case CheckStatusEnum.InReview:
        return "text-blue-500";

      case CheckStatusEnum.Requested:
      case CheckStatusEnum.Refused:
      case CheckStatusEnum.Cancelled:
        return "text-deepBlue-100";

      default:
        return ((_status: never) => "text-deepBlue-50" as const)(status);
    }
  })();

  const badgeIcon = (() => {
    switch (type) {
      case CheckTypeEnum.BankId:
      case CheckTypeEnum.Identity:
      case CheckTypeEnum.Passport:
      case CheckTypeEnum.DrivingLicense:
      case CheckTypeEnum.HealthPersonnelRegister:
      case CheckTypeEnum.HseCard:
      case CheckTypeEnum.RightToWork:
        return "HiIdentification";

      case CheckTypeEnum.BankAccount:
        return "HiLibrary";

      case CheckTypeEnum.Information:
        return "HiDocumentText";

      case CheckTypeEnum.CriminalRecord:
        return "HiShieldCheck";

      case CheckTypeEnum.ELearning:
        return "HiAcademicCap";

      default:
        return ((_type: never) => "HiStar" as const)(type);
    }
  })();

  return <Icon size={20} color={badgeColor} icon={badgeIcon} isFilled />;
};
