/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { SwitchAccountInput, SwitchAccountPayload } from "graphql/types";

const SWITCH_ACCOUNT = gql`
  mutation ($input: SwitchAccountInput!) {
    switchAccount(input: $input) {
      token
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload extends Pick<SwitchAccountPayload, "token"> {
  errors: ErrorFragment[];
}

export interface SwitchAccountMutation {
  switchAccount: Payload | null;
}

export const useSwitchAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    SwitchAccountMutation,
    { input: SwitchAccountInput }
  >(SWITCH_ACCOUNT);

  const switchAccount = (
    input: SwitchAccountInput,
    onSuccess?: (token: string) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.switchAccount?.errors ?? [];
        const token = data?.switchAccount?.token;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && token && onSuccess(token),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    switchAccount,
    session: data?.switchAccount,
    isLoading: loading,
    errors: data?.switchAccount?.errors ?? [],
  };
};
