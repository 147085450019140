/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { DocumentsFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const NOTIFY_DOCUMENTS = gql`
  mutation ($ids: [ID!], $filter: DocumentsFilter) {
    notifyDocuments(input: { ids: $ids, filter: $filter }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<DocumentsFilter>;

interface Payload {
  errors: ErrorFragment[];
}

interface NotifyDocumentsMutation {
  notifyDocuments: Payload | null;
}

export const useNotifyDocumentsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.notifiedDocuments",
    "Reminder(s) sent successfully"
  );
  // Values for extraction
  // t("common:success.notifiedDocuments", "Reminder(s) sent successfully")

  const [mutation, { data, loading, called }] = useMutation<
    NotifyDocumentsMutation,
    Input
  >(NOTIFY_DOCUMENTS);

  const notifyDocuments = (variables: Input, onSuccess?: () => void) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.notifyDocuments?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => notifyDocuments(variables, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    notifyDocuments,
    isLoading: loading,
    called,
    errors: data?.notifyDocuments?.errors ?? [],
  };
};
