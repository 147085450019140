/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { FREELANCE_PROFILES, FreelanceProfilesQuery } from "graphql/queries";
import { UpdateFreelanceProfileAttributes } from "graphql/types";

const UPDATE_FREELANCEPROFILE = gql`
  mutation ($id: ID!, $attributes: UpdateFreelanceProfileAttributes!) {
    updateFreelanceProfile(input: { id: $id, attributes: $attributes }) {
      freelanceProfile {
        ...FreelanceProfileFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  freelanceProfile?: FreelanceProfileFragment | null;
  errors: ErrorFragment[];
}

interface UpdateFreelanceProfileMutation {
  updateFreelanceProfile: Payload | null;
}

export const useUpdateFreelanceProfileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateFreelanceProfileMutation,
    { id: string; attributes: UpdateFreelanceProfileAttributes }
  >(UPDATE_FREELANCEPROFILE);

  const updateFreelanceProfile = (
    variables: {
      id: string;
      attributes: UpdateFreelanceProfileAttributes;
    },
    onSuccess?: (freelanceProfile: FreelanceProfileFragment) => void
  ) =>
    mutation({
      variables,
      update(cache, { data: updatedProfileData }) {
        const updatedProfileNode =
          updatedProfileData?.updateFreelanceProfile?.freelanceProfile;
        if (!updatedProfileNode) return;

        cache.modify({
          fields: {
            freelanceProfiles() {
              const existingProfiles = cache.readQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
              });
              if (!existingProfiles) return;

              const existingItems = existingProfiles?.freelanceProfiles.items;
              if (!existingItems) return;

              const indexOfExistingProfile = existingItems.findIndex(
                (profileItem) => profileItem?.id === variables.id
              );
              if (indexOfExistingProfile === -1) return;

              const items = [
                ...existingItems.slice(0, indexOfExistingProfile),
                updatedProfileNode,
                ...existingItems.slice(
                  indexOfExistingProfile + 1,
                  existingItems.length
                ),
              ];

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: existingProfiles.freelanceProfiles.total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateFreelanceProfile?.errors ?? [];
        const profile = data?.updateFreelanceProfile?.freelanceProfile;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => profile && onSuccess && onSuccess(profile),
          onRetry: () => updateFreelanceProfile(variables, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateFreelanceProfile,
    isLoading: loading,
    freelanceProfile: data?.updateFreelanceProfile?.freelanceProfile,
    errors: data?.updateFreelanceProfile?.errors ?? [],
  };
};
