import { Button, Link } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  const footerStyles = tw(
    "flex",
    "flex-col-reverse",
    "md:flex-row",
    "justify-between",
    "items-start",
    "md:items-end",
    "bg-deepBlue-900",
    "py-4",
    "px-6"
  );

  const currentYear = new Date().getFullYear();

  return (
    <footer className={footerStyles}>
      <p className={tw("text-deepBlue-100")}>
        {t("footer.copyright", "© 2018-{{ currentYear }} manymore.com AS", {
          currentYear,
        })}
      </p>

      <Link
        id="onboarding_footer-contact_us"
        to={t("footer.contact.link", "https://en.support.manymore.com/")}
        isExternal
        unstyled
      >
        <Button id="onboarding_footer-contact_us" size="md" variant="secondary">
          {t("footer.contact.label", "Contact Us")}
        </Button>
      </Link>
    </footer>
  );
};
