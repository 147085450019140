/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CreateCheckListAttributes } from "graphql/types";

const CREATE_CHECK_LISTS = gql`
  mutation ($id: ID!, $attributes: [CreateCheckListAttributes!]!) {
    createCheckLists(input: { checkRequestId: $id, attributes: $attributes }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest: CheckRequestFragment;
  errors: ErrorFragment[];
}

interface CreateCheckListsMutation {
  createCheckLists: Payload | null;
}

export const useCreateCheckListsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateCheckListsMutation,
    { id: string; attributes: CreateCheckListAttributes[] }
  >(CREATE_CHECK_LISTS);

  const createCheckLists = (
    variables: { id: string; attributes: CreateCheckListAttributes[] },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.createCheckLists?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createCheckLists,
    isLoading: loading,
    errors: data?.createCheckLists?.errors ?? [],
  };
};
