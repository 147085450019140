import { gql } from "@apollo/client";

import { Job } from "graphql/types";

import { ACCOUNT_FRAGMENT, AccountFragment } from "./account";
import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const JOB_FRAGMENT = gql`
  fragment JobFragment on Job {
    id
    account {
      ...AccountFragment
    }
    address {
      ...AddressFragment
    }
    applicationDeadline
    applicationForm {
      pages {
        description
        pageNumber
        title
        questions {
          id
          label
          required
          type
          validationMessage
          validationRegexp
          condition {
            matchValues
            questionId
            type
          }
          options {
            label
            value
          }
        }
      }
    }
    colors {
      default
      hover
      contrast
    }
    logo {
      ...AttachmentFragment
    }
    applicationLimit
    compensation
    description
    jobType
    languageRequirements
    payout
    tagline
    title
    jobChecks {
      id
      condition {
        questionId
        matchValues
        type
      }
    }
    submittedApplications
    qualifiedApplications
    rejectedApplications
    approvedApplications
    enrolledApplications
    privacyLink
    termsLink
  }
  ${ACCOUNT_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export interface JobFragment
  extends Pick<
    Job,
    | "id"
    | "applicationDeadline"
    | "applicationForm"
    | "applicationLimit"
    | "compensation"
    | "description"
    | "jobType"
    | "languageRequirements"
    | "payout"
    | "tagline"
    | "title"
    | "colors"
    | "jobChecks"
    | "submittedApplications"
    | "qualifiedApplications"
    | "rejectedApplications"
    | "approvedApplications"
    | "enrolledApplications"
    | "privacyLink"
    | "termsLink"
  > {
  account: AccountFragment;
  address?: AddressFragment;
  logo?: AttachmentFragment;
}
