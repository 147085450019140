/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { WB_REPORT_FRAGMENT, WbReportFragment } from "graphql/fragments";
import { QueryWbReportsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const WB_REPORTS = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: WbReportsFilter
    $sortBy: WbReportsSortByEnum
  ) {
    wbReports(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      exportPath
      items {
        ...WbReportFragment
      }
      total
    }
  }
  ${WB_REPORT_FRAGMENT}
`;

export type WbReportsQuery = {
  wbReports: PaginatedPayload<WbReportFragment>;
};

type QueryArgs = Pick<
  QueryWbReportsArgs,
  "filter" | "page" | "perPage" | "sortBy"
>;

export const useWbReportsQuery = (args: QueryArgs = {}) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<WbReportsQuery, QueryArgs>(WB_REPORTS, {
    variables: { ...args },
  });

  const wbReports = data?.wbReports.items?.filter(Boolean) ?? [];

  return {
    wbReports,
    isLoading,
    called,
  };
};

export const useWbReportsLazyQuery = () => {
  const [getWbReportsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<WbReportsQuery, QueryArgs>(WB_REPORTS, {
      fetchPolicy: "network-only",
    });

  const exportPath = data?.wbReports.exportPath;
  const wbReports = data?.wbReports.items?.filter(Boolean) ?? [];
  const total = data?.wbReports.total ?? 0;

  const resolveRef = useRef<(wbReports?: WbReportFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(wbReports);
      resolveRef.current = undefined;
    }
  }, [wbReports, called, isLoading]);

  const getWbReports = async (args?: QueryArgs) => {
    const variables = { ...args };

    getWbReportsQuery({ variables });

    return new Promise<WbReportFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getWbReports, exportPath, wbReports, total, isLoading, called };
};
