import { useContext, useState } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { AccountFragment } from "graphql/fragments";
import { TeamRoleEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import FindMemberForm from "./FindMemberForm";
import MemberForm from "./MemberForm";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
  account?: AccountFragment;
}

export default ({ onSuccess, account, ...props }: Props): JSX.Element => {
  const [email, setEmail] = useState<string>();
  const [memberData, setMemberData] = useState(account);

  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  const isOwner = memberData?.role === TeamRoleEnum.Owner;

  return (
    <PopupWrapper
      {...props}
      title={
        memberData
          ? t("popup.teamMember_edit.heading", "Edit member")
          : t("popup.teamMember_add.heading", "Add new member")
      }
      subTitle={
        !isOwner && memberData
          ? t(
              "popup.teamMember_edit.description",
              "Edit this member, add them to one or more departments, give them the appropriate role and additional access options."
            )
          : t(
              "popup.teamMember_add.description",
              "Add a new member, add them to one or more departments, give them the appropriate role and additional access options."
            )
      }
    >
      {email || memberData ? (
        <MemberForm
          member={memberData ?? { email }}
          onSuccess={() => {
            onSuccess?.();

            closeAllPopups();
          }}
          showAlreadyExistsNotice={Boolean(memberData && !account)}
        />
      ) : (
        <FindMemberForm
          onSuccess={(data) => {
            if (typeof data === "string") setEmail(data);
            else setMemberData(data);
          }}
        />
      )}
    </PopupWrapper>
  );
};
