import { tw } from "utils/tw";

interface Props {
  buttons: {
    props: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >;
    label: string | JSX.Element;
  }[];
}

export default ({ buttons }: React.PropsWithChildren<Props>): JSX.Element => {
  const containerStyles = tw(
    "relative",
    "z-0",
    "inline-flex",
    "shadow-sm",
    "rounded-md"
  );

  const buttonStyles = tw(
    "relative",
    "inline-flex",
    "items-center",
    "px-4",
    "py-2",
    "border",
    "border-gray-300",
    "bg-white",
    "text-sm",
    "text-gray-700",
    "hover:bg-gray-100",
    "focus:z-10",
    "focus:outline-none",
    "focus:ring-1",
    "focus:ring-gray-900",
    "focus:border-gray-900"
  );

  return (
    <div className={containerStyles}>
      {buttons.map(({ props, label }, index) => (
        <button
          {...props}
          type="button"
          className={tw(buttonStyles, {
            "rounded-l-md": index === 0,
            "-ml-px": index > 0,
            "rounded-r-md": index === buttons.length - 1,
          })}
        >
          {label}
        </button>
      ))}
    </div>
  );
};
