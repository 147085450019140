import { lazy } from "react";

const CheckTypeSelection = lazy(() => import("./CheckTypeSelection"));
const CheckRequestWizard = lazy(() => import("./CheckRequestWizard"));
const CheckMoreWizard = lazy(() => import("./CheckMoreWizard"));
const Checks = lazy(() => import("./Checks"));
const OnboardingWizard = lazy(() => import("./OnboardingWizard"));

export {
  CheckTypeSelection,
  CheckRequestWizard,
  CheckMoreWizard,
  Checks,
  OnboardingWizard,
};
