import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDefaultRoutePath } from "utils";

export default (): null => {
  const navigate = useNavigate();
  const defaultRoutePath = useDefaultRoutePath();

  useEffect(() => {
    navigate(defaultRoutePath);
  }, [defaultRoutePath]);

  return null;
};
