/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  CHECK_LIST_FRAGMENT,
  CheckListFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const CONSENT_CHECK_LIST = gql`
  mutation ($id: ID!) {
    consentCheckList(input: { id: $id }) {
      checkList {
        ...CheckListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkList: CheckListFragment;
  errors: ErrorFragment[];
}

interface ConsentCheckListMutation {
  consentCheckList: Payload | null;
}

export const useConsentCheckListMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    ConsentCheckListMutation,
    { id: string }
  >(CONSENT_CHECK_LIST);

  const consentCheckList = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
    })
      .then(({ data }) => {
        if (data?.consentCheckList?.checkList && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    consentCheckList,
    isLoading: loading,
    checkList: data?.consentCheckList?.checkList,
  };
};
