import { gql } from "@apollo/client";

import { Identity } from "graphql/types";

export const IDENTITY_FRAGMENT = gql`
  fragment IdentityFragment on Identity {
    id
    updatedAt
    identifier
    firstName
    lastName
    country
    default
  }
`;

export interface IdentityFragment
  extends Pick<
    Identity,
    | "id"
    | "updatedAt"
    | "identifier"
    | "firstName"
    | "lastName"
    | "country"
    | "default"
  > {}
