import { useContext } from "react";
import { HiUsers } from "react-icons/hi";

import { SubscriptionBanner } from "components/account/composite";
import { Button, Divider } from "components/common/basic";
import { Banner } from "components/common/composite";
import { SubscriptionFragment } from "graphql/fragments";
import { FeatureEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { TailwindContext } from "providers/Tailwind";
import { Trans, useTranslation } from "translations";
import { getPricePlanName, onDateToMoment } from "utils";
import { tw } from "utils/tw";

import checkmoreSymbol from "assets/symbols/checkmore.svg";
import tellmoreSymbol from "assets/symbols/tellmore.svg";

export default ({
  pricePlan: { feature, limit, name, enterprise, price },
  usage,
  billingCycleStartedOn,
  billingCycleEndsOn,
}: SubscriptionFragment): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);
  const { isMd } = useContext(TailwindContext);

  const isCheckmore = feature === FeatureEnum.Checks;

  return (
    <div
      className={tw(
        "w-full",
        "bg-white",
        "shadow-md",
        "rounded-lg",
        "overflow-hidden"
      )}
    >
      <div
        className={tw(
          "p-4",
          "flex",
          "space-x-4",
          "items-center",
          isCheckmore ? "bg-deepBlue-900" : "bg-blue-900"
        )}
      >
        <img src={isCheckmore ? checkmoreSymbol : tellmoreSymbol} />

        <h2 className={tw("text-white", "text-lg", "font-bold")}>
          {isCheckmore
            ? t(
                "accountSettings_org.subscriptions.subscription_checkmore.title",
                "Checkmore {{ plan }}",
                { plan: getPricePlanName(feature, name, t) }
              )
            : t(
                "accountSettings_org.subscriptions.subscription_tellmore.title",
                "Tellmore {{ plan }}",
                { plan: getPricePlanName(feature, name, t) }
              )}
        </h2>
      </div>

      <div className={tw("px-4", "pt-6", "pb-5", "space-y-8")}>
        <Banner
          variant="success"
          verticallyCentered={isMd}
          body={
            isCheckmore
              ? t(
                  "accountSettings_org.subscriptions.subscription_checkmore.usageBanner.text",
                  "You have used {{ usage }} of {{ limit }} people",
                  { usage, limit }
                )
              : t(
                  "accountSettings_org.subscriptions.subscription_tellmore.usageBanner.text",
                  "You have used {{ usage }} of {{ limit }} handlers",
                  { usage, limit }
                )
          }
          rightColumn={
            enterprise ? undefined : (
              <Button
                id="orgAccountSettings_subscriptions-upgradeSubscription"
                LeadingIcon={HiUsers}
                size="sm"
                onClick={() =>
                  openPopup({
                    id: "SubscriptionChange",
                    props: { feature, direction: "upgrade" },
                  })
                }
              >
                {isCheckmore
                  ? t(
                      "accountSettings_org.subscriptions.subscription_checkmore.usageBanner.button",
                      "Add more people"
                    )
                  : t(
                      "accountSettings_org.subscriptions.subscription_tellmore.usageBanner.button",
                      "Add more handlers"
                    )}
              </Button>
            )
          }
        />

        {enterprise ? (
          <SubscriptionBanner feature={feature} variant="contactSales" />
        ) : price === 0 ? null : (
          <div className={tw("space-y-4")}>
            <Divider />

            <SubSection
              isBold
              label={
                isCheckmore
                  ? t(
                      "accountSettings_org.subscriptions.subscription_checkmore.details.label",
                      "Your current Checkmore subscription:"
                    )
                  : t(
                      "accountSettings_org.subscriptions.subscription_tellmore.details.label",
                      "Your current Tellmore subscription:"
                    )
              }
              subLabel={
                <Trans
                  ns="account"
                  i18nKey="accountSettings_org.subscriptions.subscription_tellmore.details.downgradeOptions"
                  defaults="<0>Cancel</0> or <1>reduce licenses</1>"
                  components={[
                    <button
                      className={tw("underline")}
                      onClick={() =>
                        openPopup({
                          id: "CancelSubscription",
                          props: { feature },
                        })
                      }
                    />,
                    <button
                      className={tw("underline")}
                      onClick={() =>
                        openPopup({
                          id: "SubscriptionChange",
                          props: { feature, direction: "downgrade" },
                        })
                      }
                    />,
                  ]}
                />
              }
              value={
                isCheckmore
                  ? t(
                      "accountSettings_org.subscriptions.subscription_checkmore.details.limit",
                      {
                        count: limit,
                        defaultValue_one: "{{ count }} person",
                        defaultValue_other: "{{ count }} people",
                      }
                    )
                  : t(
                      "accountSettings_org.subscriptions.subscription_tellmore.details.limit",
                      {
                        count: limit,
                        defaultValue_one: "{{ count }} handler",
                        defaultValue_other: "{{ count }} handlers",
                      }
                    )
              }
            />

            <Divider />

            {billingCycleEndsOn && (
              <SubSection
                label={t(
                  "accountSettings_org.subscriptions.subscription.period.label",
                  "Subscription period:"
                )}
                subLabel={t(
                  "accountSettings_org.subscriptions.subscription.period.autoRenewal",
                  "Subscription auto renews on {{ date }}",
                  {
                    date: onDateToMoment(billingCycleEndsOn).format(
                      "DD MMM. YYYY"
                    ),
                  }
                )}
                value={
                  onDateToMoment(billingCycleStartedOn).format("MMM. YYYY") +
                  " - " +
                  onDateToMoment(billingCycleEndsOn).format("MMM. YYYY")
                }
              />
            )}

            <Divider />

            <SubSection
              isBold
              label={t(
                "accountSettings_org.subscriptions.subscription.price.monthly",
                "Subscription price per month:"
              )}
              subLabel={t(
                "accountSettings_org.subscriptions.subscription.price.annually",
                "Annual cost:"
              )}
              value={`${Math.round(price / 12)},- NOK`}
              subValue={`${price},- NOK`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface SubSectionProps {
  label: string;
  subLabel: string | JSX.Element;
  value: string;
  subValue?: string;
  isBold?: boolean;
}
const SubSection = (props: SubSectionProps) => (
  <div className={tw("flex", "flex-col")}>
    <div
      className={tw(
        "flex",
        "flex-col",
        "gap-2",
        "md:flex-row",
        "md:justify-between"
      )}
    >
      <h3
        className={tw("text-deepBlue-900", "text-lg", {
          "font-bold": props.isBold,
        })}
      >
        {props.label}
      </h3>

      <p
        className={tw("text-deepBlue-900", "text-lg", "shrink-0", {
          "font-bold": props.isBold,
        })}
      >
        {props.value}
      </p>
    </div>

    <div className={tw("flex", "justify-between")}>
      <p className={tw("text-deepBlue-900", "text-xs")}>{props.subLabel}</p>

      {props.subValue && (
        <p className={tw("text-deepBlue-900", "text-xs")}>{props.subValue}</p>
      )}
    </div>
  </div>
);
