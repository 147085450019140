export default (jsonString: unknown): unknown => {
  try {
    if (typeof jsonString !== "string") return null;

    return JSON.parse(jsonString);
  } catch (_error) {
    console.error("Invalid JSON");
  }

  return null;
};
