/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { CLIENT_FRAGMENT, ClientFragment } from "graphql/fragments";
import { CLIENTS, ClientsQuery } from "graphql/queries";

const ARCHIVE_CLIENT = gql`
  mutation ($id: ID!) {
    archiveClient(input: { id: $id }) {
      client {
        ...ClientFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

interface Payload {
  client: ClientFragment;
}

interface ArchiveClientMutation {
  archiveClient: Payload | null;
}

export const useArchiveClientMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    ArchiveClientMutation,
    { id: string }
  >(ARCHIVE_CLIENT);

  const archiveClient = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            clients() {
              const existingClients = cache.readQuery<ClientsQuery>({
                query: CLIENTS,
              });
              if (!existingClients?.clients.items) return;

              const items = existingClients.clients.items?.filter(
                (clientItem) => clientItem?.id !== id
              );

              cache.writeQuery<ClientsQuery>({
                query: CLIENTS,
                data: {
                  clients: {
                    items,
                    total: existingClients.clients.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data?.archiveClient?.client && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    archiveClient,
    isLoading: loading,
    client: data?.archiveClient?.client,
  };
};
