import { gql } from "@apollo/client";

import { JobApplication } from "graphql/types";

import { ENROLMENT_FRAGMENT, EnrolmentFragment } from "./enrolment";
import { JOB_FRAGMENT, JobFragment } from "./job";

export const JOB_APPLICATION_FRAGMENT = gql`
  fragment JobApplicationFragment on JobApplication {
    id
    name
    email
    submittedJobChecks
    status
    job {
      ...JobFragment
    }
    applicationFormAnswers {
      questionId
      value
      values
    }
    enrollment {
      ...EnrolmentFragment
    }
  }
  ${ENROLMENT_FRAGMENT}
  ${JOB_FRAGMENT}
`;

export interface JobApplicationFragment
  extends Pick<
    JobApplication,
    | "id"
    | "status"
    | "applicationFormAnswers"
    | "name"
    | "email"
    | "submittedJobChecks"
  > {
  job?: JobFragment;
  enrollment?: EnrolmentFragment;
}
