import { gql } from "@apollo/client";

import { Document } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { DOCUMENT_FILE_FRAGMENT, DocumentFileFragment } from "./documentFile";
import { SIGNATURE_FRAGMENT, SignatureFragment } from "./signature";
import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    id
    activatedOn
    activation
    category
    copyRecipients
    createdAt
    documentFiles {
      ...DocumentFileFragment
    }
    documentNumber
    expiration
    expirationMonths
    expiredOn
    name
    refusedAt
    signatures {
      ...SignatureFragment
    }
    signedAt
    signedDocument {
      ...AttachmentFragment
    }
    status
    terminatedCopyRecipients
    terminatedOn
    terminationReason
    owned
    interactedAt
    archivedAt
    account {
      ...SimplifiedAccountFragment
    }
    senderAccount {
      ...SimplifiedAccountFragment
    }
  }
  ${SIGNATURE_FRAGMENT}
  ${DOCUMENT_FILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
`;

export interface DocumentFragment
  extends Pick<
    Document,
    | "id"
    | "activatedOn"
    | "activation"
    | "category"
    | "copyRecipients"
    | "createdAt"
    | "documentFiles"
    | "documentNumber"
    | "expiration"
    | "expirationMonths"
    | "expiredOn"
    | "name"
    | "refusedAt"
    | "signedAt"
    | "signedDocument"
    | "status"
    | "terminatedCopyRecipients"
    | "terminatedOn"
    | "terminationReason"
    | "owned"
    | "interactedAt"
    | "archivedAt"
  > {
  signatures: SignatureFragment[];
  documentFiles: DocumentFileFragment[];
  signedDocument?: AttachmentFragment;
  account: SimplifiedAccountFragment;
  senderAccount?: SimplifiedAccountFragment;
}
