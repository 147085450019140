/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { INVOICE_FRAGMENT, InvoiceFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const INVOICE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Invoice {
        ...InvoiceFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;

export type InvoiceQuery = { node: InvoiceFragment };

export const useInvoiceLazyQuery = () => {
  const [getInvoiceQuery, { data, loading, called }] = useLazyQuery<
    InvoiceQuery,
    QueryNodeArgs
  >(INVOICE);

  const resolveRef = useRef<(invoice?: InvoiceFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getInvoice = async (id: string) => {
    getInvoiceQuery({ variables: { id } });

    return new Promise<InvoiceFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getInvoice,
    invoice: data?.node,
    isLoading: loading,
  };
};
