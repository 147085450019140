/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ATTACHMENT_FRAGMENT,
  AttachmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";

const DESTROY_ROUTINE_GUIDELINE = gql`
  mutation ($id: ID!) {
    destroyRoutineGuideline(input: { id: $id }) {
      account {
        routineGuidelines {
          ...AttachmentFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: { routineGuidelines: AttachmentFragment[] } | null;
  errors: ErrorFragment[];
}

interface DestroyRoutineGuidelineMutation {
  destroyRoutineGuideline: Payload | null;
}

export const useDestroyRoutineGuidelineMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyRoutineGuidelineMutation,
    { id: string }
  >(DESTROY_ROUTINE_GUIDELINE);

  const destroyRoutineGuideline = (
    id: string,
    onSuccess?: (routineGuidelines: AttachmentFragment[]) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            session() {
              const sessionData = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionData) return;

              const routineGuidelines =
                sessionData.session.account.routineGuidelines.filter(
                  (doc) => doc.id !== id
                );

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionData.session,
                    account: {
                      ...sessionData.session.account,
                      routineGuidelines,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyRoutineGuideline?.errors ?? [];
        const routineGuidelines =
          data?.destroyRoutineGuideline?.account?.routineGuidelines;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && routineGuidelines && onSuccess(routineGuidelines),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyRoutineGuideline,
    isLoading: loading,
    errors: data?.destroyRoutineGuideline?.errors ?? [],
  };
};
