/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const FREELANCE_PROFILE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on FreelanceProfile {
        ...FreelanceProfileFragment
      }
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
`;

export type FreelanceProfileQuery = { node: FreelanceProfileFragment };

export const useFreelanceProfileLazyQuery = () => {
  const [getFreelanceProfileQuery, { data, loading, called }] = useLazyQuery<
    FreelanceProfileQuery,
    QueryNodeArgs
  >(FREELANCE_PROFILE);

  const resolveRef = useRef<(profile?: FreelanceProfileFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getFreelanceProfile = async (id: string) => {
    getFreelanceProfileQuery({ variables: { id } });

    return new Promise<FreelanceProfileFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getFreelanceProfile,
    freelanceProfile: data?.node,
    isLoading: loading,
    called,
  };
};
