import { useContext } from "react";
import { HiOutlineCollection, HiPlus } from "react-icons/hi";

import { Button } from "components/common/basic";
import { TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import DepartmentsTableWithFilters from "./DepartmentsTableWithFilters";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  const hasDepartmentCreationAccess =
    session.role &&
    [TeamRoleEnum.Admin, TeamRoleEnum.Owner].includes(session.role);

  return (
    <div className={tw("space-y-4")}>
      <div
        className={tw(
          "mb-4",
          "flex",
          "flex-col",
          "w-full",
          "gap-4",
          "lg:flex-row",
          "lg:justify-between",
          "lg:items-start"
        )}
      >
        <div className={tw("flex", "items-center")}>
          <HiOutlineCollection
            size={24}
            className={tw("mr-2", "text-deepBlue-900")}
          />

          <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
            {t("accountSettings_org.team.departments.header", "Departments")}
          </h2>
        </div>

        {hasDepartmentCreationAccess && (
          <div>
            <Button
              id="accountSettings-add_department"
              size="md"
              LeadingIcon={HiPlus}
              onClick={() => openPopup({ id: "Department" })}
            >
              {t(
                "accountSettings_org.team.departments.action.addDepartment",
                "New department"
              )}
            </Button>
          </div>
        )}
      </div>

      <DepartmentsTableWithFilters />
    </div>
  );
};
