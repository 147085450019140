import { Button } from "components/common/basic";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import FileInput from "./FileInput";
import FilePreview from "./FilePreview";

interface Props {
  id: string;
  selectFile: (files: File[]) => void;
  clearFile: () => void;
  illustration: {
    src: string;
    alt: string;
  };
  label?: string;
  isImageUpload?: boolean;
  validFileTypes?: string[];
  fileRequirementsText: string;
  file?: string;
  filePreview?: JSX.Element;
  isInputLabel?: boolean;
  maxSize?: number;
  allowMultiple?: boolean;
}

export default ({
  id,
  selectFile,
  clearFile,
  label,
  illustration,
  isImageUpload,
  validFileTypes = isImageUpload
    ? ["image/png", "image/jpg", "image/jpeg"]
    : ["application/pdf"],
  fileRequirementsText,
  file,
  filePreview = (
    <FilePreview
      file={file}
      isImage={isImageUpload || file?.includes("data:image")}
    />
  ),
  isInputLabel,
  maxSize,
  allowMultiple,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const labelStyles = isInputLabel
    ? tw("block", "text-sm", "text-gray-700")
    : tw("mb-2", "font-bold", "text-gray-900");

  return (
    <div className={tw("flex", "flex-col", "space-y-2")}>
      <label
        htmlFor={id}
        className={tw(labelStyles, {
          "sr-only": !label,
        })}
      >
        {label ?? id}
      </label>

      <div>
        {file ? (
          <div
            className={tw(
              "w-full",
              "p-5",
              "flex",
              "flex-col",
              "space-y-5",
              "border-2",
              "border-gray-300",
              "rounded-md",
              "overflow-hidden"
            )}
          >
            {filePreview}

            <Button
              id="document_upload-remove_upload"
              variant="tertiary"
              size="md"
              fullWidth
              onClick={clearFile}
            >
              {t("FileUpload.removeUpload", "Remove upload")}
            </Button>
          </div>
        ) : (
          <FileInput
            id={id}
            onFileDrop={selectFile}
            validFileTypes={validFileTypes}
            maxSize={maxSize}
            allowMultiple={allowMultiple}
          >
            <div
              className={tw(
                "w-full",
                "p-5",
                "flex",
                "flex-col",
                "space-y-4",
                "border-2",
                "border-gray-300",
                "rounded-md",
                "overflow-hidden",
                "border-dashed",
                "bg-white"
              )}
            >
              <div className={tw("flex", "justify-center")}>
                <img src={illustration.src} alt={illustration.alt} />
              </div>

              <div className={tw("text-center")}>
                <p className={tw("text-sm", "font-semibold", "text-gray-700")}>
                  <Trans
                    ns="common"
                    i18nKey="FileUpload.button.selectUpload.upload"
                  >
                    <span className={tw("text-blue-900")}>Upload</span> a file
                    or drag and drop
                  </Trans>
                </p>

                <p className={tw("text-xs", "text-gray-500")}>
                  {fileRequirementsText}
                </p>
              </div>
            </div>
          </FileInput>
        )}
      </div>
    </div>
  );
};
