import { useContext } from "react";

import { Input } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { BankAccountFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  bankAccount: BankAccountFragment;
}

export default ({ bankAccount, ...props }: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const { closeAllPopups } = useContext(PopupContext);

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t("popup.viewBankAccount.title", "View Bank Account")}
      subTitle={t(
        "popup.viewBankAccount.form.accountDetailsSection.title",
        "Account details"
      )}
      footerActions={[
        {
          id: "view_bank_account-close",
          variant: "tertiary",
          onClick: closeAllPopups,
          label: t("popup.viewBankAccount.close", "Close"),
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        <div className={tw("w-full", "flex", "space-x-4")}>
          <Input
            className={tw("w-3/4")}
            id="accountNumber"
            label={t(
              "popup.viewBankAccount.form.accountNumber.label",
              "Account number"
            )}
            defaultValue={bankAccount.accountNumber}
            disabled
          />

          <Input
            className={tw("w-1/4")}
            id="accountNumber"
            label={t("popup.viewBankAccount.form.currency.label", "Currency")}
            defaultValue={bankAccount.currency}
            disabled
          />
        </div>

        {bankAccount.swiftCode && (
          <Input
            id="swiftCode"
            label={t(
              "popup.viewBankAccount.form.swiftCode.label",
              "BIC / SWIFT"
            )}
            defaultValue={bankAccount.swiftCode}
            disabled
          />
        )}
      </div>
    </PopupWrapper>
  );
};
