/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { SendSmsConfirmationInput } from "graphql/types";

const SEND_SMS_CONFIRMATION = gql`
  mutation ($input: SendSmsConfirmationInput!) {
    sendSmsConfirmation(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendSmsConfirmationMutation {
  sendSmsConfirmation: Payload | null;
}

export const useSendSmsConfirmationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentSMSConfirmation",
    "SMS confirmation was sent successfully"
  );
  // Values for extraction
  // t("common:success.sentSMSConfirmation", "SMS confirmation was sent successfully")

  const [mutation, { data, loading }] = useMutation<
    SendSmsConfirmationMutation,
    { input: SendSmsConfirmationInput }
  >(SEND_SMS_CONFIRMATION);

  const sendSmsConfirmation = (
    input: SendSmsConfirmationInput,
    onSuccess?: () => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendSmsConfirmation?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendSmsConfirmation,
    isLoading: loading,
    errors: data?.sendSmsConfirmation?.errors ?? [],
  };
};
