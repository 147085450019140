import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookie from "js-cookie";

import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { getParsedJSON } from "utils";
import { useDefaultRoutePath, useRoutePaths } from "utils";

export default (): null => {
  const { t } = useTranslation("common");
  const getRoutePath = useRoutePaths();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const defaultRoutePath = useDefaultRoutePath();

  const cookie = Cookie.get("dp-test-cookie");
  const parsedCookie = getParsedJSON(cookie);
  const isTest = (parsedCookie as { active?: boolean } | null)?.active;
  const isLocalhost = window.location.hostname === "localhost";
  const shouldRedirect = !isTest && !isLocalhost;

  useEffect(() => {
    if (!shouldRedirect && !isLoggedIn)
      return navigate(getRoutePath({ module: "login", routeName: "LOGIN" }));

    if (isLoggedIn) return navigate(defaultRoutePath);

    window.location.href = t("links.homepage", "https://manymore.com/en");
  }, [shouldRedirect]);

  return null;
};
