import { useContext } from "react";

import { ActionID, IconType } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { PopupContext } from "providers/PopupHandler";
import { TTextColor } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  variant: PopupVariant;
  popupSize?: "sm" | "md" | "lg";
  hasPrevious: boolean;
  // Content props
  title: string;
  subTitle?: string;
  leadingIcon?: IconType;
  leadingIconColor?: TTextColor;
}

export default ({ ...props }: Props): JSX.Element | null => {
  const { openPopup, closeOnePopup } = useContext(PopupContext);

  return (
    <PopupWrapper
      {...props}
      headerActions={
        props.variant === "slideOver"
          ? [
              {
                id: "TEST_ID:open_popup" as ActionID,
                icon: "HiOutlineArrowsExpand",
                to: "#",
              },
              {
                id: "TEST_ID:go_to_link" as ActionID,
                icon: "HiOutlineExternalLink",
                to: "#",
              },
            ]
          : []
      }
      footerActions={
        props.variant === "slideOver"
          ? [
              {
                id: "TEST_ID:open_popup" as ActionID,
                variant: "tertiary",
                onClick: () =>
                  openPopup(
                    {
                      id: "LibraryPopup",
                      variant: "popup",
                      props: { title: "Popup title" },
                    },
                    props.variant === "popup"
                  ),
                label: "Open popup",
              },
              ...(props.hasPrevious
                ? []
                : [
                    {
                      id: "TEST_ID:open_drawer" as ActionID,
                      variant: "tertiary" as const,
                      onClick: () =>
                        openPopup({
                          id: "LibraryPopup",
                          variant: "slideOver",
                          props: { title: "Drawer title" },
                        }),
                      label: "Open drawer",
                    },
                  ]),
            ]
          : [
              {
                id: "TEST_ID:close_popup" as ActionID,
                variant: "tertiary",
                onClick: closeOnePopup,
                label: "Close popup",
              },
            ]
      }
    >
      <div
        className={tw(
          "py-6",
          "bg-silver",
          "h-full",
          "w-full",
          "flex",
          "justify-center",
          "items-center"
        )}
      >
        <p>Content</p>
      </div>
    </PopupWrapper>
  );
};
