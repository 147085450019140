/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { ACCOUNT_FRAGMENT, AccountFragment } from "graphql/fragments";
import { QueryMembersArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const MEMBERS = gql`
  query ($page: Int, $perPage: Int, $filter: MembersFilter) {
    members(page: $page, perPage: $perPage, filter: $filter) {
      exportPath
      items {
        ...AccountFragment
      }
      total
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export type MembersQuery = {
  members: PaginatedPayload<AccountFragment>;
};

type QueryArgs = Pick<QueryMembersArgs, "page" | "perPage" | "filter">;

export const useMembersQuery = (args: QueryArgs = {}) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<MembersQuery, QueryArgs>(MEMBERS, {
    variables: { ...args },
  });

  const members = data?.members.items?.filter(Boolean) ?? [];
  const total = data?.members.total ?? 0;

  return { members, total, isLoading, called };
};

export const useMembersLazyQuery = () => {
  const [getMembersQuery, { data, loading: isLoading, called }] = useLazyQuery<
    MembersQuery,
    QueryArgs
  >(MEMBERS, { fetchPolicy: "network-only" });

  const members = data?.members.items?.filter(Boolean) ?? [];
  const total = data?.members.total ?? 0;

  const resolveRef = useRef<(members?: AccountFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(members);
      resolveRef.current = undefined;
    }
  }, [members, called, isLoading]);

  const getMembers = async (args?: QueryArgs) => {
    const variables = { ...args };

    getMembersQuery({ variables });

    return new Promise<AccountFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getMembers, members, total, isLoading };
};
