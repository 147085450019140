import { gql } from "@apollo/client";

import { Error } from "graphql/types";

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on Error {
    code
    path
  }
`;

export interface ErrorFragment extends Pick<Error, "code" | "path"> {}
