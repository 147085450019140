import { Button, Link } from "components/common/basic";
import { Page } from "components/common/wrappers";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import payoutPartnerLogo from "assets/branding/payoutpartner/logo.svg";
import payoutPartnerSymbol from "assets/branding/payoutpartner/symbol.svg";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <Page title={t("payoutPartnerInfo.pageTitle", "PayoutPartner")}>
      <div
        className={tw(
          "mx-auto",
          "w-full",
          "my-auto",
          "max-w-200",
          "grid",
          "grid-cols-1",
          "lg:grid-cols-2",
          "gap-4"
        )}
      >
        <div className={tw("space-y-6")}>
          <div className={tw("space-y-1")}>
            <h1
              className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}
            >
              {t(
                "payoutPartnerInfo.heading",
                "Manymore introduces PayoutPartner: "
              )}
            </h1>

            <h2 className={tw("text-deepBlue-700", "text-lg", "font-bold")}>
              {t(
                "payoutPartnerInfo.subHeading",
                "Your new payout & invoicing platform!"
              )}
            </h2>
          </div>

          <div className={tw("space-y-1")}>
            <h3 className={tw("text-deepBlue-900", "font-bold")}>
              {t(
                "payoutPartnerInfo.section1.heading",
                "Dedicated focus, better service!"
              )}
            </h3>

            <p className={tw("text-deepBlue-900")}>
              {t(
                "payoutPartnerInfo.section1.body",
                "We're pleased to inform you that starting May 2024, Manymore.com's financial services will transition to our sister company PayoutPartner.com, designed to make your payment process smoother and more efficient."
              )}
            </p>
          </div>

          <div className={tw("space-y-1")}>
            <h3 className={tw("text-deepBlue-900", "font-bold")}>
              {t("payoutPartnerInfo.section2.heading", "Dont worry!")}
            </h3>

            <p className={tw("text-deepBlue-900")}>
              {t(
                "payoutPartnerInfo.section2.body",
                "Both Manymore.com and PayoutPartner are owned by the same company, and operated by the same people, at the same office. So nothing is lost and everything will work as before."
              )}
            </p>
          </div>

          <Link
            id="payout_partner_info-go_to_payout_partner"
            unstyled
            isExternal
            to={t(
              "payoutPartnerInfo.button.goToPayoutPartner.link",
              "https://my.payoutpartner.com/en/login"
            )}
            className={tw("block")}
          >
            <Button id="payout_partner_info-go_to_payout_partner">
              {t(
                "payoutPartnerInfo.button.goToPayoutPartner.label",
                "Visit payoutpartner.com"
              )}
            </Button>
          </Link>
        </div>

        <div
          className={tw(
            "p-12",
            "flex",
            "flex-col",
            "justify-center",
            "items-center",
            "space-y-3",
            "bg-silver",
            "rounded-lg"
          )}
        >
          <img
            className={tw("h-24")}
            src={payoutPartnerSymbol}
            alt="PayoutPartner symbol"
          />
          <img
            className={tw("h-10")}
            src={payoutPartnerLogo}
            alt="PayoutPartner logo"
          />
        </div>
      </div>
    </Page>
  );
};
