import { Document as PDFDocument, Page as PDFPage } from "react-pdf";

import { tw } from "utils/tw";

interface Props {
  file?: string;
  isImage?: boolean;
}

export default ({ file, isImage }: Props): JSX.Element => {
  if (isImage)
    return <img alt="uploading" src={file} className={tw("m-0", "w-full")} />;

  return (
    <PDFDocument file={file} className={tw("m-0", "overflow-auto")}>
      <PDFPage
        pageNumber={1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </PDFDocument>
  );
};
