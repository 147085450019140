import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

// import Hotjar from "@hotjar/browser";
// import Cookie from "js-cookie";
// import mixpanel from "mixpanel-browser";
import { SessionFragment } from "graphql/fragments";

interface Context {
  identify: (user: SessionFragment["user"]) => void;
  reset: () => void;
  reportEvent: (eventName: string, options?: Record<string, unknown>) => void;
  hasConsented: boolean;
}

export const AnalyticsContext = React.createContext<Context>({
  identify: () => null,
  reset: () => null,
  reportEvent: () => null,
  hasConsented: false,
});

interface Props {
  children?: React.ReactNode;
}

export const AnalyticsProvider = ({ children }: Props): JSX.Element => {
  // const [hasConsented, setHasConsented] = useState(false);

  // const identify = (user: SessionFragment["user"]) => {
  //   if (!hasConsented) return;

  //   mixpanel.identify(user.id); // Mixpanel login
  //   window.dataLayer?.push({ userId: user.id, email: user.email }); // GA login
  // };

  // const reset = () => {
  //   mixpanel.reset(); // Mixpanel logout
  //   window.dataLayer?.push({ userID: null, email: null }); // GA logout
  // };

  // const reportEvent = (
  //   eventName: string,
  //   options?: Record<string, unknown>
  // ) => {
  //   if (!hasConsented) return;

  //   window.dataLayer?.push({ event: eventName, ...options });
  //   mixpanel.track(eventName, options);
  // };

  // // Check CookieYes consent
  // useEffect(() => {
  //   const consentCookie: string | undefined = Cookie.get("cookieyes-consent");
  //   if (!consentCookie) return setHasConsented(false);

  //   const hasConsentedToAnalytics = consentCookie
  //     .split(",")
  //     .some((str) => str === "analytics:yes");

  //   if (hasConsented !== hasConsentedToAnalytics)
  //     setHasConsented(hasConsentedToAnalytics);
  // }, [Cookie.get("cookieyes-consent")]);

  // // Setup Analytics
  // useEffect(() => {
  //   // Google Analytics
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push("js", new Date());
  //   window.dataLayer.push("config", process.env.REACT_APP_GA_MEASUREMENT_ID);

  //   // Mixpanel
  //   mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ?? "MISSING");

  //   // Hotjar
  //   const { REACT_APP_HOTJAR_SITE_ID, REACT_APP_HOTJAR_VERSION } = process.env;
  //   if (!REACT_APP_HOTJAR_SITE_ID || !REACT_APP_HOTJAR_VERSION) return;

  //   const hotjarSiteId = Number(REACT_APP_HOTJAR_SITE_ID);
  //   const hotjarVersion = Number(REACT_APP_HOTJAR_VERSION);
  //   const hotjarDebug = process.env.REACT_APP_HOTJAR_DEBUG === "true";

  //   Hotjar.init(hotjarSiteId, hotjarVersion, { debug: hotjarDebug });
  // }, []);

  // Initialize Google Tag Manager
  useEffect(() => {
    if (
      !process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
      // || !process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH
      // || !process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV
    )
      return;

    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      // auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
      // preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV,
    };

    if (document) TagManager.initialize(tagManagerArgs);
  }, [
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    // process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    // process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV,
  ]);

  return (
    <AnalyticsContext.Provider
      value={{
        identify: () => null,
        reset: () => null,
        reportEvent: () => null,
        hasConsented: false,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
