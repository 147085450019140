import { useEffect, useReducer } from "react";

import { SubscriptionBanner } from "components/account/composite";
import { Divider, Icon } from "components/common/basic";
import { PricePlanFragment, SubscriptionFragment } from "graphql/fragments";
import { FeatureEnum } from "graphql/types";
import { Trans, useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

interface LimitReducerState {
  usage: number;
  initialPlan: PricePlanFragment;
  currentPlan: PricePlanFragment;
  pricePlans: PricePlanFragment[];
  priceChangeError?: "USAGE_PREVENTS_DOWNGRADE";
}
enum ActionTypeEnum {
  reset = "RESET",
  downgrade = "DOWNGRADE",
  upgrade = "UPGRADE",
}

const reducer = (
  state: LimitReducerState,
  action: ActionTypeEnum
): LimitReducerState => {
  const currentIndex = state.pricePlans.findIndex(
    ({ id }) => id === state.currentPlan.id
  );

  const usagePreventsDowngrade =
    state.usage > state.pricePlans[currentIndex - 1]?.limit;
  const canUpgrade = currentIndex + 1 < state.pricePlans.length;
  const canDowngrade = currentIndex > 0;

  switch (action) {
    case ActionTypeEnum.reset:
      return {
        ...state,
        currentPlan: state.initialPlan,
        priceChangeError: undefined,
      };

    case ActionTypeEnum.downgrade:
      return usagePreventsDowngrade
        ? {
            ...state,
            priceChangeError:
              currentIndex === 0 ? undefined : "USAGE_PREVENTS_DOWNGRADE",
          }
        : canDowngrade
        ? {
            ...state,
            priceChangeError: undefined,
            currentPlan: state.pricePlans[currentIndex - 1],
          }
        : state;

    case ActionTypeEnum.upgrade:
      return canUpgrade
        ? {
            ...state,
            priceChangeError: undefined,
            currentPlan: state.pricePlans[currentIndex + 1],
          }
        : state;

    default:
      ((_action: never) => state)(action);
  }

  return state;
};

interface Props {
  feature: FeatureEnum;
  direction: "upgrade" | "downgrade";
  setDirection: (direction: "upgrade" | "downgrade") => void;
  subscription: SubscriptionFragment;
  pricePlans: PricePlanFragment[];
  onChange: (newLimit: PricePlanFragment) => void;
}

export default (props: Props): JSX.Element => {
  const [{ currentPlan, ...state }, dispatch] = useReducer(reducer, {
    usage: props.subscription.usage,
    initialPlan: props.subscription.pricePlan,
    currentPlan: props.subscription.pricePlan,
    pricePlans: props.pricePlans,
  });

  const { t } = useTranslation("common");
  const getRoutePath = useRoutePaths();

  // Update parent component on price plan changes
  useEffect(() => {
    props.onChange(currentPlan);
  }, [currentPlan]);

  // Reset plan on direction change
  useEffect(() => {
    dispatch(ActionTypeEnum.reset);
  }, [props.direction]);

  const usageChangeLocation = getRoutePath(
    props.feature === FeatureEnum.Checks
      ? { module: "account", routeName: "OVERVIEW_PEOPLE" }
      : { module: "tellmore", routeName: "WB_SETTINGS" }
  );

  // Same as in AppTabBar
  const createButtonBase = tw(
    "w-12",
    "h-12",
    "rounded-full",
    "flex",
    "justify-center",
    "items-center",
    "border",
    "border-transparent"
  );
  const disabledButtonStyles = tw(
    createButtonBase,
    "bg-silver",
    "border-transparent",
    "text-gray-500",
    "cursor-default"
  );
  const activeButtonStyles = tw(
    createButtonBase,
    "shadow-sm",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900",
    "bg-primary",
    "text-primary-contrast",
    "hover:bg-primary-hover"
  );

  const limit =
    props.direction === "downgrade"
      ? currentPlan.limit
      : currentPlan.limit - props.subscription.pricePlan.limit;
  const price =
    props.direction === "downgrade"
      ? currentPlan.price
      : currentPlan.price - props.subscription.pricePlan.price;
  const isCheckmore = props.feature === FeatureEnum.Checks;

  const initialPlanIndex = props.pricePlans.findIndex(
    ({ id }) => id === props.subscription.pricePlan.id
  );
  const currentIndex = props.pricePlans.findIndex(
    ({ id }) => id === currentPlan.id
  );
  const canUpgrade =
    props.direction === "upgrade"
      ? currentIndex + 1 < props.pricePlans.length
      : currentIndex < initialPlanIndex;
  const canDowngrade =
    props.direction === "downgrade"
      ? currentIndex > 0
      : currentIndex > initialPlanIndex;

  return (
    <div className={tw("space-y-4")}>
      {props.direction === "downgrade" && (
        <p className={tw("text-sm", "text-center")}>
          <Trans
            ns="account"
            i18nKey="popup.subscriptionChange_downgrade.goToUpgrade"
            defaults="If you would like to add more <0>click here</0>"
            components={[
              <button
                className={tw("underline")}
                onClick={() => props.setDirection("upgrade")}
              />,
            ]}
          />
        </p>
      )}

      <div
        className={tw(
          "pb-4",
          "flex",
          "space-x-4",
          "justify-center",
          "items-center"
        )}
      >
        <button
          disabled={!canDowngrade}
          className={!canDowngrade ? disabledButtonStyles : activeButtonStyles}
          onClick={
            !canDowngrade ? undefined : () => dispatch(ActionTypeEnum.downgrade)
          }
        >
          <Icon size={24} icon="HiMinus" isFilled />
        </button>

        <p className={tw("text-deepBlue-900", "text-2xl", "font-bold")}>
          {limit}
        </p>

        <button
          disabled={!canUpgrade}
          className={!canUpgrade ? disabledButtonStyles : activeButtonStyles}
          onClick={
            !canUpgrade ? undefined : () => dispatch(ActionTypeEnum.upgrade)
          }
        >
          <Icon size={24} icon="HiPlus" isFilled />
        </button>
      </div>

      <Divider />

      <SubSection
        isBold
        label={
          props.direction === "upgrade"
            ? isCheckmore
              ? t(
                  "popup.subscriptionChange_checkmore_upgrade.monthlyCost.label",
                  "Monthly cost for {{ count }} additional people",
                  { count: limit }
                )
              : t(
                  "popup.subscriptionChange_tellmore_upgrade.monthlyCost.label",
                  "Monthly cost for {{ count }} additional handlers",
                  { count: limit }
                )
            : isCheckmore
            ? t(
                "popup.subscriptionChange_checkmore_downgrade.monthlyCost.label",
                "Monthly cost for {{ count }} people",
                { count: limit }
              )
            : t(
                "popup.subscriptionChange_tellmore_downgrade.monthlyCost.label",
                "Monthly cost for {{ count }} handlers",
                { count: limit }
              )
        }
        subLabel={
          props.direction === "upgrade"
            ? isCheckmore
              ? t(
                  "popup.subscriptionChange_checkmore_upgrade.annualCost.label",
                  "Annual cost for {{ count }} additional people",
                  { count: limit }
                )
              : t(
                  "popup.subscriptionChange_tellmore_upgrade.annualCost.label",
                  "Annual cost for {{ count }} additional handlers",
                  { count: limit }
                )
            : isCheckmore
            ? t(
                "popup.subscriptionChange_checkmore_downgrade.annualCost.label",
                "Annual cost for {{ count }} people",
                { count: limit }
              )
            : t(
                "popup.subscriptionChange_tellmore_downgrade.annualCost.label",
                "Annual cost for {{ count }} handlers",
                { count: limit }
              )
        }
        value={`${Math.round(price / 12)},- NOK`}
        subValue={`${price},- NOK`}
      />

      <Divider />

      {state.priceChangeError === "USAGE_PREVENTS_DOWNGRADE" ? (
        <SubscriptionBanner
          feature={props.feature}
          variant="usagePreventsDowngrade"
          usageChangeLocation={usageChangeLocation}
        />
      ) : props.subscription.pricePlan.price > 0 &&
        currentPlan.price !== props.subscription.pricePlan.price ? (
        <SubscriptionBanner feature={props.feature} variant="billingInfo" />
      ) : null}
    </div>
  );
};

// TODO: Use same component as SubscriptionCard
interface SubSectionProps {
  label: string;
  subLabel: string;
  value: string;
  subValue: string;
  isBold?: boolean;
}
const SubSection = (props: SubSectionProps) => (
  <div className={tw("flex", "flex-col")}>
    <div className={tw("flex", "justify-between")}>
      <h3
        className={tw("text-deepBlue-900", "text-lg", {
          "font-bold": props.isBold,
        })}
      >
        {props.label}
      </h3>

      <p
        className={tw("text-deepBlue-900", "text-lg", "shrink-0", {
          "font-bold": props.isBold,
        })}
      >
        {props.value}
      </p>
    </div>

    <div className={tw("flex", "justify-between")}>
      <p className={tw("text-deepBlue-900", "text-xs")}>{props.subLabel}</p>

      <p className={tw("text-deepBlue-900", "text-xs")}>{props.subValue}</p>
    </div>
  </div>
);
