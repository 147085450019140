/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ACCOUNT_FRAGMENT, AccountFragment } from "graphql/fragments";
import { DestroyAccountLogoInput } from "graphql/types";

const DESTROY_ACCOUNT_LOGO = gql`
  mutation ($input: DestroyAccountLogoInput!) {
    destroyAccountLogo(input: $input) {
      account {
        ...AccountFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
}

interface DestroyAccountLogoMutation {
  destroyAccountLogo: Payload | null;
}

export const useDestroyAccountLogoMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyAccountLogoMutation,
    { input: DestroyAccountLogoInput }
  >(DESTROY_ACCOUNT_LOGO);

  const destroyAccountLogo = (
    input: DestroyAccountLogoInput = {},
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({
      variables: { input },
    })
      .then(({ data, errors }) => {
        const account = data?.destroyAccountLogo?.account;

        handleErrors({
          dataErrors: [],
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyAccountLogo,
    isLoading: loading,
    account: data?.destroyAccountLogo?.account,
  };
};
