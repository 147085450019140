import { useContext, useEffect, useRef } from "react";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { LanguageEnum } from "graphql/types";
import { useMenu } from "hooks";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { getLocalePrefix } from "utils";
import { tw } from "utils/tw";

import { MenuList } from "../wrappers/Layout/common";

export default (): JSX.Element => {
  const languageMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingLanguageMenu, setIsShowingLanguageMenu] =
    useMenu(languageMenuRef);
  const toggleLanguageMenu = () =>
    setIsShowingLanguageMenu(!isShowingLanguageMenu);

  const { currentLocale } = useContext(IntlContext);
  const { pathname } = useLocation();
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsShowingLanguageMenu(false);
  }, [pathname]);

  const languageMenuStyles = tw(
    "origin-top-right",
    "absolute",
    "right-0",
    "mt-2",
    "w-60",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  const getLabelFromLanguage = (lang: LanguageEnum) => {
    const language = t(`language.${lang.toLowerCase()}`, lang);
    const label = t("languageSelect.option", "{{ language }}", {
      language,
    });

    return label;

    // Values for extraction
    // t("common:language.en", "English")
    // t("common:language.nb", "Norwegian")
  };

  return (
    <div className={tw("relative")} ref={languageMenuRef}>
      <button
        id="language_select-toggle_menu"
        aria-label="language menu"
        aria-expanded={isShowingLanguageMenu}
        onClick={toggleLanguageMenu}
        className={tw(
          "flex",
          "space-x-2",
          "items-center",
          "focus-visible:outline-none",
          "focus-visible:rounded",
          "focus-visible:ring-2",
          "focus-visible:ring-offset-4",
          "focus-visible:ring-gray-900"
        )}
      >
        <span
          className={tw(
            "text-sm",
            "font-semibold",
            "text-deepBlue-700",
            "max-w-xs",
            "truncate"
          )}
        >
          {getLabelFromLanguage(currentLocale)}
        </span>

        <HiOutlineGlobeAlt size={20} className={tw("text-deepBlue-700")} />
      </button>

      {isShowingLanguageMenu && (
        <>
          <nav className={languageMenuStyles}>
            <MenuList
              menuItems={Object.values(LanguageEnum).map((value) => {
                const currentLocalePrefix = getLocalePrefix(currentLocale);
                const updatedLocalePrefix = getLocalePrefix(value);

                const pathWithUpdatedLanguage = pathname.replace(
                  currentLocalePrefix,
                  updatedLocalePrefix
                );

                return {
                  id: `language_select-go_to_language:${value.toLowerCase()}` as const,
                  label: getLabelFromLanguage(value),
                  to: pathWithUpdatedLanguage,
                  isActive: value === currentLocale,
                };
              })}
            />
          </nav>
        </>
      )}
    </div>
  );
};
