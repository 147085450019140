/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CheckTypeEnum } from "graphql/types";

const CREATE_CHECK_REQUEST = gql`
  mutation ($type: CheckTypeEnum!) {
    createCheckRequest(input: { type: $type }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest?: CheckRequestFragment | null;
  errors: ErrorFragment[];
}

interface CreateCheckRequestMutation {
  createCheckRequest: Payload | null;
}

export const useCreateCheckRequestMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateCheckRequestMutation,
    { type: CheckTypeEnum }
  >(CREATE_CHECK_REQUEST);

  const createCheckRequest = (
    type: CheckTypeEnum,
    onSuccess?: (checkRequest: CheckRequestFragment) => void
  ) =>
    mutation({
      variables: { type },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createCheckRequest?.errors ?? [];
        const checkRequest = data?.createCheckRequest?.checkRequest;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && checkRequest && onSuccess(checkRequest),
          onRetry: () => createCheckRequest(type, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createCheckRequest,
    isLoading: loading,
    checkRequest: data?.createCheckRequest?.checkRequest,
    errors: data?.createCheckRequest?.errors ?? [],
  };
};
