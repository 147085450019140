import { TFunction } from "i18next";

import { Badge } from "components/common/basic";
import { JobCheckStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  status: JobCheckStatusEnum;
  showStatus?: boolean;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  status,
  showStatus,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const statusMessage = showStatus
    ? getTranslatableJobCheckStatus(status, t)
    : ((): string => {
        switch (status) {
          case JobCheckStatusEnum.Pending:
            return t("jobCheckStatusMessage.pending", "Pending");
          case JobCheckStatusEnum.InReview:
            return t("jobCheckStatusMessage.inReview", "Reviewing");
          case JobCheckStatusEnum.Succeeded:
            return t("jobCheckStatusMessage.succeeded", "Verified");
          case JobCheckStatusEnum.Failed:
            return t("jobCheckStatusMessage.failed", "Rejected");

          default:
            return ((_status: never) => "")(status);
        }
      })();

  const badgeVariant = (() => {
    switch (status) {
      case JobCheckStatusEnum.Pending:
      case JobCheckStatusEnum.InReview:
        return "pending";

      case JobCheckStatusEnum.Failed:
        return "error";

      case JobCheckStatusEnum.Succeeded:
        return "success";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "text-xs", "font-bold", "text-deepBlue-900")}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};

export const getTranslatableJobCheckStatus = (
  status: JobCheckStatusEnum,
  t: TFunction
): string => {
  switch (status) {
    case JobCheckStatusEnum.Pending:
      return t("jobCheckStatus.pending", status);
    case JobCheckStatusEnum.InReview:
      return t("jobCheckStatus.inReview", status);
    case JobCheckStatusEnum.Succeeded:
      return t("jobCheckStatus.succeeded", status);
    case JobCheckStatusEnum.Failed:
      return t("jobCheckStatus.failed", status);

    default:
      return ((_status: never) => "")(status);
  }
};
