/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  IDENTITY_FRAGMENT,
  IdentityFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";

const SET_DEFAULT_IDENTITY = gql`
  mutation ($id: ID!) {
    setDefaultIdentity(input: { id: $id }) {
      identity {
        ...IdentityFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${IDENTITY_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  identity?: IdentityFragment | null;
  errors: ErrorFragment[];
}

interface SetDefaultIdentityMutation {
  setDefaultIdentity: Payload | null;
}

export const useSetDefaultIdentityMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    SetDefaultIdentityMutation,
    { id: string }
  >(SET_DEFAULT_IDENTITY);

  const setDefaultIdentity = (
    id: string,
    onSuccess?: (response: IdentityFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            session() {
              const session = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!session) return;

              const identities = session.session.account.identities?.map(
                (identity) => ({ ...identity, default: identity.id === id })
              );

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...session.session,
                    account: {
                      ...session.session.account,
                      identities,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.setDefaultIdentity?.errors ?? [];
        const identity = data?.setDefaultIdentity?.identity;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => identity && onSuccess && onSuccess(identity),
          onRetry: () => setDefaultIdentity(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setDefaultIdentity,
    isLoading: loading,
    called,
    identity: data?.setDefaultIdentity?.identity,
    errors: data?.setDefaultIdentity?.errors ?? [],
  };
};
