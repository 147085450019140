/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { CLIENT_FRAGMENT, ClientFragment } from "graphql/fragments";
import { QueryClientsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const CLIENTS = gql`
  query (
    $freelanceProfileId: ID!
    $page: Int
    $perPage: Int
    $sortBy: ClientsSortByEnum
    $filter: ClientsFilter
  ) {
    clients(
      freelanceProfileId: $freelanceProfileId
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      filter: $filter
    ) {
      exportPath
      items {
        ...ClientFragment
      }
      total
    }
  }
  ${CLIENT_FRAGMENT}
`;

export interface ClientsQuery {
  clients: PaginatedPayload<ClientFragment>;
}

type QueryArgs = Pick<
  QueryClientsArgs,
  "freelanceProfileId" | "page" | "perPage" | "sortBy" | "filter"
>;

export const useClientsQuery = (args: QueryArgs) => {
  const { data, loading: isLoading } = useQuery<ClientsQuery, QueryArgs>(
    CLIENTS,
    {
      variables: { ...args },
    }
  );

  const clients = data?.clients.items?.filter(Boolean) ?? [];

  return { clients, isLoading };
};

export const useClientsLazyQuery = () => {
  const [getClientsQuery, { data, loading: isLoading, called }] = useLazyQuery<
    ClientsQuery,
    QueryArgs
  >(CLIENTS, {
    fetchPolicy: "network-only",
  });

  const clients = data?.clients.items?.filter(Boolean) ?? [];
  const total = data?.clients.total ?? 0;

  const resolveRef = useRef<(clients?: ClientFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(clients);
      resolveRef.current = undefined;
    }
  }, [clients, called, isLoading]);

  const getClients = async (args: QueryArgs) => {
    const variables = { ...args };

    getClientsQuery({ variables });

    return new Promise<ClientFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getClients, clients, isLoading, called, total };
};
