import { useContext, useEffect, useRef } from "react";
import { IconType } from "react-icons";
import { HiChevronDown } from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { useMenu } from "hooks";
import { PopoverContext } from "providers/PopoverHandler";
import { TTailwindString, tw } from "utils/tw";

import { Button } from "../basic";
import { MenuList } from "../wrappers/Layout/common";
import { MenuItem } from "../wrappers/Layout/common/MenuList/types";

interface Props {
  id: string;
  menuItems: MenuItem[];
  menuStyles?: TTailwindString;
  buttonStyles?: TTailwindString;
  Icon?: IconType;
  label?: string | JSX.Element;
  onOpen?: (isOpen: boolean) => void;
  alignToSide: "left" | "right";
  hasDynamicMenuItems?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
}

export default ({
  id,
  menuItems,
  menuStyles,
  buttonStyles,
  Icon = HiChevronDown,
  label,
  onOpen,
  alignToSide,
  hasDynamicMenuItems = false,
  isDisabled = false,
  isFullWidth = false,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingActionMenu, setIsShowingActionMenu] = useMenu(
    containerRef,
    popoverMenuRef
  );
  const toggleMenu = () => setIsShowingActionMenu(!isShowingActionMenu);

  const {
    registerPopover,
    unregisterPopover,
    updatePopoverContent,
    setIsShownPopover,
    shownPopovers,
  } = useContext(PopoverContext);

  const popoverContent = (
    <div
      className={tw(
        menuStyles,
        "my-2",
        "w-60",
        "rounded-md",
        "shadow-lg",
        "bg-white",
        {
          "left-0": alignToSide === "left",
          "right-0": alignToSide === "right",
        }
      )}
      ref={popoverMenuRef}
    >
      <MenuList
        menuItems={menuItems}
        closeSelf={() => setIsShowingActionMenu(false)}
      />
    </div>
  );

  const { pathname } = useLocation();
  useEffect(() => {
    setIsShowingActionMenu(false);
  }, [pathname]);

  useEffect(() => {
    onOpen?.(isShowingActionMenu);
  }, [isShowingActionMenu]);

  useEffect(() => {
    const isShowingPopover = shownPopovers.some((popover) => popover.id === id);
    if (isShowingActionMenu === isShowingPopover) return;

    setIsShownPopover(id, isShowingActionMenu);
  }, [isShowingActionMenu]);

  useEffect(() => {
    if (!hasDynamicMenuItems) return;

    updatePopoverContent(id, popoverContent);
  }, [menuItems]);

  // Unregister popover on unmount
  useEffect(() => () => unregisterPopover(id), []);

  return (
    <div ref={containerRef} className={isFullWidth ? tw("w-full") : undefined}>
      <Button
        id="action_menu-toggle"
        aria-label="action menu"
        aria-expanded={isShowingActionMenu}
        onClick={toggleMenu}
        variant="tertiary"
        size="sm"
        TrailingIcon={label ? Icon : undefined}
        className={buttonStyles}
        ref={(targetRef) =>
          registerPopover({
            id,
            variant: "default",
            content: popoverContent,
            targetRef,
            placement: "bottom-end",
          })
        }
        disabled={isDisabled}
        fullWidth={isFullWidth}
      >
        {label ?? <Icon size={24} />}
      </Button>
    </div>
  );
};
