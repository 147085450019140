import toast from "react-hot-toast";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Observable, getMainDefinition } from "@apollo/client/utilities";
import { OperationDefinitionNode } from "graphql";
import { Subscription } from "zen-observable-ts";

import { ErrorCard } from "components/common/basic";
import { getDefaultLanguage } from "providers/i18n";
import { Trans } from "translations";
import { storage } from "utils";

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Account: {
      fields: {
        uiFlags: { merge: true },
        counts: { merge: true },
        checksSubscription: { merge: true },
        whistleblowingSubscription: { merge: true },
      },
    },
  },
});

const getToken = (tokenId: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchToken = searchParams.get(tokenId);
  if (searchToken) return searchToken;

  const sessionToken = storage.getSession(tokenId);
  if (sessionToken) return sessionToken;

  return null;
};

const request = (operation: Operation) => {
  const preferredLanguage = getDefaultLanguage();
  const token = getToken("dp-token");

  operation.setContext({
    headers: {
      "Accept-Language": preferredLanguage,
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let subscription: Subscription;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    })
);

const removeKeysFromMutation = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(
    operation.query
  ) as OperationDefinitionNode;
  const isMutation = definition?.operation === "mutation";

  if (isMutation && operation.variables) {
    const keysToOmit = ["__typename"];

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      (key, value) => (keysToOmit.includes(key) ? undefined : value)
    );
  }

  return forward(operation);
});

export const networkErrorHandler = (): void => {
  toast.custom((toastProps) => (
    <ErrorCard
      heading={
        <Trans ns="common" i18nKey="error.network">
          Network Error
        </Trans>
      }
      toastProps={toastProps}
    />
  ));
};

const graphqlApiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/graphql"
  : "http://localhost:3001/graphql";

export default new ApolloClient({
  link: ApolloLink.from([
    removeKeysFromMutation,
    onError(({ networkError }) => {
      if (networkError) networkErrorHandler();
    }),
    requestLink,
    new HttpLink({
      uri: graphqlApiUrl,
    }),
  ]),
  cache,
  defaultOptions: {
    query: { fetchPolicy: "network-only" },
    mutate: { errorPolicy: "all" },
  },
});
