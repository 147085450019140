import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { PhoneNumberInput } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useUpdateAccountMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface FormValues {
  phoneCountry: string;
  phoneNumber: string;
}

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props): JSX.Element => {
  const { updateAccount } = useUpdateAccountMutation();

  const { t } = useTranslation("account");
  const { closeOnePopup } = useContext(PopupContext);

  const validationSchema = Yup.object({
    phoneCountry: Yup.string().required(),
    phoneNumber: Yup.string().required(),
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ phoneCountry, phoneNumber }: FormValues) => {
    const attributes = {
      phoneNumber: `${phoneCountry} ${phoneNumber}`,
    };
    updateAccount({ attributes }, closeOnePopup);
  };

  const formId = "phoneNumberForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={t(
        "accountSettings.phoneNumberPopup.heading",
        "Add your phone number"
      )}
      subTitle={t(
        "accountSettings.phoneNumberPopup.description",
        "Add your phone number to your settings"
      )}
      footerActions={[
        {
          id: "accountSettings-add_phone",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("accountSettings.phoneNumberPopup.button.submit", "Save"),
          disabled: !isValid,
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <PhoneNumberInput
          id="phone"
          label={t(
            "accountSettings.phoneNumberPopup.phoneNumber.label",
            "Phone number"
          )}
          registerCountry={register("phoneCountry")}
          registerNumber={register("phoneNumber")}
        />
      </form>
    </PopupWrapper>
  );
};
