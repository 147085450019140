import { useContext, useEffect } from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  Merge,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { HiPlus } from "react-icons/hi";

import { Button } from "components/common/basic";
import { TailwindContext } from "providers/Tailwind";
import { TWidth } from "tw-generated";
import { tw } from "utils/tw";

import { InputType } from "./InputCell";
import InputsDesktop from "./InputsDesktop";
import InputsMobile from "./InputsMobile";

interface ItemBase extends FieldValues {
  id: string;
}

interface Props<T> {
  itemLabel: string;
  name: string;
  columns: {
    key: string;
    label: string;
    width: TWidth;
    input: InputType;
  }[];
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  clearErrors: () => void;
  addItemLabel: string;
  removeItemLabel: string;
  errors?: Merge<FieldError, FieldErrors<T[]>>;
  minItems?: number;
  defaultItem?: FieldValues;
  defaultItemAmount?: number;
  defaultValues?: T[];
}

export default <T extends ItemBase>({
  itemLabel,
  name,
  columns,
  control,
  register,
  clearErrors,
  addItemLabel,
  removeItemLabel,
  errors = [],
  minItems = 0,
  defaultItem = {} as T,
  defaultItemAmount = minItems,
  defaultValues,
}: Props<T>): JSX.Element => {
  const { isMd } = useContext(TailwindContext);
  const { fields, append, remove } = useFieldArray({ control, name });

  const addItem = (item: T) => {
    // Prevents issue occuring when adding a person after validating fields
    clearErrors?.();

    append(item as T);
  };

  const addDefaultItem = () => addItem(defaultItem as T);

  const removeItem = (index: number) => {
    remove(index);

    clearErrors?.();
  };

  useEffect(() => {
    if (fields.length > 0) return;

    if (defaultValues) defaultValues.forEach((item) => append(item));
    else if (defaultItemAmount > 0)
      [...Array(defaultItemAmount)].forEach(addDefaultItem);
  }, []);

  return (
    <>
      {isMd ? (
        <InputsDesktop
          name={name}
          columns={columns}
          fields={fields}
          register={register}
          removeItem={removeItem}
          errors={errors}
          minItems={minItems}
        />
      ) : (
        <InputsMobile
          itemLabel={itemLabel}
          name={name}
          columns={columns}
          fields={fields}
          control={control}
          register={register}
          removeItem={removeItem}
          removeItemLabel={removeItemLabel}
          errors={errors}
          minItems={minItems}
        />
      )}

      <div className={tw("flex", "space-x-4")}>
        <Button
          id="input_table-add_item"
          onClick={addDefaultItem}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          {addItemLabel}
        </Button>
      </div>
    </>
  );
};
