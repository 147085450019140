import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Select } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { getWbReportCategoryString } from "components/tellmore/utils";
import { WbReportFragment } from "graphql/fragments";
import { useUpdateWbReportMutation } from "graphql/mutations";
import { WbReportCategoryEnum, WbReportTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface FormValues {
  type: WbReportTypeEnum;
  category?: WbReportCategoryEnum;
}

interface Props {
  wbReport: WbReportFragment;
  onSuccess?: (wbReport: WbReportFragment) => void;
  hasPrevious: boolean;
}

export default ({ wbReport, onSuccess, ...props }: Props): JSX.Element => {
  const { updateWbReport } = useUpdateWbReportMutation();

  const { closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const requiredCategoryError = t(
    "popup.updateWbReport.form.error.category.required",
    "A category is required"
  );
  const validationSchema = Yup.object({
    type: Yup.string().required().oneOf(Object.values(WbReportTypeEnum)),
    category: Yup.string()
      .oneOf(Object.values(WbReportCategoryEnum), requiredCategoryError)
      .when("type", {
        is: WbReportTypeEnum.Whistleblowing,
        then: (schema) => schema.required(requiredCategoryError),
      }),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      type: wbReport.type,
      category: wbReport.category ?? undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const type = watch("type");

  const onSubmit = (values: FormValues) => {
    const attributes =
      values.type === WbReportTypeEnum.Feedback
        ? {
            type: values.type,
            category: null,
          }
        : {
            type: values.type,
            category: values.category,
          };

    updateWbReport(wbReport.id, attributes, (wbReport) => {
      onSuccess?.(wbReport);

      closeOnePopup();
    });
  };
  const formId = "assignWbHandlersForm";

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t(
        "popup.updateWbReport.title",
        "Change the report type / category"
      )}
      subTitle={t(
        "popup.updateWbReport.subTitle",
        "If you change the report type the category will also automatically change."
      )}
      footerActions={[
        {
          id: "update_report-update_report",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.updateWbReport.submit", "Save"),
        },
      ]}
    >
      <form
        className={tw("w-full", "space-y-4")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              id={field.name}
              name={field.name}
              label={t("popup.updateWbReport.form.type.label", "Select type")}
              options={Object.values(WbReportTypeEnum).map((value) => ({
                label: t(
                  `popup.updateWbReport.form.type.value.${value.toLowerCase()}`,
                  value
                ),
                value,
              }))}
              value={field.value}
              onChange={field.onChange}
              errorMessage={errors.type?.message}
            />
          )}
        />

        {type === WbReportTypeEnum.Whistleblowing && (
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                id={field.name}
                name={field.name}
                label={t(
                  "popup.updateWbReport.form.category.label",
                  "Select category"
                )}
                options={[
                  {
                    label: t(
                      `popup.updateWbReport.form.category.option_notSelected`,
                      "Select"
                    ),
                    value: "NOT_SELECTED",
                  },
                  ...[
                    WbReportCategoryEnum.BriberyCorruptionConflictOfInterest,
                    WbReportCategoryEnum.BullyingHarassmentThreatsViolence,
                    WbReportCategoryEnum.DangerousPhysicalConditions,
                    WbReportCategoryEnum.DrugOrDopingAbuse,
                    WbReportCategoryEnum.FinancialMisconductEmbezzlementTheft,
                    WbReportCategoryEnum.MishandlingOfPersonalDataDataLeaks,
                    WbReportCategoryEnum.PollutionViolationOfEnvironmentalLegislation,
                    WbReportCategoryEnum.RacismAndDiscrimination,
                    WbReportCategoryEnum.SexualHarassmentAndAbuse,
                    WbReportCategoryEnum.Other,
                  ].map((value) => ({
                    label: getWbReportCategoryString(value, t),
                    value,
                  })),
                ]}
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.category?.message}
              />
            )}
          />
        )}
      </form>
    </PopupWrapper>
  );
};
