/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ATTACHMENT_FRAGMENT,
  AttachmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";

const DESTROY_POLICY_DOCUMENT = gql`
  mutation ($id: ID!) {
    destroyPolicyDocument(input: { id: $id }) {
      account {
        policyDocuments {
          ...AttachmentFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: { policyDocuments: AttachmentFragment[] } | null;
  errors: ErrorFragment[];
}

interface DestroyPolicyDocumentMutation {
  destroyPolicyDocument: Payload | null;
}

export const useDestroyPolicyDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyPolicyDocumentMutation,
    { id: string }
  >(DESTROY_POLICY_DOCUMENT);

  const destroyPolicyDocument = (
    id: string,
    onSuccess?: (policyDocuments: AttachmentFragment[]) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            session() {
              const sessionData = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionData) return;

              const policyDocuments =
                sessionData.session.account.policyDocuments.filter(
                  (doc) => doc.id !== id
                );

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionData.session,
                    account: {
                      ...sessionData.session.account,
                      policyDocuments,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyPolicyDocument?.errors ?? [];
        const policyDocuments =
          data?.destroyPolicyDocument?.account?.policyDocuments;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && policyDocuments && onSuccess(policyDocuments),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyPolicyDocument,
    isLoading: loading,
    errors: data?.destroyPolicyDocument?.errors ?? [],
  };
};
