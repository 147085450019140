/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  PUBLIC_WB_ACCOUNT_FRAGMENT,
  PublicWbAccountFragment,
} from "graphql/fragments";
import { QueryWbAccountArgs } from "graphql/types";

export const WB_ACCOUNT = gql`
  query ($wbSlug: String!) {
    wbAccount(wbSlug: $wbSlug) {
      ...PublicWbAccountFragment
    }
  }
  ${PUBLIC_WB_ACCOUNT_FRAGMENT}
`;

export type WbAccountQuery = {
  wbAccount: PublicWbAccountFragment;
};

type QueryArgs = Pick<QueryWbAccountArgs, "wbSlug">;

export const useWbAccountQuery = (args: QueryArgs) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<WbAccountQuery, QueryArgs>(WB_ACCOUNT, {
    variables: { ...args },
  });

  return { account: data?.wbAccount, isLoading, called };
};

export const useWbAccountLazyQuery = () => {
  const [getWbAccountQuery, { data, loading: isLoading, called }] =
    useLazyQuery<WbAccountQuery, QueryArgs>(WB_ACCOUNT, {
      fetchPolicy: "network-only",
    });

  const resolveRef = useRef<(wbAccount?: PublicWbAccountFragment) => void>();

  const wbAccount = data?.wbAccount;

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(wbAccount);
      resolveRef.current = undefined;
    }
  }, [wbAccount, called, isLoading]);

  const getWbAccount = async (args: QueryArgs) => {
    const variables = { ...args };

    getWbAccountQuery({ variables });

    return new Promise<PublicWbAccountFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getWbAccount, wbAccount, isLoading, called };
};
