import { useContext } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useArchivePeopleMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  personIds: string[];
}

export default ({ personIds, ...props }: Props): JSX.Element => {
  const { archivePeople } = useArchivePeopleMutation();

  const { t } = useTranslation("account");
  const { closeOnePopup } = useContext(PopupContext);

  const { title, subTitle, submitLabel } = ((amount: number) => {
    switch (amount) {
      case 0: // All
        return {
          title: t(
            "people.archivePersonPopup_all.heading",
            "Archive all people"
          ),
          subTitle: t(
            "people.archivePersonPopup_all.body",
            "Archiving these people will move them from the active table to the archived table. If there is any activities between your account and the people the people will be moved back to the active table."
          ),
          submitLabel: t(
            "people.archivePersonPopup_all.button.archive",
            "Archive people"
          ),
        };
      case 1: // One
        return {
          title: t(
            "people.archivePersonPopup_one.heading",
            "Archive this person"
          ),
          subTitle: t(
            "people.archivePersonPopup_one.body",
            "Archiving this person will move them from the active table to the archived table. If there is any activities between your account and the person the person will be moved back to the active table."
          ),
          submitLabel: t(
            "people.archivePersonPopup_one.button.archive",
            "Archive person"
          ),
        };
      default: // Some
        return {
          title: t(
            "people.archivePersonPopup_some.heading",
            "Archive these people"
          ),
          subTitle: t(
            "people.archivePersonPopup_some.body",
            "Archiving these people will move them from the active table to the archived table. If there is any activities between your account and the people the people will be moved back to the active table."
          ),
          submitLabel: t(
            "people.archivePersonPopup_some.button.archive",
            "Archive people"
          ),
        };
    }
  })(personIds.length);

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={title}
      subTitle={subTitle}
      footerActions={[
        {
          id: "archive_people_popup-cancel",
          variant: "tertiary",
          onClick: closeOnePopup,
          label: t("people.archivePersonPopup.button.cancel", "Cancel"),
        },
        {
          id: "archive_people_popup-archive",
          variant: "primary",
          onClick: () => archivePeople({ ids: personIds }, closeOnePopup),
          label: submitLabel,
        },
      ]}
    />
  );
};
