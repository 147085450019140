import { RouteProps } from "react-router-dom";

import {
  EnrolmentWizard,
  Job,
  JobChecks,
  Jobs,
} from "components/partners/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("recruitmore", "ENROLMENT_WIZARD", ":jobId") + "/*", element: <EnrolmentWizard /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("recruitmore", "JOBS"), element: <Jobs /> },
    { path: getRoutePath("recruitmore", "JOB", ":id"), element: <Job /> },
    { path: getRoutePath("recruitmore", "JOB_CHECKS"), element: <JobChecks /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "partners", routes, loggedInRoutes, loggedOutRoutes };
};
