import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Button, Link } from "components/common/basic";
import { Page } from "components/common/wrappers";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import errorPageIllustration from "assets/illustrations/uncategorized/opps.svg";

export default (): JSX.Element => {
  const initialRenderRef = useRef(true);

  const { t } = useTranslation("common");
  const getRoutePath = useRoutePaths();
  const { pathname } = useLocation();

  useEffect(() => {
    if (initialRenderRef.current) initialRenderRef.current = false;
    else window.location.reload();
  }, [pathname]);

  const imageStyles = tw("h-40", "my-12");
  const contentStyles = tw(
    "mx-auto",
    "max-w-2xl",
    "flex",
    "flex-col",
    "space-y-4",
    "items-center",
    "text-center"
  );

  return (
    <Page title={t("errorPage.pageTitle", "Opps")} className={tw("bg-silver")}>
      <img
        className={imageStyles}
        src={errorPageIllustration}
        alt="Man with a tablet that shows an error message"
      />

      <div className={contentStyles}>
        <h1 className={tw("text-2xl", "font-extrabold")}>
          {t("errorPage.heading", "OPPS!")}
        </h1>

        <p className={tw("text-lg", "font-semibold", "text-gray-400")}>
          {t(
            "errorPage.body",
            "Something went wrong. Please refresh the page or click the link below."
          )}
        </p>

        <Link
          id="error_page-back_to_dashboard"
          unstyled
          to={getRoutePath({ module: "account", routeName: "DASHBOARD" })}
        >
          <Button id="error_page-back_to_dashboard">
            {t("errorPage.link.dashboard", "Back to dashboard")}
          </Button>
        </Link>
      </div>
    </Page>
  );
};
