/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  TRANSFER_FRAGMENT,
  TransferFragment,
} from "graphql/fragments";
import { CreateTransferAttributes } from "graphql/types";

const CREATE_TRANSFER = gql`
  mutation ($attributes: CreateTransferAttributes!) {
    createTransfer(input: { attributes: $attributes }) {
      transfer {
        ...TransferFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TRANSFER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  transfer?: TransferFragment | null;
  errors: ErrorFragment[];
}

interface CreateTransferMutation {
  createTransfer: Payload | null;
}

export const useCreateTransferMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateTransferMutation,
    { attributes: CreateTransferAttributes }
  >(CREATE_TRANSFER);

  const createTransfer = (
    attributes: CreateTransferAttributes,
    onSuccess?: (transfer: TransferFragment) => void
  ) =>
    mutation({ variables: { attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createTransfer?.errors ?? [];
        const invoice = data?.createTransfer?.transfer;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && invoice && onSuccess(invoice),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createTransfer,
    isLoading: loading,
    transfer: data?.createTransfer?.transfer,
    errors: data?.createTransfer?.errors ?? [],
  };
};
