import { ClientFragment } from "graphql/fragments";

import CreateIndClientForm from "./CreateIndClientForm";

interface Props {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  client?: ClientFragment;
}

export default ({ formId, onSuccessfulEdit, client }: Props): JSX.Element => (
  <CreateIndClientForm
    formId={formId}
    onSuccessfulEdit={onSuccessfulEdit}
    client={client}
  />
);
