import { HiChevronRight } from "react-icons/hi";

import { TPadding } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  illustration: JSX.Element;
  label: string;
  subLabel?: string | JSX.Element;
  onClick?: () => void;
  variant: "outlined" | "filled" | "card";
  isDisabled?: boolean;
  isNarrow?: boolean;
  hasShadow?: boolean;
  badge?: JSX.Element;
  padding?: TPadding;
}

export default ({
  illustration,
  label,
  subLabel,
  onClick,
  variant,
  isDisabled,
  isNarrow,
  hasShadow,
  badge,
  padding = "p-4",
}: Props): JSX.Element => {
  const buttonBase = tw(
    "w-full",
    padding,
    "flex",
    "justify-between",
    "border-2",
    "rounded-lg",
    "focus:outline-none",
    "focus:border-gray-900",
    {
      [tw("bg-silver", "border-silver")]: isDisabled,
      [tw("shadow")]: hasShadow,
      [tw("hover:shadow-md")]: hasShadow && !isDisabled,
      [tw("flex-col", "items-baseline", "space-y-4")]: isNarrow,
      [tw("space-x-4", "items-center")]: !isNarrow,
    }
  );
  const outlined = tw(
    "bg-white",
    "border-primary",
    "hover:bg-silver",
    "hover:border-primary-hover"
  );
  const filled = tw(
    "bg-primary",
    "border-primary",
    "hover:bg-primary-hover",
    "hover:border-primary-hover"
  );
  const card = tw(
    "bg-white",
    "border-white"

    // "flex",
    // "flex-col",
    // "justify-between",
    // "xl:items-center",
    // "xl:space-x-2",
    // "xl:flex-row"
  );

  const buttonStyles = tw(buttonBase, {
    [outlined as string]: !isDisabled && variant === "outlined",
    [filled as string]: !isDisabled && variant === "filled",
    [card as string]: !isDisabled && variant === "card",
  });

  const labelBase = tw("text-deepBlue-900");
  const labelStyles = tw(labelBase, {
    "font-extrabold": variant === "outlined" || variant === "filled",
    "font-bold": variant === "card",
  });

  const subLabelStyles = tw("whitespace-pre-wrap", {
    "text-xs": variant === "outlined" || variant === "filled",
    [tw("text-sm", "text-deepBlue-500")]: variant === "card",
  });

  return (
    <button
      onClick={onClick}
      type="button"
      className={buttonStyles}
      disabled={isDisabled}
    >
      {illustration}

      <div
        className={tw("flex-1", "flex", "flex-col", "text-left", {
          "space-y-2": isNarrow,
        })}
      >
        <p className={labelStyles}>{label}</p>
        <p className={subLabelStyles}>{subLabel}</p>
      </div>

      <div className={tw("self-baseline")}>{badge}</div>

      {!isDisabled && !isNarrow && <HiChevronRight size="24px" />}
    </button>
  );
};
