import { Link } from "react-router-dom";

import { TTailwindString, tw } from "utils/tw";

import { ActionID } from "./types";

interface Props {
  id: ActionID;
  to: string;
  isExternal?: boolean;
  openSeparate?: boolean;
  color?: "black" | "gray" | "white" | "blue" | "deepBlue";
  unstyled?: boolean;
  className?: TTailwindString;
  onClick?: () => void;
  title?: string;
}

export default ({
  id,
  to,
  isExternal = false,
  openSeparate = isExternal ? true : false,
  color = "black",
  unstyled,
  className,
  onClick,
  title,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const baseLink = tw("underline");

  const linkStyles = (() => {
    switch (color) {
      case "black":
        return tw("text-gray-900", "hover:text-gray-700");
      case "gray":
        return tw("text-gray-500", "hover:text-gray-300");
      case "white":
        return tw("text-white", "hover:text-gray-100");
      case "blue":
        return tw("text-blue-900", "hover:text-blue-700");
      case "deepBlue":
        return tw("text-deepBlue-900", "hover:text-deepBlue-700");

      default:
        return tw();
    }
  })();

  const props = {
    id,
    title,
    target: openSeparate ? "_blank" : undefined,
    className: unstyled ? className : tw(baseLink, linkStyles, className),
  };

  if (isExternal)
    return (
      <a {...props} href={to} rel="noopener noreferrer" onClick={onClick}>
        {children}
      </a>
    );

  return (
    <Link {...props} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};
