/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_FRAGMENT,
  CheckFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SENT_CHECK_LISTS, SentCheckListsQuery } from "graphql/queries";
import { OverrideCheckAttributes } from "graphql/types";

const OVERRIDE_CHECK = gql`
  mutation ($id: ID!, $attributes: OverrideCheckAttributes) {
    overrideCheck(input: { id: $id, attributes: $attributes }) {
      check {
        ...CheckFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  check?: CheckFragment | null;
  errors: ErrorFragment[];
}

interface OverrideCheckMutation {
  overrideCheck: Payload | null;
}

export const useoverrideCheckMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    OverrideCheckMutation,
    { id: string; attributes?: OverrideCheckAttributes }
  >(OVERRIDE_CHECK);

  const overrideCheck = ({
    id,
    attributes,
    onSuccess,
  }: {
    id: string;
    attributes?: OverrideCheckAttributes;
    onSuccess?: (check?: CheckFragment) => void;
  }) =>
    mutation({
      variables: { id, attributes },
      update(cache, { data }) {
        cache.modify({
          fields: {
            sentCheckLists() {
              const updatedCheckList = data?.overrideCheck?.check?.checkList;
              if (!updatedCheckList) return;

              const existingCheckLists = cache.readQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
              });
              if (!existingCheckLists?.sentCheckLists.items) return;

              const items = existingCheckLists.sentCheckLists.items?.map(
                (checkListItem) =>
                  checkListItem?.id !== updatedCheckList.id
                    ? { ...checkListItem, status: updatedCheckList.status }
                    : checkListItem
              );

              cache.writeQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
                data: {
                  sentCheckLists: {
                    ...existingCheckLists.sentCheckLists,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.overrideCheck?.errors ?? [];
        const check = data?.overrideCheck?.check;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(check ?? undefined),
          onRetry: () => overrideCheck({ id, attributes, onSuccess }),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    overrideCheck,
    isLoading: loading,
    called,
    check: data?.overrideCheck?.check,
    errors: data?.overrideCheck?.errors ?? [],
  };
};
