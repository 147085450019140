import { AddressFragment } from "graphql/fragments";
import { Trans } from "translations";

export default ({
  line1,
  postalCode,
  city,
  country,
}: AddressFragment): JSX.Element => {
  const line2 = postalCode && city ? `${postalCode} ${city}` : city ?? null;
  const line3 = country;

  return (
    <>
      <p>
        {line1}
        {(line2 || line3) && ","}
      </p>

      {line2 && (
        <p>
          {line2}
          {line3 && ","}
        </p>
      )}

      {line3 && (
        <p>
          <Trans
            ns="common"
            i18nKey={`common:country.${country?.toLowerCase()}`}
          >
            {country}
          </Trans>
        </p>
      )}
    </>
  );
};
