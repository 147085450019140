/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FILE_FRAGMENT,
  DocumentFileFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DOCUMENT, DocumentQuery } from "graphql/queries";
import { DocumentFileAttributes } from "graphql/types";

const CREATE_DOCUMENT_FILE = gql`
  mutation ($documentId: ID!, $attributes: DocumentFileAttributes!) {
    createDocumentFile(
      input: { documentId: $documentId, attributes: $attributes }
    ) {
      documentFile {
        ...DocumentFileFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentFile?: DocumentFileFragment | null;
  errors: ErrorFragment[];
}

interface CreateDocumentFileMutation {
  createDocumentFile: Payload | null;
}

export const useCreateDocumentFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateDocumentFileMutation,
    {
      documentId?: string;
      attributes: DocumentFileAttributes;
    }
  >(CREATE_DOCUMENT_FILE);

  const createDocumentFile = (
    variables: {
      documentId?: string;
      attributes: DocumentFileAttributes;
    },
    onSuccess?: (documentFile: DocumentFileFragment) => void
  ) =>
    mutation({
      variables,
      update(cache, { data: newDocumentFileData }) {
        const newDocumentFileNode =
          newDocumentFileData?.createDocumentFile?.documentFile;
        if (!newDocumentFileNode) return;

        if (variables.documentId)
          cache.modify({
            fields: {
              node() {
                const document = cache.readQuery<DocumentQuery>({
                  query: DOCUMENT,
                });
                if (!document) return;

                const documentFiles = [
                  ...document.node.documentFiles,
                  newDocumentFileNode,
                ];

                cache.writeQuery<DocumentQuery>({
                  query: DOCUMENT,
                  data: {
                    node: {
                      ...document.node,
                      documentFiles,
                    },
                  },
                });
              },
            },
          });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDocumentFile?.errors ?? [];
        const documentFile = data?.createDocumentFile?.documentFile;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && documentFile && onSuccess(documentFile),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createDocumentFile,
    isLoading: loading,
    documentFile: data?.createDocumentFile?.documentFile,
    errors: data?.createDocumentFile?.errors ?? [],
  };
};
