import { gql } from "@apollo/client";

import { AccountTheme, AccountThemeFont } from "graphql/types";

export const ACCOUNT_THEME_FRAGMENT = gql`
  fragment AccountThemeFragment on AccountTheme {
    primary
    primaryContrast
    primaryHover
    font {
      name
      url
    }
  }
`;

export interface AccountThemeFragment
  extends Pick<
    AccountTheme,
    "primary" | "primaryContrast" | "primaryHover" | "font"
  > {
  font: Pick<AccountThemeFont, "name" | "url">;
}
