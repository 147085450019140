/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { CREDIT_NOTE_FRAGMENT, CreditNoteFragment } from "graphql/fragments";
import { QueryCreditNotesArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

const PARTNERERED_CREDIT_NOTES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: CreditNotesFilter
    $sortBy: CreditNotesSortByEnum
  ) {
    partneredCreditNotes(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      exportPath
      items {
        ...CreditNoteFragment
      }
      total
    }
  }
  ${CREDIT_NOTE_FRAGMENT}
`;

export interface PartneredCreditNotesQuery {
  partneredCreditNotes: PaginatedPayload<CreditNoteFragment>;
}

type QueryArgs = Pick<
  QueryCreditNotesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const usePartneredCreditNotesLazyQuery = () => {
  const [getCreditNotesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PartneredCreditNotesQuery, QueryArgs>(
      PARTNERERED_CREDIT_NOTES,
      { fetchPolicy: "network-only" }
    );

  const creditNotes = data?.partneredCreditNotes.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(creditNotes?: CreditNoteFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(creditNotes);
      resolveRef.current = undefined;
    }
  }, [creditNotes, called, isLoading]);

  const getCreditNotes = async (args?: QueryArgs) => {
    const variables = { ...args };

    getCreditNotesQuery({ variables });

    return new Promise<CreditNoteFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getCreditNotes, creditNotes, isLoading, called };
};
