import { HiChevronDown } from "react-icons/hi";

import { FEPaginatedSearch } from "components/common/composite";
import { DepartmentFragment } from "graphql/fragments";
import { useDepartmentsLazyQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  label: string;
  onChange: (value: string) => void;
  value: string;
  idSeparator: string;
}

export default ({
  label,
  onChange,
  value,
  idSeparator,
}: Props): JSX.Element => {
  const { getDepartments } = useDepartmentsLazyQuery();

  const { t } = useTranslation("common");

  const getDepartmentsFromString = (departmentString: string) => {
    if (!departmentString) return;

    const [id, name] = departmentString.split(idSeparator);

    return { id, name };
  };
  const getStringFromDepartment = ({ id, name }: DepartmentFragment) =>
    id + idSeparator + name;

  const onSelect = (department: DepartmentFragment) => {
    const departmentString = getStringFromDepartment(department);

    onChange(departmentString);
  };

  const selected = getDepartmentsFromString(value);
  const buttonLabel = (
    <>
      <span className={tw("mr-2")}>
        {selected?.name ??
          t("departmentsFilter.placeholder", "Select a department")}
      </span>

      <HiChevronDown size={20} className={tw("text-gray-400")} />
    </>
  );

  return (
    <span className={tw("w-80")}>
      <FEPaginatedSearch<DepartmentFragment>
        isMultiSelect={false}
        id="departments-filter"
        itemKey="id"
        buttonLabel={buttonLabel}
        label={label}
        onSelect={onSelect}
        onClear={() => onChange("")}
        renderListItemLabel={(option) => <>{option.name}</>}
        onSearch={(search) =>
          getDepartments({ filter: { search } }).then((deps = []) =>
            selected
              ? [
                  ...deps.filter(({ id }) => id === selected.id),
                  ...deps.filter(({ id }) => id !== selected.id),
                ]
              : deps
          )
        }
        reloadOnOpen
        showPagination={false}
        perPage={10_000}
      />
    </span>
  );
};
