import { IconBaseProps } from "react-icons";

export default ({ size, ...props }: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.0002 13.0088H34.2495V11.0088H44.0002V13.0088ZM44.0002 20.9951H34.2495V18.9951H44.0002V20.9951ZM13.7507 29.0012H4V27.0012H13.7507V29.0012ZM4 36.991H13.7507V34.991H4V36.991ZM34.2495 36.991H44.0002V34.991H34.2495V36.991ZM19.1262 29.0012H28.8769V27.0012H19.1262V29.0012ZM19.1262 20.9951H28.8769V18.9951H19.1262V20.9951ZM4 13.0088H13.7507V11.0088H4V13.0088Z"
      fill="currentColor"
    />
  </svg>
);
