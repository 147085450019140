import { Trans } from "translations";
import { tw } from "utils/tw";

import SpinnerWithoutText from "./SpinnerWithoutText";

interface Props {
  size?: "sm" | "md";
  withoutText?: boolean;
}

export default ({ size = "md", withoutText = false }: Props): JSX.Element => {
  if (withoutText) return <SpinnerWithoutText size={size} />;

  const containerStyles = tw(
    "flex",
    "flex-col",
    "items-center",
    "space-y-6",
    "m-10"
  );

  const textStyles = tw("text-2xl", "font-bold", "text-gray-900");

  return (
    <div className={containerStyles}>
      <SpinnerWithoutText size={size} />

      <p className={textStyles}>
        <Trans ns="common" i18nKey="spinner">
          Loading...
        </Trans>
      </p>
    </div>
  );
};
