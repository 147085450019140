import { useContext } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props) => {
  const { t } = useTranslation("checkmore");
  const { closeOnePopup } = useContext(PopupContext);

  const priceList = [
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.identityUpload",
        "Identity: Passport / ID card"
      ),
      value: "50 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.identityBankID",
        "Identity: BankID"
      ),
      value: "5 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.policeCertificate",
        "Police certificate"
      ),
      value: "300 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.rightToWork",
        "Visa / Right to work"
      ),
      value: "50 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.driversLicense",
        "Driver's license"
      ),
      value: "50 NOK",
    },
    {
      label: t("checkRequest.pricePopup.priceLabel.hmsCard", "HMS card"),
      value: "50 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.hprNumber",
        "HPR number (health personnel)"
      ),
      value: "50 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.bankAccount",
        "Bank account"
      ),
      value: "50 NOK",
    },
    {
      label: t(
        "checkRequest.pricePopup.priceLabel.policyDocument",
        "Policy document"
      ),
      value: "50 NOK",
    },
  ];

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t("checkRequest.pricePopup.title", "Prices")}
      subTitle={t(
        "checkRequest.pricePopup.description",
        "Below are our check prices."
      )}
      footerActions={[
        {
          id: "check_more_request__price_popup-close_popup",
          label: t("checkRequest.pricePopup.button.close", "Close"),
          onClick: closeOnePopup,
        },
      ]}
    >
      <div className={tw("w-2/3", "space-y-4")}>
        {priceList.map(({ label }, index) => (
          <p key={index} className={tw("text-deepBlue-500")}>
            {label}
          </p>
        ))}
      </div>

      <span className={tw("w-px", "bg-deepBlue-100")} />

      <div className={tw("flex-1", "space-y-4", "text-right")}>
        {priceList.map(({ value }, index) => (
          <p key={index} className={tw("text-deepBlue-700")}>
            {value}
          </p>
        ))}
      </div>
    </PopupWrapper>
  );
};
