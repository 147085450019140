/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_HANDLER_FRAGMENT,
  WbHandlerFragment,
} from "graphql/fragments";
import { WbHandlerAttributes } from "graphql/types";

const UPDATE_WB_HANDLER = gql`
  mutation ($wbHandlerId: ID!, $attributes: WbHandlerAttributes!) {
    updateWbHandler(input: { id: $wbHandlerId, attributes: $attributes }) {
      wbHandler {
        ...WbHandlerFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_HANDLER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbHandler?: WbHandlerFragment | null;
  errors: ErrorFragment[];
}

interface UpdateWbHandlerMutation {
  updateWbHandler: Payload | null;
}

export const useUpdateWbHandlerMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateWbHandlerMutation,
    { wbHandlerId: string; attributes: WbHandlerAttributes }
  >(UPDATE_WB_HANDLER);

  const updateWbHandler = (
    wbHandlerId: string,
    attributes: WbHandlerAttributes,
    onSuccess?: (wbHandler: WbHandlerFragment) => void
  ) =>
    mutation({
      variables: { wbHandlerId, attributes },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateWbHandler?.errors ?? [];
        const wbHandler = data?.updateWbHandler?.wbHandler;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbHandler && onSuccess(wbHandler),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateWbHandler,
    isLoading: loading,
    wbHandler: data?.updateWbHandler?.wbHandler,
    errors: data?.updateWbHandler?.errors ?? [],
  };
};
