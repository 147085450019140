/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PUBLIC_WB_REPORT_FRAGMENT,
  PublicWbReportFragment,
} from "graphql/fragments";

const SUBMIT_WB_REPORT = gql`
  mutation ($id: ID!) {
    submitWbReport(input: { id: $id }) {
      wbReport {
        ...PublicWbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PUBLIC_WB_REPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbReport?: PublicWbReportFragment | null;
  errors: ErrorFragment[];
}

interface SubmitWbReportMutation {
  submitWbReport: Payload | null;
}

export const useSubmitWbReportMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    SubmitWbReportMutation,
    { id: string }
  >(SUBMIT_WB_REPORT);

  const submitWbReport = (
    variables: { id: string },
    onSuccess?: (wbReport: PublicWbReportFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitWbReport?.errors ?? [];
        const wbReport = data?.submitWbReport?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    submitWbReport,
    isLoading: loading,
    wbReport: data?.submitWbReport?.wbReport,
    errors: data?.submitWbReport?.errors ?? [],
  };
};
