/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
} from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

export const CHECK_REQUEST = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CheckRequest {
        ...CheckRequestFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
`;

export type CheckRequestQuery = { node: CheckRequestFragment };

export const useCheckRequestQuery = (id: string) => {
  const { data, loading } = useQuery<CheckRequestQuery>(CHECK_REQUEST, {
    variables: { id },
  });

  return { checkRequest: data?.node, isLoading: loading };
};

export const useCheckRequestLazyQuery = () => {
  const [getCheckRequestQuery, { data, loading, called }] = useLazyQuery<
    CheckRequestQuery,
    QueryNodeArgs
  >(CHECK_REQUEST);

  const resolveRef = useRef<(checkRequest?: CheckRequestFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getCheckRequest = async (id: string) => {
    getCheckRequestQuery({ variables: { id } });

    return new Promise<CheckRequestFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getCheckRequest,
    checkRequest: data?.node,
    isLoading: loading,
  };
};
