import { TTailwindString, tw } from "utils/tw";

interface Props {
  className?: TTailwindString;
}

export default ({
  className,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const cardHeaderStyles = tw("rounded-t-lg", "p-4", className);

  return <div className={cardHeaderStyles}>{children}</div>;
};
