import { gql } from "@apollo/client";

import { Receipt } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const RECEIPT_FRAGMENT = gql`
  fragment ReceiptFragment on Receipt {
    id
    file {
      ...AttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface ReceiptFragment extends Pick<Receipt, "id"> {
  file: AttachmentFragment;
}
