/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CheckRequestAttributes } from "graphql/types";

const UPDATE_CHECK_REQUEST = gql`
  mutation ($id: ID!, $attributes: CheckRequestAttributes!) {
    updateCheckRequest(input: { id: $id, attributes: $attributes }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest?: CheckRequestFragment | null;
  errors: ErrorFragment[];
}

interface UpdateCheckRequestMutation {
  updateCheckRequest: Payload | null;
}

export const useUpdateCheckRequestMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateCheckRequestMutation,
    {
      id: string;
      attributes: CheckRequestAttributes;
    }
  >(UPDATE_CHECK_REQUEST);

  const updateCheckRequest = (
    variables: {
      id: string;
      attributes: CheckRequestAttributes;
    },
    onSuccess?: (checkRequest: CheckRequestFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateCheckRequest?.errors ?? [];
        const checkRequest = data?.updateCheckRequest?.checkRequest;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && checkRequest && onSuccess(checkRequest),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    updateCheckRequest,
    isLoading: loading,
    checkRequest: data?.updateCheckRequest?.checkRequest,
    errors: data?.updateCheckRequest?.errors ?? [],
  };
};
