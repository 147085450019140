import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";

import { ApolloProvider } from "@apollo/client";
import "moment/locale/nb";
import "raf/polyfill";

import apolloClient from "graphql/apolloClient";
import { AnalyticsProvider } from "providers/Analytics";
import { AuthProvider } from "providers/Authentication";
import { ErrorLoggerProvider } from "providers/ErrorLogger";
import TailwindProvider from "providers/Tailwind";
import IntlProvider from "providers/i18n";

import App from "./App";
import "./index.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const container = document.getElementById("root");
const app = (
  <AnalyticsProvider>
    <TailwindProvider>
      <ErrorLoggerProvider>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <IntlProvider>
              <App />
            </IntlProvider>
          </AuthProvider>
        </ApolloProvider>
      </ErrorLoggerProvider>
    </TailwindProvider>
  </AnalyticsProvider>
);

if (container) createRoot(container).render(app);
