/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DocumentAttributes } from "graphql/types";

const UPDATE_DOCUMENT = gql`
  mutation ($id: ID!, $attributes: DocumentAttributes!) {
    updateDocument(input: { id: $id, attributes: $attributes }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface UpdateDocumentMutation {
  updateDocument: Payload | null;
}

export const useUpdateDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();
  const [mutation, { data, loading }] = useMutation<
    UpdateDocumentMutation,
    { id: string; attributes: DocumentAttributes }
  >(UPDATE_DOCUMENT);

  const updateDocument = (
    id: string,
    attributes: DocumentAttributes,
    onSuccess?: (document: DocumentFragment) => void
  ) =>
    mutation({ variables: { id, attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateDocument?.errors ?? [];
        const document = data?.updateDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && document && onSuccess(document),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateDocument,
    isLoading: loading,
    document: data?.updateDocument?.document,
    errors: data?.updateDocument?.errors ?? [],
  };
};
