/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { RegisterTeamAccountAttributes } from "graphql/types";

const REGISTER_TEAM_ACCOUNT = gql`
  mutation ($attributes: RegisterTeamAccountAttributes!) {
    registerTeamAccount(input: { attributes: $attributes }) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface RegisterTeamAccountMutation {
  registerTeamAccount: Payload | null;
}

export const useRegisterTeamAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    RegisterTeamAccountMutation,
    { attributes: RegisterTeamAccountAttributes }
  >(REGISTER_TEAM_ACCOUNT);

  const registerTeamAccount = (
    attributes: RegisterTeamAccountAttributes,
    onSuccess?: (account: AccountFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables: { attributes },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.registerTeamAccount?.errors ?? [];
        const account = data?.registerTeamAccount?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    registerTeamAccount,
    isLoading: loading,
    account: data?.registerTeamAccount?.account,
    errors: data?.registerTeamAccount?.errors ?? [],
  };
};
