/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { CLIENT_FRAGMENT, ClientFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

const CLIENT = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Client {
        ...ClientFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const useClientLazyQuery = () => {
  const [getClientQuery, { data, loading, called }] = useLazyQuery<
    { node: ClientFragment },
    QueryNodeArgs
  >(CLIENT);

  const resolveRef = useRef<(client?: ClientFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getClient = async (id: string) => {
    getClientQuery({ variables: { id } });

    return new Promise<ClientFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getClient,
    client: data?.node,
    isLoading: loading,
  };
};
