import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
  SALARY_REPORT_FRAGMENT,
  SalaryReportFragment,
} from "graphql/fragments";
import { QuerySalaryReportsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

const SALARY_REPORTS = gql`
  query ($page: Int, $perPage: Int) {
    salaryReports(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...SalaryReportFragment
      }
      total
    }
  }
  ${SALARY_REPORT_FRAGMENT}
`;

export type SalaryReportsQuery = {
  salaryReports: PaginatedPayload<SalaryReportFragment>;
};

type QueryArgs = Pick<QuerySalaryReportsArgs, "page" | "perPage">;

export const useSalaryReportsQuery = (
  args: QueryArgs = {}
): {
  salaryReports: SalaryReportFragment[];
  isLoading: boolean;
} => {
  const { data, loading: isLoading } = useQuery<SalaryReportsQuery, QueryArgs>(
    SALARY_REPORTS,
    { variables: { ...args } }
  );

  const salaryReports = data?.salaryReports.items?.filter(Boolean) ?? [];

  return { salaryReports, isLoading };
};

export const useSalaryReportsLazyQuery = (): {
  getSalaryReports: (
    args?: QueryArgs
  ) => Promise<SalaryReportFragment[] | undefined>;
  salaryReports: SalaryReportFragment[];
  total: number;
  isLoading: boolean;
  called: boolean;
} => {
  const [getSalaryReportsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<SalaryReportsQuery, QueryArgs>(SALARY_REPORTS, {
      fetchPolicy: "network-only",
    });

  const salaryReports = data?.salaryReports.items?.filter(Boolean) ?? [];
  const total = data?.salaryReports.total ?? 0;

  const resolveRef = useRef<(salaryReports?: SalaryReportFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(salaryReports);
      resolveRef.current = undefined;
    }
  }, [salaryReports, called, isLoading]);

  const getSalaryReports = async (args?: QueryArgs) => {
    const variables = { ...args };

    getSalaryReportsQuery({ variables });

    return new Promise<SalaryReportFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getSalaryReports, salaryReports, total, isLoading, called };
};
