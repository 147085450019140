import { useContext } from "react";
import { HiOutlineAdjustments, HiOutlinePencilAlt } from "react-icons/hi";

import { Button, Card, CardBody, Divider, Link } from "components/common/basic";
import { AvatarUpload, HeadingWithNotice } from "components/common/composite";
import LogoUpload from "components/common/composite/LogoUpload";
import { AccountTypeEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import {
  AccountCrumbs,
  AddressSection,
  EmailSection,
  PhoneSection,
} from "../shared";
import TeamLanguageSelect from "./TeamLanguageSelect";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);
  const getRoutePath = useRoutePaths();

  if (!session) return null;

  const name = session.account.name ?? "-";

  const personalAccount = session.accounts.find(
    (account) => account.accountType === AccountTypeEnum.Personal
  );

  const isOwner = session.role === TeamRoleEnum.Owner;

  return (
    <div className={tw("space-y-8")}>
      <div className={tw("space-y-4")}>
        <AccountCrumbs
          currentPage={t(
            "accountSettings_org.accountInfo.breadcrumb",
            "Org info"
          )}
        />

        <HeadingWithNotice
          heading={t("accountSettings_org.accountInfo.header", "Org info")}
          LeadingIcon={HiOutlineAdjustments}
          notice={{
            id: "orgAccountSettings_orgInfo-headerNotice",
            heading: t(
              "accountSettings_org.accountInfo.noticeHeader",
              "Settings overview"
            ),
            body: t(
              "accountSettings_org.accountInfo.noticeDescription",
              "Keep your profile up to date and complete the fields below. The ideal profile picture is square in format e.g. 500x500 pixels. Read more in our help center."
            ),
          }}
        />
      </div>

      <div
        className={tw(
          "max-w-screen-lg",
          "flex",
          "flex-col",
          "space-y-8",
          "lg:flex-row",
          "lg:space-y-0",
          "lg:space-x-28"
        )}
      >
        <Card
          isFlat
          className={tw(
            "border",
            "border-deepBlue-100",
            "shrink-0",
            "w-full",
            "lg:w-72"
          )}
        >
          <CardBody>
            <h2 className={tw("text-lg", "font-bold")}>
              {t(
                "accountSettings_org.accountInfo.card.symbol.heading",
                "Symbol"
              )}
            </h2>

            <AvatarUpload account={session.account} />

            <Divider />

            <h2 className={tw("text-lg", "font-bold")}>
              {t("accountSettings_org.accountInfo.card.logo.heading", "Logo")}
            </h2>

            <LogoUpload account={session.account} />

            <Divider />

            <div className={tw("space-y-2")}>
              <div className={tw("w-full", "flex", "justify-between")}>
                <h2 className={tw("text-lg", "font-bold")}>
                  {t(
                    "accountSettings_org.accountInfo.card.name.header",
                    "Team space name"
                  )}
                </h2>

                {session.account.canManage && (
                  <button
                    className={tw(
                      "text-deepBlue-500",
                      "hover:text-deepBlue-700"
                    )}
                    onClick={() =>
                      openPopup({
                        id: "TeamInfo",
                        props: { account: session.account },
                      })
                    }
                  >
                    <HiOutlinePencilAlt size={24} />
                  </button>
                )}
              </div>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_org.accountInfo.contactDetails.name.helpText",
                  "The name which will be used for this space, e.g. your organisation's name"
                )}
              </p>
            </div>

            <div>
              <p className={tw("text-sm", "text-deepBlue-300")}>
                {t("accountSettings_org.accountInfo.card.nameLabel", "Name:")}
              </p>
              <p className={tw("text-lg", "font-bold")}>{name}</p>
            </div>

            <div>
              <p className={tw("text-sm", "text-deepBlue-300")}>
                {t("accountSettings_org.accountInfo.card.org", "Org:")}
              </p>

              {session.account.organizationNumber ? (
                <>
                  <p className={tw("text-lg", "font-bold")}>
                    {session.account.organizationNumber}
                  </p>

                  <p className={tw("text-deepBlue-500", "text-sm", "mt-1")}>
                    {session.account.organizationName}
                  </p>
                </>
              ) : (
                session.account.canManage && (
                  <button
                    className={tw("underline")}
                    onClick={() =>
                      openPopup({
                        id: "TeamInfo",
                        props: { account: session.account },
                      })
                    }
                  >
                    {t("accountSettings_org.accountInfo.card.editOrg", "Add")}
                  </button>
                )
              )}
            </div>
          </CardBody>
        </Card>

        <div className={tw("flex", "flex-col", "space-y-7", "w-full")}>
          <EmailSection
            account={session.account}
            heading={t(
              "accountSettings_org.accountInfo.contactDetails.email.heading",
              "Contact email"
            )}
            description={t(
              "accountSettings_org.accountInfo.contactDetails.email.descriptioni",
              "This is your default contact email and will be used as the “reply-to” for all emails sent out by the system on your behalf."
            )}
            isEditable
          />

          <Divider />

          <PhoneSection
            account={session.account}
            heading={t(
              "accountSettings_org.accountInfo.contactDetails.phone.heading",
              "Contact phone number (optional)"
            )}
            description={t(
              "accountSettings_org.accountInfo.contactDetails.phone.description",
              "This is your default contact phone number and will be included in your contact information to your team and contacts."
            )}
          />

          <Divider />

          <AddressSection
            account={session.account}
            heading={t(
              "accountSettings_org.accountInfo.contactDetails.address.heading",
              "Contact address (optional)"
            )}
            description={t(
              "accountSettings_org.accountInfo.contactDetails.address.description",
              "Add a visiting or postal address, and it will be included as part of your contact information to your team and contacts."
            )}
          />

          <Divider />

          <div className={tw("space-y-6")}>
            <div className={tw("space-y-2")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t(
                  "accountSettings_org.accountInfo.communicationSettings.language.header",
                  "Default language for communication"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {personalAccount && (
                  <Trans
                    ns="account"
                    i18nKey="accountSettings_org.accountInfo.communicationSettings.language.helpText"
                    defaults="Select the default language that you’d like to use in your emails and communication. If you’d like to browse Manymore in a different language, <linkToPersonalAccount>click here</linkToPersonalAccount> to go to your personal account settings."
                    components={{
                      linkToPersonalAccount: (
                        <Link
                          id="accountSettings-go_to_personal_account"
                          to={getRoutePath(
                            { module: "account", routeName: "SETTINGS_INFO" },
                            {
                              account: {
                                slug: personalAccount.slug,
                                type: personalAccount.accountType,
                              },
                            }
                          )}
                        />
                      ),
                    }}
                  />
                )}
              </p>
            </div>

            <TeamLanguageSelect />
          </div>

          <Divider />

          <div className={tw("space-y-6")}>
            <div className={tw("space-y-2")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t(
                  "accountSettings_org.accountInfo.accountDeletion.header",
                  "Delete team"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_org.accountInfo.accountDeletion.helpText",
                  "Deleting a team means that all information will be deleted incl. members, department. etc. This action can't be undone."
                )}
              </p>
            </div>

            {isOwner ? (
              <Button
                id="accountSettings-delete_account"
                variant="tertiary"
                size="sm"
                onClick={() => openPopup({ id: "DeleteAccount" })}
              >
                {t(
                  "accountSettings_org.accountInfo.accountDeletion.button.label",
                  "Delete account"
                )}
              </Button>
            ) : (
              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_org.accountInfo.accountDeletion.notOwnerText",
                  "Only team owners can delete the entire team account."
                )}
              </p>
            )}
          </div>

          <Divider />
        </div>
      </div>
    </div>
  );
};
