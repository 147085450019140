import mmIcon from "assets/branding/manymore/icon.svg";
import mmLogo from "assets/branding/manymore/logo.svg";
import mm3rdPartyEN from "assets/branding/manymore/mm3rdParty_en.svg";
import mm3rdPartyNO from "assets/branding/manymore/mm3rdParty_no.svg";
import mmFirma from "assets/branding/manymore/mmFirma.svg";
import mmFirmaDark from "assets/branding/manymore/mmFirma_dark.svg";
import mmLonn from "assets/branding/manymore/mmLonn.svg";
import mmLonnDark from "assets/branding/manymore/mmLonn_dark.svg";
import mmSymbol from "assets/branding/manymore/symbol.svg";
import mmSymbolDark from "assets/branding/manymore/symbol_dark.svg";
import ppIcon from "assets/branding/payoutpartner/icon.svg";
import ppLogo from "assets/branding/payoutpartner/logo.svg";
import pp3rdPartyEN from "assets/branding/payoutpartner/pop3rdParty_en.svg";
import pp3rdPartyNO from "assets/branding/payoutpartner/pop3rdParty_no.svg";
import ppFirma from "assets/branding/payoutpartner/popFirma.svg";
import ppFirmaDark from "assets/branding/payoutpartner/popFirma_dark.svg";
import ppLonn from "assets/branding/payoutpartner/popLonn.svg";
import ppLonnDark from "assets/branding/payoutpartner/popLonn_dark.svg";
import ppSymbol from "assets/branding/payoutpartner/symbol.svg";
import ppSymbolDark from "assets/branding/payoutpartner/symbol_dark.svg";

const isPayoutPartner = process.env.REACT_APP_BRAND === "payoutpartner";

export const logo = isPayoutPartner ? ppLogo : mmLogo;
export const icon = isPayoutPartner ? ppIcon : mmIcon;
export const symbol = isPayoutPartner ? ppSymbol : mmSymbol;
export const symbolDark = isPayoutPartner ? ppSymbolDark : mmSymbolDark;
export const lonn = isPayoutPartner ? ppLonn : mmLonn;
export const lonnDark = isPayoutPartner ? ppLonnDark : mmLonnDark;
export const firma = isPayoutPartner ? ppFirma : mmFirma;
export const firmaDark = isPayoutPartner ? ppFirmaDark : mmFirmaDark;
export const en3rdParty = isPayoutPartner ? pp3rdPartyEN : mm3rdPartyEN;
export const no3rdParty = isPayoutPartner ? pp3rdPartyNO : mm3rdPartyNO;
