import { PropsWithChildren, useState } from "react";
import toast from "react-hot-toast";

import { ErrorCard } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  id: string;
  onFileDrop: (file: File[]) => void;
  validFileTypes: string[];
  maxSize?: number;
  allowMultiple?: boolean;
}

export default ({
  id,
  onFileDrop,
  maxSize,
  validFileTypes,
  allowMultiple,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const [isOver, setIsOver] = useState(false);
  const { t } = useTranslation("common");

  const getValidFiles = (fileList: FileList): File[] => {
    const files = Array.from(fileList);

    const validatedFiles = files.reduce(
      (acc, file) => {
        if (maxSize && file.size > maxSize)
          return { ...acc, sizeError: acc.sizeError + 1 };

        const isValidVideo =
          validFileTypes.includes("video/*") && file.type.includes("video/");
        const isValidAudio =
          validFileTypes.includes("audio/*") && file.type.includes("audio/");
        const isValidOther = validFileTypes.includes(file.type);
        const isValid = isValidVideo || isValidAudio || isValidOther;
        if (!isValid) return { ...acc, typeError: acc.typeError + 1 };

        return { ...acc, files: [...acc.files, file] };
      },
      {
        files: [] as File[],
        typeError: 0,
        sizeError: 0,
      }
    );

    if (validatedFiles.typeError && validatedFiles.sizeError) {
      toast.custom((toastProps) => (
        <ErrorCard
          heading={t(
            "fileDrop.error.invalid.all",
            "The file(s) must be of the correct type and size."
          )}
          toastProps={toastProps}
        />
      ));
    } else if (validatedFiles.typeError) {
      toast.custom((toastProps) => (
        <ErrorCard
          heading={t(
            "fileDrop.error.invalid.type",
            "{{ amount }} file(s) did not meet the type requirements.",
            { amount: validatedFiles.typeError }
          )}
          toastProps={toastProps}
        />
      ));
    } else if (validatedFiles.sizeError) {
      toast.custom((toastProps) => (
        <ErrorCard
          heading={t(
            "fileDrop.error.invalid.size",
            "{{ amount }} file(s) did not meet the size requirements.",
            { amount: validatedFiles.sizeError }
          )}
          toastProps={toastProps}
        />
      ));
    }

    return validatedFiles.files;
  };

  const onDragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();

    setIsOver(true);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsOver(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsOver(false);

    const fileList = event.dataTransfer.files;

    const validFiles = getValidFiles(fileList);
    if (validFiles.length) onFileDrop(validFiles);
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fileList = event.target.files;
    if (!fileList) return;

    const validFiles = getValidFiles(fileList);
    if (validFiles.length) onFileDrop(validFiles);
  };

  const fileUploadContainerStyles = tw(
    "relative",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "w-full",
    "cursor-pointer"
  );

  const overlayBase = tw("absolute", "h-full", "w-full");
  const defaultOverlay = tw(
    "flex",
    "justify-center",
    "items-center",
    "p-3",
    "bg-success",
    "bg-opacity-40",
    "border-2",
    "border-dashed",
    "border-success",
    "rounded-md"
  );
  const overlayStyles = tw(overlayBase, "z-10", defaultOverlay);

  return (
    <label className={fileUploadContainerStyles} onDragEnter={onDragEnter}>
      {isOver && (
        <>
          <div
            className={tw(overlayBase, "z-20")}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          />

          <div className={overlayStyles}>
            <p>{t("fileDrop.overlayText", "Drop to add file")}</p>
          </div>
        </>
      )}
      <input
        id={id}
        type="file"
        accept={validFileTypes?.join(",")}
        multiple={allowMultiple}
        style={{ display: "none" }}
        onChange={onUpload}
      />

      {children}
    </label>
  );
};
