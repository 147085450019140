import React, { useContext } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
} from "components/common/basic";
import { BankAccountFragment } from "graphql/fragments";
import { useDestroyBankAccountMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  name: string;
  bankAccounts: BankAccountFragment[];
  isOrg: boolean;
}

export default ({ name, bankAccounts, isOrg }: Props): JSX.Element => {
  const { destroyBankAccount } = useDestroyBankAccountMutation();

  const { openPopup } = useContext(PopupContext);
  const { t } = useTranslation("account");

  return (
    <Card
      header={
        <CardHeader
          className={isOrg ? tw("bg-deepBlue-900") : tw("bg-blue-900")}
        >
          <h3 className={tw("text-lg", "font-bold", "text-white")}>
            {isOrg ? (
              <Trans
                ns="account"
                i18nKey="payoutSettings.bankAccounts.card_organization.header"
              >
                Organisation
              </Trans>
            ) : (
              <Trans
                ns="account"
                i18nKey="payoutSettings.bankAccounts.card_individual.header"
              >
                Individual
              </Trans>
            )}
          </h3>

          <div className={tw("mt-2", "w-1/2", "p-4", "bg-white", "rounded-lg")}>
            <p className={tw("text-sm", "text-gray-900")}>
              <Trans
                ns="account"
                i18nKey="payoutSettings.bankAccounts.card.payoutProfile.label"
              >
                Attached to payout profile
              </Trans>
            </p>

            <p className={tw("font-bold", "text-gray-900")}>{name}</p>
          </div>
        </CardHeader>
      }
    >
      <CardBody>
        {bankAccounts.map((bankAccount, index) => {
          const Row = ({ label, value }: { label: string; value: string }) => (
            <p className={tw("flex")}>
              <span
                className={tw(
                  "w-40",
                  "font-semibold",
                  "text-sm",
                  "text-deepBlue-900"
                )}
              >
                {label}
              </span>

              <span className={tw("text-sm", "text-deepBlue-700")}>
                {value}
              </span>
            </p>
          );

          return (
            <React.Fragment key={bankAccount.id}>
              {index > 0 && <Divider />}

              <div>
                <Row
                  label={t(
                    "payoutSettings.bankAccounts.card.accountNumber",
                    "Account nr."
                  )}
                  value={bankAccount.accountNumber}
                />

                <Row
                  label={t(
                    "payoutSettings.bankAccounts.card.currency",
                    "Currency"
                  )}
                  value={bankAccount.currency}
                />

                {bankAccount.swiftCode && (
                  <Row
                    label={t(
                      "payoutSettings.bankAccounts.card.swiftCode",
                      "BIC / Swift"
                    )}
                    value={bankAccount.swiftCode}
                  />
                )}
              </div>

              <div>
                <Button
                  id="profile_with_bank_accounts-delete_account"
                  variant="tertiary"
                  size="sm"
                  LeadingIcon={HiOutlineTrash}
                  onClick={() =>
                    openPopup({
                      id: "ConfirmAction",
                      props: {
                        onConfirmation: () =>
                          destroyBankAccount(bankAccount.id),
                        confirmationLabel: t(
                          "payoutSettings.bankAccounts.deleteConfirmation.label",
                          "Delete"
                        ),
                        confirmationHeading: t(
                          "payoutSettings.bankAccounts.deleteConfirmation.heading",
                          "Are you sure you want to delete this bank account?"
                        ),
                        confirmationBody: t(
                          "payoutSettings.bankAccounts.deleteConfirmation.body",
                          "This action is not reversible"
                        ),
                      },
                    })
                  }
                >
                  <Trans
                    ns="account"
                    i18nKey="payoutSettings.bankAccounts.card.deleteAccount"
                  >
                    Delete account
                  </Trans>
                </Button>
              </div>
            </React.Fragment>
          );
        })}
      </CardBody>
    </Card>
  );
};
