import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Input, Link } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { AccountFragment } from "graphql/fragments";
import { useCreateTeamAccountMutation } from "graphql/mutations/createTeamAccount";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import { Banner } from "../composite";

interface FormValues {
  name: string;
}

interface Props {
  hasPrevious: boolean;
  onSuccess?: (account: AccountFragment) => void;
}

export default ({ onSuccess, ...props }: Props): JSX.Element => {
  const { createTeamAccount } = useCreateTeamAccountMutation();

  const { closeAllPopups } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t("popup.addTeamAccount.name.error.required", "A name is required")
    ),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const name = watch("name");

  const onSubmit = ({ name }: FormValues) =>
    createTeamAccount({ name }, (teamAccount) => {
      onSuccess?.(teamAccount);

      closeAllPopups();
    });

  const formId = "addTeamAccountForm";

  return (
    <PopupWrapper
      {...props}
      title={t("popup.addTeamAccount.title", "Create a new team space")}
      variant="popup"
      footerActions={[
        {
          id: "add_team-add_team",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.addTeamAccount.submit", "Save"),
          disabled: !name,
        },
      ]}
    >
      <form
        className={tw("w-full", "space-y-4")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          id="name"
          {...register("name")}
          label={t("popup.addTeamAccount.form.name.label", "Team space name")}
          placeholder={t("popup.addTeamAccount.form.name.placeholder", "Name")}
          errorMessage={errors.name?.message}
        />
      </form>

      <div className={tw("py-4")}>
        <Banner
          title={t(
            "popup.addTeamAccount.infoCard.title",
            "Volunteer organisation?"
          )}
          body={
            <p className={tw("text-deepBlue-900", "text-sm")}>
              <Trans
                ns="common"
                i18nKey="popup.addTeamAccount.infoCard.message"
                defaults="If you represent a marching band, sports club and other volunteer organisation, please contact sales at <0>sales@manymore.com</0>"
                components={[
                  <Link
                    id="add_team-send_email"
                    color="deepBlue"
                    isExternal
                    to={t(
                      "popup.addTeamAccount.infoCard.link",
                      "mailto:sales@manymore.com"
                    )}
                  />,
                ]}
              />
            </p>
          }
        />
      </div>
    </PopupWrapper>
  );
};
