import { IconBaseProps } from "react-icons";

export default ({ size, ...props }: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0001 7.00439H17.1248V5.00439H22.0001V7.00439ZM22.0001 10.9976H17.1248V8.99756H22.0001V10.9976ZM6.87533 15.0006H2V13.0006H6.87533V15.0006ZM2 18.9955H6.87533V16.9955H2V18.9955ZM17.1248 18.9955H22.0001V16.9955H17.1248V18.9955ZM9.56311 15.0006H14.4384V13.0006H9.56311V15.0006ZM9.56311 10.9976H14.4384V8.99756H9.56311V10.9976ZM2 7.00439H6.87533V5.00439H2V7.00439Z"
      fill="currentColor"
    />
  </svg>
);
