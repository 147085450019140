import { RouteProps } from "react-router-dom";

import {
  CheckMoreWizard,
  CheckRequestWizard,
  CheckTypeSelection,
  Checks,
  OnboardingWizard,
} from "components/checkmore/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("checkmore","CHECK_WIZARD", ":id", ":step"), element: <CheckMoreWizard /> },
    { path: getRoutePath("checkmore","CHECK_WIZARD", ":id"), element: <CheckMoreWizard /> },
    { path: getRoutePath("checkmore","REQUEST_WIZARD", ":id", ":step"), element: <CheckRequestWizard /> },
    { path: getRoutePath("checkmore","REQUEST_WIZARD", ":id"), element: <CheckRequestWizard /> },
    { path: getRoutePath("checkmore","ONBOARDING_WIZARD", ":step"), element: <OnboardingWizard /> },
    { path: getRoutePath("checkmore","ONBOARDING_WIZARD"), element: <OnboardingWizard /> },
    { path: getRoutePath("checkmore","CHECK_TYPE_SELECTION"), element: <CheckTypeSelection /> },
    { path: getRoutePath("checkmore","CHECKS"), element: <Checks /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "checkmore", routes, loggedInRoutes, loggedOutRoutes };
};
