import { PropsWithChildren, ReactNode, useContext, useEffect } from "react";

import { PopoverContext } from "providers/PopoverHandler";

interface Props {
  id: string;
  content: ReactNode;
}

export default ({
  id,
  content,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const { registerPopover, unregisterPopover, setIsShownPopover } =
    useContext(PopoverContext);

  // Unregister popover on unmount
  useEffect(() => () => unregisterPopover(id), []);

  return (
    <div
      ref={(targetRef) =>
        registerPopover({
          id,
          variant: "tooltip",
          content,
          targetRef,
          placement: "bottom",
        })
      }
      onMouseEnter={() => setIsShownPopover(id, true)}
      onMouseLeave={() => setIsShownPopover(id, false)}
    >
      {children}
    </div>
  );
};
