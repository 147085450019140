import { TFunction } from "i18next";

import { WbReportCategoryEnum } from "graphql/types";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";

export default (category: WbReportCategoryEnum | null, t: TFunction): string =>
  category
    ? t(
        `tellmore:wbWizard.incident.form.category.option_${snakeCaseToCamelCase(
          category
        )}`,
        snakeCaseToSeparated(category)
      )
    : t("tellmore:wbWizard.incident.form.category.option_noCategory", "None");
