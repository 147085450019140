/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  CHECK_LIST_FRAGMENT,
  CheckListFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CHECK_REQUEST, CheckRequestQuery } from "graphql/queries";

const DESTROY_CHECK_LIST = gql`
  mutation ($id: ID!) {
    destroyCheckList(input: { id: $id }) {
      checkList {
        ...CheckListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkList: CheckListFragment;
  errors: ErrorFragment[];
}

interface DestroyCheckListMutation {
  destroyCheckList: Payload | null;
}

export const useDestroyCheckListMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    DestroyCheckListMutation,
    { id: string }
  >(DESTROY_CHECK_LIST);

  const destroyCheckList = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            node() {
              const checkRequest = cache.readQuery<CheckRequestQuery>({
                query: CHECK_REQUEST,
              });
              if (!checkRequest) return;

              const checkLists = checkRequest.node.checkLists.filter(
                (checkList) => checkList.id !== id
              );

              cache.writeQuery<CheckRequestQuery>({
                query: CHECK_REQUEST,
                data: {
                  node: {
                    ...checkRequest.node,
                    checkLists,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data?.destroyCheckList?.checkList && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyCheckList,
    isLoading: loading,
    checkList: data?.destroyCheckList?.checkList,
  };
};
