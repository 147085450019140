/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ENROLMENT_FRAGMENT,
  ERROR_FRAGMENT,
  EnrolmentFragment,
  ErrorFragment,
} from "graphql/fragments";

const SET_ENROLMENT = gql`
  mutation ($enrolmentID: ID!) {
    approveEnrollment(input: { id: $enrolmentID }) {
      enrollment {
        ...EnrolmentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ENROLMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  enrollment?: EnrolmentFragment | null;
  errors: ErrorFragment[];
}

interface ApproveEnrolmentMutation {
  approveEnrollment: Payload | null;
}

export const useApproveEnrolmentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.approveEnrolment",
    "The enrolment has been approved"
  );
  // Values for extraction
  // t("common:success.approveEnrolment", "The enrolment has been approved")

  const [mutation, { data, loading, called }] = useMutation<
    ApproveEnrolmentMutation,
    { enrolmentID: string }
  >(SET_ENROLMENT);

  const approveEnrolment = (
    enrolmentID: string,
    onSuccess?: (response: EnrolmentFragment) => void
  ) =>
    mutation({ variables: { enrolmentID } })
      .then(({ data, errors }) => {
        const dataErrors = data?.approveEnrollment?.errors ?? [];
        const enrolment = data?.approveEnrollment?.enrollment;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => enrolment && onSuccess && onSuccess(enrolment),
          onRetry: () => approveEnrolment(enrolmentID, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    approveEnrolment,
    isLoading: loading,
    called,
    enrolment: data?.approveEnrollment?.enrollment,
    errors: data?.approveEnrollment?.errors ?? [],
  };
};
