import { useTranslation } from "translations";

enum BulkVariant {
  One = "ONE",
  Some = "SOME",
  All = "ALL",
}

export default (amount: number) => {
  const variant = ((amount) => {
    switch (amount) {
      case 0:
        return BulkVariant.All;
      case 1:
        return BulkVariant.One;
      default:
        return BulkVariant.Some;
    }
  })(amount);

  const { t } = useTranslation("common");

  const getTitle = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.cancelDocuments_one.heading",
          "Are you sure you want to cancel this document?"
        );

      case BulkVariant.Some:
        return t(
          "popup.cancelDocuments_some.heading",
          "Are you sure you want to cancel these documents?"
        );

      case BulkVariant.All:
        return t(
          "popup.cancelDocuments_all.heading",
          "Are you sure you want to cancel all documents?"
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  const getSubTitle = () => {
    switch (variant) {
      case BulkVariant.One:
        return t(
          "popup.cancelDocuments_one.body",
          "Please write a reason to the people who you sent the document to. We will send them an email with your reason."
        );

      case BulkVariant.Some:
        return t(
          "popup.cancelDocuments_some.body",
          "Please write a reason to the people who you sent these documents to. We will send them an email with your reason."
        );

      case BulkVariant.All:
        return t(
          "popup.cancelDocuments_all.body",
          "Please write a reason to the people who you sent these documents to. We will send them an email with your reason."
        );

      default:
        return ((_variant: never) => "")(variant);
    }
  };

  return {
    title: getTitle(),
    subTitle: getSubTitle(),
  };
};
