import { useEffect, useState } from "react";

import { Spinner } from "components/common/basic";
import PageNav from "components/common/basic/PageNav";
import {
  DepartmentsFilterSingle,
  FilterBar,
  FilterTypeEnum,
  FilterValues,
} from "components/common/composite";
import { useMembersLazyQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import MembersTable from "./MembersTable";

enum ConfirmedFilterEnum {
  All = "SHOW_ALL",
  Active = "SHOW_ACTIVE",
  Invited = "SHOW_INVITED",
}

export default (): JSX.Element | null => {
  const perPage = 25;
  const [page, setPage] = useState(1);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    search: "",
  });
  const idSeparator = "::";

  const { members, getMembers, isLoading, total } = useMembersLazyQuery();

  const { t } = useTranslation("account");

  const fetchAndSetPage = (p: number) =>
    getMembers({
      page: p,
      perPage,
      filter: {
        confirmed: (() => {
          switch (filterValues.status) {
            case ConfirmedFilterEnum.Active:
              return true;

            case ConfirmedFilterEnum.Invited:
              return false;

            default:
              return undefined;
          }
        })(),
        departmentId: filterValues.departments?.split(idSeparator)[0],
        search: filterValues.search,
      },
    }).then(() => setPage(p));

  useEffect(() => {
    fetchAndSetPage(1);
  }, [filterValues]);

  return (
    <div>
      <FilterBar
        filters={[
          {
            type: FilterTypeEnum.text,
            name: "search",
            label: t(
              "accountSettings_org.team.members.filter.search.label",
              "Search"
            ),
            placeholder: t(
              "accountSettings_org.team.members.filter.name.placeholder",
              "Member name"
            ),
          },
          {
            type: FilterTypeEnum.custom,
            name: "departments",
            label: t(
              "accountSettings_org.team.members.filter.departments.label",
              "Departments"
            ),
            CustomFilter: (props) => (
              <DepartmentsFilterSingle {...props} idSeparator={idSeparator} />
            ),
          },
          {
            type: FilterTypeEnum.select,
            name: "status",
            label: t(
              "accountSettings_org.team.members.filter.status.label",
              "Status"
            ),
            options: [
              {
                label: t(
                  "accountSettings_org.team.members.filter.status.option.all",
                  "Any status"
                ),
                value: ConfirmedFilterEnum.All,
                key: ConfirmedFilterEnum.All,
              },
              {
                label: t(
                  "accountSettings_org.team.members.filter.status.option.active",
                  "Active"
                ),
                value: ConfirmedFilterEnum.Active,
                key: ConfirmedFilterEnum.Active,
              },
              {
                label: t(
                  "accountSettings_org.team.members.filter.status.option.invited",
                  "Invited"
                ),
                value: ConfirmedFilterEnum.Invited,
                key: ConfirmedFilterEnum.Invited,
              },
            ],
          },
        ]}
        defaultValues={filterValues}
        onChange={setFilterValues}
      />

      {isLoading ? (
        <Spinner />
      ) : (
        <MembersTable
          members={members}
          footer={
            total > perPage ? (
              <div className={tw("px-6")}>
                <PageNav
                  currentPage={page}
                  totalPages={Math.ceil(total / perPage)}
                  onPageChange={fetchAndSetPage}
                />
              </div>
            ) : undefined
          }
        />
      )}
    </div>
  );
};
