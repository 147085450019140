/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";

const APPROVE_JOB_APPLICATION = gql`
  mutation ($jobApplicationID: ID!) {
    approveJobApplication(input: { id: $jobApplicationID }) {
      jobApplication {
        ...JobApplicationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
  errors: ErrorFragment[];
}

interface ApproveJobApplicationMutation {
  approveJobApplication: Payload | null;
}

export const useApproveJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.approveJobApplication",
    "The job application has been approved"
  );
  // Values for extraction
  // t("common:success.approveJobApplication",  "The job application has been approved")

  const [mutation, { data, loading, called }] = useMutation<
    ApproveJobApplicationMutation,
    { jobApplicationID: string }
  >(APPROVE_JOB_APPLICATION);

  const approveJobApplication = (
    jobApplicationID: string,
    onSuccess?: (response: JobApplicationFragment) => void
  ) =>
    mutation({ variables: { jobApplicationID } })
      .then(({ data, errors }) => {
        const dataErrors = data?.approveJobApplication?.errors ?? [];
        const jobApplication = data?.approveJobApplication?.jobApplication;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            jobApplication && onSuccess && onSuccess(jobApplication),
          onRetry: () => approveJobApplication(jobApplicationID, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    approveJobApplication,
    isLoading: loading,
    called,
    jobApplication: data?.approveJobApplication?.jobApplication,
    errors: data?.approveJobApplication?.errors ?? [],
  };
};
