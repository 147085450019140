/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  BENEFIT_LINE_FRAGMENT,
  DIET_LINE_FRAGMENT,
  DependentLine,
  ERROR_FRAGMENT,
  EXPENSE_LINE_FRAGMENT,
  EXTRA_LINE_FRAGMENT,
  ErrorFragment,
  InvoiceLineFragment,
  MILEAGE_LINE_FRAGMENT,
  WORK_LINE_FRAGMENT,
  WorkLine,
} from "graphql/fragments";
import { INVOICE, InvoiceQuery } from "graphql/queries";
import { UpdateInvoiceLineAttributes } from "graphql/types";

const UPDATE_INVOICE_LINE = gql`
  mutation ($id: ID!, $attributes: UpdateInvoiceLineAttributes!) {
    updateInvoiceLine(input: { id: $id, attributes: $attributes }) {
      invoiceLine {
        ...BenefitLineFragment
        ...DietLineFragment
        ...ExpenseLineFragment
        ...ExtraLineFragment
        ...MileageLineFragment
        ...WorkLineFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BENEFIT_LINE_FRAGMENT}
  ${DIET_LINE_FRAGMENT}
  ${EXPENSE_LINE_FRAGMENT}
  ${EXTRA_LINE_FRAGMENT}
  ${MILEAGE_LINE_FRAGMENT}
  ${WORK_LINE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoiceLine?: InvoiceLineFragment | null;
  errors: ErrorFragment[];
}

interface UpdateInvoiceLineMutation {
  updateInvoiceLine: Payload | null;
}

export const useUpdateInvoiceLineMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateInvoiceLineMutation,
    { id: string; attributes: UpdateInvoiceLineAttributes }
  >(UPDATE_INVOICE_LINE);

  const updateInvoiceLine = (
    id: string,
    attributes: UpdateInvoiceLineAttributes,
    onSuccess?: () => void
  ) =>
    mutation({
      variables: { id, attributes },
      update(cache, { data: invoiceLineData }) {
        const invoiceLineNode = invoiceLineData?.updateInvoiceLine?.invoiceLine;
        if (!invoiceLineNode) return;

        cache.modify({
          fields: {
            node() {
              const invoice = cache.readQuery<InvoiceQuery>({
                query: INVOICE,
              });
              if (!invoice) return;

              const existingLines = invoice.node.lines;
              if (!existingLines) return;

              const lines = ((): WorkLine[] => {
                const updatedDependentLine = invoiceLineNode as DependentLine;

                if (attributes.workInvoiceLineId) {
                  const workLine = invoice.node.lines.find(
                    (line) => line.id === attributes.workInvoiceLineId
                  );
                  if (!workLine) return existingLines;

                  const dependentLineIndex = workLine.dependentLines.findIndex(
                    (line) => line.id === id
                  );
                  if (dependentLineIndex === -1) return existingLines;

                  return [
                    ...existingLines,
                    {
                      ...workLine,
                      dependentLines: [
                        ...workLine.dependentLines.slice(0, dependentLineIndex),
                        updatedDependentLine,
                        ...workLine.dependentLines.slice(
                          dependentLineIndex + 1,
                          workLine.dependentLines.length
                        ),
                      ],
                    },
                  ];
                }

                const updatedWorkLine = invoiceLineNode as WorkLine;
                const workLineIndex = invoice.node.lines.findIndex(
                  (line) => line.id === id
                );
                if (workLineIndex === -1) return existingLines;

                return [
                  ...existingLines.slice(0, workLineIndex),
                  updatedWorkLine,
                  ...existingLines.slice(
                    workLineIndex + 1,
                    existingLines.length
                  ),
                ];
              })();

              cache.writeQuery<InvoiceQuery>({
                query: INVOICE,
                data: {
                  node: {
                    ...invoice.node,
                    lines,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateInvoiceLine?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateInvoiceLine,
    isLoading: loading,
    invoiceLine: data?.updateInvoiceLine?.invoiceLine,
    errors: data?.updateInvoiceLine?.errors ?? [],
  };
};
