import { HiPlus } from "react-icons/hi";

import { ActionID, Button, Link } from "components/common/basic";
import { tw } from "utils/tw";

interface Action {
  type: "click" | "link";
  actionID: ActionID;
}

interface ClickAction extends Action {
  type: "click";
  onClick: () => void;
}

interface LinkAction extends Action {
  type: "link";
  to: string;
  isExternal?: boolean;
}

interface Props {
  header: string;
  action?: ClickAction | LinkAction;
  actionLabel?: string;
}

export default ({ header, action, actionLabel }: Props): JSX.Element => (
  <div
    className={tw(
      "flex",
      "space-y-4",
      "flex-col",
      "items-start",
      "sm:space-y-0",
      "sm:flex-row",
      "sm:justify-between"
    )}
  >
    <h1 className={tw("text-2xl", "font-extrabold")}>{header}</h1>

    {action && (
      <>
        {action.type === "link" ? (
          <Link
            id={action.actionID}
            to={action.to}
            isExternal={action.isExternal}
            unstyled
          >
            <Button id={action.actionID} size="md" LeadingIcon={HiPlus}>
              {actionLabel}
            </Button>
          </Link>
        ) : (
          <Button
            id={action.actionID}
            size="md"
            onClick={action.onClick}
            LeadingIcon={HiPlus}
          >
            {actionLabel}
          </Button>
        )}
      </>
    )}
  </div>
);
