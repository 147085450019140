import { Spinner } from "components/common/basic";
import { Document } from "components/documents/composite";
import { useDocumentQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";

import { PopupVariant, PopupWrapper } from "../PopupWrapper";

interface Props {
  documentId: string;
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default ({ documentId, ...props }: Props) => {
  const { document } = useDocumentQuery(documentId);
  const { t } = useTranslation("documents");

  const getRoutePath = useRoutePaths();

  return (
    <PopupWrapper
      {...props}
      nameSpace="documents"
      title={t("documentDrawer.title", "Document")}
      headerActions={[
        {
          id: "document_drawer-go_to_document",
          to: getRoutePath({
            module: "signmore",
            routeName: "DOCUMENT",
            arg1: documentId,
          }),
          isExternal: true,
          icon: "HiOutlineArrowsExpand",
        },
      ]}
    >
      {document ? <Document document={document} /> : <Spinner />}
    </PopupWrapper>
  );
};
