/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { UpdateAccountInput } from "graphql/types";

const UPDATE_ACCOUNT = gql`
  mutation ($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface UpdateAccountMutation {
  updateAccount: Payload | null;
}

export const useUpdateAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateAccountMutation,
    { input: UpdateAccountInput }
  >(UPDATE_ACCOUNT);

  const updateAccount = (
    input: UpdateAccountInput,
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateAccount?.errors ?? [];
        const account = data?.updateAccount?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => account && onSuccess && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateAccount,
    isLoading: loading,
    account: data?.updateAccount?.account,
    errors: data?.updateAccount?.errors ?? [],
  };
};

export const useSilentUpdateAccountMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    UpdateAccountMutation,
    { input: UpdateAccountInput }
  >(UPDATE_ACCOUNT);

  const updateAccount = (input: UpdateAccountInput) =>
    mutation({ variables: { input } });

  return {
    updateAccount,
    isLoading: loading,
    account: data?.updateAccount?.account,
    errors: data?.updateAccount?.errors ?? [],
  };
};
