import { gql } from "@apollo/client";

import { SalaryReport } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const SALARY_REPORT_FRAGMENT = gql`
  fragment SalaryReportFragment on SalaryReport {
    id
    payoutAmount
    pdfDocument {
      ...AttachmentFragment
    }
    salaryTaxAmount
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface SalaryReportFragment
  extends Pick<
    SalaryReport,
    "id" | "payoutAmount" | "salaryTaxAmount" | "year"
  > {
  pdfDocument?: AttachmentFragment;
}
