/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_REPORT_FRAGMENT,
  WbReportFragment,
} from "graphql/fragments";
import { WbReportAttributes } from "graphql/types";

const UPDATE_WB_REPORT = gql`
  mutation ($id: ID!, $attributes: WbReportAttributes!) {
    updateWbReport(input: { id: $id, attributes: $attributes }) {
      wbReport {
        ...WbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_REPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbReport?: WbReportFragment | null;
  errors: ErrorFragment[];
}

interface UpdateWbReportMutation {
  updateWbReport: Payload | null;
}

export const useUpdateWbReportMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateWbReportMutation,
    { id: string; attributes: WbReportAttributes }
  >(UPDATE_WB_REPORT);

  const updateWbReport = (
    id: string,
    attributes: WbReportAttributes,
    onSuccess?: (wbReport: WbReportFragment) => void
  ) =>
    mutation({ variables: { id, attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateWbReport?.errors ?? [];
        const wbReport = data?.updateWbReport?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateWbReport,
    isLoading: loading,
    wbReport: data?.updateWbReport?.wbReport,
    errors: data?.updateWbReport?.errors ?? [],
  };
};
