import { useContext, useEffect, useState } from "react";
import { HiPlus, HiSearch } from "react-icons/hi";

import { Button } from "components/common/basic";
import { DepartmentFragment } from "graphql/fragments";
import { useDepartmentsLazyQuery } from "graphql/queries";
import { PopupContext } from "providers/PopupHandler";
import { Trans } from "translations";
import { tw } from "utils/tw";

import SingleSearch from "../SingleSearch";

interface Props {
  onSelect: (departmentID?: string) => void;
  label?: string;
  errorMessage?: string;
}

export default ({ onSelect, label, errorMessage }: Props): JSX.Element => {
  const perPage = 10_000;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [department, setDepartment] = useState<DepartmentFragment>();
  const { departments, getDepartments, total, isLoading } =
    useDepartmentsLazyQuery();
  const { openPopup } = useContext(PopupContext);

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getDepartments({
      page: p,
      perPage,
      filter: { search: searchInput ?? keyword },
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  useEffect(() => {
    fetchAndSetPage(1);
  }, []);

  const buttonLabel = (
    <>
      <span className={tw("mr-2")}>
        {department ? (
          department.name
        ) : (
          <Trans ns="common" i18nKey="searchDepartment.label">
            Search by department name
          </Trans>
        )}
      </span>

      <HiSearch size={20} />
    </>
  );

  return (
    <SingleSearch<DepartmentFragment>
      id="department-search"
      itemKey="id"
      items={departments}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setDepartment(option);
        onSelect(option.id);
      }}
      buttonLabel={buttonLabel}
      label={label}
      emptyState={emptyState}
      isLoading={isLoading}
      errorMessage={errorMessage}
      footer={
        <div className={tw("py-2", "px-4")}>
          <Button
            id="department_search-add_new_department"
            onClick={() =>
              openPopup({
                id: "Department",
                props: {
                  onSuccess: (newDepartment) => {
                    onSelect(newDepartment.id);
                  },
                },
              })
            }
            variant="tertiary"
            fullWidth
            LeadingIcon={HiPlus}
          >
            <Trans ns="common" i18nKey="searchDepartment.button.addClient">
              Add a new department
            </Trans>
          </Button>
        </div>
      }
      fixedHeight="h-108"
    />
  );
};

const emptyState = (
  <div className={tw("text-center", "py-20", "px-4", "bg-white", "h-full")}>
    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchDepartment_empty.heading">
        You have not added any departments yet.
      </Trans>
    </p>

    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchDepartment_empty.body">
        Please use the add button to add a department.
      </Trans>
    </p>
  </div>
);

const renderListItemLabel = (department: DepartmentFragment) => (
  <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
    {department.name}
  </p>
);
