/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { ValidatePasswordResetTokenInput } from "graphql/types";

const VALIDATE_PASSWORD_RESET_TOKEN = gql`
  mutation ($input: ValidatePasswordResetTokenInput!) {
    validatePasswordResetToken(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface ValidatePasswordResetTokenMutation {
  validatePasswordResetToken: Payload | null;
}

export const useValidatePasswordResetTokenMutation = (
  onSuccess?: (input: ValidatePasswordResetTokenInput) => void
) => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    ValidatePasswordResetTokenMutation,
    { input: ValidatePasswordResetTokenInput }
  >(VALIDATE_PASSWORD_RESET_TOKEN);

  const validatePasswordResetToken = (input: ValidatePasswordResetTokenInput) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.validatePasswordResetToken?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(input),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    validatePasswordResetToken,
    isLoading: loading,
    errors: data?.validatePasswordResetToken?.errors ?? [],
  };
};
