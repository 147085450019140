import { TFunction } from "i18next";

import { CheckTypeEnum } from "graphql/types";

export const getTitleFromCheckRequestType = (
  type: CheckTypeEnum,
  t: TFunction,
  isShortVersion = false
): string => {
  switch (type) {
    case CheckTypeEnum.CriminalRecord:
      return isShortVersion
        ? t(
            "common:checkRequestType_criminalRecord.title_short",
            "Criminal record"
          )
        : t(
            "common:checkRequestType_criminalRecord.title",
            "Norwegian Criminal record certificate"
          );
    case CheckTypeEnum.BankId:
      return isShortVersion
        ? t("common:checkRequestType_bankID.title_short", "BankID verification")
        : t(
            "common:checkRequestType_bankID.title",
            "Norwegian BankID verification"
          );
    case CheckTypeEnum.Identity:
      return isShortVersion
        ? t(
            "common:checkRequestType_identity.title_short",
            "Identity verification"
          )
        : t(
            "common:checkRequestType_identity.title",
            "Nordic identity verification"
          );
    case CheckTypeEnum.DrivingLicense:
      return isShortVersion
        ? t(
            "common:checkRequestType_driversLicense.title_short",
            "Driving licence"
          )
        : t(
            "common:checkRequestType_driversLicense.title",
            "Norwegian driving licence (car)"
          );
    case CheckTypeEnum.BankAccount:
      return isShortVersion
        ? t("common:checkRequestType_bankAccount.title_short", "Bank account")
        : t(
            "common:checkRequestType_bankAccount.title",
            "Norwegian bank account"
          );
    case CheckTypeEnum.Passport:
      return isShortVersion
        ? t("common:checkRequestType_passport.title_short", "Passport")
        : t("common:checkRequestType_passport.title", "Passport check");

    case CheckTypeEnum.RightToWork:
      return isShortVersion
        ? t("common:checkRequestType_rightToWork.title_short", "Right to work")
        : t(
            "common:checkRequestType_rightToWork.title",
            "Norwegian Residence permit / Right to work"
          );
    case CheckTypeEnum.HseCard:
      return isShortVersion
        ? t("common:checkRequestType_hseCard.title_short", "HSE card")
        : t(
            "common:checkRequestType_hseCard.title",
            "Norwegian HSE (Health, safety and environment) card"
          );
    case CheckTypeEnum.HealthPersonnelRegister:
      return isShortVersion
        ? t("common:checkRequestType_hpr.title_short", "Helsepersonellregister")
        : t(
            "common:checkRequestType_hpr.title",
            "Norwegian Helsepersonellregister"
          );

    case CheckTypeEnum.Information:
      return isShortVersion
        ? t(
            "common:checkRequestType_documentConfirmation.title_short",
            "DocuCheck"
          )
        : t(
            "common:checkRequestType_documentConfirmation.title",
            "DocuCheck: read confirmation of documents"
          );
    case CheckTypeEnum.ELearning:
      return isShortVersion
        ? t("common:checkRequestType_eLearning.title_short", "ELearning")
        : t("common:checkRequestType_eLearning.title", "ELearning");

    default:
      return ((_type: never) => "")(type);
  }
};

export const getTitleFromCheckType = (
  type: CheckTypeEnum,
  t: TFunction
): string => {
  switch (type) {
    case CheckTypeEnum.BankId:
      return t(
        "common:checkType_bankID.title",
        "Norwegian identity verification"
      );
    case CheckTypeEnum.Identity:
      return t("common:checkType_identity.title", "Identity verification");
    case CheckTypeEnum.DrivingLicense:
      return t(
        "common:checkType_driversLicense.title",
        "Norwegian driving licence (car)"
      );
    case CheckTypeEnum.Information:
      return t(
        "common:checkType_documentConfirmation.title",
        "Policy Document"
      );
    case CheckTypeEnum.BankAccount:
      return t("common:checkType_bankAccount.title", "Norwegian bank account");
    case CheckTypeEnum.Passport:
      return t("common:checkType_passport.title", "Passport check");
    case CheckTypeEnum.CriminalRecord:
      return t(
        "common:checkType_criminalRecord.title",
        "Criminal record certificate"
      );
    case CheckTypeEnum.RightToWork:
      return t(
        "common:checkType_rightToWork.title",
        "Norwegian Residence permit / Right to work"
      );
    case CheckTypeEnum.HseCard:
      return t(
        "common:checkType_hseCard.title",
        "Norwegian HSE (Health, safety and environment) card"
      );
    case CheckTypeEnum.HealthPersonnelRegister:
      return t(
        "common:checkType_hpr.title",
        "Norwegian Helsepersonellregister"
      );
    case CheckTypeEnum.ELearning:
      return t("common:checkType_eLearning.title", "ELearning");

    default:
      return ((_type: never) => "")(type);
  }
};
