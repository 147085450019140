import { Divider } from "components/common/basic";
import { CheckListFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { getTitleFromCheckRequestType } from "utils";
import { atDateToMoment } from "utils";
import { tw } from "utils/tw";

interface Props {
  checkList: CheckListFragment;
}

export default ({ checkList }: Props) => {
  const { t } = useTranslation("checkmore");

  return (
    <div className={tw("p-4", "bg-silver", "flex", "flex-col", "space-y-4")}>
      <div className={tw("flex", "justify-between")}>
        <h4 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
          {t("checkResultpopup.request.heading", "Request")}
        </h4>

        <p className={tw("text-deepBlue-500")}>
          {t(
            "checkResultpopup.request.requestedAt",
            "Sent: {{ requestedAt }}",
            {
              requestedAt: atDateToMoment(checkList.requestedAt).format(
                "DD MMM YYYY - HH:mm"
              ),
            }
          )}
        </p>
      </div>

      <Divider />

      <div className={tw("space-y-2", "text-deepBlue-500")}>
        <p>{checkList.name}</p>

        <p>{checkList.email}</p>

        <p>
          {t("checkResultpopup.request.checkType", "Check: {{ type }}", {
            type: getTitleFromCheckRequestType(checkList.checkRequest.type, t),
          })}
        </p>

        {checkList.lastContactedAt && (
          <p>
            {t(
              "checkResultpopup.request.lastContacted",
              "Last contacted: {{ lastContactedAt }}",
              {
                lastContactedAt: atDateToMoment(
                  checkList.lastContactedAt
                ).format("DD MMM YYYY - HH:mm"),
              }
            )}
          </p>
        )}
      </div>
    </div>
  );
};
