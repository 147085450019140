import { Icon, Link } from "components/common/basic";
import { WbMessageFragment } from "graphql/fragments";
import { WbMessageTypeEnum, WbReportTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { atDateToMoment } from "utils";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";
import { tw } from "utils/tw";

import { getWbReportCategoryString } from "../utils";

interface Props {
  message: WbMessageFragment;
  sender: string;
  isHandler: boolean;
}

export default ({ message, sender, isHandler }: Props): JSX.Element => {
  const { t } = useTranslation("tellmore");

  const getReportTypeString = (type: WbReportTypeEnum) =>
    t(
      `wbReportDetails.message_typeChange.heading.value.${snakeCaseToCamelCase(
        type
      )}`,
      snakeCaseToSeparated(type)
    );

  const heading = (() => {
    switch (message.type) {
      case WbMessageTypeEnum.ReportClosed:
        return t(
          "wbReportDetails.message_reportClosed.heading",
          "The report has been closed"
        );

      case WbMessageTypeEnum.ReportReopened:
        return t(
          "wbReportDetails.message_reportReopened.heading",
          "The report has been reopened"
        );

      case WbMessageTypeEnum.ReportDeleted:
        return t(
          "wbReportDetails.message_reportDeleted.heading",
          "Deleted report"
        );

      case WbMessageTypeEnum.TypeChanged:
        return t(
          "wbReportDetails.message_typeChange.heading",
          "Type changed from {{ oldType }} to {{ newType }}",
          {
            oldType: message.oldType && getReportTypeString(message.oldType),
            newType: message.newType && getReportTypeString(message.newType),
          }
        );

      case WbMessageTypeEnum.CategoryChanged:
        return t(
          "wbReportDetails.message_categoryChange.heading",
          "Category changed from {{ oldCategory }} to {{ newCategory }}",
          {
            oldCategory: getWbReportCategoryString(
              message.oldCategory ?? null,
              t
            ),
            newCategory: getWbReportCategoryString(
              message.newCategory ?? null,
              t
            ),
          }
        );

      case WbMessageTypeEnum.HandlersChanged:
        return (
          t(
            "wbReportDetails.message_handlersChange.heading",
            "Report handler changes:"
          ) +
          (message.addedHandlers.length > 0
            ? t(
                "wbReportDetails.message_handlersChange.heading.added",
                "\nAdded: {{ added }}",
                {
                  added: message.addedHandlers
                    .map(({ name }) => name)
                    .join(", "),
                }
              )
            : "") +
          (message.removedHandlers.length > 0
            ? t(
                "wbReportDetails.message_handlersChange.heading.removed",
                "\nRemoved: {{ removed }}",
                {
                  removed: message.removedHandlers
                    .map(({ name }) => name)
                    .join(", "),
                }
              )
            : "")
        );

      case WbMessageTypeEnum.Message:
      default:
        return message.internal
          ? t("wbReportDetails.message_internal.heading", "Internal note")
          : t("wbReportDetails.message.heading", "Message");
    }
  })();

  const icon = (() => {
    switch (message.type) {
      case WbMessageTypeEnum.ReportClosed:
        return "HiXCircle";
      case WbMessageTypeEnum.ReportReopened:
        return "HiCheckCircle";
      case WbMessageTypeEnum.ReportDeleted:
        return "HiExclamation";
      case WbMessageTypeEnum.TypeChanged:
      case WbMessageTypeEnum.CategoryChanged:
      case WbMessageTypeEnum.HandlersChanged:
        return "HiRefresh";

      case WbMessageTypeEnum.Message:
      default:
        return "HiAnnotation";
    }
  })();

  return (
    <div
      className={tw("flex", "space-x-4", "items-start", {
        [tw("p-4", "rounded-lg")]:
          message.type !== WbMessageTypeEnum.Message || message.internal,
        "bg-warning-light": message.internal,
        "bg-blue-100": [
          WbMessageTypeEnum.ReportClosed,
          WbMessageTypeEnum.ReportReopened,
        ].includes(message.type),
        "bg-badge-error": message.type === WbMessageTypeEnum.ReportDeleted,
        [tw("border", "border-deepBlue-100")]: [
          WbMessageTypeEnum.TypeChanged,
          WbMessageTypeEnum.CategoryChanged,
          WbMessageTypeEnum.HandlersChanged,
        ].includes(message.type),
      })}
    >
      <Icon
        icon={icon}
        size={16}
        margin="my-1"
        color={
          message.type === WbMessageTypeEnum.ReportDeleted
            ? "text-error"
            : "text-deepBlue-500"
        }
        isFilled
      />

      <div className={tw("flex-1", "space-y-4")}>
        <div className={tw("space-y-1")}>
          <div className={tw("flex", "justify-between")}>
            <h3
              className={tw(
                "text-deepBlue-900",
                "font-semibold",
                "whitespace-pre-wrap"
              )}
            >
              {heading}
            </h3>

            <p className={tw("text-xs", "text-deepBlue-500", "shrink-0")}>
              {t("wbReportDetails.message.submittedAt", "{{ date }}", {
                date: atDateToMoment(message.sentAt).format(
                  "DD MMM YYYY @ HH:mm"
                ),
              })}
            </p>
          </div>

          <div className={tw("flex", "justify-between")}>
            <p className={tw("text-sm", "text-deepBlue-500")}>
              {message.type === WbMessageTypeEnum.Message
                ? t("wbReportDetails.message.sender", "From: {{ sender }}", {
                    sender,
                  })
                : t("wbReportDetails.message_system.actor", "By: {{ actor }}", {
                    actor: sender,
                  })}
            </p>

            {isHandler && !message.internal && message.seen && (
              <p className={tw("text-xs", "text-deepBlue-500")}>
                {t(
                  "wbReportDetails.message.seenByWhistleblower",
                  "Seen by whistleblower"
                )}
              </p>
            )}
          </div>
        </div>

        {message.content && (
          <p
            className={tw(
              "text-deepBlue-700",
              "text-sm",
              "whitespace-pre-wrap",
              "break-all"
            )}
          >
            {message.content}
          </p>
        )}

        {message.files.length > 0 && (
          <div>
            <h4 className={tw("text-deepBlue-700", "text-sm")}>
              {t("wbReportDetails.message.attachments", "Attachments:")}
            </h4>

            <ul className={tw("space-y-1")}>
              {message.files.map((file) => (
                <li key={file.id}>
                  <Link
                    id="wb_wizard-download_file"
                    isExternal
                    to={process.env.REACT_APP_API_URL + file.downloadPath}
                    color="deepBlue"
                    className={tw("text-sm")}
                  >
                    {file.filename}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
