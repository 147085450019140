import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "components/common/basic";
import { Banner } from "components/common/composite";
import { useWbAccountLazyQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { storage, useRoutePaths } from "utils";
import { tw } from "utils/tw";

export default (): JSX.Element => {
  const { wbSlug = "" } = useParams<{
    wbSlug: string;
  }>();
  const { wbAccount, getWbAccount, isLoading, called } =
    useWbAccountLazyQuery();

  const { t } = useTranslation("tellmore");
  const getRoutePath = useRoutePaths();

  // Remove expired or invalid sessions
  useEffect(() => {
    storage.removeSession("wbReport");
  }, []);

  // Get whistleblowing team account
  useEffect(() => {
    if (!wbSlug) return;

    getWbAccount({ wbSlug });
  }, [wbSlug]);

  const wbLoginRoute = getRoutePath(
    {
      module: "tellmore",
      routeName: "WB_LOGIN",
      arg1: wbSlug,
    },
    { isGeneric: true }
  );

  if (!called || isLoading) return <Spinner />;

  return (
    <div className={tw("mx-auto", "max-w-screen-lg", "w-full", "space-y-12")}>
      {wbAccount?.logo && (
        <img
          src={`${process.env.REACT_APP_API_URL}${wbAccount.logo.thumbPath}`}
          className={tw(
            "align-middle",
            "border-none",
            "max-h-20",
            "object-contain"
          )}
        />
      )}

      <div className={tw("space-y-6")}>
        <h1 className={tw("text-2xl", "font-extrabold", "text-gray-900")}>
          {t("publicWbReport_emptyState.heading", "Whistleblowing")}
        </h1>

        <Banner
          variant="info"
          title={t(
            "publicWbReport_emptyState.banner.heading",
            "Invalid whistleblowing session"
          )}
          body={t(
            "publicWbReport_emptyState.banner.body",
            "Your session has expired. Please use the link below to return to the team's whistleblower login page."
          )}
          actions={[
            {
              id: "wb_report-go_to_login",
              label: t(
                "publicWbReport_emptyState.banner.action.label",
                "Go to login page"
              ),
              to: wbLoginRoute,
            },
          ]}
        />
      </div>
    </div>
  );
};
