import { gql } from "@apollo/client";

import { JobCheck } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const JOB_CHECK_FRAGMENT = gql`
  fragment JobCheckFragment on JobCheck {
    id
    jobCheckType
    documents {
      ...AttachmentFragment
    }
    content
    status
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface JobCheckFragment
  extends Pick<JobCheck, "id" | "jobCheckType" | "content" | "status"> {
  documents: AttachmentFragment[];
}
