import { gql } from "@apollo/client";

import { HelpArticle } from "graphql/types";

export const HELP_ARTICLE_FRAGMENT = gql`
  fragment HelpArticleFragment on HelpArticle {
    blockManualInvoicing
    showAttachmentWarning
    name
    supportEmail
    enHelpArticleUrl
    nbHelpArticleUrl
    enMessage
    nbMessage
  }
`;

export interface HelpArticleFragment
  extends Pick<
    HelpArticle,
    | "blockManualInvoicing"
    | "showAttachmentWarning"
    | "name"
    | "supportEmail"
    | "enHelpArticleUrl"
    | "nbHelpArticleUrl"
    | "enMessage"
    | "nbMessage"
  > {}
