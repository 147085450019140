/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { Base64AttachmentAttributes } from "graphql/types";

const UPLOAD_CHECK_REQUEST_FILE = gql`
  mutation ($id: ID!, $file: Base64AttachmentAttributes!) {
    uploadCheckRequestFile(input: { id: $id, file: $file }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest?: CheckRequestFragment | null;
  errors: ErrorFragment[];
}

interface UploadCheckRequestFileMutation {
  uploadCheckRequestFile: Payload | null;
}

export const useUploadCheckRequestFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UploadCheckRequestFileMutation,
    {
      id: string;
      file: Base64AttachmentAttributes;
    }
  >(UPLOAD_CHECK_REQUEST_FILE);

  const uploadCheckRequestFile = (
    variables: {
      id: string;
      file: Base64AttachmentAttributes;
    },
    onSuccess?: (checkRequest: CheckRequestFragment) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.uploadCheckRequestFile?.errors ?? [];
        const checkRequest = data?.uploadCheckRequestFile?.checkRequest;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && checkRequest && onSuccess(checkRequest),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    uploadCheckRequestFile,
    isLoading: loading,
    checkRequest: data?.uploadCheckRequestFile?.checkRequest,
    errors: data?.uploadCheckRequestFile?.errors ?? [],
  };
};
