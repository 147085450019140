import { Card, CardBody } from "components/common/basic";
import { logo } from "utils/brand";
import { tw } from "utils/tw";

interface Props {
  header: string;
  body?: string;
}

export default ({
  header,
  body,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const headingContainerStyles = tw("sm:mx-auto", "sm:w-full", "sm:max-w-lg");

  const logoStyles = tw("mx-auto", "h-12", "w-auto");

  const headerStyles = tw(
    "mt-6",
    "text-center",
    "text-2xl",
    "font-extrabold",
    "text-gray-900"
  );

  const bodyStyles = tw(
    "mt-2",
    "text-center",
    "text-gray-700",
    "whitespace-pre-line"
  );

  const cardContainerStyles = tw(
    "mt-8",
    "sm:mx-auto",
    "sm:w-full",
    "sm:max-w-md"
  );

  return (
    <>
      <div className={headingContainerStyles}>
        <img className={logoStyles} src={logo} alt="logo" />

        <h1 className={headerStyles}>{header}</h1>

        {body && <p className={bodyStyles}>{body}</p>}
      </div>

      <div className={cardContainerStyles}>
        <Card>
          <CardBody variant="stretched">{children}</CardBody>
        </Card>
      </div>
    </>
  );
};
