/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { CHECK_LIST_FRAGMENT, CheckListFragment } from "graphql/fragments";
import { QuerySentCheckListsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const SENT_CHECK_LISTS = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: CheckListsFilter
    $sortBy: CheckListsSortByEnum
  ) {
    sentCheckLists(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      exportPath
      items {
        ...CheckListFragment
      }
      total
    }
  }
  ${CHECK_LIST_FRAGMENT}
`;

export type SentCheckListsQuery = {
  sentCheckLists: PaginatedPayload<CheckListFragment>;
};

type QueryArgs = Pick<
  QuerySentCheckListsArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useSentCheckListsQuery = (args: QueryArgs = {}) => {
  const {
    data,
    called,
    loading: isLoading,
  } = useQuery<SentCheckListsQuery, QueryArgs>(SENT_CHECK_LISTS, {
    variables: { ...args },
  });

  const checkLists = data?.sentCheckLists.items?.filter(Boolean) ?? [];

  return { checkLists, called, isLoading };
};

export const useSentCheckListsLazyQuery = () => {
  const [getCheckListsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<SentCheckListsQuery, QueryArgs>(SENT_CHECK_LISTS, {
      fetchPolicy: "network-only",
    });

  const exportPath = data?.sentCheckLists.exportPath;
  const checkLists = data?.sentCheckLists.items?.filter(Boolean) ?? [];
  const total = data?.sentCheckLists.total ?? 0;

  const resolveRef = useRef<(checkLists?: CheckListFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(checkLists);
      resolveRef.current = undefined;
    }
  }, [checkLists, called, isLoading]);

  const getCheckLists = async (args?: QueryArgs) => {
    const variables = { ...args };

    getCheckListsQuery({ variables });

    return new Promise<CheckListFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getCheckLists,
    exportPath,
    checkLists,
    total,
    isLoading,
  };
};
