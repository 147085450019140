import { useState } from "react";
import { HiSearch } from "react-icons/hi";

import { FreelanceProfileFragment } from "graphql/fragments";
import { useFreelanceProfilesLazyQuery } from "graphql/queries";
import { FreelanceTypeEnum } from "graphql/types";
import { Trans } from "translations";
import { tw } from "utils/tw";

import SingleSearch from "./SingleSearch";

interface Props {
  onSelect: (freelanceProfileID: string) => void;
  label?: string;
  errorMessage?: string;
}

export default ({ onSelect, label, errorMessage }: Props): JSX.Element => {
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [profile, setProfile] = useState<FreelanceProfileFragment>();
  const { freelanceProfiles, getFreelanceProfiles, total } =
    useFreelanceProfilesLazyQuery();

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getFreelanceProfiles({
      page: p,
      perPage,
      filter: { search: searchInput ?? keyword },
    }).then(() => {
      setPage(p);

      if (searchInput) setKeyword(searchInput);
    });

  const buttonLabel = (
    <>
      {profile ? (
        profile.freelanceType === FreelanceTypeEnum.Organization ? (
          `${profile.organizationName} (${profile.organizationNumber})`
        ) : (
          `${profile.firstName} ${profile.lastName}`
        )
      ) : (
        <Trans ns="common" i18nKey="searchProfile.label">
          Search by freelance profile name
        </Trans>
      )}

      <HiSearch size={20} />
    </>
  );

  return (
    <SingleSearch<FreelanceProfileFragment>
      id="supplier-search"
      itemKey="id"
      items={freelanceProfiles}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setProfile(option);
        onSelect(option.id);
      }}
      buttonLabel={buttonLabel}
      label={label}
      errorMessage={errorMessage}
    />
  );
};

const renderListItemLabel = (profile: FreelanceProfileFragment) => (
  <>
    <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
      {profile.freelanceType === FreelanceTypeEnum.Organization
        ? `${profile.organizationName} (${profile.organizationNumber})`
        : `${profile.firstName} ${profile.lastName}`}
    </p>

    <p className={tw("block", "text-gray-500", "text-xs", "h-4")}>
      {profile.organizationNumber && (
        <Trans
          ns="common"
          i18nKey="searchClient.listItem.orgNumber"
          defaults="Org. nr. {{ orgNumber }}"
          values={{ orgNumber: profile.organizationNumber }}
        />
      )}
    </p>
  </>
);
