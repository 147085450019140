import { TFunction } from "i18next";

import { Badge } from "components/common/basic";
import { DocumentStatusEnum, SignatureStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  status: DocumentStatusEnum | SignatureStatusEnum;
  showStatus?: boolean;
  customMessage?: string;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  status,
  showStatus,
  customMessage,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const statusMessage = showStatus
    ? getTranslatableDocumentStatus(status, t)
    : ((): string => {
        switch (status) {
          case DocumentStatusEnum.Draft:
            return t("documentStatusMessage.draft", "Draft");
          case DocumentStatusEnum.Pending:
          case SignatureStatusEnum.Pending:
            return t("documentStatusMessage.pending", "Pending");
          case DocumentStatusEnum.Refused:
          case SignatureStatusEnum.Refused:
            return t("documentStatusMessage.refused", "Refused");
          case DocumentStatusEnum.Signed:
          case SignatureStatusEnum.Signed:
            return t("documentStatusMessage.signed", "Signed");
          case DocumentStatusEnum.Expired:
            return t("documentStatusMessage.expired", "Expired");
          case DocumentStatusEnum.Cancelled:
            return t("documentStatusMessage.cancelled", "Cancelled");

          default:
            return ((_status: never) => "")(status);
        }
      })();

  const badgeVariant = (() => {
    switch (status) {
      case DocumentStatusEnum.Draft:
        return "pending";
      case DocumentStatusEnum.Pending:
      case SignatureStatusEnum.Pending:
        return "warning";
      case DocumentStatusEnum.Expired:
      case DocumentStatusEnum.Cancelled:
      case DocumentStatusEnum.Refused:
      case SignatureStatusEnum.Refused:
        return "error";
      case DocumentStatusEnum.Signed:
      case SignatureStatusEnum.Signed:
        return "success";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "text-xs", "font-bold", "text-deepBlue-900")}
      >
        {customMessage ?? statusMessage}
      </p>
    );

  return (
    <Badge
      size={badgeSize}
      variant={badgeVariant}
      text={customMessage ?? statusMessage}
    />
  );
};

export const getTranslatableDocumentStatus = (
  status: DocumentStatusEnum | SignatureStatusEnum,
  t: TFunction
): string => {
  switch (status) {
    case DocumentStatusEnum.Draft:
      return t("documentStatus.draft", status);
    case DocumentStatusEnum.Pending:
    case SignatureStatusEnum.Pending:
      return t("documentStatus.pending", status);
    case DocumentStatusEnum.Refused:
    case SignatureStatusEnum.Refused:
      return t("documentStatus.refused", status);
    case DocumentStatusEnum.Signed:
    case SignatureStatusEnum.Signed:
      return t("documentStatus.signed", status);
    case DocumentStatusEnum.Expired:
      return t("documentStatus.expired", status);
    case DocumentStatusEnum.Cancelled:
      return t("documentStatus.cancelled", status);

    default:
      return ((_status: never) => "")(status);
  }
};
