import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDefaultRoutePath } from "utils";

export default (): null => {
  const defaultRoute = useDefaultRoutePath();
  const navigate = useNavigate();

  useEffect(() => {
    if (!module) return;

    navigate(defaultRoute);
  }, []);

  return null;
};
