/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { SESSION_FRAGMENT, SessionFragment } from "graphql/fragments";

export const SESSION = gql`
  query {
    session {
      ...SessionFragment
    }
  }
  ${SESSION_FRAGMENT}
`;

export type SessionQuery = { session: SessionFragment };

export const useSessionQuery = () => {
  const { data, loading } = useQuery<SessionQuery>(SESSION, {
    fetchPolicy: "network-only",
  });

  return { session: data?.session, isLoading: loading };
};

export const useSessionLazyQuery = () => {
  const [getSessionQuery, { data, loading, called, refetch }] =
    useLazyQuery<SessionQuery>(SESSION, { fetchPolicy: "network-only" });

  const resolveRef = useRef<(session?: SessionFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.session);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getSession = async () => {
    getSessionQuery();

    return new Promise<SessionFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getSession,
    session: data?.session,
    isLoading: loading,
    refetch,
  };
};
