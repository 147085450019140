/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  BENEFIT_LINE_FRAGMENT,
  DIET_LINE_FRAGMENT,
  ERROR_FRAGMENT,
  EXPENSE_LINE_FRAGMENT,
  EXTRA_LINE_FRAGMENT,
  ErrorFragment,
  InvoiceLineFragment,
  MILEAGE_LINE_FRAGMENT,
  WORK_LINE_FRAGMENT,
  WorkLine,
} from "graphql/fragments";
import { INVOICE, InvoiceQuery } from "graphql/queries";
import { CreateInvoiceLineAttributes, LineTypeEnum } from "graphql/types";

const CREATE_INVOICE_LINE = gql`
  mutation ($attributes: CreateInvoiceLineAttributes!, $invoiceId: ID!) {
    createInvoiceLine(
      input: { invoiceId: $invoiceId, attributes: $attributes }
    ) {
      invoiceLine {
        ...BenefitLineFragment
        ...DietLineFragment
        ...ExpenseLineFragment
        ...ExtraLineFragment
        ...MileageLineFragment
        ...WorkLineFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BENEFIT_LINE_FRAGMENT}
  ${DIET_LINE_FRAGMENT}
  ${EXPENSE_LINE_FRAGMENT}
  ${EXTRA_LINE_FRAGMENT}
  ${MILEAGE_LINE_FRAGMENT}
  ${WORK_LINE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoiceLine?: InvoiceLineFragment | null;
  errors: ErrorFragment[];
}

interface CreateInvoiceLineMutation {
  createInvoiceLine: Payload | null;
}

export const useCreateInvoiceLineMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateInvoiceLineMutation,
    { invoiceId: string; attributes: CreateInvoiceLineAttributes }
  >(CREATE_INVOICE_LINE);

  const createInvoiceLine = (
    invoiceId: string,
    attributes: CreateInvoiceLineAttributes,
    onSuccess?: (line: InvoiceLineFragment) => void
  ) =>
    mutation({
      variables: { invoiceId, attributes },
      update(cache, { data: newInvoiceLineData }) {
        const newInvoiceLineNode =
          newInvoiceLineData?.createInvoiceLine?.invoiceLine;
        if (!newInvoiceLineNode) return;

        cache.modify({
          fields: {
            node() {
              const invoice = cache.readQuery<InvoiceQuery>({
                query: INVOICE,
              });
              if (!invoice) return;

              const lines = ((): WorkLine[] => {
                if (newInvoiceLineNode.lineType === LineTypeEnum.Work)
                  return [...invoice.node.lines, newInvoiceLineNode];

                const parentLine = invoice.node.lines.find(
                  ({ id }) => id === attributes.workInvoiceLineId
                );
                if (!parentLine) return invoice.node.lines;

                return [
                  ...invoice.node.lines,
                  {
                    ...parentLine,
                    dependentLines: [
                      ...parentLine.dependentLines,
                      newInvoiceLineNode,
                    ],
                  },
                ];
              })();

              cache.writeQuery<InvoiceQuery>({
                query: INVOICE,
                data: {
                  node: {
                    ...invoice.node,
                    lines,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createInvoiceLine?.errors ?? [];
        const line = data?.createInvoiceLine?.invoiceLine;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => line && onSuccess && onSuccess(line),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createInvoiceLine,
    isLoading: loading,
    invoiceLine: data?.createInvoiceLine?.invoiceLine,
    errors: data?.createInvoiceLine?.errors ?? [],
  };
};
