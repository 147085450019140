import { useState } from "react";

import { Button, Divider } from "components/common/basic";
import { BrregSearch } from "components/common/composite";
import { ClientFragment, CompanyFragment } from "graphql/fragments";
import {
  ClientPreferredDeliveryMethodEnum,
  ClientTypeEnum,
} from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import CreateOrgClientForm from "./CreateOrgClientForm";

interface EditProps {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  client: ClientFragment;
}

interface AddProps {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  clients: ClientFragment[];
}

type Props = EditProps | AddProps;

export default ({ formId, onSuccessfulEdit, ...props }: Props): JSX.Element => {
  const editProps: EditProps | null = Object.prototype.hasOwnProperty.call(
    props,
    "client"
  )
    ? (props as EditProps)
    : null;
  const [client, setClient] = useState<ClientFragment | undefined>(
    editProps?.client
  );

  const { t } = useTranslation("common");

  if (client)
    return (
      <CreateOrgClientForm
        formId={formId}
        onSuccessfulEdit={onSuccessfulEdit}
        client={client}
      />
    );

  const addProps = props as AddProps;
  const setBrregOrganization = ({
    address,
    name,
    orgNumber,
  }: CompanyFragment) => {
    const existingFreelanceClient = addProps.clients.find(
      (client) => client.orgNumber === orgNumber
    );

    if (existingFreelanceClient) {
      setClient(existingFreelanceClient);
    } else {
      const newClient: ClientFragment = {
        id: "",
        email: "",
        clientType: ClientTypeEnum.Organization,
        clientNumber: Math.floor(Math.random() * 100_000_000),
        address,
        name,
        orgNumber,
        referencePeople: [],
        preferredDeliveryMethod: ClientPreferredDeliveryMethodEnum.Ehf,
        nationalRegisterCheck: true,
      };

      setClient(newClient);
    }
  };

  const setUnverifiedClient = () => {
    const newClient: ClientFragment = {
      id: "",
      email: "",
      name: "",
      clientType: ClientTypeEnum.Organization,
      clientNumber: Math.floor(Math.random() * 100_000_000),
      referencePeople: [
        // Prevents automatically added reference person from stealing autofocus
        {
          name: "",
          email: "",
          phone: "",
          referenceNumber: "",
        },
      ],
      preferredDeliveryMethod: ClientPreferredDeliveryMethodEnum.Ehf,
      nationalRegisterCheck: false,
    };

    setClient(newClient);
  };

  return (
    <div className={tw("w-full", "space-y-6")}>
      <div className={tw("space-y-2")}>
        <h3 className={tw("text-sm", "font-bold")}>
          {t(
            "clientForm_organization.brreg.heading",
            "Select a business registered in Norway (brreg.no)"
          )}
        </h3>

        <BrregSearch
          onSelect={setBrregOrganization}
          placeholder={t(
            "clientForm_organization.brreg.label",
            "Type a client name to see suggestions"
          )}
        />
      </div>

      <Divider />

      <div className={tw("space-y-2")}>
        <h3 className={tw("text-sm", "font-bold")}>
          {t(
            "clientForm_organization.international.heading",
            "Is your client not registered in Norways business register (brreg.no)?"
          )}
        </h3>

        <p className={tw("text-xs", "font-semibold")}>
          {t(
            "clientForm_organization.international.notice",
            "Your client will not be automatically verified, please check the customer details carefully."
          )}
        </p>

        <Button
          id="create_org_client-create_client_outside_norway"
          size="sm"
          variant="tertiary"
          onClick={() => setUnverifiedClient()}
        >
          {t(
            "clientForm_organization.international.button.label",
            "Click here to create a client outside of Norway."
          )}
        </Button>
      </div>
    </div>
  );
};
