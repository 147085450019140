/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  TRANSFER_FRAGMENT,
  TransferFragment,
} from "graphql/fragments";
import { UpdateTransferAttributes } from "graphql/types";

const UPDATE_TRANSFER = gql`
  mutation ($attributes: CreateTransferAttributes!) {
    updateTransfer(input: { id: $id, attributes: $attributes }) {
      transfer {
        ...TransferFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TRANSFER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  transfer?: TransferFragment | null;
  errors: ErrorFragment[];
}

interface UpdateTransferMutation {
  updateTransfer: Payload | null;
}

export const useUpdateTransferMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateTransferMutation,
    { id: string; attributes: UpdateTransferAttributes }
  >(UPDATE_TRANSFER);

  const updateTransfer = (
    id: string,
    attributes: UpdateTransferAttributes,
    onSuccess?: (transfer: TransferFragment) => void
  ) =>
    mutation({ variables: { id, attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateTransfer?.errors ?? [];
        const invoice = data?.updateTransfer?.transfer;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && invoice && onSuccess(invoice),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateTransfer,
    isLoading: loading,
    transfer: data?.updateTransfer?.transfer,
    errors: data?.updateTransfer?.errors ?? [],
  };
};
