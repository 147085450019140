import { Suspense, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { Spinner } from "components/common/basic";

const server = process.env.REACT_APP_I18N_SERVER;
const saveMissing = process.env.REACT_APP_I18N_UPLOAD_MISSING === "true";
const debug = process.env.REACT_APP_I18N_DEBUG === "true";
const isNested = process.env.REACT_APP_I18N_NESTED === "true";

const projectId = process.env.REACT_APP_I18N_PROJECT_ID;
const apiKey = process.env.REACT_APP_I18N_API_KEY;
const version = "latest";

const backend = {
  projectId,
  apiKey,
  referenceLng: "en",
  loadPath: `${server}/${projectId}/${version}/{{lng}}/{{ns}}`,
  addPath: `${server}/missing/${projectId}/${version}/{{lng}}/{{ns}}`,
  crossDomain: true,
  customHeaders: {
    authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  },
};

const detection = {
  order: ["path"],
  lookupPath: "version/locale",
  lookupFromPathIndex: 1,
};

const keySeparator: string | false = isNested ? "." : false;

const initOptions = {
  ns: ["common"],
  defaultNS: "common",
  fallbackLng: "en",
  saveMissing,
  debug,
  backend: server ? backend : undefined,
  detection,
  interpolation: { escapeValue: false },
  keySeparator,
  returnNull: false,
};

export default ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isinitialized, setIsinitialized] = useState(false);

  useEffect(() => {
    i18n
      .use(LanguageDetector)
      .use(Backend)
      .use(initReactI18next)
      .init(initOptions)
      .then(() => setIsinitialized(true));
  }, []);

  if (!isinitialized) return <Spinner />;

  return <Suspense fallback={<Spinner />}>{children}</Suspense>;
};
