import { PropsWithChildren } from "react";

import { tw } from "utils/tw";

import Footer, { PopupFooterProps } from "./PopupFooter";
import Header, { PopupHeaderProps } from "./PopupHeader";

export interface PopupContentProps
  extends PopupHeaderProps,
    Partial<PopupFooterProps> {
  shouldAnimateIn?: boolean;
  isRounded?: boolean;
  isFullHeight?: boolean;
}

export default ({
  children,
  shouldAnimateIn = false,
  isRounded = false,
  isFullHeight = false,
  footerActions,
  ...props
}: PropsWithChildren<PopupContentProps>) => (
  <div
    className={tw(
      "bg-white",
      "w-full",
      "h-full",
      "flex",
      "flex-col",
      children ? "space-y-8" : "space-y-4",
      "p-4",
      "md:p-8",
      {
        ["animate-toastIn"]: shouldAnimateIn,
        "rounded-lg": isRounded,

        "overflow-x-hidden": isFullHeight,
        "overflow-y-auto": isFullHeight,
      }
    )}
  >
    <Header {...props} />

    <div className={tw("flex-1")}>{children}</div>

    {footerActions && <Footer footerActions={footerActions} />}
  </div>
);
