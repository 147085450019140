import { Reducer, useReducer } from "react";

enum ActionEnum {
  addOne = "ADD_ONE",
  removeByIndex = "REMOVE_BY_INDEX",
}
interface ActionBase {
  type: ActionEnum;
}
interface AddOneAction<T> extends ActionBase {
  type: ActionEnum.addOne;
  payload: T;
}
interface RemoveByIndexAction extends ActionBase {
  type: ActionEnum.removeByIndex;
  payload: number;
}
type Action<T> = AddOneAction<T> | RemoveByIndexAction;

interface State<T> {
  items: T[];
}

interface Props<T> {
  items: T[];
}

interface Pagination<T> {
  items: T[];
  addOne: (item: T) => void;
  removeByIndex: (index: number) => void;
}

const reducer = <T,>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case ActionEnum.addOne:
      return {
        ...state,
        items: [...state.items, action.payload],
      };

    case ActionEnum.removeByIndex:
      return {
        ...state,
        items: state.items.filter((_file, index) => index !== action.payload),
      };

    default:
      return state;
  }
};

const initializeState = <T,>({ items }: Props<T>): State<T> => ({
  items,
});

export default <T,>(props: Props<T>): Pagination<T> => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>, Props<T>>(
    reducer,
    props,
    initializeState
  );

  const addOne = (item: T) =>
    dispatch({ type: ActionEnum.addOne, payload: item });

  const removeByIndex = (index: number) =>
    dispatch({ type: ActionEnum.removeByIndex, payload: index });

  return {
    items: state.items,
    addOne,
    removeByIndex,
  };
};
