import { useEffect, useState } from "react";

import { Spinner } from "components/common/basic";
import PageNav from "components/common/basic/PageNav";
import {
  FilterBar,
  FilterTypeEnum,
  FilterValues,
} from "components/common/composite";
import { useDepartmentsLazyQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import DepartmentsTable from "./DepartmentsTable";

export default (): JSX.Element | null => {
  const perPage = 25;
  const [page, setPage] = useState(1);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    search: "",
  });

  const { departments, getDepartments, isLoading, total } =
    useDepartmentsLazyQuery();

  const { t } = useTranslation("account");

  const fetchAndSetPage = (p: number) =>
    getDepartments({
      page: p,
      perPage,
      filter: {
        search: filterValues.search,
        // department:
        //   filterValues.department === "SHOW_ALL"
        //     ? undefined
        //     : filterValues.department,
      },
    }).then(() => setPage(p));

  useEffect(() => {
    fetchAndSetPage(1);
  }, [filterValues]);

  return (
    <div>
      <FilterBar
        filters={[
          {
            type: FilterTypeEnum.text,
            name: "search",
            label: t(
              "accountSettings_org.team.departments.filter.search.label",
              "Search"
            ),
            placeholder: t(
              "accountSettings_org.team.departments.filter.name.placeholder",
              "Department name"
            ),
          },
          // {
          //   type: FilterTypeEnum.custom,
          //   name: "departments",
          //   label: t(
          //     "accountSettings_org.team.departments.filter.departments.label",
          //     "Departments"
          //   ),
          //   CustomFilter: (props) => <DepartmentsFilter {...props} />,
          // },
        ]}
        defaultValues={filterValues}
        onChange={setFilterValues}
      />

      {isLoading ? (
        <Spinner />
      ) : (
        <DepartmentsTable
          departments={departments.map((department) => ({
            ...department,
            description: department.description ?? "",
          }))}
          footer={
            total > perPage ? (
              <div className={tw("px-6")}>
                <PageNav
                  currentPage={page}
                  totalPages={Math.ceil(total / perPage)}
                  onPageChange={fetchAndSetPage}
                />
              </div>
            ) : undefined
          }
        />
      )}
    </div>
  );
};
