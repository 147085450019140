import { gql } from "@apollo/client";

import { WbHandler } from "graphql/types";

import {
  SIMPLIFIED_ACCOUNT_FRAGMENT,
  SimplifiedAccountFragment,
} from "./simplifiedAccount";

export const WB_HANDLER_FRAGMENT = gql`
  fragment WbHandlerFragment on WbHandler {
    id
    name
    title
    default
    hasReports
    account {
      ...SimplifiedAccountFragment
    }
  }
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
`;

export interface WbHandlerFragment
  extends Pick<WbHandler, "id" | "name" | "title" | "default" | "hasReports"> {
  account: SimplifiedAccountFragment;
}
