import { gql } from "@apollo/client";

import { PublicWbHandler } from "graphql/types";

export const PUBLIC_WB_HANDLER_FRAGMENT = gql`
  fragment PublicWbHandlerFragment on PublicWbHandler {
    id
    name
    title
    default
  }
`;

export interface PublicWbHandlerFragment
  extends Pick<PublicWbHandler, "id" | "name" | "title" | "default"> {}
