import { CountryCodeEnum } from "graphql/types";

import flag_dk from "assets/symbols/flag_dk_s.svg";
import flag_fi from "assets/symbols/flag_fi_s.svg";
import flag_no from "assets/symbols/flag_no_s.svg";
import flag_se from "assets/symbols/flag_se_s.svg";

export default (country: CountryCodeEnum): string => {
  switch (country) {
    case CountryCodeEnum.No:
      return flag_no;

    case CountryCodeEnum.Se:
      return flag_se;

    case CountryCodeEnum.Fi:
      return flag_fi;

    case CountryCodeEnum.Dk:
      return flag_dk;

    default:
      return ((_country: never) => flag_no)(country);
  }
};
