import { useEffect, useState } from "react";
import { HiSearch } from "react-icons/hi";

import moment from "moment";

import { Input, RadioPill, Select } from "components/common/basic";
import { DateInput } from "components/common/composite";
import { useDebounce } from "hooks";
import { tw } from "utils/tw";

import BESearchFilter from "./BESearchFilter";
import DatePicker from "./DatePicker";

export enum FilterTypeEnum {
  text = "TEXT",
  startDate = "START_DATE",
  endDate = "END_DATE",
  select = "SELECT",
  BESearch = "BE_SEARCH",
  dateRange = "DATE_RANGE",
  custom = "CUSTOM",
}

interface FilterBase {
  type: FilterTypeEnum;
  name: string;
  label: string;
}
interface TextFilter extends FilterBase {
  type: FilterTypeEnum.text;
  placeholder?: string;
}
interface DateFilter extends FilterBase {
  type: FilterTypeEnum.startDate | FilterTypeEnum.endDate;
  placeholder?: string;
}
interface DateRangeFilter extends FilterBase {
  type: FilterTypeEnum.dateRange;
  placeholder?: string;
  setDateFilters: (from: string, to: string) => void;
}
interface SelectFilter extends FilterBase {
  type: FilterTypeEnum.select;
  options: {
    value: string;
    label: string;
    key: string;
  }[];
  isPills?: boolean;
}
interface BESearchFilterProps extends FilterBase {
  type: FilterTypeEnum.BESearch;
  lazyQuery: {
    options: {
      value: string;
      label: string;
    }[];
    getOptions: (
      page: number,
      perPage: number,
      searchInput?: string
    ) => Promise<unknown>;
    total: number;
    isLoading: boolean;
  };
  placeholder: string;
}
interface CustomFilterProps extends FilterBase {
  type: FilterTypeEnum.custom;
  CustomFilter: (props: {
    name: string;
    label: string;
    onChange: (value: string) => void;
    value: string;
  }) => JSX.Element;
}

export type Filter =
  | TextFilter
  | DateFilter
  | SelectFilter
  | BESearchFilterProps
  | DateRangeFilter
  | CustomFilterProps;

export type FilterValues = { [name: string]: string };

interface Props {
  filters: Filter[];
  values: FilterValues;
  setFilterValue: (name: string, value: string) => void;
  setFilterValues: (valueRecord: Record<string, string>) => void;
}

export default ({
  filters,
  values,
  setFilterValue,
  setFilterValues,
}: Props): JSX.Element => {
  const [valueToDebounce, setValueToDebounce] = useState<{
    name: string;
    value: string;
  }>();
  const debouncedValue = useDebounce(valueToDebounce, 500);

  const onInputChange =
    (inputName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      setFilterValue(inputName, event.target.value);

  const onDebouncedInputChange =
    (inputName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      setValueToDebounce({ name: inputName, value: event.target.value });

  useEffect(() => {
    if (!valueToDebounce) return;

    setFilterValue(valueToDebounce.name, valueToDebounce.value);
  }, [debouncedValue]);

  return (
    <>
      {filters.map((filter) => {
        const filterValue = values[filter.name];

        switch (filter.type) {
          case FilterTypeEnum.text:
            return (
              <Input
                key={filter.name}
                defaultValue={filterValue ?? ""}
                onChange={onDebouncedInputChange(filter.name)}
                id={filter.name}
                name={filter.name}
                label={filter.label}
                placeholder={filter.placeholder}
                TrailingIcon={HiSearch}
              />
            );

          case FilterTypeEnum.startDate:
          case FilterTypeEnum.endDate:
            return (
              <DateInput
                key={filter.name}
                value={
                  filterValue
                    ? moment(filterValue).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(date) => {
                  const value =
                    filter.type === FilterTypeEnum.startDate
                      ? date.startOf("day").toDate().toUTCString()
                      : date.endOf("day").toDate().toUTCString();

                  setFilterValue(filter.name, value);
                }}
                id={filter.name}
                name={filter.name}
                label={filter.label}
                placeholder={filter.placeholder}
              />
            );

          case FilterTypeEnum.select:
            return filter.isPills ? (
              <div
                key={filter.name}
                className={tw("w-full", "flex", "flex-wrap", "gap-2")}
              >
                {filter.options.map((option) => (
                  <RadioPill
                    key={`${filter.name}-${option.key}`}
                    id={`${filter.name}-${option.key}`}
                    label={option.label}
                    value={option.value}
                    checked={filterValue === option.value}
                    onChange={onInputChange(filter.name)}
                  />
                ))}
              </div>
            ) : (
              <Select
                key={filter.name}
                value={filterValue}
                id={filter.name}
                name={filter.name}
                label={filter.label}
                options={filter.options}
                onChange={onInputChange(filter.name)}
              />
            );

          case FilterTypeEnum.BESearch:
            return (
              <BESearchFilter
                key={filter.name}
                lazyQuery={filter.lazyQuery}
                onSelect={(selected) => setFilterValue(filter.name, selected)}
                placeholder={filter.placeholder}
                label={filter.label}
              />
            );

          case FilterTypeEnum.custom:
            return (
              <filter.CustomFilter
                key={filter.name}
                name={filter.name}
                label={filter.label}
                onChange={(value) => setFilterValue(filter.name, value)}
                value={filterValue}
              />
            );

          case FilterTypeEnum.dateRange:
            return (
              <DatePicker
                key={filter.name}
                label={filter.label}
                setDateFilters={(dateFrom, dateTo) =>
                  setFilterValues({
                    [`${filter.name}From`]: dateFrom,
                    [`${filter.name}To`]: dateTo,
                  })
                }
                from={values[`${filter.name}From`]}
                to={values[`${filter.name}To`]}
              />
            );

          default:
            ((_filter: never) => null)(filter);
            return null;
        }
      })}
    </>
  );
};
