import { useContext, useState } from "react";

import { Button, Divider } from "components/common/basic";
import { BrregSearch } from "components/common/composite";
import { CompanyFragment, FreelanceProfileFragment } from "graphql/fragments";
import { useFreelanceProfilesQuery } from "graphql/queries";
import { FreelanceTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { getNameFromSession } from "utils";
import { firma } from "utils/brand";
import { tw } from "utils/tw";

import Agreement from "./Agreement";
import CreateOrgProfileForm from "./CreateOrgProfileForm";

interface Props {
  formId: string;
  onSuccessfulEdit: (profile: FreelanceProfileFragment) => void;
  setIsChecked: () => void;
  isChecked: boolean;
  profile?: FreelanceProfileFragment;
}

export default ({
  formId,
  onSuccessfulEdit,
  setIsChecked,
  isChecked,
  profile,
}: Props): JSX.Element => {
  const [freelanceProfile, setFreelanceProfile] = useState<
    FreelanceProfileFragment | undefined
  >(profile);

  const { freelanceProfiles } = useFreelanceProfilesQuery();

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const setBrregOrganization = ({
    address,
    name: organizationName,
    orgNumber: organizationNumber,
  }: CompanyFragment) => {
    const existingFreelanceProfile = freelanceProfiles.find(
      (profile) => profile.organizationNumber === organizationNumber
    );

    if (existingFreelanceProfile) {
      setFreelanceProfile(existingFreelanceProfile);
    } else {
      const referencePerson = session && {
        email: session.user.email,
        name: getNameFromSession(session),
      };

      const newFreelanceProfile: FreelanceProfileFragment = {
        id: "",
        freelanceType: FreelanceTypeEnum.Organization,
        address: address ?? undefined,
        organizationName,
        organizationNumber,
        referencePeople: referencePerson ? [referencePerson] : [],
        jobChecks: [],
        bankAccounts: [],
        verified: true,
      };

      setFreelanceProfile(newFreelanceProfile);
    }
  };

  const setUnverifiedOrganization = () => {
    const referencePerson = session && {
      email: session.user.email,
      name: getNameFromSession(session),
    };

    const newFreelanceProfile: FreelanceProfileFragment = {
      id: "",
      freelanceType: FreelanceTypeEnum.Organization,
      referencePeople: referencePerson ? [referencePerson] : [],
      jobChecks: [],
      bankAccounts: [],
      verified: false,
    };

    setFreelanceProfile(newFreelanceProfile);
  };

  return (
    <div className={tw("w-full", "flex", "flex-col", "space-y-6")}>
      <div className={tw("p-4", "mb-4", "bg-deepBlue-900")}>
        <img className={tw("h-9")} src={firma} alt="Firma" />
      </div>

      {!freelanceProfile ? (
        <div className={tw("space-y-4")}>
          <div className={tw("w-full", "flex", "flex-col")}>
            <h2 className={tw("my-4", "text-lg", "font-bold")}>
              {t(
                "popup.freelanceProfile_organization.brregSearch.label",
                "Find your ENK / AS on Brreg"
              )}
            </h2>

            <BrregSearch onSelect={setBrregOrganization} />
          </div>

          <Divider />

          <div className={tw("w-full", "flex", "flex-col", "space-y-2")}>
            <h3 className={tw("text-sm", "font-bold")}>
              {t(
                "popup.freelanceProfile_organization.international.heading",
                "Is your organization not registered in Norways business register (brreg.no)?"
              )}
            </h3>

            <p className={tw("text-xs", "font-semibold")}>
              {t(
                "popup.freelanceProfile_organization.international.notice",
                "This organization will not be automatically verified, please check your details carefully."
              )}
            </p>

            <Button
              id="create_org_profile-create_profile_for_org_outside_norway"
              size="sm"
              variant="tertiary"
              onClick={() => setUnverifiedOrganization()}
            >
              {t(
                "popup.freelanceProfile_organization.international.button.label",
                "Click here to create a freelance profile for an organization outside of Norway."
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div className={tw("space-y-4")}>
          <CreateOrgProfileForm
            onSuccessfulEdit={onSuccessfulEdit}
            freelanceProfile={freelanceProfile}
            formId={formId}
          />

          <Agreement
            isChecked={isChecked}
            onChecked={setIsChecked}
            isOrganization={true}
          />
        </div>
      )}
    </div>
  );
};
