/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ATTACHMENT_FRAGMENT,
  AttachmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";
import { Base64AttachmentAttributes } from "graphql/types";

const CREATE_ROUTINE_GUIDELINE = gql`
  mutation ($attributes: Base64AttachmentAttributes!) {
    createRoutineGuideline(input: { attributes: $attributes }) {
      account {
        routineGuidelines {
          ...AttachmentFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: { routineGuidelines: AttachmentFragment[] } | null;
  errors: ErrorFragment[];
}

interface CreateRoutineGuidelineMutation {
  createRoutineGuideline: Payload | null;
}

export const useCreateRoutineGuidelineMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateRoutineGuidelineMutation,
    { attributes: Base64AttachmentAttributes }
  >(CREATE_ROUTINE_GUIDELINE);

  const createRoutineGuideline = (
    attributes: Base64AttachmentAttributes,
    onSuccess?: (routineGuidelines: AttachmentFragment[]) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data }) {
        cache.modify({
          fields: {
            session() {
              const sessionData = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionData) return;

              const routineGuidelines =
                data?.createRoutineGuideline?.account?.routineGuidelines;
              if (!routineGuidelines) return;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionData.session,
                    account: {
                      ...sessionData.session.account,
                      routineGuidelines,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createRoutineGuideline?.errors ?? [];
        const routineGuidelines =
          data?.createRoutineGuideline?.account?.routineGuidelines;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && routineGuidelines && onSuccess(routineGuidelines),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createRoutineGuideline,
    isLoading: loading,
    routineGuidelines: data?.createRoutineGuideline?.account?.routineGuidelines,
    errors: data?.createRoutineGuideline?.errors ?? [],
  };
};
