import { useContext } from "react";
import { IconType } from "react-icons";
import {
  HiOutlineCurrencyDollar,
  HiOutlineIdentification,
} from "react-icons/hi";

import { ActionID } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { RoutesByModule } from "utils";

interface LinkProps {
  actionId: ActionID;
  routeName: RoutesByModule["account"];
  Icon: IconType;
  label: string;
  helpText: string;
}

export default (): LinkProps[] => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  const links: LinkProps[] = [
    {
      actionId: "accountSettings-go_to_accountInfo",
      routeName: "SETTINGS_INFO",
      Icon: HiOutlineIdentification,
      label: t("accountSettings_ind.link.accountInfo.label", "Account info"),
      helpText: t(
        "accountSettings_ind.link.orgInfo.helpText",
        "Provide personal details and how we can reach you"
      ),
    },
    ...(session?.account.features.invoices !== false
      ? [
          {
            actionId: "accountSettings-go_to_paymentSettings" as const,
            routeName: "SETTINGS_PAYOUT" as const,
            Icon: HiOutlineCurrencyDollar,
            label: t(
              "accountSettings_ind.link.paymentPlan.label",
              "Payout service"
            ),
            helpText: t(
              "accountSettings_ind.link.paymentPlan.helpText",
              "Use our salary service to get paid without starting your own company"
            ),
          },
        ]
      : []),
  ];

  return links;
};
