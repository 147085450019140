/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const SEND_INVOICE_VIA_EMAIL = gql`
  mutation ($id: ID!) {
    sendInvoiceViaEmail(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendInvoiceViaEmailMutation {
  sendInvoiceViaEmail: Payload | null;
}

export const useSendInvoiceViaEmailMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentInvoiceViaEmail",
    "Invoice was sent successfully via email"
  );
  // Values for extraction
  // t("common:success.sentInvoiceViaEmail", "Invoice was sent successfully via email")

  const [mutation, { data, loading, called }] = useMutation<
    SendInvoiceViaEmailMutation,
    { id: string }
  >(SEND_INVOICE_VIA_EMAIL);

  const sendInvoiceViaEmail = (id: string, onSuccess?: () => void) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendInvoiceViaEmail?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(),
          onRetry: () => sendInvoiceViaEmail(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendInvoiceViaEmail,
    isLoading: loading,
    called,
    errors: data?.sendInvoiceViaEmail?.errors ?? [],
  };
};
