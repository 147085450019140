const getLocal = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (_error) {
    console.info(`Unable to get ${key} from local storage`);

    return null;
  }
};
const setLocal = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (_error) {
    console.info(`Unable to set ${key} in local storage`);
  }
};
const removeLocal = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (_error) {
    console.info(`Unable to remove ${key} from local storage`);
  }
};

const getSession = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key);
  } catch (_error) {
    console.info(`Unable to get ${key} from session storage`);

    return null;
  }
};
const setSession = (key: string, value: string): void => {
  try {
    sessionStorage.setItem(key, value);
  } catch (_error) {
    console.info(`Unable to set ${key} in session storage`);
  }
};
const removeSession = (key: string): void => {
  try {
    sessionStorage.removeItem(key);
  } catch (_error) {
    console.info(`Unable to remove ${key} from session storage`);
  }
};

const storage = {
  getLocal,
  setLocal,
  removeLocal,

  getSession,
  setSession,
  removeSession,
};

export default storage;
