import { useContext } from "react";
import { FieldErrors } from "react-hook-form";

import { ErrorLoggerContext } from "providers/ErrorLogger";

export default (): {
  reportErrors: (errors: FieldErrors, prefix?: string) => void;
} => {
  const { logger } = useContext(ErrorLoggerContext);

  const reportErrors = (errors: FieldErrors, prefix?: string) => {
    if (!errors) return;

    if (logger && Object.keys(errors).length > 0)
      Object.keys(errors).forEach((key) => {
        const error = errors[key];
        const field = prefix ? `${prefix}.${key}` : key;

        if (typeof error?.type === "string")
          logger.info(`validation failed @ ${field} : ${error.type}`);
        else reportErrors(error as FieldErrors, field);
      });
  };

  return { reportErrors };
};
