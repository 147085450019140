import { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";

import { FEPaginatedSearch } from "components/common/composite";
import { useDepartmentsLazyQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Option {
  id: string;
  name: string;
}

interface Props {
  label: string;
  onChange: (value: string) => void;
  value: string;
  idSeparator: string;
}

export default ({
  label,
  onChange,
  value,
  idSeparator,
}: Props): JSX.Element => {
  const [shouldChange, setShouldChange] = useState(false);
  const [selected, setSelected] = useState<Option[]>([]);

  const { getDepartments } = useDepartmentsLazyQuery();

  const { t } = useTranslation("common");

  const departmentSeparator = ",";
  const getStringFromDepartments = (departments: Option[]) =>
    departments
      .map(({ id, name }) => id + idSeparator + name)
      .join(departmentSeparator);
  const getDepartmentsFromString = (departmentString: string) =>
    departmentString.split(departmentSeparator).map((departmentString) => {
      const [id, name] = departmentString.split(idSeparator);

      return { id, name };
    });

  useEffect(() => {
    if (!value) return;

    const selectedDepartments = getDepartmentsFromString(value);
    setSelected(selectedDepartments);
  }, [value]);

  useEffect(() => {
    if (!shouldChange) return;

    const selectedDepartmentsString = getStringFromDepartments(selected);
    onChange(selectedDepartmentsString);

    setShouldChange(false);
  }, [shouldChange]);

  const onSelect = (options: Option[]) => {
    setShouldChange(true);

    setSelected(options);
  };

  const buttonLabel = (
    <>
      <span className={tw("mr-2")}>
        {(() => {
          switch (selected.length) {
            case 0:
              return t("departmentsFilter.placeholder", "Select a department");

            case 1:
              return selected[0].name;

            default:
              return t(
                "departmentsFilter.selected",
                "{{ amount }} departments selected",
                { amount: selected.length }
              );
          }
        })()}
      </span>

      <HiChevronDown size={20} className={tw("text-gray-400")} />
    </>
  );

  return (
    <span className={tw("w-80")}>
      <FEPaginatedSearch<Option>
        isMultiSelect={true}
        id="departments-filter"
        itemKey="id"
        buttonLabel={buttonLabel}
        label={label}
        selectedItems={selected}
        onSelect={onSelect}
        renderListItemLabel={(option) => <>{option.name}</>}
        onSearch={(search) =>
          getDepartments({ filter: { search } }).then((deps = []) => [
            ...deps.filter((dep) => selected.some(({ id }) => id === dep.id)),
            ...deps.filter((dep) => !selected.some(({ id }) => id === dep.id)),
          ])
        }
        reloadOnOpen
        showPagination={false}
        perPage={10_000}
      />
    </span>
  );
};
