/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_REPORT_FRAGMENT,
  WbReportFragment,
} from "graphql/fragments";

const REOPEN_WB_REPORT = gql`
  mutation ($id: ID!) {
    reopenWbReport(input: { id: $id }) {
      wbReport {
        ...WbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${WB_REPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  wbReport?: WbReportFragment | null;
  errors: ErrorFragment[];
}

interface ReopenWbReportMutation {
  reopenWbReport: Payload | null;
}

export const useReopenWbReportMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    ReopenWbReportMutation,
    { id: string }
  >(REOPEN_WB_REPORT);

  const reopenWbReport = (
    variables: { id: string },
    onSuccess?: (wbReport: WbReportFragment) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.reopenWbReport?.errors ?? [];
        const wbReport = data?.reopenWbReport?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    reopenWbReport,
    isLoading: loading,
    wbReport: data?.reopenWbReport?.wbReport,
    errors: data?.reopenWbReport?.errors ?? [],
  };
};
