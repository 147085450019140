import { useContext } from "react";

import { Spinner } from "components/common/basic";
import { WbMessageInput, WbReportDetails } from "components/tellmore/composite";
import { useWbReportQuery } from "graphql/queries";
import { WbReportStatusEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { Banner } from "../composite";
import { PopupVariant, PopupWrapper } from "./PopupWrapper";

interface Props {
  wbReportId: string;
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default ({ wbReportId, ...props }: Props) => {
  const { wbReport, isLoading, called } = useWbReportQuery(wbReportId);
  const { t } = useTranslation("tellmore");
  const { session } = useContext(AuthContext);

  const getRoutePath = useRoutePaths();

  const showMessageInput = wbReport
    ? [WbReportStatusEnum.Submitted, WbReportStatusEnum.InProgress].includes(
        wbReport.status
      )
    : false;

  return (
    <PopupWrapper
      {...props}
      nameSpace="tellmore"
      title={t("wbReportDrawer.title", "Report")}
      headerActions={[
        {
          id: "wb_report_drawer-go_to_report",
          to: getRoutePath({
            module: "tellmore",
            routeName: "WB_REPORT",
            arg1: wbReportId,
          }),
          isExternal: true,
          icon: "HiOutlineArrowsExpand",
        },
      ]}
    >
      {!called || isLoading ? (
        <Spinner />
      ) : !wbReport?.isAccessible ? (
        <Banner
          variant="warning"
          title={t("wbReportDrawer.notFound.title", "No report found")}
          body={t(
            "wbReportDrawer.notFound.body",
            "You do not have access to any report with this report ID"
          )}
        />
      ) : (
        <div>
          {session && (
            <WbReportDetails
              type="handler"
              wbReport={wbReport}
              guidelines={session.account.routineGuidelines}
            />
          )}

          {showMessageInput && (
            <>
              <div
                className={tw(
                  "w-screen",
                  "md:max-w-screen-sm", // SlideOver size
                  "flex",
                  "justify-center",
                  "fixed",
                  "bottom-0",
                  "right-0"
                )}
              >
                <WbMessageInput type="handler" wbReportId={wbReportId} />
              </div>

              {/* Space for expanded textarea */}
              <span className={tw("block", "mt-4", "h-44", "md:h-108")} />
            </>
          )}
        </div>
      )}
    </PopupWrapper>
  );
};
