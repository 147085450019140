import { PropsWithChildren, ReactNode } from "react";

import { Placement } from "@floating-ui/react-dom";

import { tw } from "utils/tw";

import PopoverTemplate, { PopoverTemplateProps } from "./PopoverTemplate";

interface Props extends Omit<PopoverTemplateProps, "children"> {
  content: ReactNode;
  placement: Placement;
}

export default ({
  content,
  placement,
  ...templateProps
}: PropsWithChildren<Props>) => {
  const arrow = (() => {
    switch (placement) {
      case "right":
        return <span className="arrow-left" style={{ left: -7 }} />;

      case "bottom":
        return <span className="arrow-top" style={{ top: -7 }} />;

      default:
        return null;
    }
  })();

  return (
    <PopoverTemplate
      {...templateProps}
      className={tw(
        "z-50",
        "p-2",
        "bg-gray-900",
        "rounded-md",
        "text-white",
        "text-xs",
        "font-semibold",
        "flex",
        "flex-col",
        "items-center",
        {
          "ml-2": placement === "right",
          "mt-2": placement === "bottom",
        }
      )}
    >
      {arrow}

      {content}
    </PopoverTemplate>
  );
};
