import moment from "moment";

// Expects: YYYY-MM-DD 00:00:00 UTC
export const atDateToMoment = (date: string): moment.Moment => {
  const [d, t] = date.split(" ");

  return moment.utc(`${d}T${t}`).local();
};

// Expects: YYYY-MM-DD
export const onDateToMoment = (date: string): moment.Moment =>
  moment(date, "YYYY-MM-DD");
