import { useContext, useState } from "react";
import {
  HiCheckCircle,
  HiChevronDown,
  HiChevronUp,
  HiOutlineLogin,
} from "react-icons/hi";

import { Button } from "components/common/basic";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

export interface SideMenuStep {
  icon: JSX.Element;
  label: string;
  status?: StepStatus;
  statusLabel?: JSX.Element;
  helpText?: string;
}

interface Props {
  header: JSX.Element;
  title: string;
  description: string | JSX.Element;
  currentStep: number;
  steps: SideMenuStep[];
  logout?: () => void;
}

export default (props: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const { isMd } = useContext(TailwindContext);
  const [isShowingSteps, setIsShowingSteps] = useState(true);

  // Show/hide steps when size changes
  // useEffect(() => {
  //   setIsShowingSteps(isMd);
  // }, [isMd]);

  return (
    <div
      className={tw(
        "shrink-0",
        "w-full",
        "bg-silver",
        "flex",
        "flex-col",
        "space-y-6",
        "p-4",
        "pt-6",
        "md:pt-12",
        "md:pb-8",
        "md:pl-8",
        "md:h-full",
        "md:max-w-sm"
      )}
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        {props.header}

        <h1 className={tw("text-2xl", "font-extrabold", "text-deepBlue-900")}>
          {props.title}
        </h1>

        {isMd && (
          <p
            className={tw(
              "text-sm",
              "text-deepBlue-500",
              "whitespace-pre-wrap"
            )}
          >
            {props.description}
          </p>
        )}
      </div>

      {isMd && (
        <div className={tw("space-y-2")}>
          <ol>
            {props.steps.map((step, index) => {
              const isCurrent = index === props.currentStep;
              if (!isShowingSteps && !isCurrent) return null;

              return (
                <SideMenuItem
                  key={index}
                  icon={step.icon}
                  label={step.label}
                  statusLabel={step.statusLabel}
                  helpText={step.helpText}
                  status={((): StepStatus => {
                    if (isCurrent) return "active";

                    if (step.status) return step.status;

                    if (index < props.currentStep) return "success";

                    return "default";
                  })()}
                  isFirst={index === 0}
                  isLast={index === props.steps.length - 1}
                  isOnly={!isShowingSteps}
                />
              );
            })}
          </ol>

          <button
            onClick={() => setIsShowingSteps(!isShowingSteps)}
            className={tw(
              "w-full",
              "flex",
              "justify-between",
              "text-sm",
              "text-deepBlue-500",
              "md:hidden"
            )}
          >
            <span>
              {isShowingSteps
                ? t("progressSideMenu.button.hideSteps", "Minimise")
                : t("progressSideMenu.button.showSteps", "Maximise")}
            </span>

            {isShowingSteps ? (
              <HiChevronUp size={24} />
            ) : (
              <HiChevronDown size={24} />
            )}
          </button>
        </div>
      )}

      {isMd && props.logout && (
        <div className={tw("flex-1", "flex", "items-end")}>
          <Button
            id="enrolment_wizard_side_menu-log_out"
            size="sm"
            variant="tertiary"
            LeadingIcon={HiOutlineLogin}
            onClick={props.logout}
          >
            {t("progressSideMenu.button.logout", "Log out")}
          </Button>
        </div>
      )}
    </div>
  );
};

type StepStatus = "default" | "active" | "success" | "pending";
interface SideMenuItemProps {
  icon: JSX.Element;
  label: string;
  status?: StepStatus;
  statusLabel?: JSX.Element;
  helpText?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isOnly?: boolean;
}
const SideMenuItem = ({
  icon,
  label,
  status,
  statusLabel,
  helpText,
  isFirst,
  isLast,
  isOnly,
}: SideMenuItemProps) => (
  <li
    className={tw("p-4", "mx-0.5", "flex", "space-x-3", "border", {
      "rounded-t-lg": !isOnly && isFirst,
      "rounded-b-lg": !isOnly && isLast,
      "rounded-lg": isOnly,
      [tw("border-deepBlue-100", "bg-white")]: status === "pending",
      [tw("border-deepBlue-100", "bg-deepBlue-50")]: status === "default",
      [tw("border-blue-900", "bg-blue-100")]: status === "active",
      [tw("border-deepBlue-100", "bg-teal-100")]: status === "success",
    })}
  >
    <span className={tw("h-6", "w-6")}>{icon}</span>

    <div className={tw("flex-1")}>
      <p className={tw("text-sm", "font-semibold", "text-gray-900")}>{label}</p>

      {helpText && <p className={tw("text-sm", "text-gray-500")}>{helpText}</p>}
    </div>

    {status === "pending" && <span>{statusLabel}</span>}

    {status === "success" && (
      <span className={tw("h-6", "w-6")}>
        <HiCheckCircle size={24} className={tw("text-teal-900")} />
      </span>
    )}
  </li>
);
