/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  CHECK_FRAGMENT,
  CheckFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const CONSENT_CHECK = gql`
  mutation ($id: ID!) {
    consentCheck(input: { id: $id }) {
      check {
        ...CheckFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  check: CheckFragment;
  errors: ErrorFragment[];
}

interface ConsentCheckMutation {
  consentCheck: Payload | null;
}

export const useConsentCheckMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    ConsentCheckMutation,
    { id: string }
  >(CONSENT_CHECK);

  const consentCheck = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
    })
      .then(({ data }) => {
        if (data?.consentCheck?.check && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    consentCheck,
    isLoading: loading,
    check: data?.consentCheck?.check,
  };
};
