import { PropsWithChildren, useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import WizardContext, { WizardStep } from "./WizardContext";
import WizardLayout from "./WizardLayout";

interface StepReducerState {
  currentStep: number;
  totalSteps: number;
}
const initialState: StepReducerState = {
  currentStep: 0,
  totalSteps: 0,
};

enum ActionTypeEnum {
  goBack = "GO_BACK",
  goNext = "GO_NEXT",
  goTo = "GO_TO",
  setTotal = "SET_TOTAL",
}

interface StepReducerGoBackAction {
  type: ActionTypeEnum.goBack;
}
interface StepReducerGoNextAction {
  type: ActionTypeEnum.goNext;
}
interface StepReducerGoToAction {
  type: ActionTypeEnum.goTo;
  payload: number;
}
interface StepReducerSetTotalAction {
  type: ActionTypeEnum.setTotal;
  payload: number;
}
type StepReducerAction =
  | StepReducerGoBackAction
  | StepReducerGoNextAction
  | StepReducerGoToAction
  | StepReducerSetTotalAction;

const reducer = (
  state: StepReducerState,
  action: StepReducerAction
): StepReducerState => {
  window.scrollTo(0, 0);

  switch (action.type) {
    case ActionTypeEnum.goBack:
      return { ...state, currentStep: state.currentStep - 1 };

    case ActionTypeEnum.goNext:
      return { ...state, currentStep: state.currentStep + 1 };

    case ActionTypeEnum.goTo:
      return { ...state, currentStep: action.payload };

    case ActionTypeEnum.setTotal:
      return { ...state, totalSteps: action.payload };

    default:
      ((_action: never) => state)(action);
  }

  return state;
};

interface Props {
  wizardTitle: string;
  sideMenuProps: {
    header: JSX.Element;
    title: string;
    description: string | JSX.Element;
    hideLogout?: boolean;
  };
  steps: WizardStep[];
  exitWizard?: () => void;
  unbranded?: boolean;
}

export default ({
  steps,
  sideMenuProps,
  wizardTitle,
  exitWizard,
  unbranded,
  children,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const [{ currentStep, totalSteps }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { step } = useParams<{ step?: string }>();

  // Set total steps
  useEffect(() => {
    dispatch({
      type: ActionTypeEnum.setTotal,
      payload: steps.length,
    });
  }, [steps.length]);

  // Synchronize step in params and reducer
  useEffect(() => {
    if (!step) return;

    dispatch({ type: ActionTypeEnum.goTo, payload: Number(step) });
  }, [step]);

  if (!totalSteps) return null;

  const currentStepContent =
    currentStep > totalSteps ? null : steps[currentStep];

  return (
    <WizardContext.Provider
      value={{
        steps,
        currentStep,
        goBack: () => dispatch({ type: ActionTypeEnum.goBack }),
        goNext: () => dispatch({ type: ActionTypeEnum.goNext }),
        goTo: (payload) => dispatch({ type: ActionTypeEnum.goTo, payload }),
      }}
    >
      <Helmet
        titleTemplate={`${process.env.REACT_APP_PAGE_TITLE} - ${wizardTitle} | %s`}
        defaultTitle={process.env.REACT_APP_PAGE_TITLE}
      />

      {currentStepContent &&
        (currentStepContent.withoutLayout ? (
          currentStepContent.page
        ) : (
          <WizardLayout
            {...sideMenuProps}
            exitWizard={exitWizard}
            unbranded={unbranded}
          >
            {currentStepContent.page}
          </WizardLayout>
        ))}

      {children}
    </WizardContext.Provider>
  );
};
