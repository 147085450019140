/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { MEMBERS, MembersQuery, SESSION, SessionQuery } from "graphql/queries";
import { SetAccountOwnerInput, TeamRoleEnum } from "graphql/types";

const SET_ACCOUNT_OWNER = gql`
  mutation ($input: SetAccountOwnerInput!) {
    setAccountOwner(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface SetAccountOwnerMutation {
  setAccountOwner: Payload | null;
}

export const useSetAccountOwnerMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    SetAccountOwnerMutation,
    { input: SetAccountOwnerInput }
  >(SET_ACCOUNT_OWNER);

  const setAccountOwner = (
    input: SetAccountOwnerInput,
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache) {
        cache.modify({
          fields: {
            members() {
              const membersQuery = cache.readQuery<MembersQuery>({
                query: MEMBERS,
              });
              if (!membersQuery) return;

              const items = membersQuery.members.items?.map((member) => ({
                ...member,
                role:
                  member.role === TeamRoleEnum.Owner
                    ? TeamRoleEnum.Admin
                    : member.id === input.memberId
                    ? TeamRoleEnum.Owner
                    : member.role,
              }));
              const total = membersQuery.members.total;

              cache.writeQuery<MembersQuery>({
                query: MEMBERS,
                data: { members: { items, total } },
              });
            },
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    role: TeamRoleEnum.Admin,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.setAccountOwner?.errors ?? [];
        const account = data?.setAccountOwner?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setAccountOwner,
    isLoading: loading,
    account: data?.setAccountOwner?.account,
    errors: data?.setAccountOwner?.errors ?? [],
  };
};
