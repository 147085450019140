import { useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";

import { AddressFragment } from "graphql/fragments";
import { useFormatter } from "hooks";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import SingleSearch from "./SingleSearch";

interface Option {
  label: string;
  value: AddressFragment;
}

interface Props {
  label: string;
  setAddress: (address: AddressFragment) => void;
  address?: AddressFragment;
  errorMessage?: string;
}

export default (props: Props): JSX.Element | null => {
  if (!window.google) return null;

  const sessionToken = new window.google.maps.places.AutocompleteSessionToken();

  const [address, setAddress] = useState(props.address);

  const { getFormattedAddress } = useFormatter();
  const { t } = useTranslation("common");

  const buttonLabel = (
    <>
      {address?.line1 ? (
        getFormattedAddress(address)
      ) : (
        <Trans ns="common" i18nKey="searchAddress.label">
          Search for your location
        </Trans>
      )}

      <HiOutlineLocationMarker size={20} />
    </>
  );

  return (
    <SingleSearch<Option>
      id="address-search"
      itemKey="label"
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchQuery) => loadOptions(searchQuery, sessionToken)}
      onSelect={(option) => {
        setAddress(option.value);
        props.setAddress(option.value);
      }}
      buttonLabel={buttonLabel}
      label={props.label}
      placeholder={t(
        "searchAddress.searchInput.placeholder",
        "Search for your location"
      )}
      errorMessage={props.errorMessage}
    />
  );
};

const renderListItemLabel = ({ label }: Option) => (
  <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
    {label}
  </p>
);

const loadOptions = async (
  searchQuery: string,
  sessionToken: google.maps.places.AutocompleteSessionToken
): Promise<Option[]> => {
  if (!window.google?.maps.places.PlacesServiceStatus.OK) return [];

  const autocompleteService =
    new window.google.maps.places.AutocompleteService();
  const suggestions: google.maps.places.AutocompletePrediction[] =
    await new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input: searchQuery,
          sessionToken,
          types: ["address"],
          componentRestrictions: { country: ["no"] },
        },
        (predictions, status: google.maps.places.PlacesServiceStatus) => {
          if (["ZERO_RESULTS", "OK"].includes(status)) {
            resolve(predictions);
          } else {
            reject(new Error(status));
          }
        }
      );
    });

  if (!suggestions) return [];

  const options = await Promise.all(
    suggestions.map(async ({ place_id }) => {
      const location = await getLocation(place_id, sessionToken);
      if (!location) return undefined;

      return {
        label: location.addressString ?? "",
        value: location.address,
      };
    })
  );

  const filteredOptions = options.reduce(
    (acc: Option[], option) => (option ? [...acc, option] : acc),
    []
  );

  return filteredOptions;
};

const getLocation = async (
  placeId: string,
  sessionToken: google.maps.places.AutocompleteSessionToken
) => {
  if (!window.google) return;

  const placesService = new window.google.maps.places.PlacesService(
    document.createElement("div")
  );
  const placeDetails: google.maps.places.PlaceResult = await new Promise(
    (resolve, reject) => {
      placesService.getDetails(
        {
          sessionToken,
          placeId,
          fields: ["geometry", "formatted_address", "address_components"],
        },
        (details, status: google.maps.places.PlacesServiceStatus) => {
          if (status !== "OK") {
            reject(new Error(status));
          } else {
            resolve(details);
          }
        }
      );
    }
  );

  if (
    !placeDetails ||
    !placeDetails.address_components ||
    !placeDetails.geometry
  )
    return;

  const address = placeDetails.address_components.reduce(
    (
      acc: AddressFragment,
      {
        long_name,
        short_name,
        types,
      }: { long_name: string; short_name: string; types: string[] }
    ) => {
      if (types.includes("route"))
        return {
          ...acc,
          line1: acc.line1 ? `${long_name} ${acc.line1}` : long_name,
        };

      if (types.includes("street_number"))
        return {
          ...acc,
          line1: acc.line1 ? `${acc.line1} ${long_name}` : long_name,
        };

      if (types.includes("postal_town")) return { ...acc, city: long_name };

      if (types.includes("administrative_area_level_1"))
        return { ...acc, county: long_name };

      if (types.includes("administrative_area_level_2") && !acc.city)
        return { ...acc, city: long_name };

      if (types.includes("locality") && !acc.city)
        return { ...acc, city: long_name };

      if (types.includes("country")) return { ...acc, country: short_name };

      if (types.includes("postal_code"))
        return { ...acc, postalCode: long_name };

      return acc;
    },
    {} as AddressFragment
  );

  const addressDefaultValues = {
    line1: "not available",
    city: "not available",
    country: "NO",
    postalCode: "0000",
  };

  const addressWithLocation = {
    ...addressDefaultValues,
    ...address,
    latitude: placeDetails.geometry.location.lat(),
    longitude: placeDetails.geometry.location.lng(),
  };
  const addressString = placeDetails.formatted_address;

  return { address: addressWithLocation, addressString };
};
