import { tw } from "utils/tw";

interface Props {
  message?: string;
}

export default ({ message }: Props): JSX.Element => {
  if (!message) return <LoadingBar />;

  return (
    <div className={tw("flex", "flex-col", "space-y-3")}>
      <p className={tw("text-sm", "font-semibold")}>{message}</p>

      <LoadingBar />
    </div>
  );
};

const LoadingBar = () => {
  const outerStyles = tw(
    "relative",
    "bg-primary",
    "h-2",
    "w-full",
    "rounded-full",
    "overflow-hidden"
  );

  const innerStyles = tw(
    "absolute",
    "bg-primary-hover",
    "h-2",
    "w-1/4",
    "rounded-full",
    "animate-leftToRight"
  );

  return (
    <div className={outerStyles}>
      <span className={innerStyles} />
    </div>
  );
};
