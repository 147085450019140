import React from "react";

import { TTailwindString, tw } from "utils/tw";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  name: string;
  label: string | JSX.Element;
  helpText?: string | JSX.Element;
  errorMessage?: string;
  hideLabel?: boolean;
  isNonClickable?: boolean;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      helpText,
      errorMessage,
      hideLabel,
      isNonClickable,
      className,
      ...props
    }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText && `${id}-help`;
    const errorId = hasError && `${id}-error`;
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

    const checkBoxStyles = tw(className, "relative", "flex", "items-start", {
      "pointer-events-none": isNonClickable,
    });

    const inputContainerStyles = tw("flex", "items-center", "h-5");

    const inputBase = tw(
      "focus:ring-gray-900",
      "h-4",
      "w-4",
      "rounded",
      "border-gray-300"
    );
    const inputDefault = tw("text-gray-900", "hover:text-gray-700");
    const inputError = tw(
      "border-2",
      "border-error",
      "bg-error-light",
      "text-error"
    );
    const inputDisabled = tw("bg-gray-100", "text-gray-100");
    const inputStyles = tw(inputBase, {
      [inputDefault as string]: !hasError && !props.disabled,
      [inputError as string]: hasError,
      [inputDisabled as string]: props.disabled,
    });

    const labelContainerStyles = tw("text-sm", {
      "ml-3": Boolean(!hideLabel || helpText || errorMessage),
    });

    const labelStyles = tw("text-deepBlue-900", "font-semibold", {
      "sr-only": hideLabel,
    });

    return (
      <div className={checkBoxStyles}>
        <div className={inputContainerStyles}>
          <input
            {...props}
            id={id}
            ref={ref}
            name={name}
            type="checkbox"
            className={inputStyles}
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
            readOnly={isNonClickable}
            tabIndex={isNonClickable ? -1 : undefined}
          />
        </div>

        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {helpTextId && (
            <p id={helpTextId} className={tw("text-deepBlue-500")}>
              {helpText}
            </p>
          )}

          {errorId && (
            <p id={errorId} className={tw("text-error")}>
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    );
  }
);
