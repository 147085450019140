import { Fragment } from "react";

import { Divider, Icon, IconType } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

type AccessLevel = 1 | 2 | 3 | 4;

export interface Role {
  icon: IconType;
  title: string;
  accessLevel: AccessLevel;
}

interface Props {
  roles: Role[];
}

export default ({ roles }: Props): JSX.Element => {
  const { t } = useTranslation("account");

  const permissions: {
    level: AccessLevel;
    label: string;
    optionalFrom?: AccessLevel;
  }[] = [
    {
      level: 1,
      label: t(
        "accountSettings_org.team.roles.permissions.level1.item1",
        "Delete team space (account)"
      ),
    },
    {
      level: 1,
      label: t(
        "accountSettings_org.team.roles.permissions.level1.item2",
        "Transfer team space ownership (only one person can be the owner)"
      ),
    },
    {
      level: 2,
      label: t(
        "accountSettings_org.team.roles.permissions.level2.item1",
        "Assign system roles to people"
      ),
    },
    {
      level: 2,
      label: t(
        "accountSettings_org.team.roles.permissions.level2.item2",
        "Add, delete, edit subscription and payment methods"
      ),
    },
    {
      level: 2,
      label: t(
        "accountSettings_org.team.roles.permissions.level2.item3",
        "Add, delete, edit departments"
      ),
    },
    {
      level: 2,
      label: t(
        "accountSettings_org.team.roles.permissions.level2.item4",
        "Add, delete, edit members in all departments"
      ),
    },
    {
      level: 3,
      label: t(
        "accountSettings_org.team.roles.permissions.level3.item1",
        "Edit their own department"
      ),
    },
    {
      level: 3,
      label: t(
        "accountSettings_org.team.roles.permissions.level3.item2",
        "Add, delete, edit members in their own department"
      ),
    },
    {
      level: 4,
      label: t(
        "accountSettings_org.team.roles.permissions.level4.item1",
        "Send and view checks results to candidates in their own department"
      ),
    },
    {
      level: 4,
      label: t(
        "accountSettings_org.team.roles.permissions.level4.optionalItem1",
        "Police Criminal Record access"
      ),
      optionalFrom: 3,
    },
    {
      level: 4,
      label: t(
        "accountSettings_org.team.roles.permissions.level4.optionalItem2",
        "Personal number access"
      ),
      optionalFrom: 3,
    },
  ];

  const rowStyles = tw("grid", "grid-cols-5", "lg:grid-cols-8");
  const cellPadding = roles.length > 1 ? tw("p-4") : tw("py-4", "px-6");
  const relevantPermissions =
    roles.length > 1
      ? permissions
      : permissions.filter(({ level }) => level >= roles[0].accessLevel);

  return (
    <div className={tw("max-w-4xl")}>
      <div className={rowStyles}>
        {roles.length > 1 && <div className={tw(cellPadding, "col-span-4")} />}

        {roles.map(({ icon, title }, roleIndex) => (
          <div
            key={roleIndex}
            className={tw(
              cellPadding,
              "col-span-5",
              "lg:col-span-1",
              "flex",
              "flex-row",
              "lg:flex-col",
              "lg:items-center",
              "gap-2"
            )}
          >
            <Icon icon={icon} size={24} color="text-gray-900" />

            <h2 className={tw("text-lg", "font-bold", "text-gray-900")}>
              {title}
            </h2>
          </div>
        ))}
      </div>

      <ul className={tw("border", "border-deepBlue-100", "rounded-lg")}>
        {relevantPermissions.map(
          ({ level, label, optionalFrom }, permissionIndex) => (
            <Fragment key={permissionIndex}>
              <li className={tw(rowStyles)}>
                <p
                  className={tw(
                    cellPadding,
                    "col-span-4",
                    "border-r",
                    "border-deepBlue-100",
                    "text-sm",
                    "text-deepBlue-500"
                  )}
                >
                  {label}
                </p>

                {roles.map(({ accessLevel }, roleIndex) => (
                  <div
                    key={roleIndex}
                    className={tw(
                      cellPadding,
                      "flex",
                      "flex-col",
                      "items-center"
                    )}
                  >
                    {accessLevel <= level && (
                      <Icon
                        icon="HiCheckCircle"
                        size={20}
                        isFilled
                        color="text-teal-900"
                      />
                    )}

                    {optionalFrom && accessLevel >= optionalFrom && (
                      <p className={tw("text-xs", "text-deepBlue-500")}>
                        {t(
                          "accountSettings_org.team.roles.permissions.optional",
                          "Optional"
                        )}
                      </p>
                    )}
                  </div>
                ))}
              </li>

              {permissionIndex + 1 < relevantPermissions.length && <Divider />}
            </Fragment>
          )
        )}
      </ul>
    </div>
  );
};
