/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DEPARTMENT_FRAGMENT,
  DepartmentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import {
  DEPARTMENTS,
  DepartmentsQuery,
  SESSION,
  SessionQuery,
} from "graphql/queries";
import { DepartmentAttributes } from "graphql/types";

const CREATE_DEPARTMENT = gql`
  mutation ($attributes: DepartmentAttributes!) {
    createDepartment(input: { attributes: $attributes }) {
      department {
        ...DepartmentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
`;

interface Payload {
  department?: DepartmentFragment | null;
  errors: ErrorFragment[];
}

export interface CreateDepartmentMutation {
  createDepartment: Payload | null;
}

export const useCreateDepartmentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateDepartmentMutation,
    { attributes: DepartmentAttributes }
  >(CREATE_DEPARTMENT);

  const createDepartment = (
    attributes: DepartmentAttributes,
    onSuccess?: (department: DepartmentFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newDepartmentData }) {
        const newDepartmentNode =
          newDepartmentData?.createDepartment?.department;
        if (!newDepartmentNode) return;

        cache.modify({
          fields: {
            departments() {
              const existingDepartments = cache.readQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
              });
              if (!existingDepartments) return;

              const items = [
                ...(existingDepartments.departments.items ?? []),
                newDepartmentNode,
              ];

              cache.writeQuery<DepartmentsQuery>({
                query: DEPARTMENTS,
                data: {
                  departments: {
                    items,
                    total: existingDepartments.departments.total + 1,
                  },
                },
              });
            },
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              const counts = sessionQuery.session.account.counts
                ? {
                    ...sessionQuery.session.account.counts,
                    departments:
                      sessionQuery.session.account.counts.departments + 1,
                  }
                : undefined;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      counts,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDepartment?.errors ?? [];
        const department = data?.createDepartment?.department;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => department && onSuccess && onSuccess(department),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createDepartment,
    department: data?.createDepartment?.department,
    isLoading: loading,
    errors: data?.createDepartment?.errors ?? [],
  };
};
