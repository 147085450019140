import { Link } from "components/common/basic";
import Button, { ButtonProps } from "components/common/basic/Button";
import { tw } from "utils/tw";

export type FooterAction = Omit<ButtonProps, "ref" | "size"> & {
  label: string;
  to?: string;
  isExternal?: boolean;
};

export interface PopupFooterProps {
  footerActions: FooterAction[];
}

export default ({ footerActions }: PopupFooterProps) => (
  <div className={tw("flex", "justify-end", "space-x-4")}>
    {footerActions.map(({ label, ...buttonProps }) =>
      buttonProps.to ? (
        <Link id={buttonProps.id} to={buttonProps.to} key={buttonProps.id}>
          <Button {...buttonProps} size="md" key={buttonProps.id}>
            {label}
          </Button>
        </Link>
      ) : (
        <Button {...buttonProps} size="md" key={buttonProps.id}>
          {label}
        </Button>
      )
    )}
  </div>
);
