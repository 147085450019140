import { useContext } from "react";

import { Radio } from "components/common/basic";
import { useUpdateAccountMutation } from "graphql/mutations";
import { LanguageEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { getIsValidLanguage } from "providers/i18n";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

export default (): JSX.Element | null => {
  const { updateAccount, isLoading } = useUpdateAccountMutation();
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!getIsValidLanguage(event.target.value)) return;
    const language = event.target.value as LanguageEnum;

    updateAccount({ attributes: { language }, personal: false });
  };

  const getLabelFromLanguage = (lang: LanguageEnum) => {
    const language = t(`teamLanguage.${lang.toLowerCase()}`, lang);
    const label = t(
      "teamLanguageSelect.option",
      "Communicate with my people in {{ language }}",
      { language }
    );

    return label;

    // Values for extraction
    // t("account:teamLanguage.en", "English")
    // t("account:teamLanguage.nb", "Norwegian")
  };

  if (!session) return null;

  return (
    <div className={tw("space-y-1")}>
      <label htmlFor="languageSelect" className={tw("sr-only")}>
        {t("languageSelect.label", "Language")}
      </label>

      <div className={tw("space-y-4")}>
        {session.account.canManage ? (
          Object.values(LanguageEnum).map((value) => (
            <Radio
              key={value}
              id={`languageSelectOption:${value}`}
              label={getLabelFromLanguage(value)}
              value={value}
              checked={value === session?.account.language}
              onChange={onChange}
              disabled={isLoading}
            />
          ))
        ) : (
          <p className={tw("text-sm", "text-deepBlue-500")}>
            {getLabelFromLanguage(session.account.language)}
          </p>
        )}
      </div>
    </div>
  );
};
