import { HiDownload, HiOutlineTrash } from "react-icons/hi";
import { Document as PDFDocument, Page as PDFPage } from "react-pdf";

import { Button } from "components/common/basic";
import { Base64AttachmentAttributes } from "graphql/types";
import { useTranslation } from "translations";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { tw } from "utils/tw";

interface Props {
  file: Base64AttachmentAttributes;
  destroyFile?: () => void;
  onClick?: () => void;
  isActive?: boolean;
  fallbackImageSrc?: string;
}

export default ({
  file,
  destroyFile,
  onClick,
  isActive,
  fallbackImageSrc,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <div className={tw("flex", "flex-col", "space-y-4")}>
      <ClickWrapper
        onClick={onClick}
        className={tw(
          "p-3",
          "flex",
          "flex-col",
          "space-y-2",
          "bg-white",
          "shadow",
          "rounded-lg",
          "border-2",
          isActive ? "border-blue-900" : "border-silver"
        )}
      >
        <>
          <div
            className={tw(
              "flex",
              "justify-between",
              "space-x-4",
              "sm:space-x-11"
            )}
          >
            {file.data?.includes("data:image") ? (
              <img
                className={tw("h-20", "w-20", "object-cover")}
                src={file.data}
                alt="File upload"
              />
            ) : file.data?.includes("application/pdf") ? (
              <div className={tw("h-20", "w-20", "overflow-hidden")}>
                <PDFDocument
                  file={file.data}
                  className={tw("m-0", "overflow-auto")}
                >
                  <PDFPage
                    pageNumber={1}
                    width={80}
                    height={80}
                    className="pdfPage"
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </PDFDocument>
              </div>
            ) : (
              <img
                className={tw("h-20", "w-20", "object-contain", "object-left")}
                src={fallbackImageSrc}
                alt="File upload"
              />
            )}

            {Boolean(destroyFile) && (
              <button type="button" className={tw("p-3")} onClick={destroyFile}>
                <HiOutlineTrash size={20} />
              </button>
            )}
          </div>

          <p className={tw("text-xs", "w-32", "sm:w-40", "truncate")}>
            {capitalizeFirstLetter(file.filename ?? "")}
          </p>
        </>
      </ClickWrapper>

      <a download={file.filename} href={file.data}>
        <Button
          id="attachment-download_document"
          variant="tertiary"
          size="sm"
          fullWidth
          LeadingIcon={HiDownload}
        >
          {t("attachment.download.label", "Download doc.")}
        </Button>
      </a>
    </div>
  );
};

interface ClickWrapperProps {
  children: JSX.Element;
  className: string;
  onClick?: () => void;
}

const ClickWrapper = ({ children, ...props }: ClickWrapperProps) => {
  if (props.onClick) return <button {...props}>{children}</button>;

  return <div {...props}>{children}</div>;
};
