import { HeaderTemplate } from "components/common/wrappers";

export default () => (
  <HeaderTemplate
    left={null}
    right={null}
    menuList={[]}
    showMenuToggle={false}
  />
);
