import {
  UseTranslationOptions,
  UseTranslationResponse,
  useTranslation,
} from "react-i18next";

import { NameSpace } from "./shared";

export default (
  ns: NameSpace,
  options?: UseTranslationOptions
): UseTranslationResponse<NameSpace> => useTranslation(ns, options);
