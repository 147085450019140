import { gql } from "@apollo/client";

import { DocumentFile } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const DOCUMENT_FILE_FRAGMENT = gql`
  fragment DocumentFileFragment on DocumentFile {
    id
    name
    file {
      ...AttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface DocumentFileFragment
  extends Pick<DocumentFile, "id" | "name"> {
  file: AttachmentFragment;
}
