import { PropsWithChildren, useContext } from "react";

import { PopupContext } from "providers/PopupHandler";
import { tw } from "utils/tw";

import { PopupContent } from "./PopupContent";
import { PopupContentProps } from "./PopupContent/PopupContent";

interface Props
  extends Omit<PopupContentProps, "isRounded" | "shouldAnimateIn"> {
  hasPrevious: boolean;
}

export default ({ hasPrevious, ...contentProps }: PropsWithChildren<Props>) => {
  const { isClosing, isChanging } = useContext(PopupContext);

  return (
    <div
      key={isClosing ? "slide-out" : "slide-in"}
      className={tw(
        "fixed",
        "top-0",
        "left-0",
        "z-50",
        "w-screen",
        "h-screen",
        "overflow-x-hidden",
        "overflow-y-auto",
        "flex",
        "justify-center",
        {
          "animate-drawerBgIn": !isClosing,
          "animate-drawerBgOut": isClosing,
        }
      )}
      style={
        hasPrevious
          ? undefined
          : {
              background: "rgba(32, 50, 79, 0.6)",
            }
      }
    >
      <button
        tabIndex={-1}
        className={tw("z-0", "fixed", "w-full", "h-full", "opacity-0")}
        onClick={contentProps.onClose}
      />

      <div
        className={tw(
          "z-10",
          "absolute",
          "right-0",
          "max-w-screen-sm",
          "w-full",
          "h-full",
          "bg-white",
          {
            "animate-drawerIn": !isClosing && !hasPrevious,
            "animate-drawerOut": isClosing,
          }
        )}
      >
        <PopupContent
          {...contentProps}
          isFullHeight
          shouldAnimateIn={!isClosing && isChanging}
        />
      </div>
    </div>
  );
};
