import { gql } from "@apollo/client";

import { FreelanceProfile } from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { BANK_ACCOUNT_FRAGMENT, BankAccountFragment } from "./bankAccount";
import { JOB_CHECK_FRAGMENT, JobCheckFragment } from "./jobCheck";
import {
  REFERENCE_PERSON_FRAGMENT,
  ReferencePersonFragment,
} from "./referencePerson";

export const FREELANCE_PROFILE_FRAGMENT = gql`
  fragment FreelanceProfileFragment on FreelanceProfile {
    id
    freelanceType
    organizationName
    organizationNumber
    bankAccounts {
      ...BankAccountFragment
    }
    defaultBankAccount {
      ...BankAccountFragment
    }
    jobChecks {
      ...JobCheckFragment
    }
    referencePeople {
      ...ReferencePersonFragment
    }
    firstName
    lastName
    invoiceStartingNumber
    archivedAt
    address {
      ...AddressFragment
    }
    verified
    archivedAt
  }
  ${ADDRESS_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
  ${JOB_CHECK_FRAGMENT}
  ${BANK_ACCOUNT_FRAGMENT}
`;

export interface FreelanceProfileFragment
  extends Pick<
    FreelanceProfile,
    | "id"
    | "freelanceType"
    | "organizationName"
    | "organizationNumber"
    | "address"
    | "firstName"
    | "lastName"
    | "invoiceStartingNumber"
    | "archivedAt"
    | "referencePeople"
    | "jobChecks"
    | "verified"
    | "archivedAt"
  > {
  bankAccounts: BankAccountFragment[];
  defaultBankAccount?: BankAccountFragment;
  jobChecks: JobCheckFragment[];
  referencePeople: ReferencePersonFragment[];
  address?: AddressFragment;
}
