/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { RefreshSessionOtpInput } from "graphql/types";

const REFRESH_SESSION_OTP = gql`
  mutation ($input: RefreshSessionOtpInput!) {
    refreshSessionOtp(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

export interface RefreshSessionOtpMutation {
  refreshSessionOtp: Payload | null;
}

export const useRefreshSessionOtpMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    RefreshSessionOtpMutation,
    { input: RefreshSessionOtpInput }
  >(REFRESH_SESSION_OTP);

  const refreshSessionOtp = (
    input: RefreshSessionOtpInput,
    onSuccess?: () => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.refreshSessionOtp?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    refreshSessionOtp,
    isLoading: loading,
    errors: data?.refreshSessionOtp?.errors ?? [],
  };
};
