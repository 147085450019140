/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { ACCOUNT_FRAGMENT, AccountFragment } from "graphql/fragments";
import { QueryRelatedTeamsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const RELATED_TEAMS = gql`
  query ($page: Int, $perPage: Int, $filter: RelatedTeamsFilter) {
    relatedTeams(page: $page, perPage: $perPage, filter: $filter) {
      exportPath
      items {
        ...AccountFragment
      }
      total
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export type RelatedTeamsQuery = {
  relatedTeams: PaginatedPayload<AccountFragment>;
};

type QueryArgs = Pick<QueryRelatedTeamsArgs, "page" | "perPage" | "filter">;

export const useRelatedTeamsQuery = (args: QueryArgs = {}) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<RelatedTeamsQuery, QueryArgs>(RELATED_TEAMS, {
    variables: { ...args },
  });

  const relatedTeams = data?.relatedTeams.items?.filter(Boolean) ?? [];
  const total = data?.relatedTeams.total ?? 0;

  return { relatedTeams, total, isLoading, called };
};

export const useRelatedTeamsLazyQuery = () => {
  const [getRelatedTeamsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<RelatedTeamsQuery, QueryArgs>(RELATED_TEAMS, {
      fetchPolicy: "network-only",
    });

  const relatedTeams = data?.relatedTeams.items?.filter(Boolean) ?? [];
  const total = data?.relatedTeams.total ?? 0;

  const resolveRef = useRef<(relatedTeams?: AccountFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(relatedTeams);
      resolveRef.current = undefined;
    }
  }, [relatedTeams, called, isLoading]);

  const getRelatedTeams = async (args?: QueryArgs) => {
    const variables = { ...args };

    getRelatedTeamsQuery({ variables });

    return new Promise<AccountFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getRelatedTeams, relatedTeams, total, isLoading };
};
