import { TBorderColor } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  message?: string;
  borderColor?: TBorderColor;
  isVertical?: boolean;
}

export default ({
  message,
  borderColor = "border-deepBlue-100",
  isVertical = false,
}: Props): JSX.Element => {
  const horizontalStyles = tw("w-full", "border-t");
  const verticalStyles = tw("min-h-full", "border-l");
  const lineStyles = tw(
    isVertical ? verticalStyles : horizontalStyles,
    borderColor
  );

  if (message) {
    const lineContainerStyles = tw(
      "absolute",
      "inset-0",
      "flex",
      "items-center"
    );

    const textContainerStyles = tw(
      "relative",
      "flex",
      "justify-center",
      "text-sm"
    );
    const textStyles = tw("px-2", "bg-white", "text-gray-500");

    return (
      <div className={tw("relative")}>
        <div className={lineContainerStyles}>
          <div className={lineStyles} />
        </div>

        <div className={textContainerStyles}>
          <span className={textStyles}>{message}</span>
        </div>
      </div>
    );
  }

  return <div className={lineStyles}></div>;
};
