import { AttachmentUpload } from "components/common/composite";
import { AttachmentFragment } from "graphql/fragments";
import {
  useCreateRoutineGuidelineMutation,
  useDestroyRoutineGuidelineMutation,
} from "graphql/mutations";
import { useTranslation } from "translations";

import FileUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";

interface Props {
  routineGuidelines: AttachmentFragment[];
  hasEditAccess?: boolean;
  isFullWidth?: boolean;
}

export default ({
  routineGuidelines,
  hasEditAccess = false,
  isFullWidth = false,
}: Props): JSX.Element | null => {
  const { createRoutineGuideline } = useCreateRoutineGuidelineMutation();
  const { destroyRoutineGuideline } = useDestroyRoutineGuidelineMutation();

  const { t } = useTranslation("tellmore");

  return (
    <AttachmentUpload
      files={routineGuidelines}
      createFile={({ file }) => createRoutineGuideline(file)}
      destroyFile={(id) => destroyRoutineGuideline(id as string)}
      illustration={{
        src: FileUploadIllustration,
        alt: t("guidelines.uploadFiles.illustration.alt", "File attachment"),
      }}
      validFileTypes={[
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]}
      fileRequirementsText={t(
        "guidelines.uploadFiles.validFileTypes",
        "PDF, word-documents. File size up to 10MB"
      )}
      allowMultiple
      hasEditAccess={hasEditAccess}
      isFullWidth={isFullWidth}
    />
  );
};
