import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";

import { Input } from "components/common/basic";
import { DateInput } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { InvoiceFragment } from "graphql/fragments";
import { useUpdateInvoiceMutation } from "graphql/mutations";
import { UpdateInvoiceAttributes } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { onDateToMoment } from "utils";
import { tw } from "utils/tw";

type FormValues = Pick<
  UpdateInvoiceAttributes,
  "dueOn" | "invoicedOn" | "buyerReference" | "orderReference"
>;

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  invoice: InvoiceFragment;
}

export default ({ invoice, ...props }: Props): JSX.Element => {
  const { updateInvoice } = useUpdateInvoiceMutation();

  const { t } = useTranslation("invoices");
  const { closeOnePopup } = useContext(PopupContext);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<FormValues>();

  const onSubmit = (attributes: FormValues) =>
    updateInvoice(invoice.id, attributes, closeOnePopup);

  const formId = "invoiceInformationUpdateForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="invoices"
      title={t("draftInfoCard.popup.heading", "Edit invoice information")}
      footerActions={[
        {
          id: "draft_info_card-cancel",
          variant: "tertiary",
          label: t("popup.jobCreation.cancel", "Cancel"),
          onClick: closeOnePopup,
        },
        {
          id: "draft_info_card-save",
          variant: "primary",
          type: "submit",
          form: formId,
          label: t("popup.jobCreation.submit", "Save"),
        },
      ]}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className={tw("w-full", "flex", "flex-col", "space-y-4")}
      >
        <div className={tw("flex", "space-x-4")}>
          <Controller
            control={control}
            name="invoicedOn"
            defaultValue={invoice.invoicedOn}
            render={({ field }) => (
              <DateInput
                className={tw("flex-1")}
                id={field.name}
                name={field.name}
                label={t(
                  "draftInfoCard.popup.input.invoicedOn.label",
                  "Invoiced on"
                )}
                value={
                  field.value
                    ? onDateToMoment(field.value).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(date) => field.onChange(date.format("YYYY-MM-DD"))}
                errorMessage={errors.invoicedOn?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="dueOn"
            defaultValue={invoice.dueOn}
            render={({ field }) => (
              <DateInput
                className={tw("flex-1")}
                id={field.name}
                name={field.name}
                label={t("draftInfoCard.popup.input.dueOn.label", "Due on")}
                value={
                  field.value
                    ? onDateToMoment(field.value).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(date) => field.onChange(date.format("YYYY-MM-DD"))}
                errorMessage={errors.dueOn?.message}
              />
            )}
          />
        </div>

        <Input
          id="buyerReference"
          {...register("buyerReference")}
          label={t(
            "draftInfoCard.popup.input.buyerReference.label",
            "Buyer reference"
          )}
          errorMessage={errors.buyerReference?.message}
          defaultValue={invoice.buyerReference ?? ""}
        />
        <Input
          id="orderReference"
          {...register("orderReference")}
          label={t(
            "draftInfoCard.popup.input.orderReference.label",
            "Order reference"
          )}
          errorMessage={errors.orderReference?.message}
          defaultValue={invoice.orderReference ?? ""}
        />
      </form>
    </PopupWrapper>
  );
};
