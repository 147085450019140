/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { ResetPasswordInput } from "graphql/types";

const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface ResetPasswordMutation {
  resetPassword: Payload | null;
}

export const useResetPasswordMutation = (onSuccess?: () => void) => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.resetPassword",
    "Password was reset successfully"
  );
  // Values for extraction
  // t("common:success.resetPassword", "Password was reset successfully")

  const [mutation, { data, loading }] = useMutation<
    ResetPasswordMutation,
    { input: ResetPasswordInput }
  >(RESET_PASSWORD);

  const resetPassword = (input: ResetPasswordInput) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.resetPassword?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    resetPassword,
    isLoading: loading,
    errors: data?.resetPassword?.errors ?? [],
  };
};
