import { gql } from "@apollo/client";

import { Subscription } from "graphql/types";

import { PRICE_PLAN_FRAGMENT, PricePlanFragment } from "./pricePlan";

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on Subscription {
    pricePlan {
      ...PricePlanFragment
    }
    usage
    billingCycleStartedOn
    billingCycleEndsOn
    startedOn
    endedOn
    cancelledOn
    downgradedOn
  }
  ${PRICE_PLAN_FRAGMENT}
`;

export interface SubscriptionFragment
  extends Pick<
    Subscription,
    | "usage"
    | "billingCycleStartedOn"
    | "billingCycleEndsOn"
    | "startedOn"
    | "endedOn"
    | "cancelledOn"
    | "downgradedOn"
  > {
  pricePlan: PricePlanFragment;
}
