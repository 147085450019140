import { tw } from "utils/tw";

interface Props {
  imageSrc: string;
  imageAlt: string;
  isFullImage?: boolean;
}

export default ({
  imageSrc,
  imageAlt,
  isFullImage = false,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => (
  <div className={tw("space-y-9")}>
    <img
      className={tw("h-80", "object-cover", { "w-full": isFullImage })}
      src={imageSrc}
      alt={imageAlt}
    />

    <div className={tw("flex", "flex-col", "space-y-6")}>{children}</div>
  </div>
);
