import { useContext } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useUnarchivePeopleMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  personIds: string[];
}

export default ({ personIds, ...props }: Props): JSX.Element => {
  const { unarchivePeople } = useUnarchivePeopleMutation();

  const { t } = useTranslation("account");
  const { closeOnePopup } = useContext(PopupContext);

  const { title, subTitle, submitLabel } = ((amount: number) => {
    switch (amount) {
      case 0: // All
        return {
          title: t(
            "people.unarchivePersonPopup_all.heading",
            "Unarchive all people"
          ),
          subTitle: t(
            "people.unarchivePersonPopup_all.body",
            "Unarchiving these people will move them from the archived table to the active table."
          ),
          submitLabel: t(
            "people.unarchivePersonPopup_all.button.archive",
            "Unarchive people"
          ),
        };
      case 1: // One
        return {
          title: t(
            "people.unarchivePersonPopup_one.heading",
            "Unarchive this person"
          ),
          subTitle: t(
            "people.unarchivePersonPopup_one.body",
            "Unarchiving this person will move them from the archived table to the active table."
          ),
          submitLabel: t(
            "people.unarchivePersonPopup_one.button.archive",
            "Unarchive person"
          ),
        };
      default: // Some
        return {
          title: t(
            "people.unarchivePersonPopup_some.heading",
            "Unarchive these people"
          ),
          subTitle: t(
            "people.unarchivePersonPopup_some.body",
            "Unarchiving these people will move them from the archived table to the active table."
          ),
          submitLabel: t(
            "people.unarchivePersonPopup_some.button.archive",
            "Unarchive people"
          ),
        };
    }
  })(personIds.length);

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={title}
      subTitle={subTitle}
      footerActions={[
        {
          id: "unarchive_people_popup-cancel",
          variant: "tertiary",
          onClick: closeOnePopup,
          label: t("people.unarchivePersonPopup.button.cancel", "Cancel"),
        },
        {
          id: "unarchive_people_popup-unarchive",
          variant: "primary",
          onClick: () => unarchivePeople({ ids: personIds }, closeOnePopup),
          label: submitLabel,
        },
      ]}
    />
  );
};
