import { useContext, useEffect } from "react";

import { Divider, Link } from "components/common/basic";
import { PopoverContext } from "providers/PopoverHandler";
import { tw } from "utils/tw";

import MenuItemGroupComponent from "./MenuItemGroup";
import {
  HeadingItem,
  MenuItem,
  MenuItemAction,
  MenuItemGroup,
  MenuItemLink,
} from "./types";

interface Props {
  item: MenuItem;
  iconsOnly?: boolean;
  closeSelf?: () => void;
}
export default ({ item, iconsOnly, closeSelf }: Props) => {
  const { registerPopover, unregisterPopover, setIsShownPopover } =
    useContext(PopoverContext);

  // Unregister popover on unmount
  useEffect(
    () => () =>
      Object.prototype.hasOwnProperty.call(item, "id")
        ? unregisterPopover((item as { id: string }).id)
        : undefined,
    []
  );

  if (item === "DIVIDER")
    return (
      <div className={tw("py-2")}>
        <Divider />
      </div>
    );

  const isHeadingItem = Object.prototype.hasOwnProperty.call(item, "text");
  if (isHeadingItem) {
    const headingItem = item as HeadingItem;

    if (iconsOnly) return <span className={tw("h-3", "block")} />;

    const { Icon, text, isSubHeading } = headingItem;
    return (
      <div
        className={tw("flex", "space-x-1", "items-center", {
          "pt-6": isSubHeading,
          "pr-4": true,
          "pb-0": true,
          "pl-4": !isSubHeading,
          "pl-2": isSubHeading,
        })}
      >
        <h5
          className={tw(
            "text-xs",
            "font-semibold",
            "uppercase",
            "text-deepBlue-300",
            "overflow-hidden",
            "text-ellipsis"
          )}
        >
          {text}
        </h5>

        {Icon && <Icon size={16} color="text-deepBlue-300" />}
      </div>
    );
  }

  const menuItem = item as MenuItemLink | MenuItemAction | MenuItemGroup;

  const listItemStyles = tw(
    "group",
    "flex",
    "items-center",
    "w-full",
    "space-x-3",
    "p-2",
    "rounded-md",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900",
    {
      "bg-blue-100": !menuItem.isDestructive && menuItem.isActive,
      "text-blue-900": !menuItem.isDestructive && menuItem.isActive,
      "text-deepBlue-700": !menuItem.isDestructive && !menuItem.isActive,
      "bg-error-light": menuItem.isDestructive && menuItem.isActive,
      "text-error": menuItem.isDestructive && menuItem.isActive,
      "hover:bg-silver": !menuItem.isDestructive && !menuItem.isActive,
      "hover:bg-error-light": menuItem.isDestructive,
      "hover:text-error": menuItem.isDestructive,
    }
  );
  const listItemIconStyles = tw({
    "text-deepBlue-500": !menuItem.isActive,
    "text-blue-900": menuItem.isActive,
    "group-hover:text-error": menuItem.isDestructive,
  });

  if (Object.prototype.hasOwnProperty.call(menuItem, "items"))
    return (
      <MenuItemGroupComponent
        item={menuItem as MenuItemGroup}
        iconsOnly={iconsOnly}
        closeSelf={closeSelf}
      />
    );

  const popoverProps = !iconsOnly
    ? undefined
    : {
        ref: (targetRef: HTMLSpanElement) =>
          registerPopover({
            id: menuItem.id,
            variant: "tooltip",
            content: menuItem.label,
            targetRef,
            placement: "right",
          }),
        onMouseEnter: () => setIsShownPopover(menuItem.id, true),
        onMouseLeave: () => setIsShownPopover(menuItem.id, false),
      };

  const { Icon, iconClassName } = menuItem;
  const label = Icon ? (
    <>
      <span {...popoverProps}>
        <Icon size="24px" className={iconClassName ?? listItemIconStyles} />
      </span>

      {iconsOnly ? null : (
        <span className={tw("truncate")}>{menuItem.label}</span>
      )}
    </>
  ) : (
    <span className={tw("pl-1")}>{menuItem.label}</span>
  );

  if (Object.prototype.hasOwnProperty.call(menuItem, "onClick")) {
    const { onClick } = menuItem as MenuItemAction;

    return (
      <li>
        <button
          className={listItemStyles}
          onClick={() => {
            onClick();
            closeSelf?.();
          }}
        >
          {label}
        </button>
      </li>
    );
  }

  if (Object.prototype.hasOwnProperty.call(menuItem, "to")) {
    const linkProps = menuItem as MenuItemLink;

    return (
      <li>
        <Link
          {...linkProps}
          title={
            iconsOnly && typeof menuItem.label === "string"
              ? menuItem.label
              : undefined
          }
          unstyled
          className={listItemStyles}
        >
          {label}
        </Link>
      </li>
    );
  }

  return null;
};
