import { Reducer, useReducer } from "react";

enum ActionEnum {
  prev = "PREVIOUS_PAGE",
  next = "NEXT_PAGE",
  goto = "SPECIFIC_PAGE",
  resetItems = "RESET_ITEMS",
}
interface ActionBase {
  type: ActionEnum;
}
interface PrevAction extends ActionBase {
  type: ActionEnum.prev;
}
interface NextAction extends ActionBase {
  type: ActionEnum.next;
}
interface GoToAction extends ActionBase {
  type: ActionEnum.goto;
  payload: number;
}
interface ResetAction<T> extends ActionBase {
  type: ActionEnum.resetItems;
  payload: T[];
}
type Action<T> = PrevAction | NextAction | GoToAction | ResetAction<T>;

interface State<T> {
  page: number;
  pages: number;
  perPage: number;
  pageItems: T[];
  items: T[];
}

interface Props<T> {
  items: T[];
  perPage: number;
}

interface Pagination<T> {
  page: number;
  pages: number;
  pageItems: T[];
  goBack: () => void;
  goNext: () => void;
  goToPage: (page: number) => void;
  resetItems: (items: T[]) => void;
}

const getPageItemsForPage = <T,>(state: State<T>, page: number): T[] => {
  const currentIndexes = [...Array(state.perPage)]
    .map((_, index) => (page - 1) * state.perPage + index)
    .filter((index) => index < state.items.length);

  const pageItems = currentIndexes
    .map((index) => state.items[index])
    .filter((item) => typeof item !== "undefined");

  return pageItems;
};

const reducer = <T,>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case ActionEnum.prev:
      if (state.page > 1)
        return {
          ...state,
          page: state.page - 1,
          pageItems: getPageItemsForPage(state, state.page - 1),
        };
      return state;

    case ActionEnum.next:
      if (state.page < state.pages)
        return {
          ...state,
          page: state.page + 1,
          pageItems: getPageItemsForPage(state, state.page + 1),
        };
      return state;

    case ActionEnum.goto:
      return {
        ...state,
        page: action.payload,
        pageItems: getPageItemsForPage(state, action.payload),
      };

    case ActionEnum.resetItems:
      return initializeState({
        items: action.payload,
        perPage: state.perPage,
      });

    default:
      return state;
  }
};

const initializeState = <T,>({ items, perPage }: Props<T>): State<T> => ({
  page: 1,
  pages: Math.ceil(items.length / perPage),
  perPage,
  pageItems: items.slice(0, perPage),
  items,
});

export default <T,>(props: Props<T>): Pagination<T> => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>, Props<T>>(
    reducer,
    props,
    initializeState
  );

  const goBack = () => dispatch({ type: ActionEnum.prev });
  const goNext = () => dispatch({ type: ActionEnum.next });
  const goToPage = (page: number) =>
    dispatch({
      type: ActionEnum.goto,
      payload: page,
    });
  const resetItems = (newItems: T[]) => {
    dispatch({ type: ActionEnum.resetItems, payload: newItems });
  };

  return {
    page: state.page,
    pages: state.pages,
    pageItems: state.pageItems,
    goBack,
    goNext,
    goToPage,
    resetItems,
  };
};
