import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Banner } from "components/common/composite";
import { FeatureEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";

import getText from "./getText";

interface Props {
  variant:
    | "usagePreventsDowngrade"
    | "contactAdmin"
    | "contactSales"
    | "billingInfo";
  feature: FeatureEnum;
  usageChangeLocation?: string;
}

export default ({
  variant,
  feature,
  usageChangeLocation,
}: Props): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { pathname } = useLocation();
  const { session } = useContext(AuthContext);

  if (!session) return null;

  const text = getText(t, { feature });

  switch (variant) {
    case "contactAdmin":
      return (
        <Banner
          variant="info"
          title={text.contactAdmin.title}
          body={text.contactAdmin.body}
        />
      );

    case "contactSales":
      return (
        <Banner
          variant="info"
          title={text.contactSales.title}
          body={text.contactSales.body}
          actions={[
            {
              id: "subscription_change_popup-contact_sales",
              label: text.contactSales.actionLabel,
              to: text.contactSales.actionLink,
            },
          ]}
        />
      );

    case "billingInfo":
      return (
        <Banner
          variant="info"
          title={text.billingInfo.title}
          body={text.billingInfo.body}
        />
      );

    case "usagePreventsDowngrade":
      return usageChangeLocation ? (
        <Banner
          variant="warning"
          title={text.usagePreventsDowngrade.title}
          body={text.usagePreventsDowngrade.body}
          actions={
            pathname === usageChangeLocation
              ? undefined
              : [
                  {
                    id: text.usagePreventsDowngrade.actionId,
                    label: text.usagePreventsDowngrade.actionLabel,
                    to: usageChangeLocation,
                  },
                ]
          }
        />
      ) : null;

    default:
      ((_variant: never) => null)(variant);
  }

  return null;
};
