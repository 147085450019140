/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { DOCUMENTS, DocumentsQuery } from "graphql/queries";
import {
  CancelDocumentsInput,
  DocumentStatusEnum,
  DocumentsFilter,
} from "graphql/types";

import { BulkInput } from "./shared/types";

const CANCEL_DOCUMENT = gql`
  mutation (
    $ids: [ID!]!
    $filter: DocumentsFilter
    $attributes: CancelDocumentAttributes!
  ) {
    cancelDocuments(
      input: { ids: $ids, filter: $filter, attributes: $attributes }
    ) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<DocumentsFilter> &
  Pick<CancelDocumentsInput, "attributes">;

interface Payload {
  errors: ErrorFragment[];
}

interface CancelDocumentsMutation {
  cancelDocuments: Payload | null;
}

export const useCancelDocumentsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.cancelledDocuments",
    "Documents cancelled successfully"
  );
  // Values for extraction
  // t("common:success.cancelledDocuments", "Documents cancelled successfully")

  const [mutation, { data, loading, called }] = useMutation<
    CancelDocumentsMutation,
    Input
  >(CANCEL_DOCUMENT);

  const cancelDocuments = (variables: Input, onSuccess?: () => void) =>
    mutation({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            documents() {
              const existingDocuments = cache.readQuery<DocumentsQuery>({
                query: DOCUMENTS,
              });
              if (!existingDocuments?.documents.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const ids = isSome ? (variables as { ids: string[] }).ids : [];

              const items = existingDocuments.documents.items?.map((doc) =>
                !isSome || ids.includes(doc.id)
                  ? { ...doc, status: DocumentStatusEnum.Cancelled }
                  : doc
              );

              cache.writeQuery<DocumentsQuery>({
                query: DOCUMENTS,
                data: {
                  documents: {
                    ...existingDocuments.documents,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.cancelDocuments?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => cancelDocuments(variables, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    cancelDocuments,
    isLoading: loading,
    called,
    errors: data?.cancelDocuments?.errors ?? [],
  };
};
