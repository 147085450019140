import { useContext, useState } from "react";

import { Textarea } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { CheckListFragment } from "graphql/fragments";
import { useUpdateCheckListMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  checkList: Pick<CheckListFragment, "id" | "jobDescription">;
}

export default ({ checkList, ...props }: Props) => {
  const [jobDescription, setJobDescription] = useState(
    checkList.jobDescription ?? ""
  );

  const { updateCheckList } = useUpdateCheckListMutation();

  const { t } = useTranslation("checkmore");
  const { closeOnePopup } = useContext(PopupContext);

  const onSubmit = () => {
    if (!jobDescription) return;

    const attributes = { jobDescription };
    updateCheckList({ id: checkList.id, attributes }, closeOnePopup);
  };

  const formId = "checklist-job-description-form";

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t(
        "checkRequest.jobDescriptionPopup.title",
        "Edit job description"
      )}
      footerActions={[
        {
          id: "check_more_request__job_description-submit",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("checkRequest.jobDescriptionPopup.button.save", "Save"),
        },
      ]}
    >
      <form id={formId} onSubmit={onSubmit}>
        <Textarea
          id="jobDescription"
          name="jobDescription"
          label={t(
            "checkRequest.jobDescriptionPopup.description.label",
            "Job description"
          )}
          onChange={(event) => setJobDescription(event.target.value)}
          defaultValue={jobDescription}
          rows={12}
        />
      </form>
    </PopupWrapper>
  );
};
