import { lazy } from "react";

const PayoutService = lazy(() => import("./PayoutService"));
const CreditNote = lazy(() => import("./CreditNote"));
const CreditNoteCreation = lazy(() => import("./CreditNoteCreation"));
const Invoices = lazy(() => import("./Invoices"));
const Invoice = lazy(() => import("./Invoice"));
const InvoiceCreation = lazy(() => import("./InvoiceCreation"));
const InvoiceDuplication = lazy(() => import("./InvoiceDuplication"));
const Payslips = lazy(() => import("./Payslips"));
const PublicInvoice = lazy(() => import("./PublicInvoice"));
const Purchases = lazy(() => import("./Purchases"));
const FreelanceProfileWizard = lazy(() => import("./FreelanceProfileWizard"));

export {
  PayoutService,
  CreditNote,
  CreditNoteCreation,
  Invoices,
  Invoice,
  InvoiceCreation,
  InvoiceDuplication,
  Payslips,
  PublicInvoice,
  Purchases,
  FreelanceProfileWizard,
};
