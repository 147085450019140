import { useState } from "react";
import {
  HiOutlineOfficeBuilding,
  HiOutlinePencilAlt,
  HiOutlineUserCircle,
} from "react-icons/hi";

import { AccountFragment } from "graphql/fragments";
import {
  useDestroyAccountSymbolMutation,
  useUpdateAccountMutation,
} from "graphql/mutations";
import { AccountTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  account: Pick<
    AccountFragment,
    "accountType" | "symbol" | "symbolDisplayPath" | "canManage"
  >;
  maxSize?: number;
}

export default ({ account, maxSize = 5000_000 }: Props): JSX.Element => {
  const [fileError, setFileError] = useState<string>();

  const { destroyAccountSymbol } = useDestroyAccountSymbolMutation();
  const { updateAccount } = useUpdateAccountMutation();

  const { t } = useTranslation("common");

  const isOrg = account.accountType === AccountTypeEnum.Team;
  const DefaultIcon = isOrg ? HiOutlineOfficeBuilding : HiOutlineUserCircle;

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const file = fileList[0];
    if (file.size > maxSize)
      return setFileError(
        t("avatarUpload.error.size", "Please provide a file smaller than 5mb")
      );

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (typeof target?.result !== "string") return;

      updateAccount({
        attributes: { symbol: { data: target.result } },
        personal: !isOrg,
      });
    };
    reader.readAsDataURL(file);
  };

  const hasUploadedFile = Boolean(account.symbol);
  const symbol = hasUploadedFile
    ? `${process.env.REACT_APP_API_URL}${account.symbolDisplayPath}`
    : undefined;

  const errorId = "upload-error";

  return (
    <div
      className={tw("w-full", "flex", "flex-col", "space-y-4", "items-center")}
    >
      <div
        className={tw(
          "relative",
          "rounded-full",
          "w-24",
          "h-24",
          "self-center"
        )}
      >
        {symbol ? (
          <img
            src={symbol}
            className={tw(
              "rounded-full",
              "align-middle",
              "border-none",
              "h-full",
              "w-full",
              "object-cover"
            )}
          />
        ) : (
          <DefaultIcon
            size={96}
            strokeWidth={0.5}
            className={tw("rounded-full", "bg-silver", { "py-3": isOrg })}
          />
        )}

        {account.canManage && (
          <label
            className={tw(
              "absolute",
              "bottom-0",
              "right-0",
              "rounded-full",
              "cursor-pointer"
            )}
          >
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onFileUpload}
              aria-invalid={Boolean(fileError)}
              aria-describedby={errorId}
            />

            <span className={tw("block", "p-2", "bg-white", "rounded-full")}>
              <HiOutlinePencilAlt size={16} />
            </span>
          </label>
        )}
      </div>

      {fileError && (
        <p className={tw("text-error", "text-center")} id={errorId}>
          {fileError}
        </p>
      )}

      {account.canManage && hasUploadedFile && (
        <button
          className={tw("m-auto", "underline")}
          onClick={() => destroyAccountSymbol({ personal: !isOrg })}
        >
          {t("avatarUpload.button.destroyImage", "Delete symbol")}
        </button>
      )}
    </div>
  );
};
