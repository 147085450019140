import { useEffect, useState } from "react";

import PageNav from "components/common/basic/PageNav";
import { useFreelanceProfilesLazyQuery } from "graphql/queries";
import { FreelanceProfilesFilter } from "graphql/types";
import { tw } from "utils/tw";

import PayoutProfileCard from "./PayoutProfileCard";

interface Props {
  filter?: FreelanceProfilesFilter;
}

export default ({ filter }: Props): JSX.Element | null => {
  const perPage = 6;
  const [page, setPage] = useState(1);

  const { getFreelanceProfiles, freelanceProfiles, total, isLoading, called } =
    useFreelanceProfilesLazyQuery();

  useEffect(() => {
    fetchAndSetPage(1);
  }, []);

  const fetchAndSetPage = (p: number) =>
    getFreelanceProfiles({
      page: p,
      perPage,
      filter,
    }).then(() => setPage(p));

  if (!called || isLoading || freelanceProfiles.length === 0) return null;

  return (
    <>
      <div className={tw("flex", "flex-col", "space-y-4")}>
        {freelanceProfiles.map((profile) => (
          <PayoutProfileCard {...profile} key={profile.id} />
        ))}
      </div>

      {total > perPage ? (
        <PageNav
          currentPage={page}
          totalPages={Math.ceil(total / perPage)}
          onPageChange={fetchAndSetPage}
        />
      ) : undefined}
    </>
  );
};
