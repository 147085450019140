/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  SUBSCRIPTION_FRAGMENT,
  SubscriptionFragment,
} from "graphql/fragments";
import { SESSION, SessionQuery } from "graphql/queries";
import { FeatureEnum } from "graphql/types";

const UPDATE_SUBSCRIPTION = gql`
  mutation ($pricePlanId: ID!) {
    updateSubscription(input: { pricePlanId: $pricePlanId }) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  subscription?: SubscriptionFragment | null;
  errors: ErrorFragment[];
}

interface UpdateSubscriptionMutation {
  updateSubscription: Payload | null;
}

export const useUpdateSubscriptionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateSubscriptionMutation,
    { pricePlanId: string }
  >(UPDATE_SUBSCRIPTION);

  const updateSubscription = (
    pricePlanId: string,
    onSuccess?: (subscription: SubscriptionFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables: { pricePlanId },
      update(cache, { data }) {
        const updatedSubscription = data?.updateSubscription?.subscription;
        if (!updatedSubscription) return;

        cache.modify({
          fields: {
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              const { account } = sessionQuery.session;
              const checksSubscription =
                updatedSubscription.pricePlan.feature === FeatureEnum.Checks
                  ? updatedSubscription
                  : account.checksSubscription;
              const whistleblowingSubscription =
                updatedSubscription.pricePlan.feature ===
                FeatureEnum.Whistleblowing
                  ? updatedSubscription
                  : account.whistleblowingSubscription;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    account: {
                      ...sessionQuery.session.account,
                      checksSubscription,
                      whistleblowingSubscription,
                    },
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateSubscription?.errors ?? [];
        const subscription = data?.updateSubscription?.subscription;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && subscription && onSuccess(subscription),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    updateSubscription,
    isLoading: loading,
    subscription: data?.updateSubscription?.subscription,
    errors: data?.updateSubscription?.errors ?? [],
  };
};
