import { gql } from "@apollo/client";

import { ReferencePerson } from "graphql/types";

export const REFERENCE_PERSON_FRAGMENT = gql`
  fragment ReferencePersonFragment on ReferencePerson {
    email
    name
    phone
    referenceNumber
  }
`;

export interface ReferencePersonFragment
  extends Pick<
    ReferencePerson,
    "email" | "name" | "phone" | "referenceNumber"
  > {}
