import { useContext } from "react";
import {
  HiOutlineCheckCircle,
  HiOutlineCollection,
  HiOutlineDownload,
  HiOutlineTrash,
} from "react-icons/hi";

import { PublicWbReportFragment, WbReportFragment } from "graphql/fragments";
import {
  useCloseWbReportMutation,
  useDeleteWbReportMutation,
  useReopenWbReportMutation,
} from "graphql/mutations";
import { WbReportStatusEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

export const useWbReportActions = () => {
  const { closeWbReport } = useCloseWbReportMutation();
  const { reopenWbReport } = useReopenWbReportMutation();
  const { deleteWbReport } = useDeleteWbReportMutation();

  const language = window.location.pathname.split("/")[2];
  const { t } = useTranslation("tellmore");
  const { openPopup } = useContext(PopupContext);

  const closeAction = (id: string) =>
    openPopup({
      id: "ConfirmAction",
      props: {
        onConfirmation: () => closeWbReport({ id }),
        confirmationLabel: t(
          "wbReportActions.closeWbReportConfirmation.label",
          "Close report"
        ),
        confirmationHeading: t(
          "wbReportActions.closeWbReportConfirmation.heading",
          "Close report"
        ),
        confirmationBody: t(
          "wbReportActions.closeWbReportConfirmation.body",
          "Are you sure you this report can be closed?"
        ),
      },
    });
  const reopenAction = (id: string) => reopenWbReport({ id });

  const deleteAction = (id: string) =>
    openPopup({
      id: "ConfirmAction",
      props: {
        onConfirmation: () => deleteWbReport({ id }),
        confirmationLabel: t(
          "wbReportActions.deleteWbReportConfirmation.label",
          "Delete report"
        ),
        confirmationHeading: t(
          "wbReportActions.deleteWbReportConfirmation.heading",
          "Are you sure you want to delete this report?"
        ),
        confirmationBody: t(
          "wbReportActions.deleteWbReportConfirmation.body",
          "We will mark this report as deleted by you and we will notify the reporter about the deletion including any other assignees from your organisation."
        ),
        isDestructive: true,
        closeOnConfirmation: "one",
      },
    });

  const getWbReportActions = (wbReport: WbReportFragment) => [
    ...([WbReportStatusEnum.Submitted, WbReportStatusEnum.InProgress].includes(
      wbReport.status
    )
      ? [
          {
            id: "wb_report_actions-close_wb_report" as const,
            onClick: () => closeAction(wbReport.id),
            Icon: HiOutlineCheckCircle,
            label: t("wbReportActions.closeWbReport.label", "Close"),
          },
        ]
      : []),
    ...([WbReportStatusEnum.Closed].includes(wbReport.status)
      ? [
          {
            id: "wb_report_actions-reopen_wb_report" as const,
            onClick: () => reopenAction(wbReport.id),
            Icon: HiOutlineCollection,
            label: t("wbReportActions.openWbReport.label", "Open"),
          },
        ]
      : []),
    ...(wbReport.downloadPath
      ? [
          {
            id: "wb_report_actions-download_wb_report" as const,
            to: `${process.env.REACT_APP_API_URL + wbReport.downloadPath}?language=${language}`,
            isExternal: true,
            Icon: HiOutlineDownload,
            label: t("wbReportActions.downloadWbReport.label", "Download"),
          },
        ]
      : []),
    ...([
      WbReportStatusEnum.Submitted,
      WbReportStatusEnum.InProgress,
      WbReportStatusEnum.Closed,
    ].includes(wbReport.status)
      ? [
          // openLog,
          {
            id: "wb_report_actions-delete_wb_report" as const,
            onClick: () => deleteAction(wbReport.id),
            Icon: HiOutlineTrash,
            label: t("wbReportActions.deleteWbReport.label", "Delete"),
            isDestructive: true,
          },
        ]
      : []),
  ];

  const getPublicWbReportActions = (wbReport: PublicWbReportFragment) => [
    ...(wbReport.downloadPath
      ? [
          {
            id: "wb_report_actions-download_wb_report" as const,
            to: `${process.env.REACT_APP_API_URL + wbReport.downloadPath}?language=${language}`,
            isExternal: true,
            Icon: HiOutlineDownload,
            label: t("wbReportActions.downloadWbReport.label", "Download"),
            isDestructive: false,
            onClick: undefined,
          },
        ]
      : []),
  ];

  return { getWbReportActions, getPublicWbReportActions };
};
