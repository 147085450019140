/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { QueryFreelanceProfilesArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

const ENROLLED_FREELANCE_PROFILES = gql`
  query ($page: Int, $perPage: Int, $sortBy: FreelanceProfilesSortByEnum) {
    enrolledFreelanceProfiles(page: $page, perPage: $perPage, sortBy: $sortBy) {
      exportPath
      items {
        ...FreelanceProfileFragment
      }
      total
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
`;

export type EnrolledFreelanceProfilesQuery = {
  enrolledFreelanceProfiles: PaginatedPayload<FreelanceProfileFragment>;
};

type QueryArgs = Pick<
  QueryFreelanceProfilesArgs,
  "page" | "perPage" | "sortBy"
>;

export const useEnrolledFreelanceProfilesLazyQuery = () => {
  const [getFreelanceProfilesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<EnrolledFreelanceProfilesQuery, QueryArgs>(
      ENROLLED_FREELANCE_PROFILES,
      { fetchPolicy: "network-only" }
    );

  const freelanceProfiles =
    data?.enrolledFreelanceProfiles.items?.filter(Boolean) ?? [];

  const resolveRef =
    useRef<(freelanceProfiles?: FreelanceProfileFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(freelanceProfiles);
      resolveRef.current = undefined;
    }
  }, [freelanceProfiles, called, isLoading]);

  const getFreelanceProfiles = async (args?: QueryArgs) => {
    const variables = { ...args };

    getFreelanceProfilesQuery({ variables });

    return new Promise<FreelanceProfileFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getFreelanceProfiles, freelanceProfiles, isLoading, called };
};
