import { ReferencePerson, ReferencePersonAttributes } from "graphql/types";

export default ({
  __typename,
  ...referencePerson
}: ReferencePerson): ReferencePersonAttributes => ({
  ...referencePerson,
  email: referencePerson.email ?? "",
  phone: referencePerson.phone ?? "",
});
