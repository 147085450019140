import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Button, Divider, Icon, Link } from "components/common/basic";
import { AccountFragment, SessionFragment } from "graphql/fragments";
import { AccountTypeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useRoutePaths } from "utils";
import { logo } from "utils/brand";
import { tw } from "utils/tw";

import { MenuList } from "./common";
import useSideMenuItems from "./common/useSideMenuItems";

interface Props {
  session: SessionFragment;
}

export default ({ session }: Props): JSX.Element => {
  const [redirectAccount, setRedirectAccount] = useState<AccountFragment>();
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation("common");

  const sideMenuItems = useSideMenuItems({ session });
  const { openPopup } = useContext(PopupContext);

  const navigate = useNavigate();
  const getRoutePathGetter = useRoutePaths();

  useEffect(() => {
    if (!redirectAccount) return;

    const isAvailable = session.accounts.some(
      ({ id }) => id === redirectAccount.id
    );
    if (!isAvailable) return;

    const redirectToPath = getRoutePathGetter(
      { module: "account", routeName: "SETTINGS" },
      {
        account: {
          slug: redirectAccount.slug,
          type: redirectAccount.accountType,
        },
      }
    );

    navigate(redirectToPath);
  }, [session.accounts.length]);

  const isDeletingPersonalAccount = Boolean(
    session.accounts.find(
      ({ accountType }) => accountType === AccountTypeEnum.Personal
    )?.deletionScheduledOn
  );

  const sideMenuStyles = tw(
    "sticky",
    "top-0",
    "h-screen",
    "overflow-auto",
    "z-50",
    "bg-white",
    "shadow-lg",
    "shrink-0",
    "flex",
    "flex-col",
    "items-center",
    { "w-72": isExpanded }
  );

  const logoStyles = tw("h-6", "w-auto");

  const isPayoutPartner = process.env.REACT_APP_BRAND === "payoutpartner";

  return (
    <div className={sideMenuStyles}>
      <div
        className={tw("my-4", "p-4", "flex", "justify-between", {
          "w-full": isExpanded,
        })}
      >
        {isExpanded && (
          <Link
            id="sideMenu-go_to_homepage"
            to={t("links.homepage", "https://manymore.com/en")}
            isExternal
            unstyled
          >
            <img className={logoStyles} src={logo} alt="logo" />
          </Link>
        )}

        <button onClick={() => setIsExpanded(!isExpanded)}>
          <Icon
            color="text-gray-900"
            icon={isExpanded ? "MmMenuClose" : "MmMenuOpen"}
            size={24}
          />
        </button>
      </div>

      <div className={tw("w-full", "px-4")}>
        <Divider />
      </div>

      <MenuList iconsOnly={!isExpanded} menuItems={sideMenuItems} />

      {isPayoutPartner && !isDeletingPersonalAccount && (
        <div className={tw("w-full", "p-4", "mt-auto")}>
          <Button
            id="sideMenu-create_team"
            variant="tertiary"
            size="sm"
            fullWidth
            onClick={() =>
              openPopup({
                id: "AddTeam",
                props: { onSuccess: setRedirectAccount },
              })
            }
            LeadingIcon={isExpanded ? HiPlus : undefined}
          >
            {isExpanded ? (
              t("sideMenu.button.addTeam", "Create a new team")
            ) : (
              <HiPlus />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};
