import { useContext, useEffect, useRef } from "react";
import {
  // HiOutlineDocumentDuplicate,
  HiOutlineDocument,
  HiOutlineFingerPrint,
  HiOutlinePencilAlt,
  HiPlus,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { Button } from "components/common/basic";
import { AccountTypeEnum } from "graphql/types";
import { useMenu } from "hooks";
import { AuthContext } from "providers/Authentication";
import { Trans, useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { HeaderTemplate, MenuList, TopMenuList } from "./common";

export default (): JSX.Element => {
  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingCreateMenu, setIsShowingCreateMenu] = useMenu(createMenuRef);
  const toggleCreateMenu = () => setIsShowingCreateMenu(!isShowingCreateMenu);

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  useEffect(() => {
    setIsShowingCreateMenu(false);
  }, [pathname]);

  const createMenuStyles = tw(
    "origin-top-right",
    "absolute",
    "right-0",
    "mt-2",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  const isDeletingAccount = Boolean(session?.account.deletionScheduledOn);

  const canCreateCheck =
    session?.account.features.checks &&
    session.account.accountType === AccountTypeEnum.Team;
  const hasCreationFeature =
    !isDeletingAccount &&
    Boolean(
      session?.account.features.invoices ||
        session?.account.features.eSignatures ||
        canCreateCheck
    );

  return (
    <>
      <HeaderTemplate
        left={<></>}
        right={
          <>
            <div className={tw("relative")} ref={createMenuRef}>
              {hasCreationFeature && (
                <Button
                  id="app_header-create"
                  size="md"
                  aria-label="creation menu"
                  aria-expanded={isShowingCreateMenu}
                  onClick={toggleCreateMenu}
                  TrailingIcon={HiPlus}
                >
                  <Trans ns="common" i18nKey="header_loggedIn.button.create">
                    Create
                  </Trans>
                </Button>
              )}

              {isShowingCreateMenu && (
                <>
                  <nav className={createMenuStyles}>
                    <MenuList
                      menuItems={[
                        ...(session?.account.features.invoices
                          ? [
                              {
                                id: "app_header-create_one_invoice" as const,
                                Icon: HiOutlineDocument,
                                label: t(
                                  "header_loggedIn.link.createOneInvoice",
                                  "One invoice"
                                ),
                                to: getRoutePath({
                                  module: "invoicemore",
                                  routeName: "INVOICE_CREATION",
                                }),
                              },
                              // {
                              //   id: "app_header-create_multiple_invoices" as const,
                              //   Icon: HiOutlineDocumentDuplicate,
                              //   label: t(
                              //     "header_loggedIn.link.createMultipleInvoices",
                              //     "Multiple invoices"
                              //   ),
                              //   to: getRoutePath("INVOICE_IMPORT"),
                              // },
                            ]
                          : []),
                        ...(session?.account.features.eSignatures
                          ? [
                              {
                                id: "app_header-create_esignature" as const,
                                Icon: HiOutlinePencilAlt,
                                label: t(
                                  "header_loggedIn.link.createESignature",
                                  "New eSignature"
                                ),
                                to: getRoutePath({
                                  module: "signmore",
                                  routeName: "CREATE_DOCUMENT_WIZARD",
                                }),
                              },
                            ]
                          : []),
                        ...(canCreateCheck
                          ? [
                              {
                                id: "app_header-create_check" as const,
                                Icon: HiOutlineFingerPrint,
                                label: t(
                                  "header_loggedIn.link.createCheck",
                                  "New check"
                                ),
                                to: getRoutePath({
                                  module: "checkmore",
                                  routeName: "CHECK_TYPE_SELECTION",
                                }),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </nav>
                </>
              )}
            </div>
          </>
        }
        menuList={<TopMenuList />}
        showMenuToggle
      />
    </>
  );
};
