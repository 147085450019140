/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { INVOICE_FRAGMENT, InvoiceFragment } from "graphql/fragments";
import { QueryInvoicesArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const INVOICES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: InvoicesFilter
    $sortBy: InvoicesSortByEnum
  ) {
    invoices(page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy) {
      exportPath
      items {
        ...InvoiceFragment
      }
      total
    }
  }
  ${INVOICE_FRAGMENT}
`;

export type InvoicesQuery = {
  invoices: PaginatedPayload<InvoiceFragment>;
};

type QueryArgs = Pick<
  QueryInvoicesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useInvoicesLazyQuery = () => {
  const [getInvoicesQuery, { data, loading: isLoading, called }] = useLazyQuery<
    InvoicesQuery,
    QueryArgs
  >(INVOICES, {
    fetchPolicy: "network-only",
  });

  const invoices = data?.invoices.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(invoices?: InvoiceFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(invoices);
      resolveRef.current = undefined;
    }
  }, [invoices, called, isLoading]);

  const getInvoices = async (args?: QueryArgs) => {
    const variables = { ...args };

    getInvoicesQuery({ variables });

    return new Promise<InvoiceFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getInvoices, invoices, isLoading, called };
};
