import { TFunction } from "i18next";

import { CheckListStatusLabel } from "components/checkmore/composite";
import { CheckListFragment } from "graphql/fragments";
import { CheckListEventTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { getTitleFromCheckRequestType } from "utils";
import { atDateToMoment } from "utils";
import { tw } from "utils/tw";

interface Props {
  checkList: CheckListFragment;
}

export default ({ checkList }: Props) => {
  const { t } = useTranslation("checkmore");

  return (
    <div
      className={tw("px-6", "pt-4", "w-full", "flex", "flex-col", "space-y-2")}
    >
      {checkList.events.map(
        ({ eventType, status, occurredAt, actorName, note }, index) => {
          const statusMessage = getMessageFromEventType({
            checkType: getTitleFromCheckRequestType(
              checkList.checkRequest.type,
              t
            ),
            receiverName: checkList.name,
            eventType,
            actorName,
            note,
            t,
          });

          const isLast = index + 1 === checkList.events.length;

          return (
            <div
              key={eventType + occurredAt}
              className={tw("flex", "space-x-4")}
            >
              <div className={tw("flex", "flex-col", "items-center")}>
                <span
                  className={tw(
                    "h-4",
                    "w-4",
                    "mb-2",
                    "bg-gray-100",
                    "rounded-full"
                  )}
                />

                {!isLast && (
                  <span className={tw("flex-1", "w-0.5", "bg-gray-100")} />
                )}
              </div>

              <div
                className={tw(
                  "flex",
                  "flex-col",
                  "items-start",
                  "space-y-2",
                  "pb-4"
                )}
              >
                <CheckListStatusLabel
                  viewer="sender"
                  badgeSize="sm"
                  variant="tag"
                  status={status}
                />

                <p className={tw("pt-1", "text-sm", "text-gray-500")}>
                  {atDateToMoment(occurredAt).format("DD MMM YYYY - HH:mm")}
                </p>

                <p className={tw("text-sm", "text-gray-900")}>
                  {statusMessage}
                </p>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

const getMessageFromEventType = ({
  checkType,
  receiverName,
  eventType,
  actorName = "-",
  note = "-",
  t,
}: {
  checkType: string;
  receiverName: string;
  eventType: CheckListEventTypeEnum;
  actorName?: string;
  note?: string;
  t: TFunction;
}): string => {
  switch (eventType) {
    // case CheckStatusEnum.ActionRequired:
    //   return t(
    //     "checkEventMessage.actionRequired",
    //     "The name does not match the invitation name"
    //   );
    case CheckListEventTypeEnum.CheckListCancelled:
      return t(
        "checkEventMessage.cancelled",
        "This {{ checkType }} check has been cancelled",
        { checkType, receiverName }
      );
    case CheckListEventTypeEnum.CheckListCleared:
      return t("checkEventMessage.cleared", "Cleared {{ receiverName }}", {
        receiverName,
      });
    case CheckListEventTypeEnum.CheckListConsented:
      return t(
        "checkEventMessage.inProgress",
        "Started the {{ checkType }} process",
        { checkType }
      );
    case CheckListEventTypeEnum.CheckListRefused:
      return t(
        "checkEventMessage.refused",
        "Refused to do the {{ checkType }} check",
        { checkType, receiverName }
      );
    case CheckListEventTypeEnum.CheckListRejected:
      return t(
        "checkEventMessage.rejected",
        "Rejected {{ receiverName }} submitted information",
        { receiverName }
      );
    case CheckListEventTypeEnum.CheckListRequested:
      return t(
        "checkEventMessage.requestSent",
        "Sent the {{ checkType }} request to {{ receiverName }}",
        { checkType, receiverName }
      );
    case CheckListEventTypeEnum.CheckListReminderSent:
      return t(
        "checkEventMessage.reminderSent",
        "Sent a reminder to {{ receiverName }}",
        { receiverName }
      );
    case CheckListEventTypeEnum.CheckListAutoReminderSent:
      return t(
        "checkEventMessage.autoReminderSent",
        "Sent an automatic reminder to {{ receiverName }}",
        { receiverName }
      );
    case CheckListEventTypeEnum.CheckListFinalReminderSent:
      return t(
        "checkEventMessage.finalReminderSent",
        "Sent the final reminder to {{ receiverName }}",
        { receiverName }
      );
    case CheckListEventTypeEnum.CheckListInReview:
      return t(
        "checkEventMessage.inReview",
        "The {{ checkType }} request has been completed by {{ receiverName }} and is being reviewed",
        { checkType, receiverName }
      );
    case CheckListEventTypeEnum.CheckListPurposeLetterGenerated:
      return t(
        "checkEventMessage.purposeLetterGenerated",
        "A purpose letter has been generated"
      );

    // Check events
    case CheckListEventTypeEnum.CheckCleared:
      return t("checkEventMessage.checkCleared", "Check cleared");

    case CheckListEventTypeEnum.CheckConsented:
      return t("checkEventMessage.checkConsented", "Check consented");
    case CheckListEventTypeEnum.CheckInReview:
      return t("checkEventMessage.checkInReview", "Check in review");
    case CheckListEventTypeEnum.CheckRefused:
      return t("checkEventMessage.checkRefused", "Check refused");
    case CheckListEventTypeEnum.CheckRejected:
      return t("checkEventMessage.checkRejected", "Check rejected");
    case CheckListEventTypeEnum.CheckOverridden:
      return t(
        "checkEventMessage.checkOverridden",
        "Check overridden by {{ actorName }}: {{ note }}",
        { actorName, note }
      );

    // CRC auto check event
    case CheckListEventTypeEnum.CheckFileAnalysesRefuted:
      return t(
        "checkEventMessage.checkFileAnalysesRefuted",
        "Autocheck refuted"
      );
    case CheckListEventTypeEnum.CheckFileAnalysesSucceeded:
      return t(
        "checkEventMessage.checkFileAnalysesSucceeded",
        "Autocheck succeeded"
      );

    default:
      return ((_eventType: never) => "UNKNOWN EVENT")(eventType);
  }
};
