import { gql } from "@apollo/client";

import { CrcPurpose } from "graphql/types";

export const CRC_PURPOSE_FRAGMENT = gql`
  fragment CrcPurposeFragment on CrcPurpose {
    id
    category
    legalBasis
    purpose
    tags
  }
`;

export interface CrcPurposeFragment
  extends Pick<
    CrcPurpose,
    "id" | "category" | "legalBasis" | "purpose" | "tags"
  > {}
