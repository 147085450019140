import { IconType } from "react-icons";

import { tw } from "utils/tw";

type BadgeSize = "sm" | "lg";
type BadgeVariant =
  | "default"
  | "default_outlined"
  | "pending"
  | "success"
  | "warning"
  | "error";

interface Props {
  size?: BadgeSize;
  variant?: BadgeVariant;
  LeadingIcon?: IconType;
  text?: string;
}

export default ({
  variant = "default",
  size = "sm",
  LeadingIcon,
  text,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const base = tw(
    "inline-flex",
    "space-x-1",
    "items-center",
    "justify-center",
    "rounded",
    "text-sm",
    "font-semibold",
    "text-deepBlue-900",
    "px-3",
    {
      "py-0.5": size === "sm",
      "py-2": size === "lg",
    }
  );

  const badgeStyles = (() => {
    switch (variant) {
      case "default":
        return tw(base, "bg-deepBlue-50");

      case "default_outlined":
        return tw(base, "border", "border-deepBlue-100");

      case "pending":
        return tw(base, "bg-blue-100");

      case "success":
        return tw(base, "bg-badge-success");

      case "warning":
        return tw(base, "bg-badge-warning");

      case "error":
        return tw(base, "bg-badge-error");

      default:
        return base;
    }
  })();

  const iconSize = (() => {
    switch (size) {
      case "sm":
        return 12;

      case "lg":
        return 16;

      default:
        return undefined;
    }
  })();

  return (
    <div className={badgeStyles} data-testid="badge">
      {LeadingIcon && (
        <LeadingIcon
          size={iconSize}
          data-testid="leading-icon"
          className={tw("shrink-0")}
        />
      )}

      {text && <span>{text}</span>}
    </div>
  );
};
