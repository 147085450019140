import { useContext } from "react";

import { Button } from "components/common/basic";
import { Banner } from "components/common/composite";
import { AccountFragment } from "graphql/fragments";
import { useUpdateSubscriptionMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { onDateToMoment } from "utils";

interface Props {
  account: AccountFragment;
}

export default ({
  account: { whistleblowingSubscription, checksSubscription },
}: Props): JSX.Element => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);
  const { updateSubscription } = useUpdateSubscriptionMutation();

  const getRightColumn = (pricePlanId: string) => (
    <Button
      id="orgAccountSettings_subscriptions-restoreSubscription"
      variant="tertiary"
      size="sm"
      onClick={() =>
        openPopup({
          id: "ConfirmAction",
          props: {
            onConfirmation: () => updateSubscription(pricePlanId),
            confirmationLabel: t(
              "accountSettings_org.subscriptions.restoreConfirmation.label",
              "Confirm"
            ),
            confirmationHeading: t(
              "accountSettings_org.subscriptions.restoreConfirmation.heading",
              "Restore subscription"
            ),
            confirmationBody: t(
              "accountSettings_org.subscriptions.restoreConfirmation.body",
              "Are you sure you want to reactivate your subscription? Restoring it will ensure everything works just as it did before."
            ),
          },
        })
      }
    >
      {t(
        "accountSettings_org.subscriptions.banner.button.restoreSubscription",
        "Restore subscription"
      )}
    </Button>
  );

  return (
    <>
      {checksSubscription.cancelledOn && (
        <Banner
          variant="danger"
          title={t(
            "accountSettings_org.subscriptions.banner.cancelledCheckmore.title",
            "Cancelled Checkmore subscription"
          )}
          body={t(
            "accountSettings_org.subscriptions.banner.cancelledCheckmore.body",
            "Your Checkmore subscription will be cancelled {{ date }}",
            {
              date: onDateToMoment(checksSubscription.cancelledOn).format(
                "DD MMM. YYYY"
              ),
            }
          )}
          rightColumn={getRightColumn(checksSubscription.pricePlan.id)}
        />
      )}

      {checksSubscription.downgradedOn && (
        <Banner
          title={t(
            "accountSettings_org.subscriptions.banner.downgradedCheckmore.title",
            "Reduced Checkmore licenses"
          )}
          body={t(
            "accountSettings_org.subscriptions.banner.downgradedCheckmore.body",
            "Your Checkmore license will be reduced {{ date }}",
            {
              date: onDateToMoment(checksSubscription.downgradedOn).format(
                "DD MMM. YYYY"
              ),
            }
          )}
          rightColumn={getRightColumn(checksSubscription.pricePlan.id)}
        />
      )}

      {whistleblowingSubscription.cancelledOn && (
        <Banner
          variant="danger"
          title={t(
            "accountSettings_org.subscriptions.banner.cancelledTellmore.title",
            "Cancelled Tellmore subscription"
          )}
          body={t(
            "accountSettings_org.subscriptions.banner.cancelledTellmore.body",
            "Your Tellmore subscription will be cancelled {{ date }}",
            {
              date: onDateToMoment(
                whistleblowingSubscription.cancelledOn
              ).format("DD MMM. YYYY"),
            }
          )}
          rightColumn={getRightColumn(whistleblowingSubscription.pricePlan.id)}
        />
      )}

      {whistleblowingSubscription.downgradedOn && (
        <Banner
          title={t(
            "accountSettings_org.subscriptions.banner.downgradedTellmore.title",
            "Reduced Tellmore licenses"
          )}
          body={t(
            "accountSettings_org.subscriptions.banner.downgradedTellmore.body",
            "Your Tellmore licenses will be reduced {{ date }}",
            {
              date: onDateToMoment(
                whistleblowingSubscription.downgradedOn
              ).format("DD MMM. YYYY"),
            }
          )}
          rightColumn={getRightColumn(whistleblowingSubscription.pricePlan.id)}
        />
      )}
    </>
  );
};
