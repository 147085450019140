import { TFunction } from "i18next";

import { FeatureEnum } from "graphql/types";

interface Props {
  feature: FeatureEnum;
}

export default (t: TFunction, { feature }: Props) => {
  switch (feature) {
    case FeatureEnum.Checks:
      return {
        usagePreventsDowngrade: {
          title: t(
            "account:popup.subscriptionChange_checkmore.banner.usagePreventsDowngrade.title",
            "Downgrading not possible"
          ),
          body: t(
            "account:popup.subscriptionChange_checkmore.banner.usagePreventsDowngrade.body",
            "Unfortunately you are not able to downgrade right now because you have more candidates on your peoples page than you wish to downgrade too. You need to delete some candidates on your peoples page first."
          ),
          actionId: "subscription_change_popup-go_to_people" as const,
          actionLabel: t(
            "account:popup.subscriptionChange_checkmore.banner.usagePreventsDowngrade.actionLabel",
            "Take me to the people page"
          ),
        },
        billingInfo: {
          title: t(
            "account:popup.subscriptionChange_checkmore.banner.billingInfo.title",
            "Subscription cost"
          ),
          body: t(
            "account:popup.subscriptionChange_checkmore.banner.billingInfo.body",
            "Changes to subscriptions will apply from the next subscription period as the current period has already been invoiced"
          ),
        },
        contactAdmin: {
          title: t(
            "account:popup.subscriptionChange_checkmore.banner.contactAdmin.title",
            "Need more candidates?"
          ),
          body: t(
            "account:popup.subscriptionChange_checkmore.banner.contactAdmin.body",
            "Please contact your admin to add more candidates to your check subscription."
          ),
        },
        contactSales: {
          title: t(
            "account:popup.subscriptionChange_checkmore.banner.contactSales.title",
            "Contact your key account manager"
          ),
          body: t(
            "account:popup.subscriptionChange_checkmore.banner.contactSales.body",
            "Please contact your key account manager to manage your checkmore subscription."
          ),
          actionLink: t(
            "account:popup.subscriptionChange_checkmore.banner.contactSales.actionLink",
            "mailto:sales@manymore.com"
          ),
          actionLabel: t(
            "account:popup.subscriptionChange_checkmore.banner.contactSales.actionLabel",
            "Contact your key account manager Fredrik"
          ),
        },
      };

    case FeatureEnum.Whistleblowing:
      return {
        usagePreventsDowngrade: {
          title: t(
            "account:popup.subscriptionChange_tellmore.banner.usagePreventsDowngrade.title",
            "Downgrading not possible"
          ),
          body: t(
            "account:popup.subscriptionChange_tellmore.banner.usagePreventsDowngrade.body",
            "Unfortunately you are not able to downgrade right now because you have more report handlers active than you wish to downgrade too. You need to delete some report handlers on your whistleblowing settings page"
          ),
          actionId: "subscription_change_popup-go_to_settings" as const,
          actionLabel: t(
            "account:popup.subscriptionChange_checkmore.banner.usagePreventsDowngrade.action",
            "Take me to the settings page"
          ),
        },
        billingInfo: {
          title: t(
            "account:popup.subscriptionChange_tellmore.banner.billingInfo.title",
            "Subscription cost"
          ),
          body: t(
            "account:popup.subscriptionChange_tellmore.banner.billingInfo.body",
            "Changes to subscriptions will apply from the next subscription period as the current period has already been invoiced"
          ),
        },
        contactAdmin: {
          title: t(
            "account:popup.subscriptionChange_tellmore.banner.contactAdmin.title",
            "Need more report handlers?"
          ),
          body: t(
            "account:popup.subscriptionChange_tellmore.banner.contactAdmin.body",
            "Please contact your admin to add more report handlers to your subscription."
          ),
        },
        contactSales: {
          title: t(
            "account:popup.subscriptionChange_tellmore.banner.contactSales.title",
            "Contact your key account manager"
          ),
          body: t(
            "account:popup.subscriptionChange_tellmore.banner.contactSales.body",
            "Please contact your key account manager to manage your tellmore subscription."
          ),
          actionLink: t(
            "account:popup.subscriptionChange_tellmore.banner.contactSales.actionLink",
            "mailto:sales@manymore.com"
          ),
          actionLabel: t(
            "account:popup.subscriptionChange_tellmore.banner.contactSales.actionLabel",
            "Contact your key account manager Fredrik"
          ),
        },
      };

    default:
      return {
        usagePreventsDowngrade: {
          actionId: "TEST_ID" as const,
          actionLabel: "",
        },
        billingInfo: {},
        contactAdmin: {},
        contactSales: {
          actionLink: "",
          actionLabel: "",
        },
      };
  }
};
