import { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";

import { AccountFragment } from "graphql/fragments";
import { useMembersLazyQuery } from "graphql/queries";
import { Trans } from "translations";
import { getNameFromAccount } from "utils";
import { tw } from "utils/tw";

import SingleSearch from "./SingleSearch";

interface Props {
  onSelect: (member: AccountFragment) => void;
  label?: string;
  buttonText?: string;
  errorMessage?: string;
  defaultMember?: AccountFragment;
  omitHandlers?: boolean;
}

export default ({
  onSelect,
  label,
  buttonText,
  errorMessage,
  defaultMember,
  omitHandlers = false,
}: Props): JSX.Element => {
  const perPage = 5;
  const isWbHandler = omitHandlers === true ? false : undefined;

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [member, setMember] = useState<AccountFragment | undefined>(
    defaultMember
  );
  const { members, getMembers, total, isLoading } = useMembersLazyQuery();

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getMembers({
      page: p,
      perPage,
      filter: {
        search: searchInput ?? keyword,
        isWbHandler,
      },
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  // Get initial members
  useEffect(() => {
    fetchAndSetPage(1);
  }, []);

  const buttonLabel = (
    <>
      {buttonText ??
        (member ? (
          getNameFromAccount(member)
        ) : (
          <Trans ns="common" i18nKey="searchMember.label">
            Select a member
          </Trans>
        ))}

      <HiChevronDown size={20} />
    </>
  );

  return (
    <SingleSearch<AccountFragment>
      id="member-search"
      itemKey="id"
      items={members}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setMember(option);
        onSelect(option);
      }}
      buttonLabel={buttonLabel}
      label={label}
      errorMessage={errorMessage}
      fixedHeight="h-72"
      isLoading={isLoading}
      reloadOnOpen
    />
  );
};

const renderListItemLabel = (member: AccountFragment) => (
  <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
    {getNameFromAccount(member)} - {member.email}
  </p>
);
