import { Button } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import Pagination from "./Pagination";

interface Props<T> {
  itemKey: string;
  items: T[];
  page: number;
  pages: number;
  showPagination: boolean;
  setPage: (page: number) => void;
  renderListItemLabel: (item: T) => JSX.Element;
  onSelect: (item: T) => void;
  onClose: () => void;
  onClear?: () => void;
  listHeader?: JSX.Element;
  emptyState?: JSX.Element;
  footer?: JSX.Element;
}

export default <T,>({
  itemKey,
  items,
  page,
  pages,
  showPagination,
  setPage,
  renderListItemLabel,
  onSelect,
  onClose,
  onClear,
  listHeader,
  emptyState,
  footer,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation("common");

  const goBack = () => {
    if (page <= 1) return;

    setPage(page - 1);
  };

  const goNext = () => {
    if (page >= pages) return;

    setPage(page + 1);
  };

  if (emptyState && items.length < 1) return emptyState;

  return (
    <>
      {listHeader}

      <ul
        className={tw(
          "bg-white",
          "py-1",
          "space-y-1",
          "h-full",
          "overflow-y-auto"
        )}
      >
        {items.map((item, index) => {
          const selectOption = () => {
            onSelect(item);

            onClose();
          };

          const keyFromItem =
            Object.prototype.hasOwnProperty.call(item, itemKey) &&
            (item as { [itemKey: string]: unknown })[itemKey];
          const key = typeof keyFromItem === "string" ? keyFromItem : index;

          return (
            <li key={key}>
              <button
                type="button"
                onClick={selectOption}
                className={tw(
                  "text-left",
                  "w-full",
                  "hover:bg-silver",
                  "py-1",
                  "px-3"
                )}
              >
                {renderListItemLabel(item)}
              </button>
            </li>
          );
        })}
      </ul>

      {(showPagination || onClear) && (
        <div className={tw("p-4", "space-y-4")}>
          {showPagination && (
            <Pagination
              page={page}
              pages={pages}
              goBack={goBack}
              goNext={goNext}
            />
          )}

          {onClear && (
            <Button
              id="single_select-clear_selection"
              onClick={() => {
                onClear();

                onClose();
              }}
              variant="tertiary"
              size="md"
              fullWidth
            >
              {t("search_single.clearSelected", "Clear")}
            </Button>
          )}
        </div>
      )}

      {footer}
    </>
  );
};
