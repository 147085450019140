import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { HeaderTemplate } from "components/common/wrappers";
import { Trans } from "translations";
import { symbol } from "utils/brand";
import { tw } from "utils/tw";

interface Props {
  goBack?: () => void;
  unbranded?: boolean;
}

export default (props: Props) => {
  const navigate = useNavigate();
  const goBack = props.goBack ?? (() => navigate(-1));

  return (
    <HeaderTemplate
      left={
        <button
          id="focused_header-go_back"
          className={tw("flex", "space-x-4", "items-center")}
          onClick={goBack}
        >
          <HiChevronLeft />

          {!props.unbranded && (
            <img src={symbol} alt="Logo symbol" className={tw("w-8", "h-8")} />
          )}

          <span className={tw("font-semibold", "text-gray-900")}>
            <Trans ns="common" i18nKey="focusedHeader.back">
              Back
            </Trans>
          </span>
        </button>
      }
      right={null}
      menuList={[]}
      showMenuToggle={false}
    />
  );
};
