/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { INVOICE, InvoiceQuery } from "graphql/queries";

const DESTROY_RECEIPT = gql`
  mutation ($id: ID!) {
    destroyReceipt(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface DestroyReceiptMutation {
  destroyReceipt: Payload | null;
}

export const useDestroyReceiptMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyReceiptMutation,
    { id: string }
  >(DESTROY_RECEIPT);

  const destroyReceipt = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            node() {
              const invoice = cache.readQuery<InvoiceQuery>({
                query: INVOICE,
              });
              if (!invoice) return;

              const receipts = invoice.node.receipts.filter(
                (receipt) => receipt.id !== id
              );

              cache.writeQuery<InvoiceQuery>({
                query: INVOICE,
                data: {
                  node: {
                    ...invoice.node,
                    receipts,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyReceipt?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyReceipt,
    isLoading: loading,
    errors: data?.destroyReceipt?.errors ?? [],
  };
};
