import { PropsWithChildren, RefObject } from "react";
import { HiSearch } from "react-icons/hi";

import { Input, Spinner } from "components/common/basic";
import { useTranslation } from "translations";
import { THeight } from "tw-generated";
import { tw } from "utils/tw";

interface Props {
  menuRef: RefObject<HTMLDivElement>;
  id: string;
  defaultSearchInput: string;
  onSearch: (searchInput: string) => void;
  isOpen: boolean;
  toggleIsOpen: () => void;
  buttonLabel: JSX.Element;
  label?: string;
  placeholder?: string;
  hint?: string;
  isLoading?: boolean;
  errorMessage?: string;
  fixedHeight?: THeight;
}

export default ({
  menuRef,
  id,
  defaultSearchInput,
  onSearch,
  isOpen,
  toggleIsOpen,
  buttonLabel,
  label,
  placeholder,
  hint,
  isLoading,
  errorMessage,
  fixedHeight = "h-96",
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <div className={tw("relative")} ref={menuRef}>
      {label && (
        <div className={tw("flex", "justify-between")}>
          <label
            htmlFor={id}
            className={tw("block", "text-sm", "text-gray-700", "mb-1")}
          >
            {label}
          </label>

          {hint && (
            <span className={tw("text-sm", "text-gray-500")}>{hint}</span>
          )}
        </div>
      )}

      <button
        id={id}
        type="button"
        onClick={toggleIsOpen}
        className={tw(
          "py-2",
          "px-3",
          "rounded-md",
          "shadow-sm",
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "shadow-sm",
          "sm:text-sm",
          "rounded-md",
          "focus:ring-gray-900",
          "focus:border-gray-900",
          {
            [tw("bg-white", "border", "border-gray-300")]: !errorMessage,
            [tw("bg-error-light", "border-2", "border-error")]: !!errorMessage,
          }
        )}
        aria-describedby={errorMessage ? "search-error" : undefined}
      >
        {buttonLabel}
      </button>

      {errorMessage && (
        <p className={tw("mt-2", "text-sm", "text-error")} id="search-error">
          {errorMessage}
        </p>
      )}

      {/* Prevent closing popups when clicking outside to save */}
      {isOpen && (
        <button
          className={tw(
            "fixed",
            "left-0",
            "top-0",
            "w-screen",
            "h-screen",
            "cursor-default"
          )}
          onClick={toggleIsOpen}
        />
      )}

      {isOpen && (
        <div
          className={tw(
            "absolute",
            "z-10",
            "w-full",
            "mt-1",
            "bg-silver",
            "shadow",
            "rounded-lg",
            "flex",
            "flex-col",
            fixedHeight
          )}
        >
          <div className={tw("py-2", "px-4")}>
            <Input
              autoFocus
              id="searchInput"
              name="searchInput"
              defaultValue={defaultSearchInput}
              label={t("dropdown.searchInput.label", "Search")}
              placeholder={
                placeholder ?? t("dropdown.searchInput.label", "Search")
              }
              hideLabel
              onChange={(event) => onSearch(event.target.value)}
              LeadingIcon={HiSearch}
            />
          </div>

          {isLoading ? (
            <div className={tw("h-full", "overflow-hidden")}>
              <Spinner />
            </div>
          ) : (
            children
          )}
        </div>
      )}
    </div>
  );
};
