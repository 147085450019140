import React, { useState } from "react";
import { IconType } from "react-icons";

import { ActionID, Divider } from "components/common/basic";
import { ActionMenu } from "components/common/composite";
import { MenuItem } from "components/common/wrappers/Layout/common/MenuList/types";
import { Trans, useTranslation } from "translations";
import { THeight, TWidth } from "tw-generated";
import { TTailwindString, tw } from "utils/tw";

import manOnSofa from "assets/illustrations/emptyState/manOnSofa.svg";

import HeaderButton, { HeaderButtonProps } from "./HeaderButton";
import SortByMenu from "./SortByMenu";

export interface Column {
  title: string;
  width: TWidth;
  cellID: string;
  showTotal: boolean;
  isFixedWidth?: boolean;
  totalStyle?: TTailwindString;
  formatTotal?: (amount: number) => string;
  sortByActions?: {
    id: ActionID;
    label: string;
    Icon: IconType;
    onClick: () => void;
  }[];
}

export interface Row {
  id: string;
  cells: Record<string, JSX.Element>;
  actions?: MenuItem[];
}

interface Props {
  columns: Column[];
  rows: Row[];
  totals?: Record<string, number>;
  footer?: JSX.Element;
  footerHeight?: THeight;
  isCompact?: boolean;
  actionsLabel?: string;
  headerButton?: HeaderButtonProps;
  skeletonRowAmount?: number;
}

export default ({
  columns,
  rows,
  totals,
  footer,
  footerHeight = totals ? "h-40" : "h-56",
  isCompact,
  actionsLabel,
  headerButton,
  skeletonRowAmount = 0,
}: Props): JSX.Element => {
  const [openActionMenu, setOpenActionMenu] = useState("");
  const hasActions =
    rows.findIndex(({ actions }) => typeof actions !== "undefined") !== -1;

  const { t } = useTranslation("common");

  const tableStyles = tw(
    "w-full",
    "overflow-x-auto",
    "overflow-y-hidden",
    "grid"
  );

  const headerStyles = tw(
    "py-3",
    "px-6",
    "space-x-3",
    "flex",
    "uppercase",
    "bg-deepBlue-50",
    "text-xs",
    "font-semibold"
  );
  const columnBase = tw(
    "h-full",
    "shrink-0",
    "text-left",
    "flex",
    "items-center"
  );
  const actionColumnStyles = tw(
    columnBase,
    actionsLabel ? "w-32" : "w-16",
    "justify-center",
    "sticky",
    "right-0"
  );

  return (
    <div className={tableStyles}>
      <div className={headerStyles}>
        {columns.map((column) => {
          if (column.sortByActions)
            return (
              <SortByMenu
                key={`header-for-${column.cellID}`}
                id={`header-for-${column.cellID}`}
                className={tw(columnBase, column.width, {
                  grow: !column.isFixedWidth,
                })}
                label={column.title}
                sortByActions={column.sortByActions}
              />
            );

          return (
            <p
              key={`header-for-${column.cellID}`}
              className={tw(
                columnBase,
                column.width,
                { grow: !column.isFixedWidth },
                "whitespace-nowrap",
                "overflow-hidden",
                "text-ellipsis"
              )}
            >
              {column.title}
            </p>
          );
        })}

        {(hasActions || Boolean(headerButton)) && (
          <div className={tw(actionColumnStyles, "bg-deepBlue-50")}>
            {headerButton && <HeaderButton {...headerButton} />}
          </div>
        )}
      </div>

      {rows.map(({ id, cells, actions }) => (
        <React.Fragment key={id}>
          <Divider />

          <div
            className={tw(
              "py-2",
              "px-6",
              "space-x-3",
              "flex",
              "items-center",
              "text-sm",
              "font-semibold"
            )}
          >
            {columns.map((column) => (
              <div
                key={`value-for-${column.cellID}`}
                className={tw(columnBase, column.width, {
                  grow: !column.isFixedWidth,
                })}
              >
                {cells[column.cellID]}
              </div>
            ))}

            {hasActions && (
              <div
                className={tw(actionColumnStyles, "bg-white", {
                  "z-20": openActionMenu === id,
                })}
              >
                {actions?.length ? (
                  <ActionMenu
                    id={id}
                    menuItems={actions}
                    label={actionsLabel}
                    buttonStyles={actionsLabel ? tw("w-28") : undefined}
                    onOpen={(isOpen) => setOpenActionMenu(isOpen ? id : "")}
                    alignToSide="right"
                    hasDynamicMenuItems
                  />
                ) : null}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}

      {[...Array(skeletonRowAmount)].map((_row, index) => (
        <React.Fragment key={index}>
          <Divider />

          <div
            className={tw(
              "py-2",
              "px-6",
              "space-x-3",
              "flex",
              "items-center",
              "text-sm",
              "font-semibold"
            )}
          >
            {columns.map((column) => (
              <div
                key={`value-for-${column.cellID}`}
                className={tw(columnBase, column.width, {
                  grow: !column.isFixedWidth,
                })}
              >
                <span className={tw("bg-silver", "h-5", "w-1/3")} />
              </div>
            ))}

            {hasActions && (
              <div className={tw(actionColumnStyles, "bg-white")}>
                <span
                  className={tw("inline-block", "bg-silver", "h-5", "w-5")}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      ))}

      {rows.length === 0 && skeletonRowAmount === 0 ? (
        <div
          className={tw(
            "py-10",
            "flex",
            "flex-col",
            "items-center",
            "space-y-10"
          )}
        >
          <img
            src={manOnSofa}
            alt="A man lying down on a sofa with a dog lying down next to it"
          />

          <p className={tw("text-lg", "font-extrabold")}>
            {t(
              "table.emptyState.description",
              "Change your filters to see results"
            )}
          </p>
        </div>
      ) : (
        <Divider />
      )}

      {totals && (
        <div
          className={tw(
            "py-2",
            "px-6",
            "space-x-3",
            "flex",
            "bg-deepBlue-50",
            "text-sm",
            "font-bold",
            "text-gray-900"
          )}
        >
          {columns.map(
            (
              {
                width,
                isFixedWidth,
                showTotal,
                totalStyle,
                cellID,
                formatTotal = (amount) => amount,
              },
              index
            ) => (
              <React.Fragment key={`total-for-${cellID}`}>
                {index === 0 ? (
                  <p className={tw(columnBase, width, { grow: !isFixedWidth })}>
                    <Trans ns="common" i18nKey="table.footer.total">
                      Total:
                    </Trans>
                  </p>
                ) : showTotal ? (
                  <p
                    className={tw(columnBase, width, totalStyle, {
                      grow: !isFixedWidth,
                    })}
                  >
                    {formatTotal(totals[cellID])}
                  </p>
                ) : (
                  <span
                    className={tw(columnBase, width, { grow: !isFixedWidth })}
                  />
                )}
              </React.Fragment>
            )
          )}

          {hasActions && <span className={actionColumnStyles} />}
        </div>
      )}

      {!isCompact && <div className={footerHeight}>{footer}</div>}
    </div>
  );
};
