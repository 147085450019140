import toast from "react-hot-toast";
import { HiOutlineLink } from "react-icons/hi";

import { SuccessCard } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  textToCopy: string;
  successMessage?: string;
  buttonComponent?: (onClick: () => void) => JSX.Element;
}

export default ({
  textToCopy,
  successMessage,
  buttonComponent,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.custom((toastProps) => (
        <SuccessCard
          heading={t("copyToClipboard.success.heading", "Copied!")}
          body={
            successMessage ??
            t("copyToClipboard.success.body", "Copied to your clipboard")
          }
          toastProps={toastProps}
        />
      ));
    });
  };

  if (buttonComponent) return buttonComponent(copyToClipboard);

  return (
    <button onClick={copyToClipboard}>
      <HiOutlineLink size={24} className={tw("text-gray-400")} />
    </button>
  );
};
