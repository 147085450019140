/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { INVOICE_FRAGMENT, InvoiceFragment } from "graphql/fragments";
import { QueryPurchasesArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const PURCHASES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: InvoicesFilter
    $sortBy: InvoicesSortByEnum
  ) {
    purchases(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      exportPath
      items {
        ...InvoiceFragment
      }
      total
    }
  }
  ${INVOICE_FRAGMENT}
`;

export type PurchasesQuery = {
  purchases: PaginatedPayload<InvoiceFragment>;
};

type QueryArgs = Pick<
  QueryPurchasesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const usePurchasesLazyQuery = () => {
  const [getPurchasesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PurchasesQuery, QueryArgs>(PURCHASES, {
      fetchPolicy: "network-only",
    });

  const purchases = data?.purchases.items?.filter(Boolean) ?? [];
  const total = data?.purchases.total ?? 0;

  const resolveRef = useRef<(purchases?: InvoiceFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(purchases);
      resolveRef.current = undefined;
    }
  }, [purchases, called, isLoading]);

  const getPurchases = async (args?: QueryArgs) => {
    const variables = { ...args };

    getPurchasesQuery({ variables });

    return new Promise<InvoiceFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getPurchases, purchases, total, isLoading, called };
};
