import { IconBaseProps } from "react-icons";

export default ({ size, ...props }: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5941 21.3172V2.68277H5.41129V21.3172H18.5941ZM4.72852 2H19.2716V22H4.72852V2ZM7.45312 18.4082H16.5445V17.4082H7.45312V18.4082ZM12.1177 6.208C13.3615 6.24594 14.4945 6.87091 15.1867 7.88488L14.2414 8.36749C13.6537 7.38161 12.8923 6.62927 12.1177 6.208ZM15.1988 12.2387C15.871 11.2407 16.0609 9.95492 15.6405 8.776L14.6959 9.25824C15.1479 10.3096 15.3103 11.3652 15.1988 12.2387ZM14.1491 13.1878C14.7405 12.4354 14.6293 10.8902 14.087 9.56911L12.6725 10.2912L14.1491 13.1878ZM13.2533 13.6326C13.2466 13.6327 13.2399 13.6327 13.2332 13.6327C12.4294 13.6327 11.2376 12.8917 10.3727 11.4653L11.7818 10.7459L13.2533 13.6326ZM12.2183 9.40032L10.7455 6.51119C10.7554 6.51097 10.7653 6.51086 10.7752 6.51086C11.5788 6.51086 12.7701 7.25144 13.635 8.67707L12.2183 9.40032ZM9.85352 6.96318C9.26884 7.71812 9.38087 9.25671 9.92091 10.5732L11.3277 9.85501L9.85352 6.96318ZM8.8078 7.90025C8.69469 8.7756 8.85694 9.83398 9.30809 10.886L8.35882 11.3707C7.93796 10.1885 8.13066 8.89906 8.8078 7.90025ZM11.902 13.9412C11.1221 13.5205 10.3541 12.765 9.76286 11.7767L8.8121 12.262C9.50783 13.2824 10.6498 13.9093 11.902 13.9412ZM14.1793 14.0594C16.3375 12.8879 17.1786 10.2036 16.0573 8.00239C15.2484 6.43201 13.6518 5.52865 11.9974 5.52865C11.3329 5.52865 10.654 5.67339 10.016 5.986C9.99049 5.99749 9.96519 6.00963 9.94013 6.02241C9.91349 6.03594 9.88729 6.05009 9.86154 6.06487C7.68226 7.22114 6.82586 9.92773 7.95327 12.1358C8.75684 13.7114 10.3535 14.6148 12.0079 14.6148C12.6615 14.6148 13.3292 14.4747 13.958 14.1726C13.9953 14.1568 14.032 14.1396 14.0683 14.1212C14.1062 14.1018 14.1432 14.0812 14.1793 14.0594Z"
      fill="currentColor"
    />
  </svg>
);
