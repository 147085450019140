import { InvoiceFragment } from "graphql/fragments";
import { SimpleInvoice } from "graphql/queries";
import { Popup } from "providers/PopupHandler";

export default (
  invoice: InvoiceFragment | SimpleInvoice,
  openPopup: (popup: Popup) => void
): void => {
  if (!invoice?.client?.helpArticle) return;

  const props = {
    helpArticle: invoice.client.helpArticle,
    clientId: invoice.client.id,
    freelanceProfileId: invoice.freelanceProfile.id,
  };

  openPopup({ id: "BlockedManualInvoicing", props });
};
