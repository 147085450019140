import { useContext, useEffect, useState } from "react";
import { HiPlus, HiSearch } from "react-icons/hi";

import { Button } from "components/common/basic";
import { DepartmentFragment } from "graphql/fragments";
import { useDepartmentsLazyQuery } from "graphql/queries";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import MultiSearch from "../MultiSearch";

interface Props {
  selectedDepartments: DepartmentFragment[];
  setSelectedDepartments: (departments: DepartmentFragment[]) => void;
  label?: string;
  errorMessage?: string;
}

export default ({
  selectedDepartments,
  setSelectedDepartments,
  label,
  errorMessage,
}: Props): JSX.Element => {
  const perPage = 10_000;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const { departments, getDepartments, total, isLoading } =
    useDepartmentsLazyQuery();
  const { openPopup } = useContext(PopupContext);
  const { t } = useTranslation("common");

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getDepartments({
      page: p,
      perPage,
      filter: {
        search: searchInput ?? keyword,
      },
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  useEffect(() => {
    fetchAndSetPage(1);
  }, []);

  const buttonLabel = (
    <>
      <span className={tw("mr-2")}>
        {selectedDepartments.length === 1
          ? t("searchDepartment.selected_one.label", "{{ department }}", {
              department: selectedDepartments[0].name,
            })
          : selectedDepartments.length > 1
          ? t(
              "searchDepartment.selected_multiple.label",
              "{{ amount }} departments selected",
              { amount: selectedDepartments.length }
            )
          : t("searchDepartment.placeholder", "Search by department name")}
      </span>

      <HiSearch size={20} />
    </>
  );

  return (
    <MultiSearch<DepartmentFragment>
      id="department-search"
      itemKey="id"
      items={departments ?? []}
      selectedItems={selectedDepartments}
      onSelect={setSelectedDepartments}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      buttonLabel={buttonLabel}
      label={label}
      emptyState={emptyState}
      isLoading={isLoading}
      errorMessage={errorMessage}
      footer={
        <div className={tw("py-2", "px-4")}>
          <Button
            id="department_search-add_new_department"
            onClick={() =>
              openPopup({
                id: "Department",
                props: {
                  onSuccess: (newDepartment) => {
                    setSelectedDepartments([
                      ...selectedDepartments,
                      newDepartment,
                    ]);
                  },
                },
              })
            }
            variant="tertiary"
            fullWidth
            LeadingIcon={HiPlus}
          >
            {t("searchDepartment.button.addDepartment", "Add a new department")}
          </Button>
        </div>
      }
      fixedHeight="h-108"
    />
  );
};

const emptyState = (
  <div className={tw("text-center", "py-20", "px-4", "bg-white", "h-full")}>
    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchDepartment_empty.heading">
        You have not added any departments yet.
      </Trans>
    </p>

    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchDepartment_empty.body">
        Please use the add button to add a department.
      </Trans>
    </p>
  </div>
);

const renderListItemLabel = (department: DepartmentFragment) => (
  <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
    {department.name}
  </p>
);
