import { RouteProps } from "react-router-dom";

import { ComponentLibrary, ErrorPage } from "components/common/pages";
import { SignatureTestPage } from "components/documents/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("test","ERROR_PAGE"), element: <ErrorPage /> },
    { path: getRoutePath("test","SIGNATURE"), element: <SignatureTestPage /> },
    { path: getRoutePath("test","COMPONENTS"), element: <ComponentLibrary /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "common", routes, loggedInRoutes, loggedOutRoutes };
};
