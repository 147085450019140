import { useContext } from "react";

import { Button, Link } from "components/common/basic";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import WomanSitting from "assets/illustrations/emptyState/womanSitting.svg";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  const openBankAccountPopup = () => openPopup({ id: "AddBankAccount" });

  return (
    <div
      className={tw(
        "mt-10",
        "max-w-sm",
        "mx-auto",
        "text-center",
        "flex",
        "flex-col",
        "space-y-10"
      )}
    >
      <img
        className={tw("h-40", "object-cover")}
        src={WomanSitting}
        alt="Woman sitting in chair"
      />

      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-extrabold")}>
          <Trans ns="account" i18nKey="bankAccounts.empty.heading">
            You have not added any bank account yet
          </Trans>
        </h2>

        <p>
          <Trans ns="account" i18nKey="bankAccounts.empty.paragraph">
            To be able to use Manymore to get paid, we need you to register at
            least one bank account.{" "}
            <Link
              id="bank_accounts__empty_state-go_to_readmore"
              isExternal
              to={t(
                "bankAccounts.empty.link.readMore",
                "https://en.support.manymore.com"
              )}
            >
              Read more about bank accounts here.
            </Link>
          </Trans>
        </p>
      </div>

      <Button
        id="bank_accounts__empty_state-add_first_account"
        onClick={openBankAccountPopup}
      >
        <Trans
          ns="account"
          i18nKey="bankAccounts.empty.button.addFreelanceProfile"
        >
          Add your first bank account now
        </Trans>
      </Button>
    </div>
  );
};
