import { lazy } from "react";

const PublicWbReport = lazy(() => import("./PublicWbReport"));
const WbDirectory = lazy(() => import("./WbDirectory"));
const WbLogin = lazy(() => import("./WbLogin"));
const WbSettings = lazy(() => import("./WbSettings"));
const WbReport = lazy(() => import("./WbReport"));
const WbWizard = lazy(() => import("./WbWizard"));

export { PublicWbReport, WbDirectory, WbLogin, WbSettings, WbReport, WbWizard };
