import { useContext } from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  Merge,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";

import { Button, Input } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { getNameFromSession } from "utils";
import { tw } from "utils/tw";

interface Recipient {
  name: string;
  email: string;
}

export interface FormWithRecipientFields {
  recipients?: Recipient[];
}

interface Props {
  control: Control<FormWithRecipientFields>;
  register: UseFormRegister<FormWithRecipientFields>;
  recipientsErrors?: Merge<FieldError, FieldErrors<Recipient[]>>;
  minRecipients?: number;
}

export default ({
  control,
  register,
  recipientsErrors,
  minRecipients = 0,
}: Props): JSX.Element => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("documents");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const addRecipient = (
    recipient = {
      name: "",
      email: "",
    } as Recipient
  ) => append(recipient);

  const addDefaultRecipient = () => addRecipient();

  const addSelf = () => {
    if (!session) return;

    const name = getNameFromSession(session);

    addRecipient({ name, email: session.user.email } as Recipient);
  };

  return (
    <>
      {fields.map(({ id, name, email }, index) => {
        const fieldWidth = tw("w-52");
        const recipientErrors = recipientsErrors?.[index];

        return (
          <div key={id} className={tw("flex", "space-x-4")}>
            <Input
              id={`recipients.${index}.name`}
              className={fieldWidth}
              {...register(`recipients.${index}.name`)}
              label={t("recipientFields.name.label", "Name")}
              defaultValue={name}
              errorMessage={recipientErrors?.name?.message}
            />

            <Input
              id={`recipients.${index}.email`}
              className={fieldWidth}
              {...register(`recipients.${index}.email`)}
              label={t("recipientFields.email.label", "Email")}
              defaultValue={email}
              errorMessage={recipientErrors?.email?.message}
            />

            {fields.length > minRecipients && (
              <button
                type="button"
                className={tw("p-2", "self-start", "mt-6")}
                onClick={
                  fields.length > minRecipients
                    ? () => remove(index)
                    : undefined
                }
              >
                <HiOutlineTrash size={20} />
              </button>
            )}
          </div>
        );
      })}

      <div className={tw("flex", "space-x-4")}>
        <Button
          id="recipient_fields-add_recipient"
          onClick={addDefaultRecipient}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          {t(
            "createDocumentWizard.documentFiles.form.addRecipient",
            "Add recipient"
          )}
        </Button>

        <Button
          id="recipient_fields-add_me"
          onClick={addSelf}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          {t("createDocumentWizard.documentFiles.form.addMe", "Add me")}
        </Button>
      </div>
    </>
  );
};
