import { useContext } from "react";
import { HiOutlineAdjustments } from "react-icons/hi";

import { Link } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { AccountTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import useOrgLinks from "./organization/useOrgLinks";
import useIndLinks from "./personal/useIndLinks";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const getRoutePath = useRoutePaths();

  const indLinks = useIndLinks();
  const orgLinks = useOrgLinks();

  const isOrg = session?.account.accountType === AccountTypeEnum.Team;
  const links = isOrg ? orgLinks : indLinks;

  return (
    <Page
      title={
        isOrg
          ? t("accountSettings_org.overview.pageTitle", "Team settings")
          : t("accountSettings_ind.overview.pageTitle", "Settings")
      }
    >
      <div className={tw("space-y-8")}>
        <HeadingWithNotice
          heading={
            isOrg
              ? t("accountSettings_org.overview.header", "Team settings")
              : t("accountSettings_ind.overview.header", "Settings")
          }
          LeadingIcon={HiOutlineAdjustments}
          notice={{
            id: "accountSettings-headerNotice",
            heading: isOrg
              ? t(
                  "accountSettings_org.overview.noticeHeader",
                  "Settings overview"
                )
              : t(
                  "accountSettings_ind.overview.noticeHeader",
                  "Settings overview"
                ),
            body: isOrg
              ? t(
                  "accountSettings_org.overview.noticeDescription",
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                )
              : t(
                  "accountSettings_ind.overview.noticeDescription",
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                ),
          }}
        />

        <div
          className={tw(
            "max-w-screen-md",
            "grid",
            "grid-cols-1",
            "gap-4",
            "lg:grid-cols-3"
          )}
        >
          {links.map(({ actionId, routeName, Icon, label, helpText }) => (
            <Link
              key={routeName}
              id={actionId}
              to={getRoutePath({ module: "account", routeName })}
              unstyled
              className={tw(
                "cursor-pointer",
                "p-4",
                "space-y-3",
                "rounded-lg",
                "border",
                "border-gray-100",
                "hover:border-gray-300"
              )}
            >
              <Icon size={24} strokeWidth={1.5} />

              <div className={tw("space-y-1")}>
                <p className={tw("text-sm", "font-bold")}>{label}</p>

                <p
                  className={tw(
                    "whitespace-pre-wrap",
                    "text-deepBlue-500",
                    "text-sm"
                  )}
                >
                  {helpText}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Page>
  );
};
