import { createContext } from "react";

import { SideMenuStep } from "./ProgressSideMenu";

export interface WizardStep extends SideMenuStep {
  page: JSX.Element;
  withoutLayout?: boolean;
}

interface Props {
  steps: WizardStep[];
  currentStep: number;
  goBack: () => void;
  goNext: () => void;
  goTo: (step: number) => void;
}

export default createContext<Props>({
  steps: [],
  currentStep: 0,
  goBack: () => null,
  goNext: () => null,
  goTo: () => null,
});
