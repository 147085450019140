import { createContext, useState } from "react";

import { ClientFragment } from "graphql/fragments";
import { useClientsQuery } from "graphql/queries";
import { ClientTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import CreateIndClient from "./CreateIndClient";
import CreateOrgClient from "./CreateOrgClient";

interface Context {
  freelanceProfileId?: string;
}
export const AddClientContext = createContext<Context>({});

interface Props {
  formId: string;
  freelanceProfileId: string;
  onClose: () => void;
  onSuccess?: (client: ClientFragment) => void;
}

export default ({
  formId,
  freelanceProfileId,
  onClose,
  onSuccess,
}: Props): JSX.Element => {
  const [clientType, setClientType] = useState<ClientTypeEnum>(
    ClientTypeEnum.Organization
  );
  const { clients } = useClientsQuery({ freelanceProfileId });
  const { t } = useTranslation("common");

  const tabsContainerStyles = tw(
    "w-full",
    "flex",
    "border-2",
    "rounded-lg",
    "border-deepBlue-50",
    "bg-deepBlue-50",
    "space-x-1"
  );
  const tabBase = tw(
    "flex-1",
    "p-2",
    "rounded-lg",
    "font-bold",
    "text-center",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900"
  );
  const inactiveTabStyles = tw(tabBase, "bg-deepBlue-50");
  const activeTabStyles = tw(tabBase, "bg-primary", "hover:bg-primary-hover");

  const isOrg = clientType === ClientTypeEnum.Organization;

  return (
    <div className={tw("flex", "flex-col", "space-y-6")}>
      <div className={tabsContainerStyles}>
        <button
          className={isOrg ? activeTabStyles : inactiveTabStyles}
          type="button"
          onClick={() => setClientType(ClientTypeEnum.Organization)}
        >
          {t("popup.clientCreation.clientType.organization", "Company")}
        </button>

        <button
          className={isOrg ? inactiveTabStyles : activeTabStyles}
          type="button"
          onClick={() => setClientType(ClientTypeEnum.Individual)}
        >
          {t("popup.clientCreation.clientType.individual", "Individual")}
        </button>
      </div>

      <AddClientContext.Provider value={{ freelanceProfileId }}>
        {isOrg ? (
          <CreateOrgClient
            formId={formId}
            onSuccessfulEdit={onSuccess ?? onClose}
            clients={clients}
          />
        ) : (
          <CreateIndClient
            formId={formId}
            onSuccessfulEdit={onSuccess ?? onClose}
          />
        )}
      </AddClientContext.Provider>
    </div>
  );
};
