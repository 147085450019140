/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { JOB_FRAGMENT, JobFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

const JOB = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Job {
        ...JobFragment
      }
    }
  }
  ${JOB_FRAGMENT}
`;

export type JobQuery = { node: JobFragment };

export const useJobQuery = (id: string) => {
  const { data, loading } = useQuery<JobQuery>(JOB, { variables: { id } });

  return { job: data?.node, isLoading: loading };
};

export const useJobLazyQuery = () => {
  const [getJobQuery, { data, loading, called }] = useLazyQuery<
    JobQuery,
    QueryNodeArgs
  >(JOB);

  const resolveRef = useRef<(job?: JobFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getJob = async (id: string) => {
    getJobQuery({ variables: { id } });

    return new Promise<JobFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getJob,
    job: data?.node,
    isLoading: loading,
  };
};
