import { gql } from "@apollo/client";

import { WbReport } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  PUBLIC_WB_HANDLER_FRAGMENT,
  PublicWbHandlerFragment,
} from "./publicWbHandler";
import { WB_MESSAGE_FRAGMENT, WbMessageFragment } from "./wbMessage";

export const WB_REPORT_FRAGMENT = gql`
  fragment WbReportFragment on WbReport {
    id
    reportNumber
    status
    type
    privacy
    action
    category
    description
    message
    email
    firstName
    lastName
    location
    people
    timing
    lastInteractionAt
    submittedAt
    deletedBy
    deletedAt
    closedBy
    closedAt
    abandoned
    files {
      ...AttachmentFragment
    }
    routineGuidelines {
      ...AttachmentFragment
    }
    wbHandlers {
      ...PublicWbHandlerFragment
    }
    phoneNumber {
      countryCode
      number
    }
    wbMessages {
      ...WbMessageFragment
    }
    wbMessagesCount
    isAccessible
    downloadPath
  }
  ${ATTACHMENT_FRAGMENT}
  ${PUBLIC_WB_HANDLER_FRAGMENT}
  ${WB_MESSAGE_FRAGMENT}
`;

export interface WbReportFragment
  extends Pick<
    WbReport,
    | "id"
    | "reportNumber"
    | "status"
    | "type"
    | "privacy"
    | "action"
    | "category"
    | "description"
    | "message"
    | "email"
    | "firstName"
    | "lastName"
    | "location"
    | "people"
    | "timing"
    | "lastInteractionAt"
    | "submittedAt"
    | "deletedBy"
    | "deletedAt"
    | "closedBy"
    | "closedAt"
    | "abandoned"
    | "files"
    | "phoneNumber"
    | "wbMessages"
    | "wbMessagesCount"
    | "isAccessible"
    | "downloadPath"
  > {
  files: AttachmentFragment[];
  routineGuidelines: AttachmentFragment[];
  wbHandlers: PublicWbHandlerFragment[];
  phoneNumber?: { countryCode?: string; number?: string };
  wbMessages: WbMessageFragment[];
}
