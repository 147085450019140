import { Badge } from "components/common/basic";
import { WbReportStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  status: WbReportStatusEnum;
  customMessage?: string;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  status,
  customMessage,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const statusMessage = t(
    `wbReportStatusMessage.${snakeCaseToCamelCase(status)}`,
    snakeCaseToSeparated(status)
  );

  const badgeVariant = (() => {
    switch (status) {
      case WbReportStatusEnum.Draft:
        return "default";

      case WbReportStatusEnum.Submitted:
        return "default_outlined";

      case WbReportStatusEnum.InProgress:
        return "pending";

      case WbReportStatusEnum.Closed:
        return "success";

      case WbReportStatusEnum.Deleted:
        return "error";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "text-xs", "font-bold", "text-deepBlue-900")}
      >
        {customMessage ?? statusMessage}
      </p>
    );

  return (
    <Badge
      size={badgeSize}
      variant={badgeVariant}
      text={customMessage ?? statusMessage}
    />
  );
};
