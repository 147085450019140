import { AccountTheme } from "graphql/types";

interface Props {
  theme: AccountTheme;
}

export default ({
  theme: { primary, primaryHover, primaryContrast, font },
}: Props) => (
  <style type="text/css">{`
${font ? `@import url('${font.url}');` : ""}

:root {
  ${primary ? `--primary: ${primary};` : ""}
  ${primaryHover ? `--primary-hover: ${primaryHover};` : ""}
  ${primaryContrast ? `--primary-contrast: ${primaryContrast};` : ""}
  ${font ? `--font-family: ${font.name};` : ""}
}`}</style>
);
