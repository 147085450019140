import { useContext } from "react";
import { HiXCircle } from "react-icons/hi";

import Request from "components/checkmore/composite/RequestSection";
import { CheckListFragment } from "graphql/fragments";
import { useCancelCheckListsMutation } from "graphql/mutations";
import { CheckStatusEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupVariant, PopupWrapper } from "../PopupWrapper";
import Result from "./Result";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  checkList: CheckListFragment;
  viewer: "sender" | "receiver";
  overrideCRC?: () => void;
}

export default ({ checkList, viewer, overrideCRC, ...props }: Props) => {
  const { cancelCheckLists } = useCancelCheckListsMutation();
  const { openPopup } = useContext(PopupContext);
  const { t } = useTranslation("checkmore");

  const cancelCheck = () =>
    openPopup({
      id: "ConfirmAction",
      props: {
        onConfirmation: () => cancelCheckLists({ ids: [checkList.id] }),
        confirmationLabel: t(
          "checkResultpopup.cancelRequest.confirmation.buttonLabel",
          "Cancel request"
        ),
        confirmationHeading: t(
          "checkResultpopup.cancelRequest.confirmation.heading",
          "Are you sure you want to cancel this request?"
        ),
        confirmationBody: t(
          "checkResultpopup.cancelRequest.confirmation.body",
          "Cancelling this request means that the person you sent the request to, won't be able to do it anymore!"
        ),
      },
    });

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t("checkResultpopup.title", "Check result")}
      footerActions={
        viewer === "sender" &&
        [CheckStatusEnum.Requested, CheckStatusEnum.Consented].includes(
          checkList.status
        )
          ? [
              {
                id: "validate_sms_code_form-submit_code",
                type: "submit",
                onClick: cancelCheck,
                variant: "tertiary",
                label: t("checkResultpopup.cancelRequest", "Cancel request"),
                LeadingIcon: HiXCircle,
              },
            ]
          : undefined
      }
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        <Request checkList={checkList} />

        <Result
          checkList={checkList}
          viewer={viewer}
          overrideCRC={overrideCRC}
        />
      </div>
    </PopupWrapper>
  );
};
