import { gql } from "@apollo/client";

import { CreditNoteLine, InvoiceLine } from "graphql/types";

import {
  OCCUPATION_CODE_FRAGMENT,
  OccupationCodeFragment,
} from "./occupationCode";

export const CREDIT_NOTE_LINE_FRAGMENT = gql`
  fragment CreditNoteLineFragment on CreditNoteLine {
    id
    amount
    description
    platformFeeAmount
    grossSalary
    invoiceLine {
      id
      occupationCode {
        ...OccupationCodeFragment
      }
      unitPrice
    }
    payoutAmount
    quantity
    salaryTaxAmount
    vat
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

interface InvoiceLineFields extends Pick<InvoiceLine, "id" | "unitPrice"> {
  occupationCode: OccupationCodeFragment;
}

export interface CreditNoteLineFragment
  extends Pick<
    CreditNoteLine,
    | "id"
    | "amount"
    | "description"
    | "platformFeeAmount"
    | "grossSalary"
    | "payoutAmount"
    | "quantity"
    | "salaryTaxAmount"
    | "vat"
  > {
  invoiceLine: InvoiceLineFields;
}
