import Breadcrumbs from "components/common/basic/Breadcrumbs";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";

interface Props {
  currentPage: string;
}

export default ({ currentPage }: Props): JSX.Element => {
  const { t } = useTranslation("account");
  const getRoutePath = useRoutePaths();

  return (
    <Breadcrumbs
      previousPages={[
        {
          id: "accountSettings__breadcrumbs-go_to_accountSettings",
          to: getRoutePath({ module: "account", routeName: "SETTINGS" }),
          label: t("accountSettings.breadcrumbs.topLevel", "Settings"),
        },
      ]}
      currentPage={currentPage}
    />
  );
};
