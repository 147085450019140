import { gql } from "@apollo/client";

import { BankAccount } from "graphql/types";

export const BANK_ACCOUNT_FRAGMENT = gql`
  fragment BankAccountFragment on BankAccount {
    id
    accountNumber
    currency
    default
    swiftCode
    deletedAt
  }
`;

export interface BankAccountFragment
  extends Pick<
    BankAccount,
    "id" | "accountNumber" | "currency" | "default" | "swiftCode" | "deletedAt"
  > {}
