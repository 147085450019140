/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  WB_REPORT_FRAGMENT,
  WbReportFragment,
} from "graphql/fragments";
import { WbMessageAttributes } from "graphql/types";

const CREATE_WB_MESSAGE = gql`
  mutation ($id: ID!, $attributes: WbMessageAttributes!) {
    createWbMessage(input: { wbReportId: $id, attributes: $attributes }) {
      wbReport {
        ...WbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${WB_REPORT_FRAGMENT}
`;

interface Payload {
  wbReport?: WbReportFragment | null;
  errors: ErrorFragment[];
}

interface CreateWbMessageMutation {
  createWbMessage: Payload | null;
}

export const useCreateWbMessageMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateWbMessageMutation,
    { id: string; attributes: WbMessageAttributes }
  >(CREATE_WB_MESSAGE);

  const createWbMessage = (
    variables: { id: string; attributes: WbMessageAttributes },
    onSuccess?: (wbReport: WbReportFragment) => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.createWbMessage?.errors ?? [];
        const wbReport = data?.createWbMessage?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createWbMessage,
    isLoading: loading,
    wbReport: data?.createWbMessage?.wbReport,
    errors: data?.createWbMessage?.errors ?? [],
  };
};
