/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { CRC_PURPOSE_FRAGMENT, CrcPurposeFragment } from "graphql/fragments";
import { QueryCrcPurposesArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const CRC_PURPOSES = gql`
  query ($page: Int, $perPage: Int) {
    crcPurposes(page: $page, perPage: $perPage) {
      exportPath
      items {
        ...CrcPurposeFragment
      }
      total
    }
  }
  ${CRC_PURPOSE_FRAGMENT}
`;

export interface CrcPurposesQuery {
  crcPurposes: PaginatedPayload<CrcPurposeFragment>;
}

type QueryArgs = Pick<QueryCrcPurposesArgs, "page" | "perPage">;

export const useCrcPurposesQuery = ({
  perPage = 10_000,
  ...args
}: QueryArgs) => {
  const { data, loading: isLoading } = useQuery<CrcPurposesQuery, QueryArgs>(
    CRC_PURPOSES,
    {
      variables: { ...args, perPage },
    }
  );

  const crcPurposes = data?.crcPurposes.items?.filter(Boolean) ?? [];

  return { crcPurposes, isLoading };
};

export const useCrcPurposesLazyQuery = () => {
  const [getCrcPurposesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<CrcPurposesQuery, QueryArgs>(CRC_PURPOSES, {
      fetchPolicy: "network-only",
    });

  const crcPurposes = data?.crcPurposes.items?.filter(Boolean) ?? [];
  const total = data?.crcPurposes.total ?? 0;

  const resolveRef = useRef<(crcPurposes?: CrcPurposeFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(crcPurposes);
      resolveRef.current = undefined;
    }
  }, [crcPurposes, called, isLoading]);

  const getCrcPurposes = async ({ perPage = 10_000, ...args }: QueryArgs) => {
    const variables = { ...args, perPage };

    getCrcPurposesQuery({ variables });

    return new Promise<CrcPurposeFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getCrcPurposes, crcPurposes, isLoading, called, total };
};
