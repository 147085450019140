import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Textarea } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import {
  useRefuseCheckListMutation,
  useRefuseCheckMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface FormValues {
  message: string;
}

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  id: string;
  type: "check" | "checkList";
  sender: string;
}

export default ({ id, type, sender, ...props }: Props) => {
  const { t } = useTranslation("checkmore");
  const { closeOnePopup } = useContext(PopupContext);

  const refuse =
    type === "check"
      ? useRefuseCheckMutation().refuseCheck
      : useRefuseCheckListMutation().refuseCheckList;

  const formId = "refuse-check-form";

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string().required(
          t(
            "checkmore.popup_refusal.error.message.required",
            "Please write a message"
          )
        ),
      })
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ message }: FormValues) =>
    refuse({ id, message }, closeOnePopup);

  return (
    <PopupWrapper
      {...props}
      nameSpace="checkmore"
      title={t(
        "checkmore.popup_refusal.title",
        "I don't want to do this check..."
      )}
      subTitle={t(
        "checkmore.popup_refusal.description",
        "Are you sure you want to refuse to do this check for {{ sender }}? If you refuse, we will notify {{ sender }}, and include your reason.",
        { sender }
      )}
      footerActions={[
        {
          id: "check_more_wizard__start_page-close_popup",
          variant: "tertiary",
          onClick: closeOnePopup,
          label: t("checkmore.popup_refusal.button.cancel", "Cancel"),
        },
        {
          id: "check_more_wizard__start_page-refuse_check",
          variant: "primary",
          type: "submit",
          form: formId,
          label: t(
            "checkmore.popup_refusal.button.submit",
            "Submit and Refuse"
          ),
        },
      ]}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={tw("w-full", "space-y-4")}
        id={formId}
      >
        <Textarea
          label={t(
            "checkmore.popup_refusal.message.label",
            "Please give a reason for why you will not do the check. (Mandatory)"
          )}
          id="message"
          {...register("message")}
          rows={5}
        />
      </form>
    </PopupWrapper>
  );
};
