import { MarkdownTemplate } from "components/markdown/composite";
import { useTranslation } from "translations";

/* eslint max-len: 0 */
const markdownText = `
# Midlertidig frilansavtale

Mellom

● Manymore.com AS, org. nr. 923004114 heretter «Oppdragsgiver», og

● {{freelancer_name}}, {{freelancer_personal_number}, {{freelancer_address}} heretter «Frilanser»

er det inngått slik avtale om midlertidig frilansforhold («Frilansavtalen»). Oppdragsgiver og Frilanser omtales i fellesskap som partene.


1. KONTRAKTSFORHOLDETS RAMMER

Frilansavtalen regulerer tilknytningsforhold for tjenester som Frilanser allerede har utført overfor tredjeparter, hvoretter denne Frilansavtalen har til hensikt å regulere avlønning uten at Frilanseren må opprette eget enkeltpersonforetak, aksjeselskap el.



Frilansavtalen medfører at Oppdragsgiver påtar seg rollen som kontraktuelt mellomledd for det arbeidet frilanseren allerede har påtatt seg overfor tredjepart, hvoretter Oppdragsgiver utsteder faktura og avlønner Frilanser som nærmere angitt i denne Frilansavtalen.



Avtaleinngåelsen forutsetter at tredjeparter, som Frilanser allerede har utført sin tjeneste for, aksepterer Oppdragsgiver som kontraktuelt mellomledd, herunder betaler utstedt faktura etter nærmere instruks fra Frilanseren.

1. IKKE-EKSLUSIVITET OG INGEN INSTRUKSJONSRETT

Frilansavtalen er en form for avlønningsregulering, som frilanser kan velge eller ikke å velge å benytte seg av for allerede utført oppdrag. At det tidligere er inngått Frilansavtale for tidligere allerede utførte tjenester for tredjeparter, medfører ikke at Frilanser er forpliktet til å inngå ny Frilansavtale.

Oppdragsgiver har ingen instruksjonsmyndighet overfor Frilanser. Avtaleinngåelse forutsetter at tjenesten allerede er utført. Videre fakturerer Oppdragsgiver i samsvar med de opplysningene Frilanser alene har ført inn i Oppdragsgivers systemer.

1. KONTRAKTSFORHOLDETS VARIGHET

Kontraktsforholdet varer fra signatur av Frilansavtalen frem til faktura for allerede utført tjeneste er betalt og Frilanser er avlønnet i samsvar med pkt. 5 nedenfor.

1. OPPDRAGSGIVERS FAKTURERING AV TREDJEPART

Oppdragsgiver fakturerer tredjepart i henhold til de opplysningene som Frilanser har fylt inn. Når beløp i henhold til utstedt faktura er betalt, avlønnes Frilanser i samsvar med punkt 5 nedenfor.



1. AVLØNNINGEN AV FRILANSEREN

Frilanser gis andel av fakturerte inntekter ut fra følgende utregning:



| Fakturert beløp |   | NOK {{total_amount}} |
| --- | --- | --- |
| Merverdiavgift | 25% | NOK {{vat_amount}} |
| Arbeidsgiveravgift | 14.10% | NOK {{employers_tax}} |
| Administrasjonsgebyr | Manymore, 5% | NOK {{dipps_fee}} |
| Brutto lønn |   | NOK {{gross_salary}} |
| Skattetrekk | {{salary_tax}} | NOK {{salary_tax_amount}} |
| Nettolønn | anslag | NOK {{net_salary}} |

**Lønnsavgift avhenger av din personlige skattekategori. Faktisk verdi kan variere når lønnen din utstedes.**

Såfremt Oppdragsgivers pliktige utgifter skulle endre seg, herunder at Oppdragsgiver er pliktig å betale feriepenger eller pensjonsopptjening, reduseres nettoutbetaling med det tilsvarende.

1. FERIEPENGER, PENSJON, SYKEPENGER, FORSIKRINGSFORHOLD MV.

Idet tilknytningsformen regnes som et frilansforhold, beregnes det verken feriepenger eller pensjonsinnskudd av mottatt avlønning. Som Frilanser ytes først sykepenger fra NAV fra og med 17. sykedag. Sykepenger for tidligere periode forutsetter at Frilanser tegner frivillig sykeforsikring. Videre forutsetter kontraktsinngåelsen at tjenesten allerede er utført at Frilanseren, herunder at Frilanser ikke har vært helsemessige avskåret fra å utføre tjenesten.



For tilfellet det er foretatt feil i avlønning, gis Oppdragsgiver rett til å forestå nødvendig justering i ny avlønning i medhold av ny Frilansavtale.



Det forutsettes at Frilanser selv har aktuell forsikring for den allerede utførte tjenesten.

1. MIDLERTIDIG ANSETTELSE SÅFREMT FRILANSFORHOLD ANSES SOM ET ARBEIDSFORHOLD

Såfremt Frilansforholdet mot formodning og i strid med partenes intensjon måtte anses som et arbeidsforhold, skal det anses som midlertidig arbeidsforhold etter arbeidsmiljøloven § 14-9, der arbeidsforholdet starter og avsluttes ved den allerede utførte tjenesten.



1. FORUTSETNING OM AT TJENESTEN ER UTFØRT I SAMSVAR MED TREDJEPARTS VILKÅR OG STANDARDER

Kontraktsforholdet, herunder avlønningen av Frilanser i medhold av denne kontrakten, forutsetter at Tjenesten er levert i samsvar med de krav og vilkår som måtte være satt av tredjepart. Såfremt Tjenesten ikke er levert til disse standardene, eller Frilanser har opptrådt på slikt vis at Oppdragsgiver blir ansvarlig overfor tredjeparter eller andre, kan Oppdragsgiver at Frilanser erstatter det kravet Oppdragsgiver eventuelt måtte lide.

Oslo, den {{invoice_submission_date}}

Innsendt av frilanser på manymore.com

IP Adresse: {{ip_address}}

Tidsstempel: {{timestamp}}

Nettleser: {{user_agent}}

Telefonnummer brukt for bekreftelse: {{phone_number}}

Faktura internt ID: {{submitted_invoice_id}}
  `;

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <MarkdownTemplate
      title={t("markdown_freelancer.title", "Freelancer agreement")}
      markdownText={markdownText}
    />
  );
};
