/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const CREATE_CREDIT_NOTE = gql`
  mutation ($id: ID!) {
    sendCreditNoteViaEhf(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendCreditNoteViaEhfMutation {
  sendCreditNoteViaEhf: Payload | null;
}

export const useSendCreditNoteViaEhfMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentCreditNoteViaEhf",
    "Credit note was sent successfully via ehf"
  );
  // Values for extraction
  // t("common:success.sentCreditNoteViaEhf", "Credit note was sent successfully via ehf")

  const [mutation, { data, loading }] = useMutation<
    SendCreditNoteViaEhfMutation,
    { id: string }
  >(CREATE_CREDIT_NOTE);

  const sendCreditNoteViaEhf = (id: string, onSuccess?: () => void) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendCreditNoteViaEhf?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendCreditNoteViaEhf,
    isLoading: loading,
    errors: data?.sendCreditNoteViaEhf?.errors ?? [],
  };
};
