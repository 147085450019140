/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { VerifyPhoneNumberInput } from "graphql/types";

const VERIFY_PHONE_NUMBER = gql`
  mutation ($input: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface VerifyPhoneNumberMutation {
  verifyPhoneNumber: Payload | null;
}

export const useVerifyPhoneNumberMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.verifiedPhoneNumber",
    "Phone number was verified successfully"
  );
  // Values for extraction
  // t("common:success.verifiedPhoneNumber", "Phone number was verified successfully")

  const [mutation, { data, loading }] = useMutation<
    VerifyPhoneNumberMutation,
    { input: VerifyPhoneNumberInput }
  >(VERIFY_PHONE_NUMBER);

  const verifyPhoneNumber = (
    input: VerifyPhoneNumberInput,
    onSuccess?: () => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.verifyPhoneNumber?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    verifyPhoneNumber,
    isLoading: loading,
    errors: data?.verifyPhoneNumber?.errors ?? [],
  };
};
