/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import {
  COMPANY_FRAGMENT,
  CompanyFragment,
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { QueryFreelanceProfileLookupArgs } from "graphql/types";

export const FREELANCE_PROFILE_LOOKUP = gql`
  query ($jobApplicationId: ID!, $organizationNumber: String!) {
    freelanceProfileLookup(
      jobApplicationId: $jobApplicationId
      organizationNumber: $organizationNumber
    ) {
      belongsToAccount
      company {
        ...CompanyFragment
      }
      freelanceProfile {
        ...FreelanceProfileFragment
      }
    }
  }
  ${COMPANY_FRAGMENT}
  ${FREELANCE_PROFILE_FRAGMENT}
`;

interface FreelanceProfileLookup {
  belongsToAccount: boolean;
  company?: CompanyFragment;
  freelanceProfile?: FreelanceProfileFragment;
}

export type FreelanceProfileLookupQuery = {
  freelanceProfileLookup: FreelanceProfileLookup;
};

type QueryArgs = Pick<
  QueryFreelanceProfileLookupArgs,
  "jobApplicationId" | "organizationNumber"
>;

export const useFreelanceProfileLookupLazyQuery = () => {
  const [getFreelanceProfileLookupQuery, { data, loading, called }] =
    useLazyQuery<FreelanceProfileLookupQuery, QueryArgs>(
      FREELANCE_PROFILE_LOOKUP
    );

  const resolveRef = useRef<(profileLookup?: FreelanceProfileLookup) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.freelanceProfileLookup);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getFreelanceProfileLookup = async (variables: QueryArgs) => {
    getFreelanceProfileLookupQuery({ variables });

    return new Promise<FreelanceProfileLookup | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getFreelanceProfileLookup,
    freelanceProfileLookup: data?.freelanceProfileLookup,
    isLoading: loading,
    called,
  };
};
