/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  INVOICE_FRAGMENT,
  InvoiceFragment,
} from "graphql/fragments";
import { CreateInvoiceAttributes } from "graphql/types";

const CREATE_INVOICE = gql`
  mutation ($attributes: CreateInvoiceAttributes!) {
    createInvoice(input: { attributes: $attributes }) {
      invoice {
        ...InvoiceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoice?: InvoiceFragment | null;
  errors: ErrorFragment[];
}

interface CreateInvoiceMutation {
  createInvoice: Payload | null;
}

export const useCreateInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateInvoiceMutation,
    { attributes: CreateInvoiceAttributes }
  >(CREATE_INVOICE);

  const createInvoice = (
    attributes: CreateInvoiceAttributes,
    onSuccess?: (invoice: InvoiceFragment) => void
  ) =>
    mutation({ variables: { attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createInvoice?.errors ?? [];
        const invoice = data?.createInvoice?.invoice;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && invoice && onSuccess(invoice),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createInvoice,
    isLoading: loading,
    invoice: data?.createInvoice?.invoice,
    errors: data?.createInvoice?.errors ?? [],
  };
};
