import { FEMultiProps, FESingleProps } from "../shared/types";
import MultiSearch from "./MultiSearch";
import SingleSearch from "./SingleSearch";

type Props<T> = FESingleProps<T> | FEMultiProps<T>;

export default <T,>(props: Props<T>): JSX.Element => {
  if (props.isMultiSelect) return <MultiSearch {...props} />;

  return <SingleSearch {...props} />;
};
