import React from "react";

import { TWidth } from "tw-generated";
import { tw } from "utils/tw";

import Icon from "./Icon";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  label: string | JSX.Element;
  type?: "radio" | "checkbox";
  width?: TWidth;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      type = "radio",
      width = "w-56",
      ...props
    }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const pillStyles = tw(
      width,
      "flex",
      "justify-between",
      "items-center",
      "py-3",
      "px-4",
      "text-deepBlue-900",
      "font-semibold",
      "border",
      "rounded",
      "cursor-pointer",
      "bg-white",
      "hover:bg-silver",
      {
        [tw("border-deepBlue-100")]: !props.checked,
        [tw("ring-1", "ring-deepBlue-900", "border-deepBlue-900")]:
          props.checked,
      }
    );

    return (
      <label htmlFor={id} className={pillStyles}>
        <input
          {...props}
          id={id}
          ref={ref}
          type={type}
          className={tw("opacity-0", "absolute", "pointer-events-none")}
        />

        <span className={tw("truncate", "mr-2")}>{label}</span>

        {props.checked ? (
          <span className={tw("-m-1")}>
            <Icon
              icon="HiCheckCircle"
              isFilled
              size={25}
              color="text-deepBlue-900"
            />
          </span>
        ) : (
          <span
            className={tw(
              "h-5",
              "w-5",
              "border",
              "border-deepBlue-900",
              "rounded-full",
              "shrink-0"
            )}
          />
        )}
      </label>
    );
  }
);
