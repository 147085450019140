import { SessionFragment } from "graphql/fragments";
import { tw } from "utils/tw";

interface Props {
  session: SessionFragment;
}

export default ({ session }: Props): JSX.Element => {
  const headerStyles = tw(
    "bg-gray-900",
    "p-4",
    "flex",
    "flex-col",
    "space-y-4",
    "md:flex-row",
    "md:space-y-0",
    "md:space-x-8"
  );

  return (
    <div className={headerStyles}>
      <p className={tw("text-primary")}>
        Logged in as admin: {session.administrator}
      </p>

      <p className={tw("text-white")}>User: {session.user.email}</p>
    </div>
  );
};
