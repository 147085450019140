import { TFunction } from "i18next";

import { Badge } from "components/common/basic";
import { InvoiceStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  status: InvoiceStatusEnum;
  showStatus?: boolean;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  status,
  showStatus,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const statusMessage = showStatus
    ? getTranslatableInvoiceStatus(status, t)
    : ((): string => {
        switch (status) {
          case InvoiceStatusEnum.Approved:
            return t(
              "invoiceStatusMessage.approved",
              "Waiting for publication"
            );
          case InvoiceStatusEnum.Draft:
            return t("invoiceStatusMessage.draft", "Draft");
          case InvoiceStatusEnum.Published:
            return t("invoiceStatusMessage.published", "Published");
          case InvoiceStatusEnum.Rejected:
            return t("invoiceStatusMessage.rejected", "Unable to process");
          case InvoiceStatusEnum.Resolved:
            return t("invoiceStatusMessage.resolved", "Your client has paid");
          case InvoiceStatusEnum.Submitted:
            return t("invoiceStatusMessage.submitted", "Waiting for approval");
          case InvoiceStatusEnum.Nullified:
            return t("invoiceStatusMessage.nullified", "Invoice nullified");

          default:
            return ((_status: never) => "")(status);
        }
      })();

  const badgeVariant = (() => {
    switch (status) {
      case InvoiceStatusEnum.Draft:
      case InvoiceStatusEnum.Submitted:
      case InvoiceStatusEnum.Published:
        return "pending";

      case InvoiceStatusEnum.Approved:
        return "success";

      case InvoiceStatusEnum.Resolved:
      case InvoiceStatusEnum.Nullified:
        return "warning";

      case InvoiceStatusEnum.Rejected:
        return "error";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "text-xs", "font-bold", "text-deepBlue-900")}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};

export const getTranslatableInvoiceStatus = (
  status: InvoiceStatusEnum,
  t: TFunction
): string => {
  switch (status) {
    case InvoiceStatusEnum.Approved:
      return t("invoiceStatus.approved", status);
    case InvoiceStatusEnum.Draft:
      return t("invoiceStatus.draft", status);
    case InvoiceStatusEnum.Published:
      return t("invoiceStatus.published", status);
    case InvoiceStatusEnum.Rejected:
      return t("invoiceStatus.rejected", status);
    case InvoiceStatusEnum.Resolved:
      return t("invoiceStatus.resolved", status);
    case InvoiceStatusEnum.Submitted:
      return t("invoiceStatus.submitted", status);
    case InvoiceStatusEnum.Nullified:
      return t("invoiceStatus.nullified", status);

    default:
      return ((_status: never) => "")(status);
  }
};
