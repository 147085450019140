/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CLIENT_FRAGMENT,
  ClientFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { UpdateClientInput } from "graphql/types";

const UPDATE_CLIENT = gql`
  mutation ($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        ...ClientFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  client?: ClientFragment | null;
  errors: ErrorFragment[];
}

interface UpdateClientMutation {
  updateClient: Payload | null;
}

export const useUpdateClientMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateClientMutation,
    { input: UpdateClientInput }
  >(UPDATE_CLIENT);

  const updateClient = (
    input: UpdateClientInput,
    onSuccess?: (client: ClientFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateClient?.errors ?? [];
        const client = data?.updateClient?.client;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => client && onSuccess && onSuccess(client),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateClient,
    isLoading: loading,
    client: data?.updateClient?.client,
    errors: data?.updateClient?.errors ?? [],
  };
};
