import { icon } from "utils/brand";
import { tw } from "utils/tw";

interface Props {
  size?: "sm" | "md";
}

export default ({ size = "md" }: Props) => {
  const small = tw("h-8", "w-8");
  const medium = tw("h-24", "w-24");

  const spinnerStyles = tw("animate-spin", {
    [small as string]: size === "sm",
    [medium as string]: size === "md",
  });

  return (
    <div className={tw("relative")} data-testid="spinner-component">
      {size === "md" && (
        <img
          className={tw("absolute", "h-12", "w-12", "top-6", "left-6")}
          src={icon}
          alt="logo icon"
        />
      )}
      <svg
        className={spinnerStyles}
        viewBox="0 0 220 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="110" cy="110" r="102" stroke="#222222" strokeWidth="15" />
        <path
          d="M212 110C212 53.667 166.333 8 110 8"
          stroke="#E6E8EB"
          strokeWidth="15"
        />
      </svg>
    </div>
  );
};
