/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_REQUEST_FRAGMENT,
  CheckRequestFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const DESTROY_CHECK_REQUEST_FILE = gql`
  mutation ($id: ID!, $fileId: String!) {
    destroyCheckRequestFile(input: { id: $id, fileId: $fileId }) {
      checkRequest {
        ...CheckRequestFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkRequest: CheckRequestFragment[];
  errors: ErrorFragment[];
}

interface DestroyCheckRequestFileMutation {
  destroyCheckRequestFile: Payload | null;
}

export const useDestroyCheckRequestFileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DestroyCheckRequestFileMutation,
    { id: string; fileId: string }
  >(DESTROY_CHECK_REQUEST_FILE);

  const destroyCheckRequestFile = (
    id: string,
    fileId: string,
    onSuccess?: () => void
  ) =>
    mutation({
      variables: { id, fileId },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyCheckRequestFile?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyCheckRequestFile,
    isLoading: loading,
    errors: data?.destroyCheckRequestFile?.errors ?? [],
  };
};
