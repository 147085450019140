/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { SESSION, SessionQuery } from "graphql/queries";
import { OnboardInput } from "graphql/types";

const ONBOARD = gql`
  mutation ($input: OnboardInput!) {
    onboard(input: $input) {
      onboarded
    }
  }
`;

interface Payload {
  onboarded: boolean;
}

export interface OnboardMutation {
  onboard: Payload | null;
}

export const useOnboardMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    OnboardMutation,
    { input: OnboardInput }
  >(ONBOARD);

  const onboard = (input = {}, onSuccess?: () => void) =>
    mutation({
      variables: { input },
      update(cache, { data: updatedData }) {
        const onboarded = updatedData?.onboard?.onboarded;
        if (typeof onboarded === "undefined") return;

        cache.modify({
          fields: {
            session() {
              const sessionQuery = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!sessionQuery) return;

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...sessionQuery.session,
                    needsOnboarding: !onboarded,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ errors }) => {
        handleErrors({
          dataErrors: [],
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    onboarded: data?.onboard?.onboarded,
    onboard,
    isLoading: loading,
  };
};
