import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";

import { AccountStyles, Button, Spinner } from "components/common/basic";
import { Page } from "components/common/wrappers";
import { WbMessageInput, WbReportDetails } from "components/tellmore/composite";
import { usePublicWbReportLazyQuery } from "graphql/queries";
import { WbReportStatusEnum, WbReportTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { atDateToMoment, storage, useRoutePaths } from "utils";
import { tw } from "utils/tw";

import EmptyState from "./EmptyState";

enum WbSessionErrorEnum {
  missing = "MISSING_SESSION",
  invalid = "INVALID_SESSION",
  expired = "EXPIRED_SESSION",
}

interface WbSession {
  accessCode: string;
  reportNumber: string;
  createdAt: string;
}

export default (): JSX.Element => {
  const { wbSlug = "" } = useParams<{
    wbSlug: string;
  }>();
  const [errorMessage, setErrorMessage] = useState("");
  const [secondsLeft, setSecondsLeft] = useState<number>();
  const [wbSession, setWbSession] = useState<WbSession>();

  const { wbReport, getWbReport, isLoading, called } =
    usePublicWbReportLazyQuery();

  const { t } = useTranslation("tellmore");
  const getRoutePath = useRoutePaths();
  const navigate = useNavigate();

  const wbLoginRoute = getRoutePath(
    {
      module: "tellmore",
      routeName: "WB_LOGIN",
      arg1: wbSlug,
    },
    { isGeneric: true }
  );

  const wbReportSession = storage.getSession("wbReport");
  const refreshWbSession = () => {
    if (!wbSession) return;

    const createdAt = moment().utc().format("YYYY-MM-DD HH:mm:ss UTC");
    storage.setSession(
      "wbReport",
      JSON.stringify({
        accessCode: wbSession.accessCode,
        reportNumber: wbSession.reportNumber,
        createdAt,
      })
    );

    setTimerFromCreationDate(createdAt);
  };

  const setTimerFromCreationDate = (createdAt: string) => {
    const timeOfCreation = atDateToMoment(createdAt);
    const timeOfExpiry = timeOfCreation.add(5, "minutes");
    const timeNow = moment.utc();
    const secondsToExpiry = timeOfExpiry.diff(timeNow, "seconds");
    if (secondsToExpiry < 0) return setErrorMessage(WbSessionErrorEnum.expired);

    setSecondsLeft(secondsToExpiry);
  };

  const wbReportLogout = () => {
    storage.removeSession("wbReport");

    navigate(wbLoginRoute);
  };

  // Get session variables
  useEffect(() => {
    if (!wbReportSession) return setErrorMessage(WbSessionErrorEnum.missing);

    try {
      const wbSession: WbSession = JSON.parse(wbReportSession);
      const isValid =
        Object.prototype.hasOwnProperty.call(wbSession, "accessCode") &&
        Object.prototype.hasOwnProperty.call(wbSession, "reportNumber") &&
        Object.prototype.hasOwnProperty.call(wbSession, "createdAt");
      if (!isValid) setErrorMessage(WbSessionErrorEnum.invalid);

      setTimerFromCreationDate(wbSession.createdAt);
      setWbSession(wbSession);
    } catch (error) {
      setErrorMessage(WbSessionErrorEnum.invalid);
    }
  }, []);

  // Get wbReport
  useEffect(() => {
    if (!wbSession) return;

    getWbReport({
      wbSlug,
      accessCode: wbSession.accessCode,
      reportNumber: wbSession.reportNumber,
    });
  }, [wbSession]);

  // Check expiration
  useEffect(() => {
    if (typeof secondsLeft === "undefined") return;
    if (secondsLeft < 0) return setErrorMessage(WbSessionErrorEnum.expired);

    const countdown = setInterval(() => setSecondsLeft(secondsLeft - 1), 1000);

    return () => clearInterval(countdown);
  }, [secondsLeft]);

  const showMessageInput = wbReport
    ? [WbReportStatusEnum.Submitted, WbReportStatusEnum.InProgress].includes(
        wbReport.status
      )
    : false;

  return (
    <>
      {wbReport?.account?.theme && (
        <AccountStyles theme={wbReport.account.theme} />
      )}

      <Page title={t("publicWbReport.pageTitle", "Report")}>
        {errorMessage ? (
          <EmptyState />
        ) : !wbSession || !called || isLoading ? (
          <Spinner />
        ) : !wbReport ? (
          <EmptyState />
        ) : (
          <div
            className={tw("mx-auto", "max-w-screen-lg", "w-full", "space-y-12")}
          >
            {wbReport.account.logo && (
              <img
                src={`${process.env.REACT_APP_API_URL}${wbReport.account.logo.thumbPath}`}
                className={tw(
                  "align-middle",
                  "border-none",
                  "max-h-20",
                  "object-contain",
                  "object-left"
                )}
              />
            )}

            <div className={tw("space-y-6")}>
              <div
                className={tw(
                  "flex",
                  "flex-col",
                  "items-start",
                  "gap-4",
                  "w-full",
                  "mb-4",
                  "lg:flex-row",
                  "lg:justify-between"
                )}
              >
                <h1
                  className={tw("text-2xl", "font-extrabold", "text-gray-900")}
                >
                  {wbReport.type === WbReportTypeEnum.Feedback
                    ? t("publicWbReport_feedback.heading", "Feedback")
                    : t("publicWbReport_wbReport.heading", "Whistleblowing")}
                </h1>

                <div className={tw("flex", "flex-col", "space-y-1")}>
                  <Button
                    id="wb_report-logout"
                    variant="secondary"
                    size="sm"
                    onClick={wbReportLogout}
                  >
                    {t("publicWbReport.logout", "Log out")}
                  </Button>

                  {typeof secondsLeft === "number" && secondsLeft > -1 && (
                    <div className={tw("flex")}>
                      <p className={tw("text-xs", "text-deepBlue-500")}>
                        {t("publicWbReport.expiration.label", "Expires in:")}
                      </p>

                      <p
                        className={tw(
                          "w-10",
                          "text-right",
                          "text-xs",
                          "text-deepBlue-500"
                        )}
                      >
                        {moment(secondsLeft * 1000).format("mm:ss")}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <WbReportDetails type="whistleblower" wbReport={wbReport} />
            </div>

            {showMessageInput && (
              <div
                className={tw(
                  "w-screen",
                  "flex",
                  "justify-center",
                  "fixed",
                  "bottom-0",
                  "left-0"
                )}
              >
                <WbMessageInput
                  maxWidth="max-w-screen-lg"
                  type="whistleblower"
                  wbSlug={wbSlug}
                  accessCode={wbSession.accessCode}
                  reportNumber={wbSession.reportNumber}
                  onInput={refreshWbSession}
                  onMessageSent={refreshWbSession}
                />
              </div>
            )}
          </div>
        )}

        {/* Space for expanded textarea */}
        {showMessageInput && <span className={tw("h-40", "md:h-108")} />}
      </Page>
    </>
  );
};
