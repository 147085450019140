/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const SUBMIT_DOCUMENT = gql`
  mutation ($id: ID!) {
    submitDocument(input: { id: $id }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface SubmitDocumentMutation {
  submitDocument: Payload | null;
}

export const useSubmitDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.submittedDocument",
    "Document was submitted successfully"
  );
  // Values for extraction
  // t("common:success.submittedDocument", "Document was submitted successfully")

  const [mutation, { data, loading, called }] = useMutation<
    SubmitDocumentMutation,
    { id: string }
  >(SUBMIT_DOCUMENT);

  const submitDocument = (
    id: string,
    onSuccess?: (response: DocumentFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitDocument?.errors ?? [];
        const document = data?.submitDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => submitDocument(id, onSuccess),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    submitDocument,
    isLoading: loading,
    called,
    document: data?.submitDocument?.document,
    errors: data?.submitDocument?.errors ?? [],
  };
};
