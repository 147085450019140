/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { CHECK_LIST_FRAGMENT, CheckListFragment } from "graphql/fragments";
import { QueryReceivedCheckListsArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const RECEIVED_CHECK_LISTS = gql`
  query ($page: Int, $perPage: Int, $filter: CheckListsFilter) {
    receivedCheckLists(page: $page, perPage: $perPage, filter: $filter) {
      exportPath
      items {
        ...CheckListFragment
      }
      total
    }
  }
  ${CHECK_LIST_FRAGMENT}
`;

export type ReceivedCheckListsQuery = {
  receivedCheckLists: PaginatedPayload<CheckListFragment>;
};

type QueryArgs = Pick<
  QueryReceivedCheckListsArgs,
  "page" | "perPage" | "filter"
>;

export const useReceivedCheckListsQuery = (args: QueryArgs = {}) => {
  const {
    data,
    called,
    loading: isLoading,
  } = useQuery<ReceivedCheckListsQuery, QueryArgs>(RECEIVED_CHECK_LISTS, {
    variables: { ...args },
  });

  const checkLists = data?.receivedCheckLists.items?.filter(Boolean) ?? [];

  return { checkLists, called, isLoading };
};

export const useReceivedCheckListsLazyQuery = () => {
  const [getCheckListsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<ReceivedCheckListsQuery, QueryArgs>(RECEIVED_CHECK_LISTS, {
      fetchPolicy: "network-only",
    });

  const checkLists = data?.receivedCheckLists.items?.filter(Boolean) ?? [];
  const total = data?.receivedCheckLists.total ?? 0;

  const resolveRef = useRef<(checkLists?: CheckListFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(checkLists);
      resolveRef.current = undefined;
    }
  }, [checkLists, called, isLoading]);

  const getCheckLists = async (args?: QueryArgs) => {
    const variables = { ...args };

    getCheckListsQuery({ variables });

    return new Promise<CheckListFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getCheckLists,
    checkLists,
    total,
    isLoading,
  };
};
