import { gql } from "@apollo/client";

import { PricePlan } from "graphql/types";

export const PRICE_PLAN_FRAGMENT = gql`
  fragment PricePlanFragment on PricePlan {
    id
    feature
    name
    advertised
    enterprise
    nonProfit
    limit
    employeeLimit
    price
  }
`;

export interface PricePlanFragment
  extends Pick<
    PricePlan,
    | "id"
    | "feature"
    | "name"
    | "advertised"
    | "enterprise"
    | "nonProfit"
    | "limit"
    | "employeeLimit"
    | "price"
  > {}
