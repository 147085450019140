import { tw } from "utils/tw";

interface Props {
  heading: JSX.Element;
  content: JSX.Element;
}
export default ({ heading, content }: Props): JSX.Element => {
  const topPaddingStyles = tw("pt-6", "md:pt-12");
  const horizontalPaddingStyles = tw("px-4", "md:px-8", "lg:px-10");
  const bottomPaddingStyles = tw("pb-12");

  return (
    <>
      <div
        className={tw(topPaddingStyles, horizontalPaddingStyles, "bg-silver")}
      >
        {heading}
      </div>

      <div className={tw(bottomPaddingStyles, "bg-white")}>
        <div className={tw("relative")}>
          <div className={tw("absolute", "inset-0", "h-32", "bg-silver")} />

          <div className={tw(horizontalPaddingStyles, "relative")}>
            {content}
          </div>
        </div>
      </div>
    </>
  );
};
