import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  Merge,
  UseFormRegister,
} from "react-hook-form";

import { Button, Input, Select } from "components/common/basic";
import { TWidth } from "tw-generated";
import { tw } from "utils/tw";

import DateInput from "../DateInput";
import { InputType } from "./InputCell";

interface ItemBase extends FieldValues {
  id: string;
}

interface Props<T> {
  itemLabel: string;
  name: string;
  columns: {
    key: string;
    label: string;
    width: TWidth;
    input: InputType;
  }[];
  fields: Record<string, string>[];
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  removeItem: (rowIndex: number) => void;
  removeItemLabel: string;
  errors: Merge<FieldError, FieldErrors<T[]>>;
  minItems: number;
}

export default <T extends ItemBase>({
  itemLabel,
  name,
  columns,
  fields,
  control,
  register,
  removeItem,
  removeItemLabel,
  errors,
  minItems,
}: Props<T>): JSX.Element => {
  return (
    <div className={tw("w-full", "space-y-8")}>
      {fields.map((field, rowIndex) => {
        const rowErrors = errors?.[rowIndex];

        return (
          <div
            className={tw(
              "w-full",
              "p-6",
              "border",
              "border-deepBlue-100",
              "rounded-lg",
              "flex",
              "flex-col",
              "space-y-8"
            )}
            key={field.id}
          >
            <h2 className={tw("text-lg", "font-bold")}>{itemLabel}</h2>

            {columns.map((column) => {
              const value = Object.prototype.hasOwnProperty.call(
                field,
                column.key
              )
                ? field[column.key as keyof typeof field]
                : "";

              const error =
                rowErrors &&
                Object.prototype.hasOwnProperty.call(rowErrors, column.key) &&
                rowErrors[column.key as keyof typeof rowErrors];
              const errorMessage = (error as FieldError)?.message;

              const id = `${name}.${rowIndex}.${column.key}`;
              const options =
                column.input.type === "select"
                  ? column.input.options.map((option) => ({
                      key: String(option.key ?? option.value),
                      value: option.value,
                      label: option.label,
                      isDisabled: option.isDisabled,
                    }))
                  : [];

              switch (column.input.type) {
                case "text":
                case "email":
                case "phone":
                  return (
                    <Input
                      key={id}
                      id={id}
                      label={column.label}
                      {...register(id)}
                      defaultValue={value}
                      placeholder={column.input.placeholder}
                      errorMessage={errorMessage}
                    />
                  );

                case "date":
                  return (
                    <Controller
                      key={id}
                      control={control}
                      name={id}
                      defaultValue={value}
                      render={({ field: dateField }) => (
                        <DateInput
                          id={dateField.name}
                          name={dateField.name}
                          label={column.label}
                          defaultValue={dateField.value ?? undefined}
                          onChange={(date) =>
                            dateField.onChange(date.format("YYYY-MM-DD"))
                          }
                          errorMessage={errorMessage}
                        />
                      )}
                    />
                  );

                case "select":
                  return (
                    <Controller
                      key={id}
                      control={control}
                      name={id}
                      defaultValue={
                        options.some((option) => option.value === value)
                          ? value
                          : options[0].value
                      }
                      render={({ field: selectField }) => (
                        <Select
                          id={selectField.name}
                          name={selectField.name}
                          label={column.label}
                          options={options}
                          value={selectField.value ?? undefined}
                          onChange={selectField.onChange}
                          errorMessage={errorMessage}
                        />
                      )}
                    />
                  );

                default:
                  return ((_type: never) => null)(column.input);
              }
            })}

            {fields.length > minItems && (
              <span>
                <Button
                  id="input_table-remove_item"
                  variant="tertiary"
                  size="sm"
                  onClick={
                    fields.length > minItems
                      ? () => removeItem(rowIndex)
                      : undefined
                  }
                >
                  {removeItemLabel}
                </Button>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
