export const snakeCaseToCamelCase = (str: string) =>
  str.split("_").reduce((acc, str) => {
    if (!acc.length) return str.toLowerCase();

    return acc + str[0] + str.slice(1).toLowerCase();
  }, "");

export const snakeCaseToSeparated = (str: string) =>
  str.split("_").reduce((acc, str) => {
    if (!acc.length) return str[0] + str.slice(1).toLowerCase();

    return `${acc} ${str.toLowerCase()}`;
  }, "");
