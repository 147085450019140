/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { PRICE_PLAN_FRAGMENT, PricePlanFragment } from "graphql/fragments";
import { QueryPricePlansArgs } from "graphql/types";

import { PaginatedPayload } from "./shared/types";

export const PRICE_PLANS = gql`
  query ($page: Int, $perPage: Int, $filter: PricePlansFilter) {
    pricePlans(page: $page, perPage: $perPage, filter: $filter) {
      exportPath
      items {
        ...PricePlanFragment
      }
      total
    }
  }
  ${PRICE_PLAN_FRAGMENT}
`;

export type PricePlansQuery = {
  pricePlans: PaginatedPayload<PricePlanFragment>;
};

type QueryArgs = Pick<QueryPricePlansArgs, "page" | "perPage" | "filter">;

export const usePricePlansQuery = (args: QueryArgs) => {
  const {
    data,
    loading: isLoading,
    called,
  } = useQuery<PricePlansQuery, QueryArgs>(PRICE_PLANS, {
    variables: { ...args },
  });

  const pricePlans = data?.pricePlans.items?.filter(Boolean) ?? [];
  const total = data?.pricePlans.total ?? 0;

  return { pricePlans, total, isLoading, called };
};

export const usePricePlansLazyQuery = () => {
  const [getPricePlansQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PricePlansQuery, QueryArgs>(PRICE_PLANS, {
      fetchPolicy: "network-only",
    });

  const pricePlans = data?.pricePlans.items?.filter(Boolean) ?? [];
  const total = data?.pricePlans.total ?? 0;

  const resolveRef = useRef<(pricePlans?: PricePlanFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(pricePlans);
      resolveRef.current = undefined;
    }
  }, [pricePlans, called, isLoading]);

  const getPricePlans = async (args: QueryArgs) => {
    const variables = { ...args };

    getPricePlansQuery({ variables });

    return new Promise<PricePlanFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getPricePlans, pricePlans, total, isLoading };
};
