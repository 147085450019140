import { lazy } from "react";

const CreateDocumentWizard = lazy(() => import("./CreateDocumentWizard"));
const Document = lazy(() => import("./Document"));
const Documents = lazy(() => import("./Documents"));
const PublicDocument = lazy(() => import("./PublicDocument"));
const SignDocumentWizard = lazy(() => import("./SignDocumentWizard"));
const SignatureTestPage = lazy(() => import("./SignatureTestPage"));

export {
  CreateDocumentWizard,
  Document,
  Documents,
  PublicDocument,
  SignDocumentWizard,
  SignatureTestPage,
};
