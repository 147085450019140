/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import {
  REFERENCE_PERSON_FRAGMENT,
  ReferencePersonFragment,
} from "graphql/fragments";
import {
  FreelanceProfile,
  FreelanceTypeEnum,
  QueryNodeArgs,
} from "graphql/types";

const FREELANCE_PROFILE_REFERENCE_PEOPLE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on FreelanceProfile {
        freelanceType
        referencePeople {
          ...ReferencePersonFragment
        }
      }
    }
  }
  ${REFERENCE_PERSON_FRAGMENT}
`;

interface SimplifiedFreelanceProfile
  extends Pick<FreelanceProfile, "freelanceType" | "referencePeople"> {
  referencePeople: ReferencePersonFragment[];
}

export type FreelanceProfileReferencePeopleQuery = {
  node: SimplifiedFreelanceProfile;
};

export const useFreelanceProfileReferencePeopleLazyQuery = () => {
  const [getFreelanceProfileReferencePeopleQuery, { data, loading, called }] =
    useLazyQuery<FreelanceProfileReferencePeopleQuery, QueryNodeArgs>(
      FREELANCE_PROFILE_REFERENCE_PEOPLE
    );

  const resolveRef =
    useRef<(freelanceProfile?: SimplifiedFreelanceProfile) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getReferencePeople = async (id: string) => {
    getFreelanceProfileReferencePeopleQuery({ variables: { id } });

    return new Promise<SimplifiedFreelanceProfile | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const isIndividual =
    data?.node.freelanceType === FreelanceTypeEnum.Individual;

  return {
    getReferencePeople,
    isIndividual,
    referencePeople: data?.node.referencePeople ?? [],
    isLoading: loading,
  };
};
