import { ClientFragment } from "graphql/fragments";
import { ClientTypeEnum } from "graphql/types";

import CreateIndClient from "./CreateIndClient";
import CreateOrgClient from "./CreateOrgClient";

interface Props {
  formId: string;
  client: ClientFragment;
  onClose: () => void;
  onSuccess?: (client: ClientFragment) => void;
}

export default ({ formId, client, onClose, onSuccess }: Props): JSX.Element => {
  const isOrg = client.clientType === ClientTypeEnum.Organization;

  if (isOrg)
    return (
      <CreateOrgClient
        formId={formId}
        onSuccessfulEdit={onSuccess ?? onClose}
        client={client}
      />
    );

  return (
    <CreateIndClient
      formId={formId}
      onSuccessfulEdit={onSuccess ?? onClose}
      client={client}
    />
  );
};
