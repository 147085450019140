import { TFunction } from "i18next";

import { AccountTagEnum, PricePlanEnum } from "graphql/types";

interface Plan {
  plan: PricePlanEnum;
  label: string;
  candidates: number;
  teamUsers: number;
  price: number;
}

export default (tag: AccountTagEnum, t: TFunction): Plan[] => {
  const premiumPlan = {
    candidates: -1,
    teamUsers: -1,
    price: -1,
  };

  const nonProfitStarter = {
    candidates: 10,
    teamUsers: 1,
    price: 1245,
  };
  const nonProfitPluss = {
    candidates: 50,
    teamUsers: 3,
    price: 6225,
  };
  const nonProfitPro = {
    candidates: 100,
    teamUsers: 6,
    price: 12450,
  };

  const forProfitStarter = {
    candidates: 10,
    teamUsers: 1,
    price: 2490,
  };
  const forProfitPluss = {
    candidates: 50,
    teamUsers: 3,
    price: 12450,
  };
  const forProfitPro = {
    candidates: 100,
    teamUsers: 6,
    price: 24900,
  };

  switch (tag) {
    case AccountTagEnum.MarchingBand:
      return [
        {
          plan: PricePlanEnum.MarchingBandSmall,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_marchingBand1.label",
            "Marching band S"
          ),
          candidates: 5,
          teamUsers: 1,
          price: 1295,
        },
        {
          plan: PricePlanEnum.MarchingBandMedium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_marchingBand2.label",
            "Marching band M"
          ),
          candidates: 10,
          teamUsers: 2,
          price: 1495,
        },
        {
          plan: PricePlanEnum.MarchingBandLarge,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_marchingBand3.label",
            "Marching band L"
          ),
          candidates: 25,
          teamUsers: 2,
          price: 1995,
        },
      ];

    // Non-profit plans
    case AccountTagEnum.Sports:
      return [
        {
          plan: PricePlanEnum.SportsStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_sportsClub1.label",
            "Starter - sports"
          ),
          ...nonProfitStarter,
        },
        {
          plan: PricePlanEnum.SportsPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_sportsClub2.label",
            "Pluss - sports"
          ),
          ...nonProfitPluss,
        },
        {
          plan: PricePlanEnum.SportsPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_sportsClub3.label",
            "Pro - sports"
          ),
          ...nonProfitPro,
        },
        {
          plan: PricePlanEnum.SportsPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_sportsClub4.label",
            "Premium - sports"
          ),
          ...premiumPlan,
        },
      ];
    case AccountTagEnum.Religious:
      return [
        {
          plan: PricePlanEnum.ReligiousStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_religious1.label",
            "Starter - religious"
          ),
          ...nonProfitStarter,
        },
        {
          plan: PricePlanEnum.ReligiousPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_religious2.label",
            "Pluss - religious"
          ),
          ...nonProfitPluss,
        },
        {
          plan: PricePlanEnum.ReligiousPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_religious3.label",
            "Pro - religious"
          ),
          ...nonProfitPro,
        },
        {
          plan: PricePlanEnum.ReligiousPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_religious4.label",
            "Premium - religious"
          ),
          ...premiumPlan,
        },
      ];
    case AccountTagEnum.Volunteer:
      return [
        {
          plan: PricePlanEnum.VolunteerStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_volunteer1.label",
            "Starter - volunteer"
          ),
          ...nonProfitStarter,
        },
        {
          plan: PricePlanEnum.VolunteerPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_volunteer2.label",
            "Pluss - volunteer"
          ),
          ...nonProfitPluss,
        },
        {
          plan: PricePlanEnum.VolunteerPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_volunteer3.label",
            "Pro - volunteer"
          ),
          ...nonProfitPro,
        },
        {
          plan: PricePlanEnum.VolunteerPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_volunteer4.label",
            "Premium - volunteer"
          ),
          ...premiumPlan,
        },
      ];

    // For profit plans
    case AccountTagEnum.Generic:
      return [
        {
          plan: PricePlanEnum.Starter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_generic1.label",
            "Starter - generic"
          ),
          ...forProfitStarter,
        },
        {
          plan: PricePlanEnum.Pluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_generic2.label",
            "Pluss - generic"
          ),
          ...forProfitPluss,
        },
        {
          plan: PricePlanEnum.Pro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_generic3.label",
            "Pro - generic"
          ),
          ...forProfitPro,
        },
        {
          plan: PricePlanEnum.Premium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_generic4.label",
            "Premium - generic"
          ),
          ...premiumPlan,
        },
      ];
    case AccountTagEnum.HealthServiceYouth:
      return [
        {
          plan: PricePlanEnum.HealthServiceYouthStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_healthServiceYouth1.label",
            "Starter - healthServiceYouth"
          ),
          ...forProfitStarter,
        },
        {
          plan: PricePlanEnum.HealthServiceYouthPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_healthServiceYouth2.label",
            "Pluss - healthServiceYouth"
          ),
          ...forProfitPluss,
        },
        {
          plan: PricePlanEnum.HealthServiceYouthPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_healthServiceYouth3.label",
            "Pro - healthServiceYouth"
          ),
          ...forProfitPro,
        },
        {
          plan: PricePlanEnum.HealthServiceYouthPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_healthServiceYouth4.label",
            "Premium - healthServiceYouth"
          ),
          ...premiumPlan,
        },
      ];
    case AccountTagEnum.Kindergarten:
      return [
        {
          plan: PricePlanEnum.KindergartenStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergarten1.label",
            "Starter - kindergarten"
          ),
          ...forProfitStarter,
        },
        {
          plan: PricePlanEnum.KindergartenPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergarten2.label",
            "Pluss - kindergarten"
          ),
          ...forProfitPluss,
        },
        {
          plan: PricePlanEnum.KindergartenPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergarten3.label",
            "Pro - kindergarten"
          ),
          ...forProfitPro,
        },
        {
          plan: PricePlanEnum.KindergartenPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergarten4.label",
            "Premium - kindergarten"
          ),
          ...premiumPlan,
        },
      ];
    case AccountTagEnum.KindergartenAndSchool:
      return [
        {
          plan: PricePlanEnum.KindergartenAndSchoolStarter,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergartenAndSchool1.label",
            "Starter - kindergartenAndSchool"
          ),
          ...forProfitStarter,
        },
        {
          plan: PricePlanEnum.KindergartenAndSchoolPluss,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergartenAndSchool2.label",
            "Pluss - kindergartenAndSchool"
          ),
          ...forProfitPluss,
        },
        {
          plan: PricePlanEnum.KindergartenAndSchoolPro,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergartenAndSchool3.label",
            "Pro - kindergartenAndSchool"
          ),
          ...forProfitPro,
        },
        {
          plan: PricePlanEnum.KindergartenAndSchoolPremium,
          label: t(
            "common:accountSettings_org.paymentPlan.plan_kindergartenAndSchool4.label",
            "Premium - kindergartenAndSchool"
          ),
          ...premiumPlan,
        },
      ];

    default:
      return [];
  }
};
