/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PUBLIC_WB_REPORT_FRAGMENT,
  PublicWbReportFragment,
} from "graphql/fragments";
import { WbMessageAttributes } from "graphql/types";

const PUBLIC_CREATE_WB_MESSAGE = gql`
  mutation (
    $wbSlug: String!
    $accessCode: String!
    $reportNumber: String!
    $attributes: WbMessageAttributes!
  ) {
    publicCreateWbMessage(
      input: {
        wbSlug: $wbSlug
        accessCode: $accessCode
        reportNumber: $reportNumber
        attributes: $attributes
      }
    ) {
      wbReport {
        ...PublicWbReportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PUBLIC_WB_REPORT_FRAGMENT}
`;

interface Payload {
  wbReport?: PublicWbReportFragment | null;
  errors: ErrorFragment[];
}

interface PublicCreateWbMessageMutation {
  publicCreateWbMessage: Payload | null;
}

export const usePublicCreateWbMessageMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    PublicCreateWbMessageMutation,
    {
      wbSlug: string;
      accessCode: string;
      reportNumber: string;
      attributes: WbMessageAttributes;
    }
  >(PUBLIC_CREATE_WB_MESSAGE);

  const publicCreateWbMessage = (
    variables: {
      wbSlug: string;
      accessCode: string;
      reportNumber: string;
      attributes: WbMessageAttributes;
    },
    onSuccess?: (wbReport: PublicWbReportFragment) => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.publicCreateWbMessage?.errors ?? [];
        const wbReport = data?.publicCreateWbMessage?.wbReport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && wbReport && onSuccess(wbReport),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    publicCreateWbMessage,
    isLoading: loading,
    wbReport: data?.publicCreateWbMessage?.wbReport,
    errors: data?.publicCreateWbMessage?.errors ?? [],
  };
};
