/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { DeleteAccountInput } from "graphql/types";

const DELETE_ACCOUNT = gql`
  mutation ($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface DeleteAccountMutation {
  deleteAccount: Payload | null;
}

export const useDeleteAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    DeleteAccountMutation,
    { input: DeleteAccountInput }
  >(DELETE_ACCOUNT);

  const deleteAccount = (
    input: DeleteAccountInput = {},
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.deleteAccount?.errors ?? [];
        const account = data?.deleteAccount?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    deleteAccount,
    isLoading: loading,
    account: data?.deleteAccount?.account,
    errors: data?.deleteAccount?.errors ?? [],
  };
};
