/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { DOCUMENTS, DocumentsQuery } from "graphql/queries";
import { DocumentsFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const ARCHIVE_DOCUMENTS = gql`
  mutation ($ids: [ID!], $filter: DocumentsFilter) {
    archiveDocuments(input: { ids: $ids, filter: $filter }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<DocumentsFilter>;

interface Payload {
  errors: ErrorFragment[];
}

interface ArchiveDocumentsMutation {
  archiveDocument: Payload | null;
}

export const useArchiveDocumentsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.archivedDocuments",
    "Documents were archived successfully"
  );
  // Values for extraction
  // t("common:success.archivedDocuments", "Documents were archived successfully")

  const [mutation, { data, loading, called }] = useMutation<
    ArchiveDocumentsMutation,
    Input
  >(ARCHIVE_DOCUMENTS);

  const archiveDocuments = (
    variables: Input,
    onSuccess?: () => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            documents() {
              const existingDocuments = cache.readQuery<DocumentsQuery>({
                query: DOCUMENTS,
              });
              if (!existingDocuments?.documents.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const ids = isSome ? (variables as { ids: string[] }).ids : [];

              const items = isSome
                ? existingDocuments.documents.items?.filter(
                    (doc) => !ids.includes(doc.id)
                  )
                : [];
              const total = isSome
                ? existingDocuments.documents.total - ids.length
                : 0;

              cache.writeQuery<DocumentsQuery>({
                query: DOCUMENTS,
                data: {
                  documents: {
                    items,
                    total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.archiveDocument?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => archiveDocuments(variables, onSuccess),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    archiveDocuments,
    isLoading: loading,
    called,
    errors: data?.archiveDocument?.errors ?? [],
  };
};
